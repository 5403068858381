import React, { useState } from 'react';
import { AnchorButton, NonIdealState, Dialog } from '@blueprintjs/core';

import { Card } from 'app/atoms/Card/Card';
import { LabelRow } from 'app/organisms/LabelRow/LabelRow';
import { useGetOrganizationLabelsQuery } from 'api/organizationLabelsApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { CardBody } from 'app/atoms/Card/Card';
import { LabelForm } from 'app/organisms/LabelForm/LabelForm';
import { AuthorizationRequiredTooltip } from 'app/molecules/AuthorizationRequiredTooltip/AuthorizationRequiredTooltip';

export const LabelSettings = () => {
  const [isCreatingLabel, setIsCreatingLabel] = useState(false);
  const { data: labels = [], isLoading } = useGetOrganizationLabelsQuery();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Card
        title="Labels"
        rightElement={
          <AuthorizationRequiredTooltip role="admin">
            <AnchorButton icon="plus" text="New Label" onClick={() => setIsCreatingLabel(true)} />
          </AuthorizationRequiredTooltip>
        }
      >
        {labels.length ? (
          labels.map(label => <LabelRow key={label.id} label={label} />)
        ) : (
          <CardBody>
            <NonIdealState
              icon="tag"
              title="No custom labels yet."
              description="Custom labels can help organize Workspaces and make search filtering easier for your organization."
            />
          </CardBody>
        )}
      </Card>
      <Dialog className="p-0" lazy isOpen={isCreatingLabel} onClose={() => setIsCreatingLabel(false)}>
        <LabelForm label={{}} onCancel={() => setIsCreatingLabel(false)} />
      </Dialog>
    </>
  );
};
