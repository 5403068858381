import React, { ComponentProps } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumbs as BlueprintBreadcrumbs, BreadcrumbProps as BlueprintBreadcrumbProps } from '@blueprintjs/core';
import { cn } from 'app/lib/cn';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';

export type BreadcrumbProps = BlueprintBreadcrumbProps & { to?: string };

type Props = {
  items?: BreadcrumbProps[];
  className?: string;
};

export const Breadcrumbs = ({ items = [], className }: Props) => {
  const { state } = useLocation();
  let crumbs = items;

  if (!crumbs.length && state?.breadcrumbs) {
    crumbs = [...state.breadcrumbs];
  }

  if (!crumbs?.length) {
    return null;
  }

  return (
    <BlueprintBreadcrumbs
      items={crumbs}
      className={cn('px-4 sm:px-0', className)}
      breadcrumbRenderer={({ text, ...attrs }) => {
        if (('to' in attrs && attrs.to) || attrs.href || attrs.onClick) {
          return (
            <LinkTag
              {...(attrs as ComponentProps<typeof LinkTag>)}
              overwriteClassName
              className="text-sm font-medium text-gray-500 no-underline hover:text-blue-600 dark:text-gray-400 dark:hover:text-blue-300 lg:text-base"
              data-test="breadcrumb"
            >
              {text}
            </LinkTag>
          );
        }

        return (
          <span className="text-sm font-medium text-gray-900 dark:text-gray-200 lg:text-base" data-test="breadcrumb">
            {text}
          </span>
        );
      }}
    />
  );
};
