import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@blueprintjs/core';
import isEmpty from 'lodash-es/isEmpty';

import { asCurrency } from 'app/lib/numbers';

const propTypes = {
  clin: PropTypes.object.isRequired
};

export const SouptoolsClinContractStatus = ({ clin }) => {
  const { onContractData } = clin;

  if (isEmpty(onContractData)) {
    return (
      <div>
        <small className="text-orange-600">
          <Icon icon="bookmark" intent="warning" />
          <strong>Contract Status Unknown</strong>
        </small>
      </div>
    );
  }

  const { status, sewpprice } = onContractData;

  let clinIntent = 'primary';

  if (['Invalid Price', 'CLIN Not Found', 'Excessive Discount'].includes(status)) {
    clinIntent = 'danger';
  }

  const intentClass = clinIntent === 'danger' ? 'text-red-600' : 'text-green-600';

  return (
    <div>
      {status === 'CLIN Not Found' ? (
        <small>
          <Icon icon="bookmark" intent={clinIntent} />
          <strong className={intentClass}>Not On Contract</strong>
        </small>
      ) : (
        <small>
          <Icon icon="bookmark" intent={clinIntent} />
          <strong className={intentClass}>On Contract</strong>
        </small>
      )}
      <>
        <Icon icon="dot" iconSize={12} />
        <small>
          <strong className={intentClass}>{status}</strong>
        </small>
      </>
      <>
        <Icon icon="dot" iconSize={12} />
        <small>
          <strong>{`On Contract Price: ${asCurrency(sewpprice)}`}</strong>
        </small>
      </>
    </div>
  );
};

SouptoolsClinContractStatus.propTypes = propTypes;
