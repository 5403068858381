import React from 'react';
import PropTypes from 'prop-types';

import {
  useCreateRecordResellerMutation,
  useUpdateRecordResellerMutation,
  useDeleteRecordResellerMutation,
  useGetResellersQuery
} from 'api/resellersApi';
import { VendorPartnerForm } from 'app/organisms/VendorPartnerForm/VendorPartnerForm';

export const VendorResellerForm = ({ vendorId, initialValues }) => (
  <VendorPartnerForm
    vendorId={vendorId}
    recordTitle="Reseller"
    initialValues={{ ...initialValues, channelPartnerableId: vendorId, channelPartnerableType: 'Vendor' }}
    useOnCreate={useCreateRecordResellerMutation}
    useOnUpdate={useUpdateRecordResellerMutation}
    useOnDelete={useDeleteRecordResellerMutation}
    useGetList={useGetResellersQuery}
  />
);

VendorResellerForm.propTypes = {
  vendorId: PropTypes.string.isRequired,
  initialValues: PropTypes.object
};
