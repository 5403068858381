import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { Button, Dialog, AnchorButton, Menu, MenuItem, Popover } from '@blueprintjs/core';

import { useCurrentUserAttribute } from 'api/currentUserApi';
import { useGetSavedSearchesQuery, useUpdateSavedSearchMutation } from 'api/savedSearchesApi';
import { useCreateSavedSearchFollowMutation, useDeleteSavedSearchFollowMutation } from 'api/savedSearchFollowsApi';
import { errorToast, successToast } from 'app/lib/toaster';
import { SavedSearchCreateForm } from 'app/organisms/SavedSearchCreateForm/SavedSearchCreateForm';
import { SavedSearchUpdateForm } from 'app/organisms/SavedSearchUpdateForm/SavedSearchUpdateForm';
import { defaultValues as contactIntitialValues } from 'app/hooks/search/useContactSearchCache';
import { defaultValues as awardInitialValues } from 'app/hooks/search/useAwardSearchCache';

export const SavedSearchControls = ({ searchableType }) => {
  const defaultState = searchableType === 'Contact' ? contactIntitialValues : awardInitialValues;
  const navigate = useNavigate();
  const {
    setValues,
    values: { query, filters, meta }
  } = useFormikContext();
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [templateSearchId, setTemplateSearchId] = useState(null);

  const routes = {
    Contact: { base: '/contacts', breadcrumb: 'Contacts' },
    USASpendingAward: { base: '/awards', breadcrumb: 'Awards' }
  };

  const selected = meta.savedSearchId;

  const currentOrgUserId = useCurrentUserAttribute('id');
  const [createSavedSearchFollow] = useCreateSavedSearchFollowMutation();
  const [deleteSavedSearchFollow] = useDeleteSavedSearchFollowMutation();
  const [updateSavedSearch] = useUpdateSavedSearchMutation();

  const { selectedSavedSearch, isLoading } = useGetSavedSearchesQuery(
    { active: true, searchableType },
    {
      selectFromResult: ({ data }) => ({
        selectedSavedSearch: data?.find(({ id }) => id === selected)
      })
    }
  );

  const toggleIsCreating = () => setIsCreating(!isCreating);
  const toggleIsEditing = () => setIsEditing(!isEditing);

  const viewAllSavedSearches = () => {
    navigate(`${routes[searchableType]?.base}/saved_searches`, {
      state: {
        breadcrumbs: [
          { to: routes[searchableType]?.base, text: routes[searchableType]?.breadcrumb },
          { text: 'Saved Searches' }
        ]
      }
    });
  };

  return (
    <>
      {selectedSavedSearch && (
        <>
          <Popover
            interactionKind="click"
            className="max-w-md"
            content={
              <Menu key="menu" large>
                {selectedSavedSearch.currentUserFollowing ? (
                  <MenuItem
                    icon="thumbs-down"
                    text="Unfollow"
                    onClick={async () => {
                      try {
                        await deleteSavedSearchFollow({
                          organizationUserId: currentOrgUserId,
                          savedSearchId: selected,
                          savedSearchType: 'SavedSearch'
                        }).unwrap();
                        successToast();
                      } catch (e) {
                        errorToast(e);
                      }
                    }}
                  />
                ) : (
                  <MenuItem
                    icon="thumbs-up"
                    text="Follow"
                    onClick={async () => {
                      try {
                        await createSavedSearchFollow({
                          organizationUserId: currentOrgUserId,
                          savedSearchId: selected,
                          savedSearchType: 'SavedSearch'
                        }).unwrap();

                        successToast();
                      } catch (e) {
                        errorToast(e);
                      }
                    }}
                  />
                )}
                <MenuItem icon="add" text="Create new" onClick={toggleIsCreating} />
                <MenuItem
                  icon="duplicate"
                  text="Duplicate"
                  onClick={() => {
                    setTemplateSearchId(selected);
                    toggleIsCreating();
                  }}
                />
                {selectedSavedSearch.currentUserFollowing && (
                  <MenuItem
                    icon="archive"
                    text="Archive"
                    onClick={async () => {
                      try {
                        await updateSavedSearch({
                          id: selected,
                          searchableType,
                          active: false
                        }).unwrap();

                        setValues(defaultState);
                        successToast();
                      } catch (e) {
                        errorToast(e);
                      }
                    }}
                  />
                )}
                <MenuItem icon="align-justify" text="View all saved searches" onClick={viewAllSavedSearches} />
              </Menu>
            }
          >
            <AnchorButton icon="more" large disabled={isLoading} />
          </Popover>
          <Button large disabled={isLoading} onClick={toggleIsEditing}>
            Update
          </Button>
        </>
      )}
      {!selected && (
        <>
          <Popover
            interactionKind="click"
            className="max-w-md"
            content={
              <Menu key="menu" large>
                <MenuItem icon="align-justify" text="View all saved searches" onClick={viewAllSavedSearches} />
              </Menu>
            }
          >
            <AnchorButton icon="more" large disabled={isLoading} />
          </Popover>
          <Button large onClick={toggleIsCreating} data-test="save-new-search-button">
            Save
          </Button>
        </>
      )}
      <Dialog
        isOpen={isCreating}
        onClose={() => setIsCreating(false)}
        className="min-w-[50%] bg-white p-0"
        title="Create a new saved search"
      >
        <SavedSearchCreateForm
          templateSavedSearchId={templateSearchId}
          searchableType={searchableType}
          onCancel={toggleIsCreating}
          handleClose={toggleIsCreating}
        />
      </Dialog>
      {selected && (
        <Dialog
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          className="min-w-[50%] bg-white p-0"
          title="Update saved search"
        >
          <SavedSearchUpdateForm
            savedSearchId={selected}
            searchableType={searchableType}
            onCancel={toggleIsEditing}
            handleClose={toggleIsEditing}
            initialQuery={query}
            initialFilters={filters}
          />
        </Dialog>
      )}
    </>
  );
};

SavedSearchControls.propTypes = {
  searchableType: PropTypes.string
};
