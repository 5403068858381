import React, { useState } from 'react';
import { Dialog, Tag } from '@blueprintjs/core';
import orderBy from 'lodash-es/orderBy';

import { useUpdateWorkspaceMutation, useCreateWorkspaceMutation } from 'api/workspacesApi';
import { WorkableForm } from 'app/organisms/WorkableForm/WorkableForm';
import { AvatarList } from 'app/molecules/AvatarList/AvatarList';
import { WorkspaceIndex, ContactIndex, USASpendingAwardIndex, OppIndex, OppShow } from 'types/__generated__/GovlyApi';

type WorkspaceFollowersProps = {
  formTitle?: string;
  workspace?: WorkspaceIndex;
  type?: string;
  resource?: ContactIndex | USASpendingAwardIndex | OppIndex | OppShow;
};

export const WorkspaceFollowers = ({ formTitle, workspace, type, resource }: WorkspaceFollowersProps) => {
  const [isAddingFollowers, setIsAddingFollowers] = useState(false);
  const [updateWorkspace, { isLoading: isUpdating }] = useUpdateWorkspaceMutation();
  const [createWorkspace, { isLoading: isCreating }] = useCreateWorkspaceMutation();
  const action = workspace?.id ? updateWorkspace : createWorkspace;

  const follows = workspace ? workspace.follows : [];
  const followers = orderBy(follows, ['stateInteger', 'organizationUser.organizationName', 'organizationUser.name'])
    .map(({ active, organizationUser, state }) => ({
      ...organizationUser,
      active,
      initials: organizationUser?.initials,
      followState: state
    }))
    .filter(({ followState }) => !['unfollowed', 'disinterested'].includes(followState));

  const dialog = (
    <Dialog onClose={() => setIsAddingFollowers(false)} isOpen={isAddingFollowers} className="min-w-[50%] p-0">
      <WorkableForm
        title={formTitle}
        workableType={type}
        onCancel={() => setIsAddingFollowers(false)}
        isSubmitting={isUpdating || isCreating}
        onSubmit={action}
        follows={follows}
        initialValues={{
          id: workspace ? workspace.id : undefined,
          workableType: workspace ? workspace.workableType : type,
          workableId: workspace ? workspace.workableId : resource?.id,
          organizationDefault: workspace ? workspace.organizationDefault : true
        }}
      />
    </Dialog>
  );

  if (followers.length) {
    return (
      <>
        <AvatarList avatarData={followers} onEdit={() => setIsAddingFollowers(true)} />
        {dialog}
      </>
    );
  } else {
    return (
      <div>
        <Tag minimal round interactive icon="plus" onClick={() => setIsAddingFollowers(true)}>
          Add followers
        </Tag>
        {dialog}
      </div>
    );
  }
};
