import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from '@blueprintjs/core';
import { CSVLink } from 'react-csv';
import startCase from 'lodash-es/startCase';
import { LineChart } from 'react-chartkick';

import { Card, CardBody } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { OppSearchAnalyticsFallback } from 'app/organisms/OppSearchAnalyticsFallback/OppSearchAnalyticsFallback';
import { useSearchMutation } from 'api/oppsApi';

const chartOptions = {
  xAxis: {
    type: 'datetime'
  },
  tooltip: {
    shared: true
  }
};

const slices = [
  { key: 'feedAgg', label: 'Feeds' },
  { key: 'noticeTypeAgg', label: 'Notice Types' },
  { key: 'setAsidesAgg', label: 'Set Asides' }
];

export const OppAggregationPostedAt = ({ query, filters, title = 'Posted Over Time', fixedCacheKey }) => {
  const [filterType, setFilterType] = useState(null);
  const { dateRangeParam } = filters;

  let aggType = 'posted_at_agg';

  if (dateRangeParam === 'modified_at') {
    aggType = 'modified_at_agg';
  }

  if (dateRangeParam === 'respond_by') {
    aggType = 'respond_by_agg';
  }

  const payload = { query, ...filters, aggs: [aggType], aggregationQuery: true };

  const [aggregate, resp] = useSearchMutation({ fixedCacheKey });

  const { data: { aggs } = {}, isLoading, isError } = resp;

  useEffect(() => {
    aggregate(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let data = [];
  let csv;

  const agg = aggs?.postedAtAgg || aggs?.modifiedAtAgg || aggs?.respondByAgg;

  if (isError) {
    return <OppSearchAnalyticsFallback />;
  }

  if (!agg || isLoading) {
    return <Loading />;
  }

  if (!filterType) {
    csv = 'Date,Count';
    data = agg.buckets.map(({ keyAsString, docCount }) => {
      const point = [keyAsString, docCount];
      csv += `\n${point.join(',')}`;
      return point;
    });
  } else {
    csv = 'Date,Count,Slice';
    const aggMap = {};

    agg.buckets.forEach(bucket => {
      const dateKey = bucket.keyAsString;
      const subAgg = bucket[filterType];
      subAgg.buckets.forEach(({ key, docCount }) => {
        aggMap[key] = { ...aggMap[key], [dateKey]: docCount };
        csv += `\n${dateKey},${docCount},${key}`;
      });
    });

    data = Object.entries(aggMap).map(([name, d]) => ({ name: startCase(name), data: d }));
  }

  return (
    <Card
      className="mb-4"
      title={title}
      rightElement={
        !!csv.length && (
          <CSVLink data={csv} filename={`govly-posted-at-results.csv`} className="text-gray-400 no-underline">
            <Button outlined icon="download">
              Download Data
            </Button>
          </CSVLink>
        )
      }
    >
      <CardBody>
        <LineChart data={data} library={chartOptions} />
      </CardBody>
      <CardBody className="text-center">
        <ButtonGroup>
          <Button active={!filterType} onClick={() => setFilterType(null)} text="No Slice" />
          {slices.map(({ key, label }) => (
            <Button key={key} active={filterType === key} onClick={() => setFilterType(key)} text={label} />
          ))}
        </ButtonGroup>
      </CardBody>
    </Card>
  );
};

OppAggregationPostedAt.propTypes = {
  filters: PropTypes.object.isRequired,
  query: PropTypes.string.isRequired,
  title: PropTypes.string,
  fixedCacheKey: PropTypes.string
};
