import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Button, Callout } from '@blueprintjs/core';

import { cn } from 'app/lib/cn';
import { Loading } from 'app/atoms/Loading/Loading';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { CenterPageHighlight } from 'app/molecules/CenterPageHighlight/CenterPageHighlight';
import { useAddOnboardingInfoMutation } from 'api/onboardingApi';
import { formErrorToast } from 'app/lib/toaster';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { OnboardingFormInfoCompany } from 'app/organisms/OnboardingFormInfoCompany/OnboardingFormInfoCompany';
import { OnboardingFormInfoContact } from 'app/organisms/OnboardingFormInfoContact/OnboardingFormInfoContact';
import { OnboardingFormInfoGovExperience } from 'app/organisms/OnboardingFormInfoGovExperience/OnboardingFormInfoGovExperience';
import { OnboardingFormInfoCVs } from 'app/organisms/OnboardingFormInfoCVs/OnboardingFormInfoCVs';
import { OnboardingFormInfoInvitations } from 'app/organisms/OnboardingFormInfoInvitations/OnboardingFormInfoInvitations';
import { onboardingSteps } from 'app/lib/onboardingSteps';
import { useEventTracking } from 'app/hooks/useEventTracking';

export const OnboardingForm = () => {
  const { trackEvent } = useEventTracking();
  const [addOnboardingInfo, { isLoading: isSubmitting }] = useAddOnboardingInfoMutation();
  const { data: currentUser, isLoading } = useGetCurrentUserQuery();
  const { onboardingStep, onboardingInfo, organization, role } = currentUser || {};
  const userHasName = currentUser?.name != null;

  const initialValues = {
    firstName: onboardingInfo?.firstName || '',
    lastName: onboardingInfo?.lastName || '',
    organizationName: onboardingInfo?.organizationName || organization?.name,
    organizationWebsite:
      onboardingInfo?.organizationWebsite || organization?.primaryDomain || organization?.approvedDomains?.[0],
    organizationType: onboardingInfo?.organizationType || [],
    organizationSize: onboardingInfo?.organizationSize || '',
    jobTitle: onboardingInfo?.jobTitle || '',
    phoneNumber: onboardingInfo?.phoneNumber || '',
    team: onboardingInfo?.team || '',
    teamSize: onboardingInfo?.teamSize || '',
    governmentExperience: onboardingInfo?.governmentExperience || '',
    contractVehicles: onboardingInfo?.contractVehicles || [],
    interestedInCustomContractVehicle: onboardingInfo?.interestedInCustomContractVehicle || false,
    invitations: onboardingInfo?.invitations || []
  };
  const [step, setStep] = useState('start');
  const [steps, setSteps] = useState(onboardingSteps.adminFlow);

  useEffect(() => {
    if (onboardingStep) setStep(onboardingStep);

    if (role === 'member') setSteps(onboardingSteps.memberFlow);
    if (!userHasName) setSteps(onboardingSteps.inviteeFlow);
  }, [isLoading, role, userHasName, onboardingStep]);

  const handleBackClick = ({ setErrors }) => {
    const prevStep = steps[step].prev;
    setStep(prevStep);
    if (prevStep) {
      setErrors({});
      setStep(prevStep);
    }
  };

  const handleSubmit = async values => {
    const nextStep = steps[step].next;

    try {
      trackEvent({
        object: 'onboarding_step',
        action: 'completed',
        properties: {
          step,
          nextStep,
          stepNumber: steps[step].number,
          stepCount: Object.keys(steps).length
        }
      });
      await addOnboardingInfo({ ...values, onboardingStep: nextStep });
      setStep(nextStep);
    } catch (e) {
      formErrorToast(e);
    }
  };

  if (isLoading)
    return (
      <CenterPageHighlight showLogo>
        <Loading />
      </CenterPageHighlight>
    );

  let start = <OnboardingFormInfoCompany />;
  if (role === 'member') start = <OnboardingFormInfoContact />;
  if (!userHasName) start = <OnboardingFormInfoContact showNames />;

  return (
    <CenterPageHighlight showLogo>
      <Formik
        enableReinitialize
        validationSchema={steps[step]?.schema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ isValid, setErrors }) => {
          if (step === 'completed') return <Navigate to="/opportunities" replace={true} />;

          const progress = (steps[step]?.number / Object.keys(steps).length) * 100;
          return (
            <Form>
              <div className="relative top-1.5 h-1.5 rounded-t bg-gray-200">
                <div
                  className={cn('h-1.5 overflow-hidden rounded-tl bg-blue-500', {
                    'rounded-tr': progress === 100
                  })}
                  style={{ width: `${progress}%` }}
                />
              </div>
              <Card title={steps[step]?.title}>
                <CardBody>
                  {steps[step]?.number === 1 && (
                    <Callout intent="primary" title="We just have a few questions before we get started" />
                  )}
                  {step === 'start' && start}
                  {step === 'contactInfo' && <OnboardingFormInfoContact />}
                  {step === 'governmentExperience' && <OnboardingFormInfoGovExperience />}
                  {step === 'contractVehicles' && <OnboardingFormInfoCVs />}
                  {step === 'invitations' && <OnboardingFormInfoInvitations />}
                </CardBody>
                <CardFooter>
                  <Button type="submit" large intent="primary" loading={isSubmitting} disabled={isValid === false}>
                    {step === 'invitations' ? 'Submit' : 'Next'}
                  </Button>
                  {steps[step]?.prev && (
                    <Button large intent="primary" onClick={() => handleBackClick({ setErrors })}>
                      Back
                    </Button>
                  )}
                </CardFooter>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </CenterPageHighlight>
  );
};
