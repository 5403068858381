import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@blueprintjs/core';

import { useCreateOppInteractionMutation } from 'api/oppInteractionsApi';
import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { useEventTracking } from 'app/hooks/useEventTracking';

const propTypes = {
  id: PropTypes.string.isRequired,
  interaction: PropTypes.object,
  isLoading: PropTypes.bool
};

export const OppSearchFollowButton = ({ id, interaction, isLoading }) => {
  const { isMobile } = useDeviceWidth();
  const { trackEvent } = useEventTracking();
  const [interact, { isLoading: isInteracting }] = useCreateOppInteractionMutation();

  return (
    <Button
      disabled={isLoading || isInteracting}
      icon="thumbs-up"
      text={isMobile ? '' : interaction?.followedAt ? 'Following' : 'Follow'}
      loading={isInteracting}
      intent={interaction?.followedAt ? 'success' : null}
      onClick={async () => {
        const followed = !interaction?.followedAt;
        await interact({ oppId: id, followed });
        trackEvent({
          object: 'opp',
          action: followed ? 'followed' : 'unfollowed',
          properties: {
            oppId: id
          }
        });
      }}
      data-test="follow-button"
    />
  );
};

OppSearchFollowButton.propTypes = propTypes;
