import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from '@blueprintjs/core';

import { useGetOrganizationLabelsQuery } from 'api/organizationLabelsApi';
import { LabelTag } from 'app/organisms/LabelTag/LabelTag';

const propTypes = {
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired
    })
  ),
  labelIds: PropTypes.arrayOf(PropTypes.string),
  onAdd: PropTypes.func
};

export const LabelList = ({ labels, labelIds, onAdd }) => {
  const { data: organizationLabels = [], isLoading: labelsLoading } = useGetOrganizationLabelsQuery(
    {},
    {
      skip: !labelIds || !!labels?.length
    }
  );

  let displayLabels = labels || [];

  if (labelIds) {
    displayLabels = organizationLabels.filter(({ id }) => labelIds.includes(id));
  }

  if (!displayLabels?.length && !onAdd) {
    return null;
  }

  return (
    <div className="flex flex-wrap gap-2 w-fit">
      {displayLabels.map(({ id, name, color }) => (
        <LabelTag key={`tagging-${id}`} name={name} color={color} />
      ))}
      {onAdd && (
        <Tag minimal round interactive icon="plus" onClick={onAdd} disabled={labelsLoading}>
          Add labels
        </Tag>
      )}
    </div>
  );
};

LabelList.propTypes = propTypes;
