import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Callout } from '@blueprintjs/core';

import { useOppSearchResults } from 'api/oppsApi';
import { useGetOrganizationUsersQuery } from 'api/organizationUsersApi';
import { useGetSavedSearchesQuery, useUpdateSavedSearchMutation } from 'api/savedSearchesApi';
import { Card, CardFooter, CardBody } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { successToast } from 'app/lib/toaster';
import { FollowerSelectInput } from 'app/organisms/FollowerSelectInput/FollowerSelectInput';
import { useEventTracking } from 'app/hooks/useEventTracking';

export const SavedSearchUpdateForm = ({
  savedSearchId,
  searchableType,
  onCancel,
  followersOnly,
  handleClose,
  initialQuery,
  initialFilters = {},
  ...rest
}) => {
  const { trackEvent } = useEventTracking();
  const { isLoading: isSearching } = useOppSearchResults();
  const { savedSearch = {}, isLoading: savedSearchesLoading } = useGetSavedSearchesQuery(
    { active: true, searchableType },
    {
      selectFromResult: ({ data }) => ({
        savedSearch: data?.find(({ id }) => id === savedSearchId)
      })
    }
  );

  const { data: organizationUsers = [], isLoading: organizationUsersLoading } = useGetOrganizationUsersQuery({
    view: 'current_org'
  });

  const [updateSavedSearch, { isLoading: isUpdating }] = useUpdateSavedSearchMutation();

  const form = {
    onSubmit: async (values, { setErrors }) => {
      try {
        const { follows = [], ...rest } = values;
        const persistedFollowIds = savedSearch.follows.map(({ id }) => id);
        const updatedFollowIds = follows.map(({ id }) => id);
        const removedFollows = persistedFollowIds
          .filter(id => !updatedFollowIds.includes(id))
          .map(id => ({ id, _destroy: '1' }));

        const followsAttributes = follows.map(follow => ({
          id: follow.id,
          state: follow.state,
          notifications: follow.notifications,
          organizationUserId: follow.organizationUserId
        }));

        let payload = {
          id: rest.id,
          followsAttributes: [...followsAttributes, ...removedFollows],
          searchableType
        };
        if (!followersOnly) payload = { ...payload, ...rest };
        await updateSavedSearch(payload).unwrap();
        const { query, setAsDefault, name } = values;
        trackEvent({
          object: `${searchableType.toLowerCase()}_saved_search`,
          action: 'updated',
          properties: {
            savedSearchId,
            ...query,
            setAsDefault,
            name,
            followersOnly,
            follows: follows?.map(f => f?.organizationUser?.email)
          }
        });

        successToast();
        handleClose();
      } catch (e) {
        const { data: { errors = {} } = {} } = e;
        setErrors(errors);
      }
    },
    initialValues: {
      id: savedSearchId,
      name: savedSearch.name || '',
      setAsDefault: savedSearch.isCurrentUserDefault || false,
      follows: savedSearch.follows,
      query:
        (savedSearch.currentUserCanEdit ? { query: initialQuery || '', ...initialFilters } : savedSearch.query) || ''
    },
    validationSchema: Yup.object({
      name: Yup.string().max(80, 'Must be 80 characters or less').required('Please give a name to your saved search')
    })
  };

  if (savedSearchesLoading || organizationUsersLoading || isSearching) {
    return <Loading />;
  }

  return (
    <div {...rest}>
      <Formik {...form}>
        <Form>
          <Card>
            <CardBody>
              {!followersOnly && (
                <>
                  <Callout intent="warning" title="Are you sure you want to update this search?">
                    <p>
                      All changes to filters and search terms will be saved. You will not be able to undo this action.
                    </p>
                  </Callout>
                  <TextInput
                    name="name"
                    label="Saved Search Name"
                    inputProps={{ placeholder: 'My Saved Search', large: true }}
                  />
                </>
              )}
              <FollowerSelectInput
                name="follows"
                label="Followers"
                defaultButtonText="Add a Teammate"
                organizationUsers={organizationUsers}
                disabled={isUpdating}
                hideFollowSettings
              />
            </CardBody>
            <CardFooter>
              <Button large type="submit" text="Save" intent="primary" loading={isUpdating} />
              {onCancel && <Button text="Cancel" disabled={isUpdating} onClick={onCancel} />}
            </CardFooter>
          </Card>
        </Form>
      </Formik>
    </div>
  );
};

SavedSearchUpdateForm.propTypes = {
  savedSearchId: PropTypes.string.isRequired,
  searchableType: PropTypes.string,
  onCancel: PropTypes.func,
  handleClose: PropTypes.func,
  followersOnly: PropTypes.bool,
  initialQuery: PropTypes.string,
  initialFilters: PropTypes.object
};
