import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { cn } from 'app/lib/cn';
import { trixAutoLink } from 'app/lib/trixAutoLink';
import { setupMentions } from 'app/lib/trixMentions';
import { FormGroup } from '@blueprintjs/core';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { EditorToolbar } from 'app/molecules/Editor/EditorToolbar';

const propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  trixEditorProps: PropTypes.object,
  trixToolbarProps: PropTypes.object,
  inputProps: PropTypes.object,
  formGroupProps: PropTypes.object,
  error: PropTypes.bool,
  notifiableUsers: PropTypes.array,
  setMentions: PropTypes.func,
  hideUploads: PropTypes.bool
};

export const Editor = forwardRef(
  (
    {
      id,
      onChange,
      inputProps = {},
      trixEditorProps = {},
      trixToolbarProps = {},
      error,
      notifiableUsers,
      setMentions,
      formGroupProps = {},
      hideUploads = false
    },
    ref
  ) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const editorRef = ref || useRef();
    const { data: currentUser = {} } = useGetCurrentUserQuery();

    useLayoutEffect(() => {
      const currRef = editorRef.current;
      if (inputProps.value) currRef.value = inputProps.value;
      currRef.addEventListener('trix-initialize', event => trixAutoLink(event));
      if (setMentions) currRef.addEventListener('trix-initialize', event => setupMentions(event, setMentions));
      if (currentUser?.compliancePreventUploads || hideUploads) {
        currRef.addEventListener('trix-initialize', () =>
          document.querySelector('.trix-button-group--file-tools')?.remove()
        );
        currRef.addEventListener('trix-file-accept', e => e.preventDefault());
      }

      return () => {
        if (notifiableUsers?.length > 0) currRef.removeEventListener('trix-initialize', setupMentions);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setupMentions, trixAutoLink, editorRef, currentUser]);

    useEffect(() => {
      const currRef = editorRef.current;
      currRef.addEventListener('trix-change', onChange);
      if (setMentions) editorRef.current.dataset.mentionables = JSON.stringify(notifiableUsers); // accessed in trixMentions

      return () => {
        currRef.removeEventListener('trix-change', onChange);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChange, editorRef]);

    return (
      <FormGroup {...formGroupProps}>
        <div className="prose prose-sm" data-test="editor">
          <trix-toolbar id={`${id}-toolbar`} {...trixToolbarProps}>
            <EditorToolbar />
          </trix-toolbar>
          <input type="hidden" id={id} name="content" {...inputProps} />
          <trix-editor
            rows="20"
            toolbar={`${id}-toolbar`}
            class={cn(
              'mt-1 block w-full rounded-md border border-gray-300 p-4 shadow-sm outline-none focus:border-2 focus:border-blue-300 focus:ring-blue-300 sm:text-sm',
              { 'border-red-500': error }
            )}
            input={id}
            ref={editorRef}
            data-direct-upload-url="/rails/active_storage/direct_uploads"
            data-blob-url-template="/rails/active_storage/blobs/proxy/:signed_id/:filename"
            {...trixEditorProps}
          />
        </div>
      </FormGroup>
    );
  }
);

Editor.displayName = 'Editor';
Editor.propTypes = propTypes;
