import Appsignal from '@appsignal/javascript';
import { plugin as pluginWindowEvents } from '@appsignal/plugin-window-events';
import { plugin as pluginPathDecorator } from '@appsignal/plugin-path-decorator';
import { plugin as pluginBreadcrumbsNetwork } from '@appsignal/plugin-breadcrumbs-network';

const appsignal = new Appsignal({
  key: process.env.APP_SIGNAL_KEY,
  revision: process.env.APP_REVISION,
  ignoreErrors: [/ResizeObserver/]
});

appsignal.use(pluginPathDecorator());
appsignal.use(pluginBreadcrumbsNetwork());
appsignal.use(pluginWindowEvents());

export { appsignal };
