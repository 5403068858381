import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@blueprintjs/core';
import { useSearchParams } from 'react-router-dom';

import { useCurrentUserAttribute } from 'api/currentUserApi';
import { useUpdateWorkspaceMutation, useCreateWorkspaceMutation } from 'api/workspacesApi';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { OppWorkspacePreview } from 'app/organisms/OppWorkspacePreview/OppWorkspacePreview';
import { WorkspaceForm } from 'app/organisms/WorkspaceForm/WorkspaceForm';
import { openDrawerOrNewTab } from 'app/lib/navigation';

const propTypes = {
  opp: PropTypes.object,
  action: PropTypes.object,
  setAction: PropTypes.func.isRequired,
  hideWorkspaces: PropTypes.bool
};

export const OppSearchWorkspaces = ({ opp, action, setAction, hideWorkspaces }) => {
  const [_searchParams, setSearchParams] = useSearchParams();
  const organizationName = useCurrentUserAttribute('organizationName');
  const [updateDefaultWorkspace, { isLoading: isUpdating }] = useUpdateWorkspaceMutation();
  const [createWorkspace, { isLoading: isCreating }] = useCreateWorkspaceMutation();

  if (!opp) return null;

  const { workspaces = [] } = opp;

  const defaultWorkspace = workspaces.find(({ organizationDefault }) => organizationDefault) || {};

  const openWorkspace = (e, workspaceId) => {
    const path = `/opportunities/${opp.id}/workspaces/${workspaceId}`;

    openDrawerOrNewTab(e, path, () =>
      setSearchParams({
        drawerType: 'workspaces',
        drawerId: workspaceId
      })
    );
  };

  return (
    <>
      {!hideWorkspaces && workspaces.length > 0 && (
        <div className="flex flex-col gap-y-4">
          {workspaces.map(workspace => {
            const { id: workspaceId, follows } = workspace;
            const displayableFollows = follows.filter(({ state }) => state !== 'unfollowed');

            return (
              <Card key={workspaceId}>
                <CardBody className="flex space-y-0 flex-wrap gap-y-2 justify-between items-center">
                  <OppWorkspacePreview
                    workspace={workspace}
                    onClick={e => openWorkspace(e, workspaceId)}
                    displayableFollows={displayableFollows}
                    headerClass="flex flex-col gap-y-2 items-start"
                    followsClass="flex flex-col gap-y-2 items-start sm:items-end mt-2"
                  />
                </CardBody>
              </Card>
            );
          })}
        </div>
      )}

      <Dialog isOpen={action.isUpdatingWorkspace} className="min-w-[50%] p-0">
        <WorkspaceForm
          title={action.createWorkspace ? 'Create a Workspace' : 'Default Workspace'}
          onCancel={() => setAction({})}
          isSubmitting={isUpdating || isCreating}
          onSubmit={defaultWorkspace.id && !action.createWorkspace ? updateDefaultWorkspace : createWorkspace}
          follows={action.createWorkspace ? [] : defaultWorkspace.follows || []}
          basicInputs={action.createWorkspace}
          followersInputs={true}
          stageInputs={true}
          trackingData={{ event: action.createWorkspace ? 'Opp Workspace Created' : null }}
          workableTypeForLink="opportunities"
          initialValues={{
            id: defaultWorkspace.id,
            name: action.createWorkspace ? '' : defaultWorkspace.name || organizationName,
            organizationDefault: !action.createWorkspace,
            oppId: opp.id,
            workflowStage: action.createWorkspace ? 'qualifying' : defaultWorkspace.workflowStage ?? 'qualifying'
          }}
          enableReinitialize
        />
      </Dialog>
    </>
  );
};

OppSearchWorkspaces.propTypes = propTypes;
