export const marketingRoutes = ['/vendors'];

export const noNavbarRoutes = [
  '/sign_in',
  '/sign_in/verify',
  '/register',
  '/loading',
  '/browser_warning',
  '/onboarding'
];

export const noFooterRoutes = [
  '/opportunities',
  '/awards',
  '/awards/analytics',
  '/awards/results',
  '/contacts',
  '/opportunities/analytics',
  '/opportunities/debug'
];

export const userOverrideRoutes = ['/vendors'];
