import React from 'react';
import { Comment } from 'app/molecules/Comment/Comment';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { deleteToast } from 'app/lib/toaster';
import {
  useUpdateOppWorkspaceCommentMutation,
  useDeleteOppWorkspaceCommentMutation
} from 'api/oppWorkspaceCommentsApi';
import { CommentShow } from 'types/__generated__/GovlyApi';
import { OppWorkspaceCommentForm } from './OppWorkspaceCommentForm';

export const OppWorkspaceComment = ({ comment, oppId }: { comment: CommentShow; oppId: string }) => {
  const currentUserId = useCurrentUserAttribute('id');

  const [updateOppWorkspaceComment, { isLoading: isUpdating }] = useUpdateOppWorkspaceCommentMutation();
  const [deleteOppWorkspaceComment] = useDeleteOppWorkspaceCommentMutation();

  return (
    <Comment
      key={comment.id}
      comment={comment}
      isAuthor={comment.organizationUserId === currentUserId}
      onRemove={() => {
        deleteOppWorkspaceComment({ id: comment.id });
        deleteToast('Message deleted.');
      }}
      editor={
        <OppWorkspaceCommentForm
          onSubmit={updateOppWorkspaceComment}
          comment={comment}
          oppId={oppId}
          workspaceId={comment.commentableId}
          isLoading={isUpdating}
        />
      }
    />
  );
};
