import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Button } from '@blueprintjs/core';
import truncate from 'lodash-es/truncate';
import DOMPurify from 'dompurify';

import { cn } from 'app/lib/cn';

const propTypes = {
  description: PropTypes.string.isRequired
};

export const OppDetailsDescription = ({ description }) => {
  const [showAll, setShowAll] = useState(false);
  if (!description) return null;

  let sanitized = DOMPurify.sanitize(`${description}`);
  sanitized = sanitized.replaceAll(/\n{2,}/g, '\n');
  const hasHTML = /<\/?[a-z][\s\S]*>/i.test(sanitized);

  if (!sanitized || !sanitized.length) {
    return null;
  }

  return (
    <div className={cn('sm:gap-4 px-5 py-2 sm:col-span-2')}>
      <dt className="text-sm font-medium leading-6">Description</dt>
      <dd className="pt-4 text-sm leading-6 sm:col-span-2 sm:mt-0">
        {showAll || hasHTML ? (
          <>
            {hasHTML ? (
              <div className="prose prose-sm">
                <div dangerouslySetInnerHTML={{ __html: sanitized }} />
              </div>
            ) : (
              <Collapse isOpen={showAll}>
                <div className="prose prose-sm whitespace-pre-line">{sanitized}</div>
              </Collapse>
            )}
          </>
        ) : (
          <span className="prose prose-sm whitespace-pre-line">
            {truncate(sanitized, {
              length: 1000
            })}
          </span>
        )}
        {sanitized.length > 1000 && !hasHTML && (
          <div className="my-4 flex justify-center">
            <Button outlined small intent="primary" onClick={() => setShowAll(!showAll)}>
              {showAll ? 'Show Less' : 'Show All'}
            </Button>
          </div>
        )}
      </dd>
    </div>
  );
};

OppDetailsDescription.propTypes = propTypes;
