import React, { useCallback, useMemo, useState } from 'react';
import { useReward } from 'react-rewards';
import { AnchorButton, Menu, MenuItem, Popover, Tooltip } from '@blueprintjs/core';
import { useSearchParams } from 'react-router-dom';

import { NewTabTooltip } from 'app/molecules/NewTabTooltip/NewTabTooltip';
import { avatarListColumn } from 'app/lib/tables';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { OppWorkspaceHeader } from 'app/organisms/OppWorkspaceHeader/OppWorkspaceHeader';
import { TableColumnHeader } from 'app/templates/ReactTableTemplate/ReactTableTemplate';
import { OppExpirationStatus } from 'app/organisms/_Opp/OppExpirationStatus';
import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { successToast, errorToast } from 'app/lib/toaster';
import { OppUpdateTags } from 'app/molecules/OppUpdateTags/OppUpdateTags';
import { openDrawerOrNewTab } from 'app/lib/navigation';
import { LabelList } from 'app/organisms/LabelList/LabelList';
import { WorkflowStageTag } from 'app/molecules/WorkflowStageTag/WorkflowStageTag';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { useGetOrganizationUsersQuery } from 'api/organizationUsersApi';
import { useGetWorklistQuery } from 'api/worklistApi';
import { useBulkUpdateOppWorkspacesMutation } from 'api/oppWorkspacesApi';
import { SetAsideTag } from 'app/organisms/SetAsideTag/SetAsideTag';

export const useWorklist = () => {
  const { isMobile, isTablet } = useDeviceWidth();
  const [workspaceFormAttrs, setWorkspaceFormAttrs] = useState(null);
  const [_searchParams, setSearchParams] = useSearchParams();
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterInTransition, setFilterTransition] = useState(false);
  const [workspaceFilter, setWorkspaceFilter] = useState('active');

  const [bulkUpdateOppWorkspaces, { isLoading: isUpdatingWorklistState }] = useBulkUpdateOppWorkspacesMutation();
  const { data: viewer = {} } = useGetCurrentUserQuery();
  const { data: organizationUsers = [], isLoading: teamLoading } = useGetOrganizationUsersQuery({
    view: 'current_org'
  });

  const team = useMemo(
    () => [
      viewer,
      ...organizationUsers.filter(
        organizationUser => organizationUser.primaryRole !== 'govly_support' && organizationUser.id !== viewer.id
      )
    ],
    [organizationUsers, viewer]
  );

  const [worklistOwnerId, setWorklistOwnerId] = useState(viewer.id);
  const viewingOwnWorklist = viewer.id === worklistOwnerId;

  const worklistQueryParams = { filter: workspaceFilter };
  if (!viewingOwnWorklist) {
    worklistQueryParams.worklistOwnerId = worklistOwnerId;
  }

  const {
    data: workspaces = [],
    isLoading: workspacesLoading,
    isFetching: workspacesFetching
  } = useGetWorklistQuery(worklistQueryParams, { refetchOnMountOrArgChange: true });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hiddenColumns = useMemo(() => {
    return getHiddenColumns({ viewerId: viewer.id, worklistOwnerId: worklistOwnerId, isMobile, isTablet });
  });

  const handleRowSelection = useCallback(
    selectedRows => {
      setSelectedRows(selectedRows.map(row => row.original.id));
    },
    [setSelectedRows]
  );

  const { reward: updateReward } = useReward('worklist-tab', 'confetti', {
    angle: 45
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateWorkspace = useCallback(updateWorkspaceCallback({ bulkUpdateOppWorkspaces, updateReward }), []);

  const columns = useMemo(
    () => getColumns({ workspaceFilter, setWorkspaceFormAttrs, setSearchParams, updateWorkspace, isMobile, isTablet }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workspacesFetching, workspaces, isMobile, isTablet]
  );

  return {
    columns,
    hiddenColumns,
    updateReward,
    worklistOwnerId,
    setWorklistOwnerId,
    team,
    teamLoading,
    setWorkspaceFormAttrs,
    workspaceFormAttrs,
    selectedRows,
    handleRowSelection,
    filterInTransition,
    setFilterTransition,
    setWorkspaceFilter,
    workspacesFetching,
    workspacesLoading,
    isUpdatingWorklistState,
    workspaceFilter,
    workspaces,
    viewer,
    bulkUpdateOppWorkspaces,
    viewingOwnWorklist,
    onActiveFilter: workspaceFilter === 'active',
    onInactiveFilter: workspaceFilter === 'inactive'
  };
};

const updateWorkspaceCallback = ({ bulkUpdateOppWorkspaces, updateReward }) => {
  return async payload => {
    try {
      await bulkUpdateOppWorkspaces(payload);

      if (payload.removeFromWorklist) {
        updateReward();
      } else {
        successToast();
      }
    } catch (e) {
      errorToast(e);
    }
  };
};

const labelsFromRow = row => {
  const set = new Set();
  const allLabels = row.original.labels;
  return allLabels.filter(l => !set.has(l.id) && set.add(l.id));
};

const filterWorkspaces = (rows, columnIds, filterValue) => {
  if (!filterValue) return rows;
  const query = filterValue.toLowerCase().trim();

  return rows.filter(row => {
    const { displayName, title, setAside } = row.original.oppData;

    if (columnIds.includes('name')) {
      const candidates = [row.original.name, displayName];
      return candidates.some(candidate => candidate?.toLowerCase()?.includes(query));
    }

    if (columnIds.includes('oppData.title')) {
      let setAsidesString;

      if (setAside) {
        setAsidesString = `${setAside.code} ${setAside.name}`;
      }

      const candidates = [title, displayName, setAsidesString];
      return candidates.some(candidate => candidate?.toLowerCase()?.includes(query));
    }
  });
};

const filterLabels = (rows, _columnIds, filterValue) => {
  if (!filterValue) return rows;
  const query = filterValue.toLowerCase().trim();

  return rows.filter(row => {
    const labels = labelsFromRow(row);
    return labels.some(label => label.name.toLowerCase().includes(query));
  });
};

const filterWorkflowStage = (rows, _columnIds, filterValue) => {
  if (!filterValue) return rows;
  const query = filterValue.toLowerCase().trim();
  const decoratedQuery = query.split(' ').join('_');

  return rows.filter(row => {
    const stage = row.original.workflowStage.toLowerCase();
    return stage.includes(query) || stage.includes(decoratedQuery);
  });
};

const getHiddenColumns = ({ viewerId, worklistOwnerId, isMobile, isTablet }) => {
  const hiddenActions = viewerId !== worklistOwnerId ? ['actions'] : [];
  if (isMobile) return [...hiddenActions, 'workflowStage', 'oppData.title'];
  if (isTablet) return [...hiddenActions, 'oppData.title'];

  return hiddenActions;
};

const getColumns = ({
  setSearchParams,
  workspaceFilter,
  setWorkspaceFormAttrs,
  updateWorkspace,
  isMobile,
  isTablet
}) => {
  return [
    {
      Header: 'Opportunity',
      HeaderComponent: React.memo(TableColumnHeader),
      accessor: 'oppData.title',
      colWidth: '15rem',
      Cell: e => (
        <div className="flex flex-col gap-1">
          <LinkTag
            to={`/opportunities/${e.row.original.oppData.id}`}
            tag="a"
            onClick={event => {
              openDrawerOrNewTab(event, `/opportunities/${e.row.original.workableId}`, () =>
                setSearchParams({ drawerType: 'opportunities', drawerId: e.row.original.workableId })
              );
            }}
            className=" font-medium no-underline"
            data-test="opportunity-title"
          >
            <NewTabTooltip title="View Opportunity">
              <dt className="whitespace-normal text-base font-semibold">{e.row.original.oppData.title}</dt>
            </NewTabTooltip>
          </LinkTag>
          <dt className="text-sm font-semibold text-gray-500">
            <OppUpdateTags updates={e.row.original.updates} expiringSoon={e.row.original.expiringSoon} />
            <Tooltip lazy content={e.row.original.oppData.displayName} className="w-full">
              <div className="w-full truncate">{e.row.original.oppData.displayName}</div>
            </Tooltip>
          </dt>

          <dt>
            <SetAsideTag code={e.row.original.oppData.setAside?.code} />
          </dt>
        </div>
      ),
      sortType: 'basic',
      filter: filterWorkspaces,
      textFilter: true
    },
    {
      Header: 'Name',
      HeaderComponent: React.memo(TableColumnHeader),
      accessor: 'name',
      // TODO we are using a custom name `colWidth` because `width` has a default set to 150 which messes with
      // all the other existing tables. Keeping these changes isolated for now.
      colWidth: '13rem',
      Cell: e => {
        let path = `/opportunities/${e.row.original.workableId}/workspaces/${e.row.original.id}`;

        return (
          <div>
            <OppWorkspaceHeader
              id={e.row.original.id}
              oppId={e.row.original.workableId}
              name={e.row.original.name}
              organizationDefault={e.row.original.organizationDefault}
              onClick={event => {
                openDrawerOrNewTab(event, path, () =>
                  setSearchParams({
                    drawerType: 'workspaces',
                    drawerId: e.row.original.id
                  })
                );
              }}
              showLink
              updates={e.row.original.updates.filter(u => u.actionTargetType === 'Workspace')}
              visibleId={isMobile || isTablet ? e.row.original.oppData.displayName : null}
              createdByOrganization={e.row.original.createdByOrganization}
            />
            {isMobile || isTablet ? (
              <OppUpdateTags updates={e.row.original.updates} expiringSoon={e.row.original.expiringSoon} />
            ) : null}
          </div>
        );
      },
      sortType: 'basic',
      filter: filterWorkspaces,
      textFilter: true
    },
    avatarListColumn({ header: 'Followers', colWidth: '10rem' }),
    {
      Header: 'Respond By',
      HeaderComponent: React.memo(TableColumnHeader),
      id: 'respondBy',
      accessor: 'oppData.respondBy',
      colWidth: '12rem',
      Cell: e => {
        const { respondBy, awardedAt, cancelledAt } = e.row.original.oppData;
        return <OppExpirationStatus respondBy={respondBy} awardedAt={awardedAt} cancelledAt={cancelledAt} hidePrefix />;
      },
      sortType: 'basic',
      filter: 'dateStringFilter',
      textFilter: true
    },
    {
      Header: 'Stage',
      HeaderComponent: React.memo(TableColumnHeader),
      accessor: 'workflowStage',
      colWidth: '9rem',
      Cell: e => (
        <WorkflowStageTag
          workflowStage={e.row.original.workflowStage}
          interactive
          onClick={() =>
            setWorkspaceFormAttrs({
              id: e.row.original.id,
              basicInputs: false,
              stageInputs: true,
              followersInputs: false
            })
          }
        />
      ),
      sortType: 'basic',
      filter: filterWorkflowStage,
      textFilter: true
    },
    {
      Header: 'Labels',
      HeaderComponent: React.memo(TableColumnHeader),
      accessor: 'labels',
      Cell: e => {
        const labels = labelsFromRow(e.row);
        return <LabelList labels={labels} />;
      },
      sortType: 'basic',
      filter: filterLabels,
      textFilter: true
    },
    {
      HeaderComponent: React.memo(TableColumnHeader),
      accessor: 'actions',
      Cell: e => {
        const space = e.row.original;
        const markAsActive = workspaceFilter === 'inactive';
        return (
          <Popover
            className="self-end"
            content={
              <Menu key="menu">
                <MenuItem
                  icon={markAsActive ? 'undo' : 'tick'}
                  onClick={() => updateWorkspace({ removeFromWorklist: !markAsActive, ids: [space.id] })}
                  text={markAsActive ? 'Mark as Active' : 'Check off my list'}
                />
                <MenuItem
                  icon="plus"
                  onClick={() =>
                    setWorkspaceFormAttrs({
                      id: space.id,
                      basicInputs: false,
                      stageInputs: false,
                      followersInputs: true
                    })
                  }
                  text="Add/Remove Followers"
                />
                <MenuItem
                  icon="edit"
                  onClick={() =>
                    setWorkspaceFormAttrs({
                      id: space.id,
                      basicInputs: true,
                      stageInputs: true,
                      organizationDefault: space.organizationDefault,
                      followersInputs: false
                    })
                  }
                  text="Edit Workspace"
                />
                <MenuItem
                  icon="thumbs-down"
                  onClick={() => updateWorkspace({ unfollow: true, ids: [space.id] })}
                  text="Stop following"
                />
              </Menu>
            }
          >
            <AnchorButton icon="more" />
          </Popover>
        );
      }
    }
  ];
};
