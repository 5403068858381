import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@blueprintjs/core';

import { pluralize } from 'app/lib/strings';
import { BackToTopButton } from 'app/atoms/BackToTopButton/BackToTopButton';

export const SearchPagination = ({ paginationHook }) => {
  const { isFetching, isSuccess, totalRelationPrefix, total, currentPage, per, totalPages, onPageChange } =
    paginationHook();

  if (isFetching || !isSuccess) {
    return null;
  }

  if (total === 0) {
    return null;
  }

  return (
    <nav className="mb-32 flex items-center justify-between py-3" aria-label="Search Pagination">
      <div className="hidden sm:block">
        <p className="m-0 text-sm text-gray-700">
          Showing <span className="font-medium">{currentPage * per - per + 1}</span> to{' '}
          <span className="font-medium">{Math.min(currentPage * per, total)}</span> of{' '}
          <span className="font-medium">{`${totalRelationPrefix}${total.toLocaleString()}`}</span>{' '}
          {pluralize('result', 'results', total)}
        </p>
      </div>
      <div className="flex flex-1 justify-between gap-x-2 sm:justify-end">
        <BackToTopButton />
        <Button outlined large icon="fast-backward" disabled={currentPage === 1} onClick={() => onPageChange(1)} />
        <Button
          outlined
          large
          text="Previous"
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
        />
        <Button
          outlined
          large
          text="Next"
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(currentPage + 1)}
        />
        <Button
          outlined
          large
          icon="fast-forward"
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(totalPages)}
        />
      </div>
    </nav>
  );
};

SearchPagination.propTypes = {
  paginationHook: PropTypes.func.isRequired
};
