import React from 'react';

import { MultiSelectInput } from 'app/atoms/inputs/MultiSelectInput/MultiSelectInput';
import { useGetSetAsidesQuery } from 'api/setAsidesApi';

export const SetAsideMultiSelectInput = props => {
  const { data: setAsides = [], isLoading } = useGetSetAsidesQuery();

  return (
    <MultiSelectInput
      items={setAsides}
      disabled={isLoading}
      searchKeys={['name', 'code']}
      labelAttr="name"
      valueAttr="id"
      {...props}
    />
  );
};
