import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FormGroup, Button, Tag, Tooltip } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';
import startCase from 'lodash-es/startCase';

import { useAuthorized } from 'app/hooks/useAuthorize';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { RadioGroupInput } from 'app/atoms/inputs/RadioGroupInput/RadioGroupInput';
import { SwitchInput } from 'app/atoms/inputs/SwitchInput/SwitchInput';
import { errorToast, successToast } from 'app/lib/toaster';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { AvatarUploader } from 'app/molecules/AvatarUploader/AvatarUploader';
import { useCreateOrganizationUserMutation, useUpdateOrganizationUserMutation } from 'api/organizationUsersApi';
import { useEventTracking } from 'app/hooks/useEventTracking';

const roleTooltip = {
  member: 'Users with this role can only access their own profile and settings.',
  admin: 'Users with this role can add, remove and edit users.'
};

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required.'),
  role: yup.string().required('Role is required.'),
  email: yup.string().email('Email is invalid.').required('Email is required.')
});

const roleLabel = role => {
  if (!roleTooltip[role]) return role;

  return (
    <Tooltip className="cursor-help" content={roleTooltip[role]}>
      {startCase(role)}
    </Tooltip>
  );
};

const UserProfileForm = ({
  organizationUser: {
    id,
    avatar,
    initials,
    sysAdmin,
    name = '',
    email = '',
    phoneNumber = '',
    primaryRole = 'member',
    subscriptionSeatPaid = true
  }
}) => {
  const navigate = useNavigate();
  const [tempAvatarURL, setTempAvatarURL] = useState();
  const adminViewer = useAuthorized({ role: 'admin' });
  const sysAdminViewer = useAuthorized({ role: 'sys_admin' });

  const currentOrgUserId = useCurrentUserAttribute('id');

  const [updateOrganizationUser] = useUpdateOrganizationUserMutation();
  const [createOrganizationUser] = useCreateOrganizationUserMutation();
  const formMutation = id ? updateOrganizationUser : createOrganizationUser;
  const { trackEvent } = useEventTracking();

  const isEditingSelf = currentOrgUserId === id;

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        id,
        name,
        email,
        phoneNumber,
        subscriptionSeatPaid,
        avatar,
        role: primaryRole,
        sendInvite: false
      }}
      onSubmit={async (values, { resetForm }) => {
        try {
          const successMessage = id ? 'User updated.' : 'User created.';
          const { data, error } = await formMutation(values);

          if (error) return Object.values(error.data.errors).forEach(e => errorToast(e));
          if (!id) {
            resetForm();
          } else {
            trackEvent({
              object: 'user',
              action: 'updated',
              properties: { ...values }
            });
          }

          if (values.send_invite) {
            trackEvent({
              object: 'user',
              action: 'invited',
              properties: { userId: data.id, created: true }
            });

            successToast('User invited. They should receive an email with instructions momentarily.');
          } else {
            successToast(successMessage);
          }
          navigate('/settings/users');
        } catch (e) {
          errorToast(e);
        }
      }}
      errors
    >
      {({ handleSubmit, isSubmitting, errors, touched, setFieldValue, values }) => (
        <Form>
          <Card
            title={name ? 'Edit Profile' : 'Add User'}
            rightElement={sysAdmin && <Tag intent="warning">SYS ADMIN</Tag>}
          >
            <CardBody>
              <div className="grid grid-cols-2 gap-x-4 gap-y-4">
                {isEditingSelf && (
                  <div className="col-span-2">
                    <FormGroup label="Avatar Image" labelFor="avatarUploader" className="mb-0">
                      <AvatarUploader
                        id="avatar"
                        initials={initials}
                        imgSrc={tempAvatarURL || (values.avatarRemoved ? null : values.avatar?.thumbUrl)}
                        className="m-0 bg-blue-700"
                        onRemove={() => {
                          setTempAvatarURL(null);
                          setFieldValue('avatar', null);
                          setFieldValue('avatarRemoved', true);
                        }}
                        onInitialize={file => {
                          setTempAvatarURL(URL.createObjectURL(file));
                        }}
                        onAttach={attachment => {
                          setFieldValue('avatarRemoved', false);
                          setFieldValue('avatar', attachment.signedId);
                        }}
                      />
                    </FormGroup>
                  </div>
                )}
                <div className="col-span-2 lg:col-span-1">
                  <TextInput
                    name="name"
                    type="text"
                    id="name"
                    label="Name"
                    labelInfo="(required)"
                    disabled={isSubmitting}
                    value={name || ''}
                    intent={errors.name && touched.name ? 'danger' : ''}
                  />
                </div>
                {(sysAdminViewer || !id) && (
                  <div className="col-span-2 lg:col-span-1">
                    <TextInput
                      name="email"
                      type="text"
                      id="email"
                      label="Email"
                      labelInfo="(required)"
                      disabled={!adminViewer || isSubmitting}
                      intent={errors.email && touched.email ? 'danger' : ''}
                      large
                      onPaste={e => {
                        const data = e.clipboardData.getData('text/plain');
                        if (!data.includes('mailto:')) return;
                        e.preventDefault();
                        window.document.execCommand('insertText', false, data.replace('mailto:', ''));
                      }}
                    />
                  </div>
                )}
                {(isEditingSelf || adminViewer) && (
                  <div className="col-span-2 lg:col-span-1">
                    <TextInput
                      name="phoneNumber"
                      type="text"
                      id="phoneNumber"
                      label="Phone"
                      disabled={isSubmitting}
                      intent={errors.phone_number && touched.phone_number ? 'danger' : ''}
                      large
                    />
                  </div>
                )}
                {adminViewer && (
                  <>
                    <div className="col-span-2 mt-2">
                      <SwitchInput name="subscriptionSeatPaid" label="Has paid seat?" labelInfo="(required)" />
                    </div>
                    <div className="col-span-2">
                      <RadioGroupInput
                        name="role"
                        label="Role"
                        labelInfo="(required)"
                        inline
                        options={[
                          { label: roleLabel('admin'), value: 'admin' },
                          { label: roleLabel('member'), value: 'member' }
                        ]}
                        intent={errors.role && touched.role ? 'danger' : ''}
                      />
                    </div>
                  </>
                )}
              </div>
            </CardBody>
            {(isEditingSelf || adminViewer) && (
              <CardFooter>
                {!id && (
                  <Button
                    className="ml-2"
                    intent="success"
                    loading={isSubmitting}
                    onClick={e => {
                      setFieldValue('sendInvite', true, false);
                      handleSubmit(e);
                    }}
                  >
                    Save & Send Invite
                  </Button>
                )}
                <Button type="submit" className="ml-2" intent="primary" loading={isSubmitting}>
                  Save
                </Button>
              </CardFooter>
            )}
          </Card>
        </Form>
      )}
    </Formik>
  );
};

UserProfileForm.propTypes = {
  organizationUser: PropTypes.object.isRequired
};

export default UserProfileForm;
