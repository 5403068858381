import React from 'react';
import { Formik, Form } from 'formik';

import { useContactSearchMutation } from 'api/contactsApi';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { getUsedFilters } from 'app/lib/tracking';
import { useScrollAppBody } from 'app/hooks/useScrollAppBody';
import { useHydrateSearchForm } from 'app/hooks/search/useHydrateSearchForm';
import { useContactSearchCache, getFormState, defaultValues } from 'app/hooks/search/useContactSearchCache';
import { useDefaultSearchStateStore } from 'app/hooks/useDefaultSearchStateStore';
import { ContactSearch } from 'app/pages/ContactSearchPage/ContactSearch';
import { SubscriptionRequiredPageWrapper } from 'app/organisms/SubscriptionRequiredPageWrapper/SubscriptionRequiredPageWrapper';

export const loader = () => {
  useDefaultSearchStateStore.setState({ defaultState: defaultValues });
  return null;
};

export const ContactSearchPage = () => {
  const { trackEvent } = useEventTracking();
  const scrollAppBody = useScrollAppBody();
  const [search] = useContactSearchMutation({
    fixedCacheKey: 'contact-search'
  });
  const { initialValues, isInitializing } = useHydrateSearchForm({ store: useContactSearchCache, getFormState });

  return (
    <SubscriptionRequiredPageWrapper
      subscriptions={['seatPaid']}
      paywallPageProps={{
        title: 'Contact Search is a Paid Feature',
        description: <p>We utilize AI to extract contact data from opportunities and their attachments.</p>
      }}
    >
      <Formik
        key={isInitializing.toString()}
        initialValues={initialValues}
        onSubmit={({ query, filters }) => {
          search({ query, ...filters });

          const usedFilters = getUsedFilters(filters);
          trackEvent({
            object: 'contacts',
            action: 'searched',
            properties: { query, ...filters, usedFilters }
          });

          scrollAppBody({ top: 0, behavior: 'smooth' });
        }}
      >
        <Form>
          <ContactSearch />
        </Form>
      </Formik>
    </SubscriptionRequiredPageWrapper>
  );
};
