import React, { useMemo } from 'react';
import { Tag, Tooltip } from '@blueprintjs/core';
import { PieChart } from 'react-chartkick';
import { createColumnHelper } from '@tanstack/react-table';
import { formatTime, DATETIME_24_SHORT } from 'app/lib/dates';

import { ReportOrganizationOppSharesReport } from 'types/__generated__/GovlyApi';
import { useGetReportQuery } from 'api/reportsApi';

import { ErrorBoundary } from 'app/atoms/ErrorBoundary/ErrorBoundary';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { ContactIdTag } from 'app/organisms/ContactIdTag/ContactIdTag';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { CardError } from 'app/atoms/ErrorFallback/CardError';

import { CardHeading } from 'app/atoms/Typography/Typography';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableRowCountTag } from 'app/molecules/GovlyTable/GovlyTableRowCountTag';

import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTableCSVExportButton } from 'app/molecules/GovlyTable/GovlyTableCSVExportButton';

type OrganizationInsightsSharesTableProps = {
  filters: {
    orgUserId: string;
    dateRange: [Date, Date];
    interaction: 'shared_at';
  };
};

const columnHelper = createColumnHelper<ReportOrganizationOppSharesReport>();

export const OrganizationInsightsSharesTable = ({ filters }: OrganizationInsightsSharesTableProps) => {
  const { data = [], isLoading } = useGetReportQuery({
    ...filters,
    report_type: 'Report::OrganizationOppSharesReport'
  });

  const report = data as ReportOrganizationOppSharesReport[];

  const overflowList = (list: string[]) =>
    list.length <= 2 ? list : [...list.slice(0, 2), `...${list.length - 2} more`];

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        sortingFn: 'basic',
        cell: e => <ContactIdTag email={e.row.original.email ?? ''} name={e.row.original.name} hideAvatar />
      }),
      columnHelper.accessor('displayName', {
        header: 'Opportunity',
        cell: e => <LinkTag to={`/opportunities/${e.row.original.oppId}`}>{e.row.original.displayName}</LinkTag>
      }),
      columnHelper.accessor('sharedAt', {
        header: 'Shared At',
        enableColumnFilter: false,
        cell: e => {
          if (e.row.original.sharedAt) {
            return formatTime(e.row.original.sharedAt, DATETIME_24_SHORT);
          }
          return 'Never';
        }
      }),
      columnHelper.accessor('sharedTo', {
        header: 'Shared To',
        enableColumnFilter: false,
        cell: e => {
          const { sharedTo = [] } = e.row.original;
          return (
            <Tooltip content={<div className="whitespace-pre">{sharedTo.join('\n')}</div>}>
              <div className="flex items-baseline space-x-2">
                {overflowList(sharedTo).map(email => (
                  <Tag intent="primary" key={email}>
                    {email}
                  </Tag>
                ))}
              </div>
            </Tooltip>
          );
        }
      })
    ],
    []
  );

  const tableData = useMemo(() => report, [report]);

  const domainCounts = report?.reduce(
    (acc, row) => {
      row.sharedTo?.forEach(email => {
        const domain = email.split('@')[1];
        if (!acc[domain]) acc[domain] = 0;
        acc[domain] += 1;
      });
      return acc;
    },
    {} as Record<string, number>
  );

  const chartData = useMemo(
    () => Object.entries(domainCounts).map(([domain, count]) => [domain, count] as [string, number]),
    [domainCounts]
  );

  return (
    <ErrorBoundary action="OrganizationInsightsShares" fallback={<CardError title="Shares" />}>
      <div className="space-y-8">
        <GovlyTable
          trackingObject="organization_insights_shares_table"
          columns={columns}
          data={tableData}
          title={
            <span className="flex gap-2 items-center">
              <CardHeading>Shares</CardHeading>
              <GovlyTableRowCountTag />
            </span>
          }
          cardProps={{
            rightElement: (
              <GovlyTableToolbar>
                <GovlyTableCSVExportButton filename="shares" />
              </GovlyTableToolbar>
            )
          }}
          isLoading={isLoading}
          emptyStateProps={{
            icon: undefined,
            title: 'No activity',
            description: (
              <div>
                {`Your filters didn't yield any activity.`}
                <br />
                {`Try adjusting your the filters above.`}
              </div>
            )
          }}
          initialState={{
            sorting: [
              {
                id: 'sharedAt',
                desc: true
              }
            ],
            pagination: {
              pageSize: 3
            }
          }}
        />

        <Card className="mb-4" title="Shares By Domain">
          <CardBody>
            <PieChart data={chartData} />
          </CardBody>
        </Card>
      </div>
    </ErrorBoundary>
  );
};
