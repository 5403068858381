import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';

import { formatTime, DATETIME_24_SHORT } from 'app/lib/dates';
import { ContactSearch, ContactPhoneNumber } from 'types/__generated__/GovlyApi';

import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';

const columnHelper = createColumnHelper<ContactPhoneNumber>();

type ContactPhoneNumbersProps = {
  contact: ContactSearch;
};

export const ContactPhoneNumbers = ({ contact }: ContactPhoneNumbersProps) => {
  const phoneNumbers = contact.contactPhoneNumbers || [];

  const columns = useMemo(
    () => [
      columnHelper.accessor('number', {
        header: 'Phone',
        enableSorting: false,
        enableColumnFilter: false,
        cell: e => <span className="text-xs">{e.row.original.number}</span>
      }),
      columnHelper.accessor('lastActive', {
        header: 'Last Active',
        enableSorting: false,
        enableColumnFilter: false,
        cell: e => (
          <span className="text-xs">
            {e.row.original.lastActive ? formatTime(e.row.original.lastActive, DATETIME_24_SHORT) : undefined}
          </span>
        )
      })
    ],
    []
  );

  if (phoneNumbers.length < 2) return null;

  return (
    <GovlyTable
      trackingObject="contact_titles"
      columns={columns}
      data={phoneNumbers}
      title="Phone Numbers"
      initialState={{
        sorting: [{ id: 'lastActive', desc: true }],
        pagination: {
          pageSize: 5
        }
      }}
    />
  );
};
