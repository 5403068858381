import React, { useMemo, useState } from 'react';

import { Loading } from 'app/atoms/Loading/Loading';
import { useGetPartnerPortalLinksQuery } from 'api/partnerPortalLinksApi';
import { Button, Dialog, NonIdealState, InputGroup } from '@blueprintjs/core';
import { match } from 'ts-pattern';
import { H2 } from 'app/atoms/Typography/Typography';
import { PartnerPortalLink } from 'app/molecules/PartnerPortalLink/PartnerPortalLink';
import { useAuthorized } from 'app/hooks/useAuthorize';
import { PartnerPortalLinkForm } from './PartnerPortalLinkForm';

export const PartnerPortalLinks = () => {
  const isAdmin = useAuthorized({ role: 'admin' });
  const { isLoading, data } = useGetPartnerPortalLinksQuery();

  const [focusedLink, setFocusedLink] = useState();
  const [query, setQuery] = useState('');

  const filteredData = useMemo(() => {
    return (
      data?.filter(link => {
        if (!query) return true;

        const text = `${link.label} ${link.url} ${link.description}`;
        return text.toLowerCase().includes(query.toLowerCase());
      }) ?? []
    );
  }, [data, query]);

  if (isLoading) return <Loading />;

  const isEmptySearch = data?.length > 0 && filteredData.length === 0;

  return (
    <div className="rounded bg-white shadow-card dark:bg-gray-600 dark:shadow-dark">
      <div className="py-4 px-6 border-b border-gray-300 flex items-center justify-between">
        <H2>Partner Portals</H2>

        {isAdmin && (
          <Button icon="plus" onClick={() => setFocusedLink({ label: query })}>
            New link
          </Button>
        )}
      </div>

      <ul>
        {data?.length > 3 && (
          <li>
            <div className="p-6 border-b border-b-gray-300 flex flex-col gap-2">
              <h4 className="uppercase font-bold text-gray-500 text-xs">search</h4>
              <InputGroup
                className="w-80  lg:w-1/3"
                placeholder="Filter rows"
                value={query}
                onChange={e => setQuery(e.target.value)}
              />
            </div>
          </li>
        )}

        {match(filteredData)
          .with([], () => (
            <li>
              <div className="py-8 flex flex-col items-center gap-4">
                <NonIdealState
                  icon="link"
                  title={isEmptySearch ? `Add a link for "${query}"` : 'Add a partner portal'}
                  description={match({ isAdmin, data, filteredData })
                    .with(
                      { isAdmin: true, data: [] },
                      () => "Your team hasn't set up any partner portal links yet. Create a new partner link now."
                    )
                    .with(
                      { isAdmin: false, data: [] },
                      () =>
                        "Your team hasn't set up any partner portal links yet. Ask your Admin to set one up for you."
                    )
                    .with(
                      { isAdmin: true, filteredData: [] },
                      () =>
                        "We couldn't find a match for the partner you're searching for. Create a new partner link now."
                    )
                    .with(
                      { isAdmin: false },
                      () =>
                        "We couldn't find a match for the partner you're searching for. Ask your Admin to set one up for you."
                    )
                    .otherwise(() => '')}
                />

                {isAdmin && (
                  <Button icon="plus" outlined intent="primary" onClick={() => setFocusedLink({ label: query })}>
                    New link
                  </Button>
                )}
              </div>
            </li>
          ))
          .otherwise(filteredData => (
            <>
              {filteredData.map(link => (
                <li key={link.id}>
                  <PartnerPortalLink partnerPortalLink={link} onClickEdit={setFocusedLink} />
                </li>
              ))}
            </>
          ))}
      </ul>
      {isAdmin && (
        <Dialog className="p-0" lazy isOpen={Boolean(focusedLink)} onClose={() => setFocusedLink(undefined)}>
          {focusedLink && (
            <PartnerPortalLinkForm partnerPortalLink={focusedLink} onCancel={() => setFocusedLink(undefined)} />
          )}
        </Dialog>
      )}
    </div>
  );
};
