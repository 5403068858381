import { FeedProfile, FeedProfileAll } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['FeedProfile', 'CurrentOrganization'] });

type GetFeedProfiles = {
  params: {
    all?: boolean;
  };
  result: FeedProfile[] | FeedProfileAll[];
};

type UpdateFeedProfile = {
  params: { id: string } & Pick<
    FeedProfile,
    'active' | 'username' | 'password' | 'type' | 'notificationEmail' | 'context'
  >;
  result: FeedProfile;
};

type CreateFeedProfile = {
  params: Pick<FeedProfile, 'active' | 'username' | 'password' | 'type' | 'notificationEmail' | 'context'>;
  result: FeedProfile;
};

type CallFeedProfile = {
  params: {
    id: string;
    feedProfileId: string;
    profileAction: 'parse' | 'no_bid' | 'acknowledge' | 'update_password' | 'crawl' | 'no_bid_dod_esi';
  };
  result: void;
};

export const feedProfilesApi = api.injectEndpoints({
  endpoints: build => ({
    getFeedProfiles: build.query<GetFeedProfiles['result'], GetFeedProfiles['params']>({
      query: params => ({ url: '/v2/feed_profiles', params }),
      providesTags: result =>
        Array.isArray(result)
          ? [...result.map(({ id }) => ({ type: 'FeedProfile' as const, id })), 'FeedProfile']
          : ['FeedProfile']
    }),

    updateFeedProfile: build.mutation<UpdateFeedProfile['result'], UpdateFeedProfile['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/feed_profiles/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['CurrentOrganization']
    }),

    createFeedProfile: build.mutation<CreateFeedProfile['result'], CreateFeedProfile['params']>({
      query: body => ({
        url: `/v2/feed_profiles`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['CurrentOrganization']
    }),

    callFeedProfile: build.mutation<CallFeedProfile['result'], CallFeedProfile['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/feed_profiles/${id}/call`,
        method: 'POST',
        body
      })
    })
  })
});

export const {
  useGetFeedProfilesQuery,
  useCreateFeedProfileMutation,
  useUpdateFeedProfileMutation,
  useCallFeedProfileMutation
} = feedProfilesApi;
