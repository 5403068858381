import React, { useMemo } from 'react';
import { NonIdealState } from '@blueprintjs/core';
import { useSearchParams } from 'react-router-dom';
import uniqBy from 'lodash-es/uniqBy';

import { FeedShow } from 'types/__generated__/GovlyApi';
import { useGetPartnerFeedsQuery } from 'api/partnerFeedsApi';
import { useGetFeedsQuery } from 'api/feedsApi';
import { useGetDeactivatedFeedsQuery } from 'api/deactivatedFeedsApi';
import { useGetCurrentOrganizationQuery } from 'api/organizationsApi';

import { Card, CardBody } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { fedFeedComparator } from 'app/organisms/SearchableFeeds/SearchableFeedsUtils';
import { notNullish } from 'app/lib/notNullish';

import { SearchableFeedsListFedCard, FeedShowExtended } from '../SearchableFeedsListFedCard/SearchableFeedsListFedCard';
import { SEARCH_KEY, SORT_KEY, SearchableFeedsSwitcher, type Sort } from '../SearchableFeedsSwitcher';

export const SearchableFeedsListFed = () => {
  const currentOrgQuery = useGetCurrentOrganizationQuery();
  const publicFeedsQuery = useGetFeedsQuery({ filter: 'public', jurisdiction: 'fed' });
  const partnerFeedsQuery = useGetPartnerFeedsQuery(undefined);
  const deactivatedFeedsQuery = useGetDeactivatedFeedsQuery();
  const feedsQuery = useGetFeedsQuery(
    { view: 'show' },
    {
      skip: partnerFeedsQuery.isLoading || publicFeedsQuery.isLoading,
      selectFromResult: result => ({
        ...result,
        data: Object.fromEntries((result.data as FeedShow[])?.map(feed => [feed.id, feed]) ?? []) as Record<
          string,
          FeedShow
        >
      })
    }
  );
  const queries = [partnerFeedsQuery, publicFeedsQuery, currentOrgQuery, feedsQuery, deactivatedFeedsQuery];

  const [searchParams] = useSearchParams();
  const search = searchParams.get(SEARCH_KEY) || '';
  const sort = (searchParams.get(SORT_KEY) as Sort) ?? 'none';

  const organizationId = currentOrgQuery.data?.id;

  const feeds = useMemo(() => {
    const publicFeeds = publicFeedsQuery.data?.map(feed => feedsQuery.data?.[feed.id]).filter(Boolean) ?? [];

    const privateFeeds = uniqBy(
      partnerFeedsQuery.data?.map(partnerFeed => feedsQuery.data?.[partnerFeed.feedId]).filter(Boolean) ?? [],
      feed => feed.id
    );

    const publicAndPrivateFeeds = publicFeeds.concat(privateFeeds);

    const publicAndPrivateFeedsIds = new Set(publicAndPrivateFeeds.map(feed => feed.id));

    const remainingFeatured = Object.values(feedsQuery.data ?? {})
      .filter(feed => feed.featuredInFeedSearch && !publicAndPrivateFeedsIds.has(feed.id))
      .map(feed => ({ ...feed, isDisabled: true }));

    const allFeeds = publicAndPrivateFeeds.concat(remainingFeatured) as FeedShowExtended[];

    return allFeeds
      .map(feed => {
        const deactivatedFeedIds = deactivatedFeedsQuery.data?.map(df => df.feedId).filter(notNullish) || [];
        const deactivatedPartnerFeedIds =
          deactivatedFeedsQuery.data?.map(df => df.partnerFeedId).filter(notNullish) || [];

        const partnerFeedIds =
          partnerFeedsQuery.data?.filter(partnerFeed => partnerFeed.feedId === feed.id).map(pf => pf.id) || [];

        const activated =
          !feed.isDisabled && feed.public
            ? !deactivatedFeedIds.includes(feed.id)
            : !partnerFeedIds.every(id => deactivatedPartnerFeedIds.includes(id));

        // Owned is only used for sorting
        const owned =
          partnerFeedsQuery.data?.some(
            partnerFeed => partnerFeed.feedId === feed.id && partnerFeed.providerId === organizationId
          ) ?? false;

        return {
          ...feed,
          activated,
          owned,
          partnerFeedIds
        };
      })
      .sort(fedFeedComparator(sort))
      .filter(feed => {
        const text = `${feed.name} ${feed.contractVehicle?.description} ${feed.contractVehicle?.shortDescription}`;
        return text.toLowerCase().includes(search.toLowerCase());
      });
  }, [
    organizationId,
    partnerFeedsQuery.data,
    feedsQuery.data,
    publicFeedsQuery.data,
    deactivatedFeedsQuery.data,
    search,
    sort
  ]);

  return (
    <div className="flex flex-col gap-4">
      <SearchableFeedsSwitcher />

      {queries.some(q => q.isLoading) ? (
        <Loading type="card-list" />
      ) : feeds.length === 0 ? (
        <Card>
          <CardBody>
            <NonIdealState icon={search ? 'search' : 'warning-sign'} title="No results found" />
          </CardBody>
        </Card>
      ) : (
        <div className="flex flex-col gap-4" role="list" aria-label="feeds">
          {feeds.map(feed => {
            return <SearchableFeedsListFedCard key={feed.id} feed={feed} />;
          })}
        </div>
      )}
    </div>
  );
};
