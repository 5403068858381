import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Tag } from '@blueprintjs/core';
import { useReward } from 'react-rewards';

import { formatWorkflowStages } from 'app/lib/workflowStages';
import { useGetOppWorkspaceQuery } from 'api/oppWorkspacesApi';
import { useUpdateWorkspaceMutation } from 'api/workspacesApi';
import { WorkspaceForm } from 'app/organisms/WorkspaceForm/WorkspaceForm';
import { WorkflowStageTag } from 'app/molecules/WorkflowStageTag/WorkflowStageTag';
import { ProgressPanels } from 'app/molecules/ProgressPanels/ProgressPanels';

export const OppWorkspaceWorkflowStage = ({ workspaceId, workflowStage, asTag = false, ...rest }) => {
  const { reward: submittedReward } = useReward(`workspace-${workspaceId}`, 'confetti', {
    lifetime: 50,
    elementCount: 100,
    elementSize: 12,
    decay: 0.97
  });
  const { reward: awardedReward } = useReward(`workspace-${workspaceId}`, 'emoji', {
    emoji: ['💰', '🤑'],
    lifetime: 50,
    elementCount: 100,
    decay: 0.97
  });
  const [updateOppWorkspace, { isLoading: isUpdating }] = useUpdateWorkspaceMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [stageValue, setStageValue] = useState(workflowStage);
  const { data: { follows = [], name } = {}, isLoading: workspaceLoading } = useGetOppWorkspaceQuery({
    id: workspaceId
  });

  const stagesToDisplay = formatWorkflowStages(workflowStage);

  if (workspaceLoading) {
    return null;
  }

  const openModal = value => {
    setStageValue(value);
    setIsOpen(true);
  };

  return (
    <>
      {asTag ? (
        <div id={`workspace-${workspaceId}`} className="flex items-center gap-2" {...rest}>
          <WorkflowStageTag workflowStage={workflowStage} />
          <Tag round minimal interactive onClick={() => openModal(workflowStage)} icon="edit">
            Edit stage
          </Tag>
        </div>
      ) : (
        <ProgressPanels steps={stagesToDisplay} onClick={openModal} {...rest} />
      )}
      <Dialog isOpen={isOpen} className="min-w-[50%] p-0">
        <WorkspaceForm
          onSubmit={updateOppWorkspace}
          isSubmitting={isUpdating}
          stageInputs
          basicInputs={false}
          followersInputs={false}
          onCancel={({ workflowStage } = {}) => {
            if (workflowStage === 'awarded') {
              awardedReward();
            }

            if (workflowStage === 'submitted') {
              submittedReward();
            }

            setIsOpen(false);
          }}
          follows={follows}
          notifyNewFollowersOnly={false}
          workableTypeForLink="opportunities"
          initialValues={{ id: workspaceId, workflowStage: stageValue, name }}
          title="Update Opportunity Stage"
        />
      </Dialog>
    </>
  );
};

OppWorkspaceWorkflowStage.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  workflowStage: PropTypes.string.isRequired,
  asTag: PropTypes.bool
};
