import { Textcomplete } from '@textcomplete/core';
import { ContenteditableEditor } from '@textcomplete/contenteditable';
import uniq from 'lodash/uniq';

let mentionSearchTerm = '';

const mentionSearch = (term, callback, editorEl) => {
  mentionSearchTerm = term;
  const mentions = JSON.parse(editorEl.dataset.mentionables).map(user => `${user.firstName} (${user.email})`);
  const searchedMentions = mentions.filter(mention => mention.toLowerCase().includes(term.toLowerCase()));
  callback(searchedMentions);
};

const mentionReplace = (editor, mention, setMentions) => {
  setMentions(mentions => uniq([...mentions, mention.match(/\(([^)]+)\)/)[1]]));
  for (let i = 0; i < mentionSearchTerm.length + 1; i++) editor.deleteInDirection('backward');
  editor.insertHTML(`<strong>@${mention}</strong>`);
  editor.deactivateAttribute('bold');
  editor.insertString(' ');
};

export const setupMentions = (e, setMentions) => {
  const textCompleteEditor = new ContenteditableEditor(e.target);
  new Textcomplete(
    textCompleteEditor,
    [
      {
        match: /\B@([-+\w]*)$/,
        search: (term, callback) => mentionSearch(term, callback, e.target),
        replace: mention => mentionReplace(e.target.editor, mention, setMentions)
      }
    ],
    {
      dropdown: {
        className: 'z-1000 bg-white border border-gray-300 rounded shadow-md max-h-32 overflow-y-scroll',
        item: {
          className: 'p-2 text-gray-700 text-sm hover:cursor-pointer hover:bg-gray-100',
          activeClassName: 'p-2 bg-gray-100 text-gray-900 text-sm hover:cursor-pointer hover:bg-gray-100'
        }
      }
    }
  );
};
