import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Tag } from '@blueprintjs/core';

import { successToast, formErrorToast } from 'app/lib/toaster';
import { useGetSouptoolsQuoteQuery, useUpdateSouptoolsQuoteMutation } from 'api/souptoolsQuotesApi';
import { useCheckClinListMutation } from 'api/souptoolsClinsApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { SelectInput } from 'app/atoms/inputs/SelectInput/SelectInput';
import { SouptoolsSubmitClinCheck } from 'app/organisms/SouptoolsSubmitClinCheck/SouptoolsSubmitClinCheck';
import { SouptoolsClinUploadsCard } from 'app/organisms/SouptoolsClinUploadsCard/SouptoolsClinUploadsCard';
import { SouptoolsClinTable } from 'app/organisms/SouptoolsClinTable/SouptoolsClinTable';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { DescriptionListField } from 'app/molecules/DescriptionListField/DescriptionListField';
import { formatTime, DATETIME_24_SHORT } from 'app/lib/dates';
import { capitalize } from 'app/lib/strings';

const statusIntent = status => {
  if (status === 'complete') {
    return 'success';
  }

  if (status === 'draft') {
    return 'primary';
  }

  return 'danger';
};

export const SouptoolsQuotePage = () => {
  const { id } = useParams();
  const { trackEvent } = useEventTracking();
  const [pollingInterval, setPollingInterval] = useState(0);
  const { data: quote = {}, isLoading } = useGetSouptoolsQuoteQuery({ id }, { pollingInterval, skip: id === 'new' });
  const [updateQuote, { isLoading: isUpdating, isError: updateError, isSuccess: updateSuccess }] =
    useUpdateSouptoolsQuoteMutation();

  const [checkClinList, { isLoading: isCheckingClinList }] = useCheckClinListMutation();

  const {
    createdAt,
    clinsCount,
    status,
    clinUploadProcessing,
    clinChecklistProcessing,
    name,
    templatePath,
    user: { email } = {}
  } = quote;

  useEffect(() => {
    if (updateSuccess) {
      successToast('Quote updated');
    }

    if (updateError) {
      formErrorToast('Error updating quote');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess, updateError, successToast, formErrorToast]);

  useEffect(() => {
    if (clinUploadProcessing || clinChecklistProcessing) {
      setPollingInterval(3000);
    } else {
      setPollingInterval(0);
    }
  }, [clinUploadProcessing, clinChecklistProcessing]);

  if (isLoading) return <Loading />;

  return (
    <>
      <Card
        title={
          <>
            <Tag>{id === 'new' ? 'New Quote' : `Quote ${id}`}</Tag>
            <Tag intent={statusIntent(status)} className="ml-2">
              {capitalize(status)}
            </Tag>
          </>
        }
        rightElement={
          <Formik
            initialValues={{ id, status }}
            onSubmit={async values => {
              await updateQuote(values);
              trackEvent({
                object: 'souptools_quote',
                action: 'updated',
                properties: { ...values }
              });
            }}
          >
            <Form>
              <SelectInput
                submitOnChange
                filterable={false}
                name="status"
                staticButtonText="Change Status"
                items={[
                  { label: 'Draft', value: 'draft' },
                  { label: 'Complete', value: 'complete' },
                  { label: 'Archived', value: 'archived' }
                ]}
                buttonProps={{ loading: isUpdating, large: false }}
              />
            </Form>
          </Formik>
        }
      >
        <CardBody>
          <Tag minimal>{`${clinsCount} CLINs`}</Tag>
          <SouptoolsSubmitClinCheck
            clinable={quote}
            onSubmit={() => checkClinList({ clinableType: 'Souptools::Quote', clinableId: id })}
            isSubmitting={isCheckingClinList}
          />
          <div className="grid grid-cols-1 gap-y-2 md:grid-cols-3">
            <DescriptionListField label="Name:" value={name} cols={1} />
            <DescriptionListField label="Created by:" value={email} cols={1} />
            <DescriptionListField label="Created at:" value={formatTime(createdAt, DATETIME_24_SHORT)} cols={1} />
          </div>
        </CardBody>
      </Card>

      <SouptoolsClinUploadsCard
        uploadable={quote}
        maxUploads={1}
        header="CLIN File Upload"
        templatePath={templatePath}
      />

      {!!quote.clins?.length && <SouptoolsClinTable clinable={quote} />}
    </>
  );
};
