import React from 'react';

import { Loading } from 'app/atoms/Loading/Loading';
import { MultiSelectInput } from 'app/atoms/inputs/MultiSelectInput/MultiSelectInput';
import { LabelTag } from 'app/organisms/LabelTag/LabelTag';
import { useGetOrganizationLabelsQuery } from 'api/organizationLabelsApi';

export const LabelTagMultiSelectInput = inputProps => {
  const { data: organizationLabels = [], isLoading: labelsLoading } = useGetOrganizationLabelsQuery();

  if (labelsLoading) {
    return <Loading type="flex-row" />;
  }

  const items = organizationLabels.map(label => ({
    label: <LabelTag {...label} />,
    value: label.id,
    name: label.name,
    description: label.description
  }));

  return <MultiSelectInput items={items} searchKeys={['name', 'description']} className="py-6" {...inputProps} />;
};
