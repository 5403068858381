import React from 'react';
import PropTypes from 'prop-types';

import { ActivityExtended } from 'types/__generated__/GovlyApi';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { NewTabTooltip } from 'app/molecules/NewTabTooltip/NewTabTooltip';
import { Avatar } from 'app/molecules/Avatar/Avatar';
import { linkDecorator, linkNameDecorator, mentionDecorator } from 'app/lib/activityMessageDecorator';

type ActivityMessageProps = {
  activity: ActivityExtended & {
    customData: {
      mentions?: string[];
      displayId?: string;
    };
  };
  email: string;
};

export const ActivityMessage = ({ activity, email }: ActivityMessageProps) => {
  const message = mentionDecorator(activity, email);
  return (
    <LinkTag to={linkDecorator(activity)}>
      <NewTabTooltip title={linkNameDecorator(activity)}>
        <div className="flex items-center">
          <div className="h-10 w-10">
            <Avatar
              initials={activity.actor?.initials || 'GV'}
              imgSrc={activity.actor?.avatar ? activity.actor.avatar.thumbUrl : ''}
              className={activity.actor?.avatarColor}
            />
          </div>
          <span className="ml-2 whitespace-normal text-sm text-gray-500">{message}</span>
        </div>
      </NewTabTooltip>
    </LinkTag>
  );
};

ActivityMessage.propTypes = {
  activity: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired
};
