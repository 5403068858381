import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash-es/orderBy';

import { ReactTableTemplate, TableColumnHeader } from 'app/templates/ReactTableTemplate/ReactTableTemplate';
import { AvatarWithName } from 'app/molecules/AvatarWithName/AvatarWithName';
import { useGetOppQuery } from 'api/oppsApi';
import { OppDetailsCreateWorkspace } from 'app/organisms/OppDetailsCreateWorkspace/OppDetailsCreateWorkspace';
import { useGetCurrentUserQuery } from 'api/currentUserApi';

const propTypes = {
  id: PropTypes.string.isRequired
};

export const OppDetailsProviderContacts = ({ id, ...rest }) => {
  const { data: { providerPartnerships = [], contractVehicle: { public: isPublic } = {} } = {}, isLoading } =
    useGetOppQuery({
      id
    });
  const { data: currentUser, isLoading: currentUserLoading } = useGetCurrentUserQuery();

  const contacts = useMemo(
    () =>
      orderBy(providerPartnerships.map(({ representatives }) => representatives).flat(), 'organizationUser.name').map(
        ({ organizationUser }) => organizationUser
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        HeaderComponent: React.memo(TableColumnHeader),
        accessor: 'name',
        Cell: e => (
          <div className="flex flex-wrap items-center justify-between">
            <div className="mr-2">
              <AvatarWithName
                initials={e.row.original.initials}
                imgSrc={e.row.original.avatar?.thumbUrl}
                avatarColor={e.row.original.avatarColor}
                id={e.row.original.id}
                email={e.row.original.email}
                name={e.value}
              />
            </div>
            <div className="mt-2">
              <OppDetailsCreateWorkspace
                name={`${currentUser?.organization?.name} <> ${e.value}`}
                oppId={id}
                follows={[
                  {
                    organizationUserId: e.row.original.id,
                    state: 'following',
                    organizationUser: { organizationId: e.row.original.organizationId }
                  },
                  {
                    organizationUserId: currentUser?.id,
                    state: 'following',
                    notifications: 'user_setting',
                    organizationUser: { organizationId: currentUser?.organization?.id }
                  }
                ]}
                tooltipProps={{ content: `Create a Workspace with ${e.value}` }}
              />
            </div>
          </div>
        ),
        sortType: 'basic',
        filter: (rows, _columnIds, filterValue) => {
          if (!filterValue) {
            return rows;
          }

          const val = filterValue.toLowerCase();
          return rows.filter(
            row =>
              row.original.name?.toLowerCase()?.includes(val) ||
              row.original.organizationName?.toLowerCase()?.includes(val)
          );
        },
        textFilter: true
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, currentUserLoading, id]
  );

  if (isPublic) {
    return null;
  }

  if (isLoading || currentUserLoading || !providerPartnerships.length || !contacts.length) {
    return null;
  }

  return (
    <div {...rest}>
      <ReactTableTemplate
        columns={columns}
        data={contacts}
        title="Provider Contacts"
        description="Provider contacts are available for collaboration in Workspaces."
        hasCSVButton={false}
        headerType="card-header"
        initialStateProps={{ pageSize: 3 }}
        hideTableHeader={contacts.length < 3}
      />
    </div>
  );
};

OppDetailsProviderContacts.propTypes = propTypes;
