/**
 * Returns the singular or plural form of a word based on the count.
 * @param {string} singular - The singular form of the word.
 * @param {string} plural - The plural form of the word.
 * @param {number} count - The number of items.
 * @returns {string} The singular or plural form of the word.
 *
 * @example
 * pluralize('item', 'items', 0); // 'items'
 * pluralize('item', 'items', 1); // 'item'
 * pluralize('item', 'items', 2); // 'items'
 */
export const pluralize = (singular: string, plural: string, count: number) => (count === 1 ? singular : plural);

export const possessive = (name?: string) => (name ? (name.endsWith('s') ? `${name}'` : `${name}'s`) : "unknown's");

export const capitalize = <Str extends string>(s?: Str) =>
  (s ? s.charAt(0).toUpperCase() + s.slice(1) : '') as Capitalize<Str>;

export const getNonEmptyString = (...strings: unknown[]): string | undefined =>
  strings.find(str => typeof str === 'string' && str.length > 0) as string | undefined;

export const safeParse = (str?: string) => {
  if (!str) return {};

  try {
    return JSON.parse(str);
  } catch (e) {
    return {};
  }
};
