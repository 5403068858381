import { SouptoolsTr, SouptoolsTrSubmission } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Souptools::Tr'] });

const provideTag = <Arg extends { id?: string }>(_result: unknown, _err: unknown, arg: Arg) => [
  { type: 'Souptools::Tr' as const, id: arg.id }
];

type GetTrs = {
  params: {
    page?: number;
  };
  result: {
    trs: SouptoolsTr[];
  };
};

type GetTr = {
  params: {
    id: string;
  };
  result: SouptoolsTr;
};

type CreateTr = {
  params: {
    souptoolsTrTemplateId: string;
  };
  result: SouptoolsTr;
};

type UpdateTr = {
  params: {
    id: string;
    souptoolsTrTemplateId: string;
    status: string;
  };
  result: SouptoolsTr;
};

type ArchiveTr = {
  params: {
    id: string;
  };
  result: void;
};

type SubmitTr = {
  params: {
    id: string;
  };
  result: SouptoolsTrSubmission;
};

type CancelTrSubmission = {
  params: {
    trId: string;
    id: string;
  };
  result: void;
};

export const souptoolsTrsApi = api.injectEndpoints({
  endpoints: build => ({
    getTrs: build.query<GetTrs['result'], GetTrs['params']>({
      providesTags: result => [
        'Souptools::Tr',
        ...(result?.trs || []).map(({ id }) => ({ type: 'Souptools::Tr' as const, id }))
      ],
      query: params => ({
        url: '/v2/souptools/trs',
        params
      })
    }),

    getTr: build.query<GetTr['result'], GetTr['params']>({
      providesTags: provideTag,
      query: ({ id, ...params }) => ({
        url: `/v2/souptools/trs/${id}`,
        params
      })
    }),

    createTr: build.mutation<CreateTr['result'], CreateTr['params']>({
      invalidatesTags: ['Souptools::Tr'],
      query: body => ({
        url: `/v2/souptools/trs/`,
        method: 'POST',
        body
      })
    }),

    updateTr: build.mutation<UpdateTr['result'], UpdateTr['params']>({
      invalidatesTags: provideTag,
      query: ({ id, ...body }) => ({
        url: `/v2/souptools/trs/${id}`,
        method: 'PATCH',
        body
      })
    }),

    archiveTr: build.mutation<ArchiveTr['result'], ArchiveTr['params']>({
      query: ({ id }) => ({
        url: `/v2/souptools/trs/${id}`,
        method: 'DELETE'
      })
    }),

    submitTr: build.mutation<SubmitTr['result'], SubmitTr['params']>({
      invalidatesTags: provideTag,
      query: ({ id, ...body }) => ({
        url: `/v2/souptools/trs/${id}/tr_submissions`,
        method: 'POST',
        body
      })
    }),

    cancelTrSubmission: build.mutation<CancelTrSubmission['result'], CancelTrSubmission['params']>({
      invalidatesTags: (result, err, arg) => [{ type: 'Souptools::Tr', id: arg.trId }],
      query: ({ trId, id }) => ({
        url: `/v2/souptools/trs/${trId}/tr_submissions/${id}`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useGetTrsQuery,
  useGetTrQuery,
  useCreateTrMutation,
  useUpdateTrMutation,
  useSubmitTrMutation,
  useCancelTrSubmissionMutation
} = souptoolsTrsApi;
