import { companySchema } from 'app/organisms/OnboardingFormInfoCompany/OnboardingFormInfoCompany';
import {
  contactInfoSchema,
  contactInfoSchemaName
} from 'app/organisms/OnboardingFormInfoContact/OnboardingFormInfoContact';
import { govExperienceSchema } from 'app/organisms/OnboardingFormInfoGovExperience/OnboardingFormInfoGovExperience';
import { contractVehicleSchema } from 'app/organisms/OnboardingFormInfoCVs/OnboardingFormInfoCVs';

export const onboardingSteps = {
  adminFlow: {
    start: {
      schema: companySchema,
      title: 'Welcome to Govly! 🎉',
      number: 1,
      next: 'contactInfo'
    },
    contactInfo: {
      schema: contactInfoSchema,
      title: 'Your Info',
      number: 2,
      prev: 'start',
      next: 'governmentExperience'
    },
    governmentExperience: {
      schema: govExperienceSchema,
      title: 'Government Experience',
      number: 3,
      prev: 'contactInfo',
      next: 'contractVehicles'
    },
    contractVehicles: {
      schema: contractVehicleSchema,
      title: 'Contract Vehicles',
      number: 4,
      prev: 'governmentExperience',
      next: 'invitations'
    },
    invitations: {
      title: 'Team Members',
      number: 5,
      prev: 'contractVehicles',
      next: 'completed'
    }
  },
  memberFlow: {
    start: {
      schema: contactInfoSchema,
      title: 'Welcome to Govly! 🎉',
      number: 1,
      next: 'invitations'
    },
    invitations: {
      title: 'Team Members',
      number: 2,
      prev: 'start',
      next: 'completed'
    }
  },
  inviteeFlow: {
    start: {
      schema: contactInfoSchemaName,
      title: 'Welcome to Govly! 🎉',
      number: 1,
      next: 'contactInfo'
    },
    contactInfo: {
      schema: contactInfoSchema,
      title: 'Your Info',
      number: 2,
      prev: 'start',
      next: 'invitations'
    },
    invitations: {
      title: 'Team Members',
      number: 3,
      prev: 'contactInfo',
      next: 'completed'
    }
  }
};
