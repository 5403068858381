import React from 'react';
import { useLocation } from 'react-router-dom';
import { marketingRoutes, noFooterRoutes } from 'app/lib/routing';
import { MarketingFooter } from 'app/molecules/MarketingFooter/MarketingFooter';
import { AppFooter } from './AppFooter';

export const Footer = () => {
  const { pathname } = useLocation();

  if (noFooterRoutes.includes(pathname)) {
    return null;
  }

  if (marketingRoutes.includes(pathname)) {
    return <MarketingFooter />;
  }

  return <AppFooter />;
};
