import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Button, AnchorButton } from '@blueprintjs/core';
import * as yup from 'yup';

import LogoIcon from 'images/components/LogoIcon';
import { CardBody } from 'app/atoms/Card/Card';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { SignInForm } from 'app/organisms/SignInForm/SignInForm';

export const SignInVerification = () => {
  const navigate = useNavigate();
  const formRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const csrfToken = document.querySelector('[name="csrf-token"]')?.content;

  const [otpClientToken, setOtpClientToken] = useState('');
  const [otpClientEmail, setOtpClientEmail] = useState('');

  const hasRun = useRef(false);

  useEffect(() => {
    if (hasRun.current) return;

    hasRun.current = true;
    const token = searchParams.get('t');
    const email = searchParams.get('email');

    if (!email || !token) {
      return navigate('/sign_in');
    }

    if (token) {
      setOtpClientToken(token);
      searchParams.delete('t');
    }

    if (email) {
      setOtpClientEmail(email);
      searchParams.delete('email');
    }

    setSearchParams(searchParams, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CardBody>
      <div className="space-y-4 text-center">
        <LogoIcon className="mx-auto h-16 w-auto rounded" />
        <h1 className="m-0 text-2xl font-semibold">Verify your account</h1>
      </div>
      <div className="space-y-4">
        <Formik
          validateOnBlur={false}
          initialValues={{ otp: '' }}
          validationSchema={yup.object({
            otp: yup.string().required('Verification code is required.')
          })}
          onSubmit={() => {
            formRef.current.submit();
          }}
        >
          {({ isSubmitting }) => (
            <Form className="space-y-4" ref={formRef} action="/sign_in/verify" method="POST">
              <input type="hidden" name="authenticity_token" value={csrfToken} readOnly={true} />
              <input type="hidden" name="otp_client_token" value={otpClientToken} readOnly={true} />
              <input type="hidden" name="email" value={otpClientEmail} readOnly={true} />
              <div className="flex flex-col space-y-4 text-base">
                <div>
                  <p>
                    <strong>Email: {otpClientEmail}</strong>
                  </p>
                  <p>A verification code was sent to your email. Enter it below to verify your identity.</p>
                </div>
                <TextInput name="otp" inputProps={{ autoFocus: true, large: true }} />
                <Button loading={isSubmitting} type="submit" large fill text="Verify and sign in" />
              </div>
            </Form>
          )}
        </Formik>
        <div>
          <div className="flex justify-between mt-8">
            <AnchorButton href="/sign_in" text="Go back" minimal small />
            <SignInForm email={otpClientEmail} />
          </div>
        </div>
      </div>
    </CardBody>
  );
};
