import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button, NonIdealState } from '@blueprintjs/core';
import keyBy from 'lodash-es/keyBy';

import { useGetPartnershipQuery } from 'api/partnershipsApi';
import { useGetPartnerFeedsQuery, useBulkCreatePartnerFeedsMutation } from 'api/partnerFeedsApi';
import { useGetProviderFeedsQuery } from 'api/providerFeedsApi';
import { ReactTableTemplate, TableColumnHeader } from 'app/templates/ReactTableTemplate/ReactTableTemplate';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { PartnerFeedTableCheckbox } from './PartnerFeedTableCheckbox';

export const PartnerFeeds = () => {
  const { id: partnershipId } = useParams();
  const { data: { partnerId } = {}, isLoading: partnerLoading } = useGetPartnershipQuery({ id: partnershipId });
  const { data: partnerFeeds = [], isLoading: authFeedsLoading } = useGetPartnerFeedsQuery({
    partnerId
  });

  const { data: managedFeeds = [], isLoading: managedFeedsLoading } = useGetProviderFeedsQuery();

  const feeds = useMemo(() => {
    const authFeedsIndex = keyBy(partnerFeeds, 'providerFeedId');

    return managedFeeds.map(feed => {
      const authFeed = authFeedsIndex[feed.id];
      return { ...feed, authFeedId: authFeed?.publicId, shared: !!authFeed };
    });
  }, [managedFeeds, partnerFeeds]);

  const columns = useMemo(
    () => [
      {
        Header: 'Feed Name',
        id: 'feedName',
        HeaderComponent: React.memo(TableColumnHeader),
        accessor: 'name',
        sortType: 'basic',
        textFilter: true
      },
      {
        Header: 'Shared?',
        sortType: 'basic',
        HeaderComponent: React.memo(TableColumnHeader),
        accessor: 'shared',
        Cell: e => (
          <div className="flex">
            <PartnerFeedTableCheckbox
              id={e.row.original.authFeedId}
              partnershipId={partnershipId}
              organizationId={partnerId}
              providerFeedPublicId={e.row.original.publicId}
            />
          </div>
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [feeds]
  );

  const tableData = useMemo(() => feeds, [feeds]);

  const bulkCreateParams = useMemo(
    () =>
      feeds
        .filter(({ authFeedId }) => !authFeedId)
        .map(({ publicId }) => ({
          providerFeedPublicId: publicId,
          partnershipId,
          organizationId: partnerId
        })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [feeds]
  );
  const [bulkCreate, { isLoading: isBulkCreating }] = useBulkCreatePartnerFeedsMutation();

  if (managedFeedsLoading || authFeedsLoading || partnerLoading) {
    return <Loading />;
  }

  if (!managedFeeds.length) {
    return (
      <Card
        title="Shared Feeds"
        subtitle="A list of all the feeds you manage in Govly and whether they should be shared with this partner."
      >
        <CardBody>
          <NonIdealState
            icon="warning-sign"
            title="No feeds configured"
            description="You are not feeding data into Govly."
          />
        </CardBody>
      </Card>
    );
  }

  const ToolbarComponents = () => (
    <div key="share-all" className="sm:flex-none">
      <Button
        loading={isBulkCreating}
        disabled={isBulkCreating || bulkCreateParams.length === 0}
        onClick={() => bulkCreate({ partnerId, partnerFeeds: bulkCreateParams })}
        large
        intent="primary"
        text="Share all"
      />
    </div>
  );

  return (
    <ReactTableTemplate
      columns={columns}
      data={tableData}
      ToolbarComponents={ToolbarComponents}
      title="Shared Feeds"
      description="A list of all the feeds you manage in Govly and whether they should be shared with this partner."
      headerType="card-header"
      initialStateProps={{
        sortBy: [{ id: 'feedName', desc: false }],
        pageSize: 50
      }}
    />
  );
};
