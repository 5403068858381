import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Popover, Tooltip } from '@blueprintjs/core';

import { useGetReactionsQuery, useCreateReactionMutation } from 'api/reactionsApi';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { availableReactions, reactionsWithDescriptions } from 'app/lib/reactions';

export const Reactions = ({ commentId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: { name: currentUserName } = {} } = useGetCurrentUserQuery();
  const { data: reactions = [], isLoading } = useGetReactionsQuery({ reactableId: commentId });
  const [createCommentReaction] = useCreateReactionMutation();

  const reactionsToDisplay = reactions.reduce((acc, reaction) => {
    if (!acc[reaction.name]) acc[reaction.name] = [];
    acc[reaction.name].push(reaction.orgUserName);

    return acc;
  }, {});

  const handleClick = reaction => {
    setIsOpen(false);
    createCommentReaction({
      name: reaction,
      reactableType: 'Comment',
      reactableId: commentId
    });
  };

  const defaultButtons = availableReactions
    .map(reaction => <Button minimal key={reaction} text={reaction} onClick={() => handleClick(reaction)} />)
    .reverse();

  const reactedButtons = Object.entries(reactionsToDisplay).map(([name, users]) => (
    <Tooltip
      content={`${users.join(', ')} reacted with ${reactionsWithDescriptions[name]}`}
      key={`${name} ${users.length}`}
    >
      <Button
        className="mx-1 rounded-full"
        intent={users.includes(currentUserName) ? 'primary' : null}
        text={`${name} ${users.length}`}
        onClick={() => handleClick(name)}
      />
    </Tooltip>
  ));

  return (
    <div className="flex justify-end -mb-2">
      <div data-test="reacted-emoji-buttons">{reactions.length > 0 && reactedButtons}</div>
      <Popover
        position="top-right"
        minimal
        isOpen={isOpen}
        content={
          <ButtonGroup className="p-1" data-test="reaction-emoji-buttons">
            {defaultButtons}
          </ButtonGroup>
        }
        onClose={() => setIsOpen(false)}
        canEscapeKeyClose
      >
        <Button
          className="rounded-full"
          minimal
          icon="emoji"
          disabled={isLoading}
          onClick={() => setIsOpen(true)}
          data-test="reactions-button"
        />
      </Popover>
    </div>
  );
};

Reactions.propTypes = {
  commentId: PropTypes.string.isRequired
};
