import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from '@blueprintjs/core';
import orderBy from 'lodash-es/orderBy';

import { pluralize } from 'app/lib/strings';

const propTypes = {
  entities: PropTypes.array.isRequired,
  pluralizedLabels: PropTypes.array.isRequired,
  tagProps: PropTypes.object
};

export const OppSearchEntityExtractTagList = ({ entities, tagProps = {}, pluralizedLabels }) => {
  if (!entities.length) return null;

  const list = orderBy(entities, ['frequency', 'attachmentFrequency'], ['desc', 'desc']).slice(0, 11);

  return (
    <>
      {list.map(({ id, name, frequency }, i) => (
        <Tag key={`${id}-${i}`} minimal intent={frequency > 0 ? 'success' : 'warning'} {...tagProps}>
          {`${name} ${frequency > 0 ? frequency : ''}`}
        </Tag>
      ))}
      {entities.length > 10 && (
        <Tag minimal intent="warning" {...tagProps}>
          <strong>{`${entities.length - 10} `}</strong>
          {`additional ${pluralize(pluralizedLabels[0], pluralizedLabels[1], entities.length - 10)} not listed...`}
        </Tag>
      )}
    </>
  );
};

OppSearchEntityExtractTagList.propTypes = propTypes;
