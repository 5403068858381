import { DateRangeShortcut } from '@blueprintjs/datetime';
import { DateTime, Interval, DateTimeFormatOptions, DurationLikeObject } from 'luxon';

export const futureDateShortcuts = (): DateRangeShortcut[] => {
  const makeDate = (action: (date: Date) => void) => {
    const date = new Date();
    action(date);
    date.setDate(date.getDate());
    return date;
  };
  const today = new Date();
  const tomorrow = makeDate(d => d.setDate(d.getDate() + 1));
  const oneWeekFromNow = makeDate(d => d.setDate(d.getDate() + 7));
  const oneMonthFromNow = makeDate(d => d.setMonth(d.getMonth() + 1));
  const threeMonthsFromNow = makeDate(d => d.setMonth(d.getMonth() + 3));
  const sixMonthsFromNow = makeDate(d => d.setMonth(d.getMonth() + 6));
  const oneYearFromNow = makeDate(d => d.setMonth(d.getMonth() + 12));
  const twoYearsFromNow = makeDate(d => d.setMonth(d.getMonth() + 24));
  const threeYearsFromNow = makeDate(d => d.setMonth(d.getMonth() + 36));
  return [
    { label: 'Today', dateRange: [today, today] },
    { label: 'Tomorrow', dateRange: [tomorrow, tomorrow] },
    { label: 'Next 7 days', dateRange: [today, oneWeekFromNow] },
    { label: 'Next month', dateRange: [today, oneMonthFromNow] },
    { label: 'Next 3 months', dateRange: [today, threeMonthsFromNow] },
    { label: 'Next 6 months', dateRange: [today, sixMonthsFromNow] },
    { label: 'Next year', dateRange: [today, oneYearFromNow] },
    { label: '2 years from now', dateRange: [today, twoYearsFromNow] },
    { label: '3 years from now', dateRange: [today, threeYearsFromNow] }
  ];
};
export const nearFutureDateShortcuts = futureDateShortcuts().slice(0, 6);

export const farFutureDateShortcuts = futureDateShortcuts().slice(2, 9);

export const contactDateRangeStart = (): Date => DateTime.local().minus({ years: 7 }).startOf('day').toJSDate();

export const oneMonthAgo = () => {
  return DateTime.local().minus({ months: 1 }).startOf('day').toJSDate();
};

export const defaultDateRangeStart = () => {
  return DateTime.local().minus({ months: 3 }).startOf('day').toJSDate();
};

export const defaultDateRangeEnd = (): Date => {
  return DateTime.local().endOf('day').toJSDate();
};

export const isToday = (isoString: string | number | Date) => {
  const date = new Date(isoString);
  const today = new Date();
  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  );
};

export const DATETIME_24_SHORT_WITH_SECONDS: DateTimeFormatOptions = {
  year: '2-digit',
  day: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
  month: 'numeric',
  second: '2-digit',
  timeZoneName: 'short',
  hour12: false
};

export const DATETIME_24_SHORT: DateTimeFormatOptions = {
  day: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
  month: 'numeric',
  year: '2-digit',
  timeZoneName: 'short',
  hour12: false
};

export const DATE_SHORT: DateTimeFormatOptions = {
  day: 'numeric',
  month: 'numeric',
  year: '2-digit'
};

export const DATE_MONTH_AND_DAY: DateTimeFormatOptions = {
  month: 'long',
  day: 'numeric',
  year: 'numeric'
};

export const TIME_HOUR_AND_MINUTE: DateTimeFormatOptions = {
  hour: 'numeric',
  minute: '2-digit'
};

export const relativeTime = (isoString: string) => {
  const time = DateTime.fromISO(isoString);
  return time.toRelative();
};

export const formatTime = (isoString: string, format = DATE_SHORT) => {
  const time = DateTime.fromISO(isoString).toLocaleString(format);
  if (time.includes('Invalid')) {
    return null;
  }
  return time;
};

export const timeAgoIn = ({ unit = 'days', startISO }: { unit?: keyof DurationLikeObject; startISO: string }) =>
  Interval.fromDateTimes(DateTime.fromISO(startISO), DateTime.local()).length(unit);

export const awardShortcuts = (): DateRangeShortcut[] => {
  const makeDate = (action: (date: Date) => void) => {
    const date = new Date();
    action(date);
    date.setDate(date.getDate());
    return date;
  };
  const today = new Date();
  const oneMonthFromNow = makeDate(d => d.setMonth(d.getMonth() + 1));
  const threeMonthsFromNow = makeDate(d => d.setMonth(d.getMonth() + 3));
  const sixMonthsFromNow = makeDate(d => d.setMonth(d.getMonth() + 6));
  const oneYearFromNow = makeDate(d => d.setMonth(d.getMonth() + 12));
  const lastMonth = makeDate(d => d.setMonth(d.getMonth() - 1));
  const lastYear = makeDate(d => d.setFullYear(d.getFullYear() - 1));
  const last6Months = makeDate(d => d.setMonth(d.getMonth() - 6));
  const last3Months = makeDate(d => d.setMonth(d.getMonth() - 3));

  return [
    { label: 'Past year', dateRange: [lastYear, today] },
    { label: 'Last 6 months', dateRange: [last6Months, today] },
    { label: 'Last 3 months', dateRange: [last3Months, today] },
    { label: 'Last month', dateRange: [lastMonth, today] },
    { label: 'Next month', dateRange: [today, oneMonthFromNow] },
    { label: 'Next 3 months', dateRange: [today, threeMonthsFromNow] },
    { label: 'Next 6 months', dateRange: [today, sixMonthsFromNow] },
    { label: 'Next year', dateRange: [today, oneYearFromNow] }
  ];
};
