import React from 'react';
import { useFormikContext } from 'formik';

import { useContactSearchMutation } from 'api/contactsApi';
import { pluralize } from 'app/lib/strings';
import { Loading } from 'app/atoms/Loading/Loading';
import { SearchResultsSort } from 'app/organisms/SearchResults/SearchResultsSort';
import { SearchResultsExportButton } from 'app/organisms/SearchResults/SearchResultsExportButton';

export const ContactSearchResultsControls = () => {
  const { values } = useFormikContext();
  const { query, filters } = values;

  const [
    _search,
    { isFetching, isSuccess, data: { meta: { totalRelation, total, currentPage, totalPages } = {} } = {} }
  ] = useContactSearchMutation({
    fixedCacheKey: 'contact-search'
  });

  if (isFetching) {
    return <Loading type="flex-row" />;
  }

  if (!isSuccess) {
    return null;
  }

  let totalRelationPrefix = '';

  if (totalRelation && totalRelation.includes('gt')) {
    totalRelationPrefix = 'More Than ';
  }

  const resultCountLabel = `${totalRelationPrefix}${total.toLocaleString()} ${pluralize('result', 'results', total)}`;

  return (
    <div className="flex flex-wrap items-end justify-between px-6 lg:px-0">
      <div>
        <span data-test="opp-search-result-count" className="block text-lg font-medium text-gray-600">
          {resultCountLabel}
        </span>
        {totalPages > 0 && (
          <span className="block text-sm text-gray-500">
            Page {currentPage.toLocaleString()} of {totalPages.toLocaleString()}
          </span>
        )}
      </div>
      <div className="flex items-center space-x-2">
        <SearchResultsSort
          sortItems={[
            { value: 'last_active', label: 'Last Active' },
            { value: 'opp_count', label: 'Opportunity Count' }
          ]}
        />
        <SearchResultsExportButton query={query} filters={filters} resultType="contacts" />
      </div>
    </div>
  );
};
