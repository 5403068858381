import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';

import { trackError } from 'app/lib/errorHelpers';

export const rtkQueryLogger: Middleware = (_api: MiddlewareAPI) => next => action => {
  try {
    if (isRejectedWithValue(action)) {
      console.error('[RTK Query] Rejected:', action);

      const message =
        'data' in action.error ? (action.error.data as { message: string }).message : action.error.message;
      const url = 'arg' in action.meta ? (action.meta.arg as { endpointName: string }).endpointName : '';
      const endpoint =
        'baseQueryMeta' in action.meta ? (action.meta.baseQueryMeta as { request: { url: string } }).request.url : '';

      trackError(new Error(message), {
        action: 'RTK Query',
        tags: {
          url,
          endpoint
        }
      });
    }
  } catch (e) {
    console.error('Error in rtkQueryLogger:', e);
  }

  return next(action);
};
