import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NavItem } from './AuthenticatedNavItemsUtils';

export function useSelectNavItem() {
  const navigate = useNavigate();
  const params = useParams();

  return useCallback(
    (node: NavItem) => {
      const resolvedNode: typeof node = { ...node, to: replaceDynamicVariables(node.to, params) };

      if (resolvedNode?.absolute && resolvedNode?.external) {
        window.open(`${window.location.origin}/${resolvedNode.to}`);
      } else if (resolvedNode?.absolute) {
        window.location.pathname = `/${resolvedNode.to}`;
      } else {
        navigate(String(resolvedNode.to));
      }
    },
    [navigate, params]
  );
}

function replaceDynamicVariables(str: string, params: Record<string, string | undefined>): string {
  return str.replace(/:([a-zA-Z]+)/g, (_, key) => {
    return params[key] !== undefined ? params[key] : `:${key}`;
  });
}
