import { useMemo, useState } from 'react';
import Fuse, { IFuseOptions } from 'fuse.js';

const defaultOptions = { tokenize: true, threshold: 0.2 };

export type UseFuzzyTypeaheadProps<Item> = {
  data: Item[];
  options?: IFuseOptions<Item>;
};

export const useFuzzyTypeahead = <Item>({
  data,
  options = {}
}: UseFuzzyTypeaheadProps<Item>): {
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  results: Item[];
} => {
  const [query, setQuery] = useState<string>('');

  const client = useMemo(() => {
    return new Fuse(data, { ...defaultOptions, ...options });
  }, [data, options]);

  const filteredData = query ? client.search(query).map(r => r.item) : data;

  return { query, setQuery, results: filteredData };
};
