import { useGetPartnerFeedsQuery, GetPartnerFeeds } from 'api/partnerFeedsApi';

export const useFeedProvidersQuery = ({
  providerFeedIds,
  queryParams = {}
}: {
  providerFeedIds: string[];
  queryParams: GetPartnerFeeds['params'];
}) => {
  const { data = [], ...rest } = useGetPartnerFeedsQuery(queryParams);

  let providerFeeds = data.filter(({ providerFeedId }) => providerFeedIds.includes(providerFeedId));

  if (providerFeeds.some(({ dataOwnerName, organizationName }) => dataOwnerName == organizationName)) {
    providerFeeds = [];
  }

  return { data: providerFeeds, ...rest };
};
