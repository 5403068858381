import React, { useMemo } from 'react';
import { Button, Intent, Tag } from '@blueprintjs/core';
import { createColumnHelper } from '@tanstack/react-table';

import { ProfileClaim } from 'types/__generated__/GovlyApi';
import { useGetProfileClaimsQuery, useUpdateProfileClaimMutation } from 'api/profileClaimsApi';

import { DATETIME_24_SHORT, formatTime } from 'app/lib/dates';
import { errorToast, successToast } from 'app/lib/toaster';

import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { CardHeading } from 'app/atoms/Typography/Typography';
import { GovlyTableRowCountTag } from 'app/molecules/GovlyTable/GovlyTableRowCountTag';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';

const columnHelper = createColumnHelper<ProfileClaim>();

const STATUS_MAP = { pending: 'primary', approved: 'success', denied: 'danger' };

export const ProfileClaimsTable = () => {
  const { data = [], isLoading } = useGetProfileClaimsQuery();
  const [updateProfileClaim] = useUpdateProfileClaimMutation();

  const sendUpdateProfileClaim = async (payload: Parameters<typeof updateProfileClaim>[number]) => {
    try {
      await updateProfileClaim(payload);
      successToast('Profile claim updated');
    } catch (error) {
      errorToast(error);
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('createdAt', {
        header: 'Sent At',
        cell: e => <>{formatTime(e.row.original.createdAt, DATETIME_24_SHORT)}</>
      }),
      columnHelper.accessor('organization.name', {
        header: 'Organization',
        enableSorting: false,
        cell: e => (
          <div>
            <div>Org: {e.row.original.organization?.name}</div>
            <div>User Name: {e.row.original.actor?.name}</div>
            <div>User Email: {e.row.original.actor?.email}</div>
          </div>
        )
      }),
      columnHelper.accessor('claimable.name', {
        header: 'Vendor',
        enableSorting: false,
        cell: e => {
          if (!e.row.original.claimable) return null;

          return (
            <div>
              <LinkTag target="_blank" to={`/vendors/${e.row.original.claimable.slug}`}>
                {e.row.original.claimable.name}
              </LinkTag>
            </div>
          );
        }
      }),
      columnHelper.accessor('state', {
        header: 'Status',
        enableSorting: false,
        cell: e => {
          const status = e.row.original.state;
          return <Tag intent={(STATUS_MAP[status] as Intent) ?? 'none'}>{status}</Tag>;
        }
      }),
      columnHelper.accessor('id', {
        header: 'Actions',
        enableSorting: false,
        cell: e => (
          <div className="space-y-2">
            <Button
              className="block"
              small
              intent="success"
              text="approve"
              onClick={() => sendUpdateProfileClaim({ id: e.row.original.id, state: 'approved' })}
            />
            <Button
              className="block"
              small
              intent="danger"
              text="deny"
              onClick={() => sendUpdateProfileClaim({ id: e.row.original.id, state: 'denied' })}
            />
          </div>
        )
      })
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tableData = useMemo(() => data, [data]);

  return (
    <GovlyTable
      trackingObject="profile_claims_table"
      columns={columns}
      data={tableData}
      title={
        <span className="flex gap-2 items-center">
          <CardHeading>Profile Claims</CardHeading>
          <GovlyTableRowCountTag />
        </span>
      }
      isLoading={isLoading}
      initialState={{
        pagination: {
          pageSize: 50
        },
        sorting: [
          {
            id: 'createdAt',
            desc: true
          }
        ]
      }}
    />
  );
};
