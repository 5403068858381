import React, { useState } from 'react';
import { useField } from 'formik';
import { Button, Classes, FormGroup, FormGroupProps } from '@blueprintjs/core';

import { cn } from 'app/lib/cn';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';
import { FileUploader } from 'app/molecules/FileUploaders/FileUploader/FileUploader';

export type FileInputProps = {
  name: string;
  buttonText?: string;
} & FormGroupProps;

export const FileInput = ({
  name,
  className,
  helperText,
  disabled,
  label,
  labelInfo,
  buttonText = 'Upload',
  ...rest
}: FileInputProps) => {
  const [pendingFilename, setPendingFilename] = useState<string>();
  const [field, meta, { setValue }] = useField({ name });
  const error = meta.touched && meta.error;
  const intent = error ? 'danger' : undefined;

  const filename = pendingFilename || field.value?.name || '';
  const truncatedName =
    filename.length > 25
      ? `${filename.substring(0, 12)}...${filename.substring(filename.length - 18, filename.length)}`
      : filename;

  return (
    <FormGroup
      className={cn('flex w-96 flex-col', className)}
      helperText={helperText}
      disabled={disabled}
      label={<LabelSpan label={label} />}
      labelInfo={labelInfo}
      labelFor={name}
      intent={intent}
      {...rest}
    >
      <FileUploader
        id={name}
        useUploadArgs={{ maxFiles: 1 }}
        wrapperElement="span"
        onInitialize={file => {
          setPendingFilename(file.name);
        }}
        onAttach={async attachment => {
          setValue(attachment.signedId);
        }}
      >
        <div className={cn(Classes.INPUT_GROUP, Classes.LARGE, 'w-96')}>
          <input readOnly value={truncatedName} className={cn(Classes.INPUT, 'cursor-pointer')} />
          <Button icon="upload" text={filename.length ? 'Change' : buttonText} />
        </div>
      </FileUploader>
      {field.value?.link && !pendingFilename && (
        <div className="mt-2 flex flex-row-reverse items-center gap-x-2">
          <Button small icon="cross" intent="danger" minimal onClick={() => setValue(null)} />
          <LinkTag tag="a" target="_blank" rel="noreferrer" href={field.value.link}>
            {truncatedName}
          </LinkTag>
        </div>
      )}
      <small className="text-xs text-red-500">{meta.error}</small>
    </FormGroup>
  );
};
