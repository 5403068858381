import React from 'react';
import * as yup from 'yup';

import { MultiTagInput } from 'app/atoms/inputs/MultiTagInput/MultiTagInput';
import { useGetContractVehiclesQuery } from 'api/contractVehiclesApi';
import { SwitchInput } from 'app/atoms/inputs/SwitchInput/SwitchInput';

export const contractVehicleSchema = yup.object({
  contractVehicles: yup.array().required('Government Experience is required')
});

export const OnboardingFormInfoCVs = () => {
  const { data: contractVehicles = [], isLoading } = useGetContractVehiclesQuery();
  const items = contractVehicles.map(({ contractName }) => contractName);

  return (
    <>
      <MultiTagInput
        label="What Existing Contract Vehicles Are You Interested In?"
        name="contractVehicles"
        items={items}
        loading={isLoading}
      />
      <SwitchInput
        label="Are you interested in creating your own contract vehicle to post opportunities to the Govly Network?"
        name="interestedInCustomContractVehicle"
      />
    </>
  );
};
