import React from 'react';
import { Formik, Form } from 'formik';
import { DateTime } from 'luxon';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';

import { SelectInput } from 'app/atoms/inputs/SelectInput/SelectInput';
import { DateRangeInput } from 'app/atoms/inputs/DateRangeInput/DateRangeInput';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { useGetProviderFeedsQuery } from 'api/providerFeedsApi';
import { useCreateReportMutation } from 'api/reportsApi';
import { successToast, errorToast } from 'app/lib/toaster';

const CONTRACTS = {
  'ites-3s': 'ITES-3S',
  'ites-sw2': 'ITES-SW2',
  'ites-3h': 'ITES-3H',
  admc3: 'ADMC3'
};

export const FeedProfileChessReports = () => {
  const { data, isLoading } = useGetProviderFeedsQuery();
  const [createReport, { isLoading: isSubmitting }] = useCreateReportMutation();

  if (isLoading) {
    return <Loading />;
  }

  const contractOptions =
    data
      ?.filter(({ contractVehicle: { slug } }) => CONTRACTS[slug])
      ?.map(({ contractVehicle: { slug } }) => ({ label: CONTRACTS[slug], value: slug })) || [];

  return (
    <Formik
      initialValues={{
        dateRange: '',
        reportType: 'Report::ChessRfqReport',
        contractVehicleSlug: ''
      }}
      validationSchema={Yup.object().shape({
        dateRange: Yup.array().required('Date Range is required'),
        reportType: Yup.string().required('Report Type is required'),
        contractVehicleSlug: Yup.string().required('Contract Vehicle is required')
      })}
      onSubmit={async values => {
        try {
          await createReport(values).unwrap();
          successToast('Your report will be emailed to you shortly.');
        } catch (e) {
          errorToast(e);
        }
      }}
    >
      <Form>
        <Card title="CHESS Reports">
          <CardBody>
            <div className="flex gap-8">
              <SelectInput label="Contract Vehicle" name="contractVehicleSlug" items={contractOptions} />
              <SelectInput
                label="Report Type"
                name="reportType"
                items={[{ label: 'CHESS RFQ Reporting Data', value: 'Report::ChessRfqReport' }]}
              />
              <DateRangeInput
                label="Date Range"
                name="dateRange"
                large
                maxDate={DateTime.local().endOf('day').toJSDate()}
              />
            </div>
          </CardBody>
          <CardFooter>
            <Button
              large
              type="submit"
              intent="primary"
              text="Generate Report"
              disabled={!contractOptions.length}
              loading={isSubmitting}
            />
          </CardFooter>
        </Card>
      </Form>
    </Formik>
  );
};
