import React, { useState } from 'react';
import { NonIdealState } from '@blueprintjs/core';

export const ActiveWorklistEmptyState = () => {
  const [emptyInboxAttrs] = useState({
    emoji: ['✅', '🎉', '👏', '👍'][Math.floor(Math.random() * 4)],
    message: ['All done here', 'Inbox zero', 'Nice work', 'Keep it up'][Math.floor(Math.random() * 4)]
  });

  return (
    <NonIdealState
      icon={
        <span role="img" aria-label="check icon">
          {emptyInboxAttrs.emoji}
        </span>
      }
      title={emptyInboxAttrs.message}
      description="Your active Worklist is empty."
    />
  );
};

export const InactiveWorklistEmptyState = () => (
  <NonIdealState
    icon="inbox"
    title="Nothing has been checked off your Worklist"
    description="Not seeing what you expect? Try using the filter dropdown in the top right corner."
  />
);
