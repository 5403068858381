import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@blueprintjs/core';
import { Formik, Form } from 'formik';

import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { TextAreaInput } from 'app/atoms/inputs/TextAreaInput/TextAreaInput';
import { useCreatePartnershipRequestMutation } from 'api/partnershipRequestApi';
import { successToast, errorToast } from 'app/lib/toaster';
import { H2 } from 'app/atoms/Typography/Typography';

export const PartnershipRequestForm = ({ onCancel, targetVendorId, targetOrganizationId, targetVendorName }) => {
  const [submitted, setSubmitted] = useState(false);
  const [createPartnershipRequestMutation] = useCreatePartnershipRequestMutation();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await createPartnershipRequestMutation({
        targetVendorId,
        targetOrganizationId,
        ...values
      }).unwrap();
      successToast('Partnership request sent!');
      resetForm();
      setSubmitted(true);
    } catch (e) {
      errorToast(e);
    }
  };

  return (
    <Formik
      initialValues={{
        details: {
          message: ''
        }
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Card
            title={`Invite ${targetVendorName} to Partner`}
            subtitle="Partnerships allow organizations to securely share opportunities and work together in Govly Workspaces."
            rightElement={<Button minimal icon="cross" onClick={onCancel} />}
          >
            {submitted ? (
              <CardBody>
                <div className="my-8 text-center">
                  <H2>✅ Invite Sent</H2>
                  {!targetOrganizationId && (
                    <p className="font-bold">
                      Note: This vendor is not a govly organization, however we will reach out to them on your behalf.
                    </p>
                  )}
                </div>
              </CardBody>
            ) : (
              <CardBody>
                <div className="grid grid-cols-2 gap-x-4 gap-y-4">
                  <div className="col-span-2">
                    <TextAreaInput
                      label="Message"
                      labelInfo="(optional)"
                      name="details.message"
                      inputProps={{ large: true }}
                    />
                  </div>
                </div>
                <CardFooter>
                  <Button intent="primary" large type="submit" text="Send" loading={isSubmitting} />
                  <Button text="Cancel" disabled={isSubmitting} onClick={onCancel} />
                </CardFooter>
              </CardBody>
            )}
          </Card>
        </Form>
      )}
    </Formik>
  );
};

PartnershipRequestForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  targetVendorId: PropTypes.string.isRequired,
  targetOrganizationId: PropTypes.string,
  targetVendorName: PropTypes.string
};
