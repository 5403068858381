import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@blueprintjs/core';

import { useCreateOppInteractionMutation } from 'api/oppInteractionsApi';
import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { useEventTracking } from 'app/hooks/useEventTracking';

const propTypes = {
  id: PropTypes.string.isRequired,
  interaction: PropTypes.object,
  isLoading: PropTypes.bool
};

export const OppSearchIgnoreButton = ({ id, interaction, isLoading }) => {
  const { isMobile } = useDeviceWidth();
  const { trackEvent } = useEventTracking();
  const [interact, { isLoading: isInteracting }] = useCreateOppInteractionMutation();

  return (
    <Button
      disabled={isLoading || isInteracting}
      icon="thumbs-down"
      text={isMobile ? '' : 'Not Interested'}
      loading={isInteracting}
      intent={interaction?.ignoredAt ? 'warning' : null}
      onClick={async () => {
        const ignored = !interaction?.ignoredAt;
        await interact({ oppId: id, ignored });
        trackEvent({
          object: 'opp',
          action: ignored ? 'ignored' : 'unignored',
          properties: { oppId: id }
        });
      }}
    />
  );
};

OppSearchIgnoreButton.propTypes = propTypes;
