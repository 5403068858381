import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';

import { OrganizationUserDisplayable } from 'types/__generated__/GovlyApi';
import { useGetPartnershipQuery } from 'api/partnershipsApi';

import { CardHeading } from 'app/atoms/Typography/Typography';
import { AvatarWithName } from 'app/molecules/AvatarWithName/AvatarWithName';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableRowCountTag } from 'app/molecules/GovlyTable/GovlyTableRowCountTag';

const columnHelper = createColumnHelper<OrganizationUserDisplayable>();

export const PartnershipContactsTable = () => {
  const { id } = useParams();
  const { data: { contacts = [] } = {}, isLoading } = useGetPartnershipQuery({ id: id ?? '' }, { skip: !id });

  const contactUsers = useMemo(
    () => contacts.map(({ organizationUser }) => organizationUser).filter(Boolean) as OrganizationUserDisplayable[],
    [contacts]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        cell: e => {
          const image = e.row.original.avatar ? e.row.original.avatar.thumbUrl : '';

          return (
            <AvatarWithName
              initials={e.row.original.initials}
              imgSrc={image}
              avatarColor={e.row.original.avatarColor}
              id={e.row.original.id}
              email={e.row.original.email}
              name={e.row.original.name}
            />
          );
        }
      })
    ],
    []
  );

  const tableData = useMemo(() => contactUsers, [contactUsers]);

  return (
    <GovlyTable
      trackingObject="managed_feed_solicitors_table"
      columns={columns}
      data={tableData}
      title={
        <span className="flex gap-2 items-center">
          <CardHeading>Contacts</CardHeading>
          <GovlyTableRowCountTag />
          <span className="text-sm text-gray-500 font-normal">
            Partnership contacts are available for collaboration in Workspaces
          </span>
        </span>
      }
      emptyStateProps={{
        icon: 'warning-sign',
        title: 'No contacts are available'
      }}
      isLoading={isLoading}
      initialState={{
        pagination: {
          pageSize: 5
        }
      }}
    />
  );
};
