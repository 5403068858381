import React from 'react';

import { AvatarWithName } from 'app/molecules/AvatarWithName/AvatarWithName';
import { OrganizationUserCurrentOrg } from 'types/__generated__/GovlyApi';

interface UsersTableRowProps {
  organizationUser: OrganizationUserCurrentOrg;
}

export const UsersTableName = ({ organizationUser }: UsersTableRowProps) => {
  const { avatar, initials, email, id, avatarColor = '', name = '' } = organizationUser;
  const image = !avatar ? '' : avatar.thumbUrl;

  return (
    <AvatarWithName
      initials={initials}
      imgSrc={image}
      avatarColor={avatarColor}
      linkProps={{
        to: `/users/${id}`,
        state: {
          breadcrumbs: [{ to: '/settings/users', text: 'Users' }, { text: email }]
        }
      }}
      id={id}
      email={email}
      name={name}
    />
  );
};
