import { OrganizationLabel } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['OrganizationLabelTaggings', 'Opp', 'Workspace'] });

type GetOrganizationLabelTaggings = {
  params: {
    taggableId: string;
    taggableType: 'Opp' | 'Workspace';
  };
  result: OrganizationLabel[];
};

type UpdateOrganizationLabelTaggings = {
  params: {
    organizationLabelIds: string[];
    taggableId: string;
    taggableType: 'Opp' | 'Workspace';
    cacheTags?: { type: 'Opp'; id: string }[];
  };
  result: void;
};

export const organizationLabelTaggingsApi = api.injectEndpoints({
  endpoints: build => ({
    getOrganizationLabelTaggings: build.query<
      GetOrganizationLabelTaggings['result'],
      GetOrganizationLabelTaggings['params']
    >({
      keepUnusedDataFor: 120,
      query: ({ taggableId, taggableType }) => ({
        url: '/v2/organization_label_taggings',
        params: { taggableId, taggableType }
      }),
      providesTags: (result, error, arg) => [
        { type: 'OrganizationLabelTaggings', id: `${arg.taggableType}-${arg.taggableId}` }
      ]
    }),
    updateOrganizationLabelTaggings: build.mutation<
      UpdateOrganizationLabelTaggings['result'],
      UpdateOrganizationLabelTaggings['params']
    >({
      query: ({ organizationLabelIds, taggableId, taggableType }) => ({
        url: `/v2/organization_label_taggings`,
        method: 'PATCH',
        body: { organizationLabelIds, taggableId, taggableType }
      }),
      invalidatesTags: (result, error, arg) => [
        { type: arg.taggableType, id: arg.taggableId },
        arg.taggableType,
        ...(arg.cacheTags ?? [])
      ]
    })
  })
});

export const { useGetOrganizationLabelTaggingsQuery, useUpdateOrganizationLabelTaggingsMutation } =
  organizationLabelTaggingsApi;
