import React from 'react';
import { MultiTagInput, MultiTagInputProps } from 'app/atoms/inputs/MultiTagInput/MultiTagInput';
import { useGetTagsQuery } from 'api/tagsApi';
import { notNullish } from 'app/lib/notNullish';

type DynamicTagMultiSelectInputProps = {
  context: string;
} & MultiTagInputProps;

export const DynamicTagMultiSelectInput = ({ context, ...rest }: DynamicTagMultiSelectInputProps) => {
  const { data: tags = [], isLoading } = useGetTagsQuery({
    context
  });
  const items = tags.map(({ value }) => value).filter(notNullish);

  return <MultiTagInput {...rest} items={items} loading={isLoading} />;
};
