import React from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Button } from '@blueprintjs/core';
import keyBy from 'lodash-es/keyBy';

import { useGetPartnershipQuery, useUpdatePartnershipMutation } from 'api/partnershipsApi';
import { UserMultiSelectInput } from 'app/organisms/inputs/UserMultiSelectInput';
import { CheckboxInput } from 'app/atoms/inputs/CheckboxInput/CheckboxInput';
import { Card, CardSection, CardFooter } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { NotFound } from 'app/molecules/NotFound/NotFound';
import { successToast } from 'app/lib/toaster';

export const PartnershipDetailsCard = () => {
  const { id } = useParams();
  const { data: partnership, isLoading, isError } = useGetPartnershipQuery({ id });
  const [updatePartnership, { isLoading: isUpdating }] = useUpdatePartnershipMutation();

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <NotFound />;
  }

  const { peerId, partnerName, privateContacts, hasBranding, shareSensitiveOppData, representatives } = partnership;
  return (
    <Formik
      enableReinitialize={true}
      onSubmit={async ({ repIds, ...values }) => {
        const representativeIndex = keyBy(representatives, 'organizationUserId');

        const removedReps = representatives
          .filter(({ organizationUserId }) => !repIds.includes(organizationUserId))
          .map(({ id }) => ({ id, _destroy: '1' }));
        const reps = repIds.map(rid => ({
          id: representativeIndex[rid]?.id,
          organizationUserId: rid,
          partnershipId: peerId
        }));

        await updatePartnership({ representativesAttributes: [...reps, ...removedReps], ...values }).unwrap();
        successToast();
      }}
      initialValues={{
        id,
        privateContacts: !!privateContacts,
        hasBranding: !!hasBranding,
        shareSensitiveOppData: !!shareSensitiveOppData,
        repIds: representatives.map(({ organizationUserId }) => organizationUserId)
      }}
    >
      {({ values }) => (
        <Form>
          <Card title="Settings">
            <CardSection>
              <CheckboxInput
                className="block"
                label="Set specific representatives?"
                name="privateContacts"
                helperText="Assign specific representatives instead of defaulting to all users."
                disabled={isUpdating}
              />
              <CheckboxInput
                className="block"
                helperText="Show your logo on opportunities provided by your organization."
                label="Partnership has branding?"
                name="hasBranding"
                disabled={isUpdating}
              />
              <CheckboxInput
                className="block"
                helperText="Allow this partner to see buyer contact information for all opportunities."
                label="Share opportunity contact information?"
                name="shareSensitiveOppData"
                disabled={isUpdating}
              />
              {values.privateContacts && (
                <UserMultiSelectInput
                  name="repIds"
                  label="Representatives"
                  className="p-0"
                  helperText={`Representatives can be contacted by ${partnerName} in Workspaces.`}
                />
              )}
            </CardSection>
            <CardFooter>
              <Button large type="submit" loading={isUpdating}>
                Save
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
