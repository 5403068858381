import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from '@blueprintjs/core';

export const OppSearchResultTags = ({ tags = [] }) => {
  if (!tags?.length) return null;

  const groups = tags.reduce((acc, { context, name }) => {
    acc[context] = acc[context] || [];
    acc[context].push(name);
    return acc;
  }, {});

  return (
    <>
      {groups['warning_tags']?.map(tag => (
        <Tag key={tag} minimal icon="warning-sign" intent="warning">
          {tag}
        </Tag>
      ))}
      {groups['contract_tags']?.map(tag => (
        <Tag key={tag} minimal intent="primary">
          {tag}
        </Tag>
      ))}
    </>
  );
};

OppSearchResultTags.propTypes = {
  tags: PropTypes.array
};
