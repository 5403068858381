import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import truncate from 'lodash-es/truncate';
import { Formik, Form } from 'formik';
import { NonIdealState, Button, Tag, Dialog } from '@blueprintjs/core';

import { ReactTableTemplate, TableColumnHeader } from 'app/templates/ReactTableTemplate/ReactTableTemplate';
import { CardBody, Card } from 'app/atoms/Card/Card';
import { useGetOppSearchesQuery, useUpdateOppSearchMutation } from 'api/oppSearchesApi';
import { useGetSavedSearchesQuery, useUpdateSavedSearchMutation } from 'api/savedSearchesApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { avatarListColumn } from 'app/lib/tables';
import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { SelectInput } from 'app/atoms/inputs/SelectInput/SelectInput';
import { successToast, errorToast } from 'app/lib/toaster';
import { H2 } from 'app/atoms/Typography/Typography';
import { formatTime, DATETIME_24_SHORT } from 'app/lib/dates';
import { OppSavedSearchUpdateForm } from 'app/organisms/OppSavedSearchUpdateForm/OppSavedSearchUpdateForm';
import { SavedSearchUpdateForm } from 'app/organisms/SavedSearchUpdateForm/SavedSearchUpdateForm';
import { CopyButton } from 'app/molecules/CopyButton/CopyButton';

export const SavedSearchesTable = ({ searchableType, ...rest }) => {
  const [isEditing, setIsEditing] = useState(null);
  const [savedSearchFilter, setSavedSearchFilter] = useState('active');
  const { isMobile, isTablet, isDesktop } = useDeviceWidth();
  const [filterInTransition, setFilterTransition] = useState(false);
  const [updateOppSearch] = useUpdateOppSearchMutation();
  const [updateSearch] = useUpdateSavedSearchMutation();
  const updateSavedSearch = searchableType ? updateSearch : updateOppSearch;

  const options = savedSearchFilter === 'active' ? { active: true } : {};
  const {
    data: oppData = [],
    isLoading: oppLoading,
    isFetching: oppFetching
  } = useGetOppSearchesQuery(options, { refetchOnMountOrArgChange: true, skip: !!searchableType });
  const {
    data: searchableData = [],
    isLoading: searchableLoading,
    isFetching: searchableFetching
  } = useGetSavedSearchesQuery(
    { ...options, searchableType },
    { refetchOnMountOrArgChange: true, skip: !searchableType }
  );

  const data = searchableType ? searchableData : oppData;
  const isLoading = searchableType ? searchableLoading : oppLoading;
  const isFetching = searchableType ? searchableFetching : oppFetching;

  const hiddenColumns = useMemo(() => {
    const hidden = [];
    if (isMobile) hidden.push(...['query.query', 'follows']);
    if (searchableType) hidden.push(...['lastMatchedAt']);
    return hidden;
  }, [isMobile, searchableType]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        HeaderComponent: React.memo(TableColumnHeader),
        accessor: 'name',
        sortType: 'basic',
        filter: 'fuzzyText',
        textFilter: true,
        Cell: e => {
          const link = `/opportunities?savedSearchId=${e.row.original.id}`;
          return (
            <div>
              <H2>{truncate(e.row.original.name, { length: isMobile ? 40 : 60 })}</H2>
              <div className="mt-2 flex space-x-5">
                {e.row.original.active && !searchableType && (
                  <LinkTag to={link} target="_blank">
                    <Button
                      small={isDesktop ?? true}
                      intent="primary"
                      icon={isMobile || isTablet ? 'list-detail-view' : null}
                      text={isDesktop ? 'View Results' : 'Results'}
                    />
                  </LinkTag>
                )}
                <Button
                  small={isDesktop ?? true}
                  icon={isMobile || isTablet ? 'archive' : null}
                  intent={e.row.original.active ? 'danger' : 'success'}
                  text={e.row.original.active ? 'Archive' : 'Unarchive'}
                  onClick={async () => {
                    try {
                      await updateSavedSearch({
                        id: e.row.original.id,
                        searchableType,
                        active: !e.row.original.active
                      }).unwrap();
                      successToast();
                    } catch (e) {
                      errorToast(e);
                    }
                  }}
                />
                <Button
                  small={isDesktop ?? true}
                  icon={isMobile || isTablet ? 'new-person' : null}
                  text={isDesktop ? 'Update Followers' : ''}
                  onClick={() => setIsEditing(e.row.original.id)}
                />

                {e.row.original.active && !searchableType && (
                  <CopyButton
                    instructions="Copy URL to Clipboard"
                    copyText={`${location.host}${link}`}
                    small={isDesktop ?? true}
                  />
                )}
              </div>
            </div>
          );
        }
      },
      {
        Header: 'Query',
        HeaderComponent: React.memo(TableColumnHeader),
        accessor: 'query.query',
        sortType: 'basic',
        filter: 'fuzzyText',
        textFilter: true,
        width: 200,
        Cell: e => (
          <div className=" max-w-md whitespace-normal break-words">
            {truncate(e.row.original.query.query, { length: 200 })}
          </div>
        )
      },
      avatarListColumn({ header: 'Followers' }),
      {
        Header: 'Last Matched',
        HeaderComponent: React.memo(TableColumnHeader),
        accessor: 'lastMatchedAt',
        sortType: 'basic',
        Cell: e => {
          const { lastMatchedAt } = e.row.original;
          return lastMatchedAt ? (
            <Tag intent="success">{formatTime(lastMatchedAt, DATETIME_24_SHORT)}</Tag>
          ) : (
            <Tag intent="danger">No matches</Tag>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading]
  );

  if (isLoading || (isFetching && filterInTransition)) return <Loading type="table" />;

  if (!data.length) {
    return (
      <Card title="Saved Searches">
        <CardBody>
          <NonIdealState title="No Saved Searches" icon="folder-open" />
        </CardBody>
      </Card>
    );
  }

  const ToolbarComponent = () => (
    <Formik
      initialValues={{ selected: savedSearchFilter }}
      onSubmit={({ selected }) => {
        setFilterTransition(true);
        setSavedSearchFilter(selected);
      }}
    >
      {() => (
        <Form>
          <SelectInput
            contentClassName="m-0"
            name="selected"
            items={[
              { value: 'active', label: 'Active' },
              { value: 'all', label: 'All' }
            ]}
            searchKeys={['label']}
            filterable={false}
            buttonProps={{
              large: true,
              outlined: true,
              icon: 'filter'
            }}
            submitOnChange
          />
        </Form>
      )}
    </Formik>
  );

  return (
    <div {...rest}>
      <ReactTableTemplate
        columns={columns}
        data={data}
        title="Saved Searches"
        ToolbarComponents={ToolbarComponent}
        hasCSVButton={false}
        headerType="card-header"
        hiddenColumns={hiddenColumns}
      />

      {isEditing && (
        <Dialog
          isOpen={!!isEditing}
          onClose={() => setIsEditing(null)}
          className="min-w-[50%] bg-white p-0"
          title="Update saved search"
        >
          {searchableType ? (
            <SavedSearchUpdateForm
              savedSearchId={isEditing}
              searchableType={searchableType}
              onCancel={() => setIsEditing(null)}
              handleClose={() => setIsEditing(null)}
              followersOnly={false}
              initialQuery=""
              initialFilters={[]}
            />
          ) : (
            <OppSavedSearchUpdateForm
              savedSearchId={isEditing}
              followersOnly
              onCancel={() => setIsEditing(null)}
              handleClose={() => setIsEditing(null)}
              initialQuery=""
              initialFilters={[]}
            />
          )}
        </Dialog>
      )}
    </div>
  );
};

SavedSearchesTable.propTypes = {
  searchableType: PropTypes.string
};
