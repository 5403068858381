import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { ErrorBoundary } from 'app/atoms/ErrorBoundary/ErrorBoundary';

import { Loading } from 'app/atoms/Loading/Loading';
import { BarGraphWithDrilldown } from 'app/molecules/BarGraphWithDrilldown/BarGraphWithDrilldown';
import { useLazyOppAggregationQuery } from 'api/oppsApi';
import { OppSearchAnalyticsFallback } from 'app/organisms/OppSearchAnalyticsFallback/OppSearchAnalyticsFallback';
import { CardError } from 'app/atoms/ErrorFallback/CardError';
import { OppSearchState } from 'app/hooks/search/useOppSearchCache';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';
import 'highcharts/es-modules/masters/modules/drilldown.src';
import {
  buyerNameSelector,
  drilldownFiltersSelector,
  useOppSearchAnalyticsBuyerStore
} from 'app/organisms/OppSearchAnalyticsBuyer/useOppSearchAnalyticsBuyerStore';
import { OppSearchAnalyticsTableDrawer } from 'app/organisms/OppSearchAnalytics/OppSearchAnalyticsTableDrawer';
import { Tag } from '@blueprintjs/core';

type AggregationQueryResponse = {
  aggs?: {
    series: {
      id: string;
      name: string;
      y: number;
      drilldown: string;
    }[];
    csv: string;
    drilldown: {
      id: string;
      name: string;
      data: [string, number][];
    }[];
  };
};

export const OppSearchAnalyticsBuyer = ({ searchIsLoading }: { searchIsLoading: boolean }) => {
  const {
    values: { query, filters }
  } = useFormikContext<OppSearchState>();
  const title = 'Buyers';

  const drilldownFilters = useOppSearchAnalyticsBuyerStore(drilldownFiltersSelector);
  const { isDrawerOpen, setIsDrawerOpen } = useOppSearchAnalyticsBuyerStore();
  const buyerName = useOppSearchAnalyticsBuyerStore(buyerNameSelector);

  const [getOppsAggregation, { data = {}, isLoading, isError }] = useLazyOppAggregationQuery();

  useEffect(() => {
    getOppsAggregation({
      query,
      ...filters,
      aggs: ['nested_buyer_agg'],
      preprocessor: 'Opp::Analytics::NestedBuyerAggPreprocessor'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchIsLoading]);

  const aggs = (data as AggregationQueryResponse).aggs;

  const options: Highcharts.Options = useMemo(() => {
    // Can't mutate the RTK Query data directly
    const series: Highcharts.SeriesBarOptions[] = [
      {
        type: 'bar',
        name: 'Buyers',
        colorByPoint: true,
        data: (aggs?.series ?? []).map(d => ({ ...d }))
      }
    ];

    return {
      chart: {
        type: 'bar',
        height: '60%'
      },
      yAxis: {
        scrollbar: {
          enabled: true
        },
        allowDecimals: false,
        title: {
          text: 'Opportunities'
        }
      },
      tooltip: {
        followPointer: true,
        useHTML: true,
        pointFormat: '<b>{point.y:,.0f}</b> opportunities'
      },
      series,
      drilldown: {
        series: aggs?.drilldown?.map(d => ({ ...d, type: 'bar' })) ?? []
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: function (this: Highcharts.Point) {
                useOppSearchAnalyticsBuyerStore.setState({
                  lastClickedPoint: this,
                  isDrawerOpen: true
                });
              }
            }
          }
        }
      }
    };
  }, [aggs]);

  if (isLoading) {
    return <Loading />;
  }

  if (isError || !aggs?.series?.length) {
    return <OppSearchAnalyticsFallback />;
  }

  return (
    <ErrorBoundary action="OppSearchAnalyticsBuyer" fallback={<CardError title={title} />}>
      <BarGraphWithDrilldown title={title} csv={aggs?.csv} options={options} />

      <OppSearchAnalyticsTableDrawer
        drawerTitle={
          <>
            <span>Buyer Opportunities</span>
            <Tag minimal>{buyerName}</Tag>
          </>
        }
        trackingObject="opps_buyers"
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        key={JSON.stringify(drilldownFilters)}
        filters={drilldownFilters}
      />
    </ErrorBoundary>
  );
};
