import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { NewTabTooltip } from 'app/molecules/NewTabTooltip/NewTabTooltip';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { OppWorkspaceUpdateTags } from 'app/molecules/OppWorkspaceUpdateTags/OppWorkspaceUpdateTags';

const propTypes = {
  oppId: PropTypes.string.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  createdByOrganization: PropTypes.string,
  organizationDefault: PropTypes.bool,
  showLink: PropTypes.bool,
  visibleId: PropTypes.string,
  linkOpp: PropTypes.bool,
  updates: PropTypes.array,
  onClick: PropTypes.func,
  path: PropTypes.string
};

export const OppWorkspaceHeader = ({
  id,
  oppId,
  name,
  createdByOrganization,
  organizationDefault,
  showLink,
  visibleId,
  linkOpp,
  updates = [],
  onClick,
  path
}) => {
  const { id: paramsId } = useParams();
  const hasUpdates = updates.length > 0;
  const { data: { organizationName: currentOrganizationName } = {} } = useGetCurrentUserQuery();
  const ownedByCurrentOrg = createdByOrganization === currentOrganizationName;

  let subheading = 'Workspace';
  if (!id || (organizationDefault && ownedByCurrentOrg)) subheading = 'Default Workspace';

  let link = `/opportunities/${oppId}/workspaces/${id}`;
  if (path) link = path;

  return (
    <div className="trucate max-w-full w-64" data-test="workspace-header-name">
      {id && showLink ? (
        <LinkTag
          tag={onClick ? 'a' : null}
          onClick={onClick ? onClick : null}
          target={!paramsId && !onClick ? '_blank' : null}
          to={!onClick ? link : null}
        >
          {paramsId || onClick ? (
            <NewTabTooltip title="View Workspace">
              <dt className="whitespace-normal text-base font-semibold">{name || currentOrganizationName}</dt>
            </NewTabTooltip>
          ) : (
            <dt className="whitespace-normal text-base font-semibold">{name || currentOrganizationName}</dt>
          )}
        </LinkTag>
      ) : (
        <dt className="text-base font-semibold">{name || createdByOrganization || currentOrganizationName}</dt>
      )}
      {!hasUpdates ? (
        <dd className="text-sm text-gray-500">{subheading}</dd>
      ) : (
        <dd>
          <OppWorkspaceUpdateTags updates={updates} />
        </dd>
      )}
      {visibleId &&
        (linkOpp ? (
          <LinkTag to={`/opportunities/${oppId}`}>
            <NewTabTooltip title="View Opportunity">
              <dt className="text-sm font-semibold">{visibleId}</dt>
            </NewTabTooltip>
          </LinkTag>
        ) : (
          <dd className="text-sm text-gray-500">{visibleId}</dd>
        ))}
    </div>
  );
};

OppWorkspaceHeader.propTypes = propTypes;
