import React from 'react';
import PropTypes from 'prop-types';
import { LabelTag } from 'app/organisms/LabelTag/LabelTag';
import { Loading } from 'app/atoms/Loading/Loading';
import { useOrganizationLabelTaggingTimelineEvent } from 'app/hooks/useOrganizationLabelTaggingTimelineEvent';

export const OrganizationLabelTaggingTimelineEvent = props => {
  return <OrganizationLabelTaggingTimelineEventUI {...useOrganizationLabelTaggingTimelineEvent(props)} />;
};

export const OrganizationLabelTaggingTimelineEventUI = ({
  email,
  createdAt,
  isLoading,
  addedLabels,
  removedLabels,
  displayName,
  message
}) => {
  const didAdd = addedLabels.length > 0;
  const didRemove = removedLabels.length > 0;

  const article = removedLabels.length + addedLabels.length === 1 ? 'the' : '';
  const unit = removedLabels.length + addedLabels.length === 1 ? 'label' : 'labels';
  const preposition = removedLabels.length >= 1 ? 'from' : 'to';

  return isLoading ? (
    <div className="flex items-center gap-1 text-sm">
      <Loading type="text">loading label updates</Loading>
    </div>
  ) : (
    <div className="flex items-center gap-1 flex-wrap" aria-label={message}>
      <span>{email}</span>

      {didAdd && (
        <div className="flex items-center gap-1">
          <span>added {article}</span>
          {addedLabels.map(l => (
            <LabelTag key={l.id} color={l.color} name={l.name} />
          ))}
        </div>
      )}

      {didAdd && didRemove && <span>and</span>}

      {didRemove && (
        <div className="flex items-center gap-1">
          <span>removed {article}</span>
          {removedLabels.map(l => (
            <LabelTag key={l.id} color={l.color} name={l.name} />
          ))}
        </div>
      )}

      <span>
        {unit} {preposition} this {displayName}
      </span>

      <span>on {createdAt}</span>
    </div>
  );
};

OrganizationLabelTaggingTimelineEventUI.propTypes = {
  email: PropTypes.string,
  createdAt: PropTypes.string,
  displayName: PropTypes.string,
  action: PropTypes.string,
  article: PropTypes.string,
  message: PropTypes.string,
  isLoading: PropTypes.bool,
  addedLabels: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string
    })
  ),
  removedLabels: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string
    })
  )
};

OrganizationLabelTaggingTimelineEvent.propTypes = {
  label: PropTypes.string,
  event: PropTypes.shape({
    customData: PropTypes.shape({
      displayName: PropTypes.string,
      removedLabelIds: PropTypes.arrayOf(PropTypes.string),
      addedLabelIds: PropTypes.arrayOf(PropTypes.string)
    }),
    actor: PropTypes.shape({ email: PropTypes.string })
  })
};
