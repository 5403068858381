import React, { useMemo, useState } from 'react';
import { Intent, Tag } from '@blueprintjs/core';

import { createColumnHelper, PaginationState } from '@tanstack/react-table';

import { FileImport } from 'types/__generated__/GovlyApi';
import { useGetFileImportsQuery } from 'api/fileImportsApi';

import { CardHeading } from 'app/atoms/Typography/Typography';
import { GovlyTableRowCountTag } from 'app/molecules/GovlyTable/GovlyTableRowCountTag';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';

const columnHelper = createColumnHelper<FileImport>();

const STATUS_MAP = {
  queued: 'warning',
  processing: 'warning',
  complete: 'success',
  failed: 'danger'
};

export const FileImportsTable = () => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: 25,
    pageIndex: 0
  });
  const { data: { fileImports = [], meta } = {}, isLoading } = useGetFileImportsQuery({
    page: pagination.pageIndex + 1
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: 'ID',
        enableSorting: false
      }),
      columnHelper.accessor('file', {
        header: 'File',
        enableSorting: false,
        cell: e => {
          if (!e.row.original.file?.link) {
            return null;
          }

          return (
            <a href={e.row.original.file.link} download>
              {e.row.original.file.name}
            </a>
          );
        }
      }),
      columnHelper.accessor('file.byteSize', {
        header: 'Size',
        enableSorting: false
      }),
      columnHelper.accessor('importWorkerClass', {
        header: 'Import Worker Class',
        enableSorting: false
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        enableSorting: false,
        cell: e => {
          const status = e.row.original.status;
          const intent = (STATUS_MAP[status] as Intent) ?? 'danger';
          return <Tag intent={intent}>{status}</Tag>;
        }
      }),
      columnHelper.accessor('recordCount', {
        header: 'Records Processed',
        enableSorting: false
      })
    ],
    []
  );

  return (
    <GovlyTable
      trackingObject="feed_profiles_table"
      columns={columns}
      data={fileImports}
      title={
        <span className="flex gap-2 items-center">
          <CardHeading>File Imports</CardHeading>
          <GovlyTableRowCountTag />
        </span>
      }
      isLoading={isLoading}
      tableProps={{
        manualPagination: true,
        onPaginationChange: setPagination,
        rowCount: meta?.total ?? 0,
        state: { pagination }
      }}
      initialState={{
        pagination,
        sorting: [
          {
            id: 'type',
            desc: false
          }
        ]
      }}
    />
  );
};
