import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button } from '@blueprintjs/core';
import partition from 'lodash-es/partition';
import keyBy from 'lodash-es/keyBy';

import { H3 } from 'app/atoms/Typography/Typography';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { SwitchInput } from 'app/atoms/inputs/SwitchInput/SwitchInput';
import { formErrorToast, successToast } from 'app/lib/toaster';
import { useGetOrganizationUsersQuery } from 'api/organizationUsersApi';
import { FollowerSelectInput } from 'app/organisms/FollowerSelectInput/FollowerSelectInput';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { WorkspaceFormNotificationsInput } from 'app/organisms/WorkspaceFormNotificationsInput/WorkspaceFormNotificationsInput';
import { workflowStages } from 'app/lib/workflowStages';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { SelectInput } from 'app/atoms/inputs/SelectInput/SelectInput';

const propTypes = {
  onCancel: PropTypes.func,
  isSubmitting: PropTypes.bool,
  title: PropTypes.string,
  titleDescription: PropTypes.string,
  follows: PropTypes.array.isRequired,
  workableTypeForLink: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    oppId: PropTypes.string,
    organizationDefault: PropTypes.bool,
    privateAccess: PropTypes.bool,
    workflowStage: PropTypes.string
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  redirectOnSuccess: PropTypes.bool,
  basicInputs: PropTypes.bool,
  stageInputs: PropTypes.bool,
  followersInputs: PropTypes.bool,
  notifyNewFollowersOnly: PropTypes.bool,
  organizationDefault: PropTypes.bool,
  submitButtonText: PropTypes.string,
  successMessage: PropTypes.string,
  trackingData: PropTypes.object,
  shouldTrack: PropTypes.bool,
  subTitle: PropTypes.string,
  enableReinitialize: PropTypes.bool
};

export const WorkspaceForm = ({
  onCancel,
  isSubmitting,
  title,
  titleDescription,
  initialValues,
  onSubmit,
  workableTypeForLink,
  basicInputs = false,
  stageInputs = false,
  followersInputs = true,
  follows,
  redirectOnSuccess,
  notifyNewFollowersOnly = true,
  organizationDefault = false,
  submitButtonText,
  successMessage,
  trackingData = {},
  shouldTrack = true,
  subTitle,
  enableReinitialize = false,
  ...rest
}) => {
  const navigate = useNavigate();
  const currentOrgId = useCurrentUserAttribute('organizationId');
  const { trackEvent } = useEventTracking();

  const { data: team = [], isLoading: teamLoading } = useGetOrganizationUsersQuery({
    view: 'current_org'
  });

  const { data: partners = [], isLoading: partnersLoading } = useGetOrganizationUsersQuery({
    filter: 'partners',
    view: 'displayable'
  });

  const [teamFollows, partnerFollows] = partition(
    follows.filter(({ state }) => state === 'following'),
    ({ organizationUser: { organizationId } }) => organizationId === currentOrgId
  );

  const followsByUser = keyBy(
    follows.map(({ organizationUser, ...rest }) => rest),
    'organizationUserId'
  );

  const form = {
    enableReinitialize,
    onSubmit: async values => {
      try {
        const { teamFollows, partnerFollows, customNotifyList, notifyGroup, ...payload } = values;

        if (followersInputs) {
          const updatedFollowsByUser = keyBy(teamFollows.concat(partnerFollows), 'organizationUserId');

          const allFollowers = [...new Set(Object.keys(followsByUser).concat(Object.keys(updatedFollowsByUser)))];

          const followsAttributes = allFollowers.map(ouid => {
            const updatedFollow = updatedFollowsByUser[ouid];
            const originalFollow = followsByUser[ouid];

            if (originalFollow && !updatedFollow) {
              return {
                id: originalFollow.id,
                state: 'unfollowed'
              };
            }

            return {
              id: originalFollow?.id || updatedFollow.id,
              state: updatedFollow.state,
              notifications: updatedFollow.notifications,
              organizationUserId: updatedFollow.organizationUserId,
              email: updatedFollow.organizationUser.email
            };
          });

          payload.followsAttributes = followsAttributes;
        }

        let notifyIds = [];

        if (notifyGroup === 'select') {
          notifyIds = customNotifyList.map(({ organizationUserId }) => organizationUserId);
        }

        if (notifyGroup === 'notifiables') {
          let notifiables = [...teamFollows, ...partnerFollows];

          if (notifyNewFollowersOnly) {
            notifiables = notifiables.filter(({ id }) => !id);
          }

          notifyIds = notifiables.map(({ organizationUserId }) => organizationUserId);
        }

        const data = await onSubmit({
          ...payload,
          notifyIds
        }).unwrap();

        const { followsAttributes, ...remainingData } = payload;
        if (shouldTrack)
          trackEvent({
            object: 'workspace',
            action: 'updated',
            properties: {
              workspaceId: remainingData.id,
              ...trackingData,
              ...remainingData,
              ...data,
              notifyIds,
              follows: followsAttributes?.map(f => f.email),
              teamFollows: teamFollows?.map(f => f.organizationUser.email),
              partnerFollows: partnerFollows?.map(f => f.organizationUser.email)
            }
          });

        if (initialValues.id) {
          successToast(successMessage || 'Workspace updated');
          const { workflowStage } = values;
          onCancel({ workflowStage });
        } else {
          successToast(successMessage || 'Workspace created');
          if (redirectOnSuccess) {
            const { workableId, id } = data;
            navigate(`/${workableTypeForLink}/${workableId}/workspaces/${id}`);
          } else {
            onCancel();
          }
        }
      } catch (e) {
        formErrorToast(e);
      }
    },
    initialValues: {
      teamFollows,
      partnerFollows,
      ...initialValues,
      notifyGroup: 'notifiables',
      customNotifyList: []
    },
    validationSchema: yup.object({
      name: yup.string().required('Name is required.')
    })
  };

  const stageInputStep = step => {
    if (basicInputs && !organizationDefault) step += 1;
    if (basicInputs) step += 1;

    return step;
  };

  const followersInputStep = step => {
    if (basicInputs && !organizationDefault) step += 1;
    if (basicInputs && stageInputs) return step + 2;
    if (stageInputs) return step + 1;
    if (basicInputs) return step + 1;

    return step;
  };

  const followerText = subTitle || 'added to this Workspace?';

  return (
    <div {...rest}>
      <Formik {...form}>
        <Form>
          <Card
            title={title}
            subtitle={titleDescription}
            rightElement={onCancel && <Button minimal icon="cross" onClick={onCancel} />}
          >
            <CardBody>
              {basicInputs && (
                <>
                  <div className="space-y-4">
                    <H3>1. What should we call this Workspace?</H3>
                    <TextInput name="name" inputProps={{ placeholder: 'Planning', large: true }} />
                  </div>
                  {!organizationDefault && (
                    <div className="space-y-4">
                      <H3>2. Should people not following the Workspace be able to view it?</H3>
                      <SwitchInput large name="privateAccess" label="Private?" data-test="private-workspace-toggle" />
                    </div>
                  )}
                </>
              )}
              {stageInputs && (
                <>
                  <div className="space-y-4">
                    <H3>{stageInputStep(1)}. What is the current stage of this Workspace?</H3>
                    <SelectInput
                      name="workflowStage"
                      items={workflowStages}
                      filterable={false}
                      filterActive={false}
                      shouldDismissPopover={true}
                    />
                  </div>
                </>
              )}
              {followersInputs && (
                <>
                  <div className="space-y-4">
                    <H3>{`${followersInputStep(1)}. Which team members should be ${followerText}`}</H3>
                    {teamLoading ? (
                      <Button
                        disabled
                        large
                        text="Add a team member"
                        rightIcon="caret-down"
                        data-test="workspace-followers-input"
                      />
                    ) : (
                      <FollowerSelectInput
                        name="teamFollows"
                        defaultButtonText="Add a team member"
                        organizationUsers={team}
                        disabled={teamLoading}
                        data-test="workspace-followers-input"
                      />
                    )}
                  </div>
                  {!partnersLoading && partners.length > 0 && (
                    <div className="space-y-4">
                      <H3>{`${followersInputStep(2)}. Which partners should be ${followerText}`}</H3>
                      <FollowerSelectInput
                        name="partnerFollows"
                        defaultButtonText="Add a partner"
                        organizationUsers={partners}
                        disabled={partnersLoading}
                        data-test="workspace-partners-input"
                      />
                    </div>
                  )}
                </>
              )}

              <WorkspaceFormNotificationsInput
                initialFollows={[...teamFollows, ...partnerFollows]}
                notifyNewFollowersOnly={notifyNewFollowersOnly}
              />
            </CardBody>
            <CardFooter>
              <Button
                large
                type="submit"
                text={submitButtonText || (initialValues.id ? 'Save' : 'Create')}
                intent="primary"
                loading={isSubmitting}
                disabled={teamLoading || partnersLoading || isSubmitting}
                data-test="workspace-submit-button"
              />
              {onCancel && <Button text="Cancel" disabled={isSubmitting} onClick={onCancel} />}
            </CardFooter>
          </Card>
        </Form>
      </Formik>
    </div>
  );
};

WorkspaceForm.propTypes = propTypes;
