import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@blueprintjs/core';

import { cn } from 'app/lib/cn';

import { useCreatePartnerFeedMutation, useDeletePartnerFeedMutation } from 'api/partnerFeedsApi';
import { successToast } from 'app/lib/toaster';

const propTypes = {
  id: PropTypes.string,
  partnershipId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  providerFeedPublicId: PropTypes.string.isRequired
};

export const PartnerFeedTableCheckbox = ({ id, partnershipId, organizationId, providerFeedPublicId, ...rest }) => {
  const [createdPartnerFeed, { isLoading: isCreating }] = useCreatePartnerFeedMutation();
  const [deletePartnerFeed, { isLoading: isDeleting }] = useDeletePartnerFeedMutation();

  const handleSubmit = async () => {
    if (id) {
      await deletePartnerFeed({ id: id });
    } else {
      await createdPartnerFeed({
        partnershipId,
        organizationId,
        providerFeedPublicId
      }).unwrap();
    }
    successToast();
  };

  return (
    <Checkbox
      checked={!!id}
      disabled={isCreating || isDeleting}
      label={<span className={cn('inline-block text-gray-600 dark:text-gray-400')}>Share with partner?</span>}
      className="m-0"
      onChange={handleSubmit}
      {...rest}
    />
  );
};

PartnerFeedTableCheckbox.propTypes = propTypes;
