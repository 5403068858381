import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Tag, Button, Checkbox, Dialog, Tooltip } from '@blueprintjs/core';
import * as yup from 'yup';

import { pluralize } from 'app/lib/strings';
import { useDeleteClinMutation } from 'api/souptoolsClinsApi';
import { asCurrency } from 'app/lib/numbers';
import { SouptoolsClinContractStatus } from 'app/organisms/SouptoolsClinContractStatus/SouptoolsClinContractStatus';
import { SouptoolsClinForm } from 'app/organisms/SouptoolsClinForm/SouptoolsClinForm';
import { formatTime } from 'app/lib/dates';

export const validations = {
  clin: { validation: yup.string().required(), required: true },
  itemdesc: { type: 'textarea', required: true },
  clintype: { required: true },
  sewpprice: { type: 'number', required: true },
  listprice: { type: 'number', required: true },
  maflag: { required: true },
  discountsubclass: {},
  discountclass: {},
  partnumber: {},
  provider: { required: true },
  eoldate: {},
  shortdesc: {},
  productline: {},
  productclass: {},
  productcondition: {},
  scrmflag: {},
  visibleflag: {},
  taaflag: {},
  energystarflag: {},
  epeatflag: {},
  discountpct: { type: 'number' },
  rebate: { type: 'number' },
  gsaprice: { type: 'number' },
  clinunit: {},
  clinqty: { type: 'number' },
  bundleflag: {},
  scrmcomment: {},
  scrmcontactemail: {},
  scrmcontactphone: {},
  scrmcontactname: {},
  unspsc: {},
  sspart: {},
  bundlepart: {},
  equivalentclin: {},
  replacementclin: {},
  modelname: {},
  bmreasoncode: {},
  backupdataflag: {}
};

const propTypes = {
  clin: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool
};

export const SouptoolsTrClin = ({ clin, onSelect, selected }) => {
  const [isEditing, setIsEditing] = useState(false);
  const {
    id,
    authorEmail,
    fileName,
    clin: clinNumber,
    clinableId,
    clinableType,
    createdAt,
    updatedAt,
    formatErrors,
    itemdesc,
    provider,
    sewpprice,
    clintype,
    discountclass,
    discountsubclass,
    epeatflag,
    taaflag,
    context: { lastEditedBy },
    hasOtherClins
  } = clin;

  const errorCount = Object.keys(formatErrors).length;
  const intent = errorCount ? 'danger' : 'success';
  const intentClass = errorCount ? 'text-red-600' : 'text-green-600';

  const [removeClin, { isLoading: isRemovingClin }] = useDeleteClinMutation();
  const addedAtText = ` Added ${formatTime(createdAt)}${authorEmail ? ` by ${authorEmail} ` : ''}${
    fileName ? ` in ${fileName}` : ''
  }`;

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <Checkbox inline className="mb-0" checked={selected} onChange={onSelect} />
          <div>
            <div>
              <Tag minimal interactive intent={intent} onClick={() => setIsEditing(true)}>
                {clinNumber}
              </Tag>
              <div className="flex flex-wrap items-center py-1">
                <>
                  {errorCount ? (
                    <Tooltip
                      content={
                        <pre className="prose prose-sm prose-code mt-2 block overflow-x-scroll whitespace-pre rounded-md bg-gray-700 p-2 text-gray-50 shadow">
                          <code>{JSON.stringify(formatErrors, null, 2)}</code>
                        </pre>
                      }
                    >
                      <Icon icon="error" intent={intent} />
                    </Tooltip>
                  ) : (
                    <Icon icon="tick" intent={intent} />
                  )}
                  <small className={`ml-1 ${intentClass}`}>
                    <strong>
                      {errorCount ? `${errorCount} ${pluralize('Error', 'Errors', errorCount)}` : 'No Errors'}
                    </strong>
                  </small>
                </>
                <>
                  <Icon icon="dot" iconSize={12} />
                  <Icon icon="office" iconSize={12} intent={provider ? null : 'danger'} />
                  <strong>
                    {provider ? (
                      <small className="ml-1">{`${provider}`}</small>
                    ) : (
                      <small className="ml-1 text-red-600">Missing Provider</small>
                    )}
                  </strong>
                </>
                <>
                  <Icon icon="dot" iconSize={12} />
                  <small>
                    <strong>{`SEWP Price: ${asCurrency(sewpprice)}`}</strong>
                  </small>
                </>
              </div>
              <div className="flex flex-wrap items-center py-1">
                <SouptoolsClinContractStatus clin={clin} />
                {hasOtherClins && (
                  <>
                    <Icon icon="dot" iconSize={12} />
                    <Tag minimal intent="primary">
                      Other CLINS available with partnumber
                    </Tag>
                  </>
                )}
              </div>
              <small>{itemdesc}</small>
              <div className="flex flex-wrap items-center py-1">
                <small>
                  <strong>Clintype:</strong>
                  {` ${clintype}`}
                </small>
                <Icon icon="dot" iconSize={8} />
                <small>
                  <strong>Discountclass:</strong>
                  {` ${discountclass}`}
                </small>
                <Icon icon="dot" iconSize={8} />
                <small>
                  <strong>Discountsubclass:</strong>
                  {` ${discountsubclass}`}
                </small>
                {epeatflag && (
                  <>
                    <Icon icon="dot" iconSize={8} />
                    <small>
                      <strong>Epeatflag:</strong>
                      {` ${epeatflag}`}
                    </small>
                  </>
                )}
                {epeatflag && (
                  <>
                    <Icon icon="dot" iconSize={8} />
                    <small>
                      <strong>Taaflag:</strong>
                      {` ${taaflag}`}
                    </small>
                  </>
                )}
              </div>
              <div className="flex flex-wrap items-center py-1">
                <>
                  <Icon icon="time" iconSize={12} />
                  <small className="ml-1">{addedAtText}</small>
                  {lastEditedBy && (
                    <small className="ml-1">{`- Last edited by: ${lastEditedBy} on ${formatTime(updatedAt)}`}</small>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
        <Tooltip content="Remove CLIN from TR">
          <Button
            icon="cross"
            minimal
            intent="danger"
            loading={isRemovingClin}
            onClick={() => removeClin({ id, clinableId, clinableType })}
          />
        </Tooltip>
      </div>
      <Dialog isOpen={isEditing} onClose={() => setIsEditing(false)} className="min-w-[50%] p-0">
        <SouptoolsClinForm clin={clin} validations={validations} onClose={() => setIsEditing(false)} stickyFooter />
      </Dialog>
    </>
  );
};

SouptoolsTrClin.propTypes = propTypes;
