import { DateTime } from 'luxon';
import { useGetOppSearchesQuery } from 'api/oppSearchesApi';
import { defaultDateRangeStart, defaultDateRangeEnd } from 'app/lib/dates';
import { SearchableActions } from 'app/hooks/search/types';
import { create } from 'zustand';
import { OppSearchDisplayable } from 'types/__generated__/GovlyApi';
import { useFormikSavableSearch } from 'app/hooks/search/useFormikSavableSearch';
import { SearchMeta } from './types';

const currentUserId = globalThis.process?.env?.CURRENT_USER_ID;

export type DateRangeParam = 'posted_at' | 'respond_by' | 'modified_at';

export type RecordTypeParam = 'Solicitation' | 'Prediction' | 'Forecast' | 'Event' | 'Other';

export const DATE_RANGE_OPTIONS: { label: string; value: DateRangeParam }[] = [
  { label: 'Posted At', value: 'posted_at' },
  { label: 'Modified At', value: 'modified_at' },
  { label: 'Respond By', value: 'respond_by' }
];

export type { State as OppSearchState };
type State<Context extends 'UI' | 'API' = 'UI'> = {
  query: string;
  meta: SearchMeta;
  filters: {
    searchFields: {
      only: string[];
      exclude: string[];
    };
    aggs: string[];
    aggSlices: string[];
    dateRangeParam: DateRangeParam;
    dateRange: [Date | null, Date | null];
    secondaryDateRanges: { range: [Date | null, Date | null]; param: DateRangeParam }[];
    contractHolderCount: string[];
    contractVehicleIds: string[];
    contractVehicleIdsNone: string[];
    feeds: string[];
    feedsNone: string[];
    predictedContractVehicleNames: string[];
    predictedContractVehicleNamesNone: string[];
    status: string[];
    groups: string[];
    setAside: string[];
    setAsideAll: string[];
    setAsideNone: string[];
    naicsCodes: string[];
    naicsCodesAll: string[];
    naicsCodesNone: string[];
    interest: string[];
    following: string[];
    followingAll: string[];
    excludeFollowing: string[];
    viewed: string[];
    viewedAll: string[];
    excludeViewed: string[];
    ignored: string[];
    ignoredAll: string[];
    excludeIgnored: string[];
    recordType: RecordTypeParam[];
    recordTypeNone: RecordTypeParam[];
    regions: string[];
    regionsAll: string[];
    regionsNone: string[];
    labels: string[];
    labelsAll: string[];
    labelsNone: string[];
    tags: string[];
    tagsAll: string[];
    tagsNone: string[];
    matchedOppSearchIds: string[];
    matchedOppSearchIdsAll: string[];
    matchedOppSearchIdsNone: string[];
    noticeType: string[];
    noticeTypeNone: string[];
    workspaceStages: string[];
    workspaceStagesNone: string[];
    sort: 'posted';
    sortDirection: 'asc' | 'desc';
    buyerDomains: string[];
    buyerDomainsNone: string[];
    buyerIds: string[];
    buyerIdsNone: string[];
    jurisdictionRegions: string[];
    buyerMatchById: boolean;
    includeSubBuyers: boolean;
  } & (Context extends 'UI'
    ? {
        /**
         * `searchType` also accepts `'predictions'` in the BE,
         * but we represent `'predictions'` as a `boolean` subset of `{ searchType: 'federal' }` in the UI
         */
        searchType: 'fed' | 'sled';
        /**
         * `{ predictions: true }` maps to `{ searchType: 'predictions' }` in the BE,
         * but we represent 'predictions' as a boolean subset of 'federal' in the UI
         */
        predictions: boolean;
      }
    : {
        searchType: 'fed' | 'sled' | 'predictions';
        predictions?: boolean;
      });
};

type Action = SearchableActions;

type Store = State & Action;

export const defaultState: State = {
  query: '',
  meta: {
    savedSearchId: null,
    searchState: 'initializing'
  },
  filters: {
    searchFields: {
      only: [],
      exclude: []
    },
    aggs: [],
    aggSlices: [],
    dateRangeParam: 'posted_at',
    dateRange: [defaultDateRangeStart(), defaultDateRangeEnd()],
    secondaryDateRanges: [],
    contractHolderCount: [],
    contractVehicleIds: [],
    contractVehicleIdsNone: [],
    feeds: [],
    feedsNone: [],
    predictedContractVehicleNames: [],
    predictedContractVehicleNamesNone: [],
    status: [],
    groups: [],
    setAside: [],
    setAsideAll: [],
    setAsideNone: [],
    naicsCodes: [],
    naicsCodesAll: [],
    naicsCodesNone: [],
    interest: [],
    following: [],
    followingAll: [],
    excludeFollowing: [],
    viewed: [],
    viewedAll: [],
    excludeViewed: [],
    ignored: [],
    ignoredAll: [],
    excludeIgnored: currentUserId ? [currentUserId] : [],
    recordType: ['Solicitation'],
    recordTypeNone: [],
    regions: [],
    regionsAll: [],
    regionsNone: [],
    labels: [],
    labelsAll: [],
    labelsNone: [],
    tags: [],
    tagsAll: [],
    tagsNone: [],
    matchedOppSearchIds: [],
    matchedOppSearchIdsAll: [],
    matchedOppSearchIdsNone: [],
    noticeType: [],
    noticeTypeNone: [],
    workspaceStages: [],
    workspaceStagesNone: [],
    sort: 'posted',
    sortDirection: 'desc',
    buyerDomains: [],
    buyerDomainsNone: [],
    buyerIds: [],
    buyerIdsNone: [],
    buyerMatchById: true,
    includeSubBuyers: true,
    jurisdictionRegions: [],
    searchType: 'fed',
    predictions: false
  }
};

export const getFormState = (state: Store): State => {
  const { reset, ...formState } = state;
  return formState;
};

export const useOppSearchCache = create<Store>(set => ({
  ...defaultState,
  reset: () => set(c => ({ ...c, ...defaultState }), true)
}));

type CalculateDateRangeFromSearchArgs = { relativeStartDayPivot?: number; relativeEndDayPivot?: number };
const calculateDateRangeFromSearch = ({
  relativeStartDayPivot,
  relativeEndDayPivot
}: CalculateDateRangeFromSearchArgs): [Date, Date] => {
  const end = DateTime.local().endOf('day').plus({ days: relativeEndDayPivot });
  const start = DateTime.local().startOf('day').plus({ days: relativeStartDayPivot });

  return [start.toJSDate(), end.toJSDate()];
};

type GetStateFromSavedSearchArgs = { savedSearch: OppSearchDisplayable; meta: State['meta'] };
export const getStateFromSavedSearch = ({ savedSearch, meta }: GetStateFromSavedSearchArgs): State => {
  const {
    context = {} as OppSearchDisplayable['context'],
    query: { query, ...savedFilters }
  } = savedSearch || { query: { query: '' } };

  if (context.relativeStartDayPivot != null && context.relativeEndDayPivot != null) {
    savedFilters.dateRange = calculateDateRangeFromSearch(context);
  }

  const { searchType = 'fed', predictions, ...otherFilters } = savedFilters as Partial<State<'API'>['filters']>;
  const safeSearchTypeParams: Pick<State['filters'], 'searchType' | 'predictions'> =
    searchType === 'predictions' || predictions
      ? { searchType: 'fed', predictions: true }
      : { searchType, predictions: false };

  return {
    query: query || '',
    filters: { ...defaultState.filters, excludeIgnored: [], ...otherFilters, ...safeSearchTypeParams },
    meta: { ...meta, savedSearchId: savedSearch.id }
  };
};

export const useInitializeOppSearchCache = () => {
  const { data: savedSearches = [] } = useGetOppSearchesQuery({ active: true });
  useFormikSavableSearch({ getStateFromSavedSearch, savedSearches, store: useOppSearchCache });
};
