import React from 'react';
import { RadioGroup, Radio, FormGroup, RadioGroupProps, RadioProps } from '@blueprintjs/core';

import { cn } from 'app/lib/cn';
import { useFormikInput } from 'app/hooks/useFormikInput';
import { LabelSpanProps, LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';

export type RadioGroupInputProps = {
  name: string;
  inputProps?: RadioProps;
  labelProps?: LabelSpanProps;
  submitOnChange?: boolean;
  beforeChange?: (value: string) => void;
} & Partial<RadioGroupProps>;

export const RadioGroupInput = ({
  name,
  className,
  disabled,
  inline,
  label,
  labelProps,
  inputProps = {},
  options,
  submitOnChange,
  beforeChange,
  onChange: onChangeProp,
  ...rest
}: RadioGroupInputProps) => {
  const { field, meta, onChange } = useFormikInput<string | boolean>({ name, submitOnChange });
  const error = meta.touched && meta.error;
  const intent = error ? 'danger' : undefined;

  return (
    <FormGroup className={cn('m-0', className)} intent={intent}>
      <RadioGroup
        label={<LabelSpan label={label} {...labelProps} />}
        selectedValue={String(field.value)}
        disabled={disabled}
        inline={inline}
        name={name}
        onChange={e => {
          const target = e.target as HTMLInputElement;
          beforeChange?.(target.value);

          if (['true', 'false'].includes(target.value)) {
            onChange(target.value === 'true');
          } else {
            onChange(e.currentTarget.value);
          }
        }}
        {...rest}
      >
        {options?.map(option => (
          <Radio
            key={option.value}
            label={option.label}
            value={option.value}
            disabled={option.disabled}
            {...inputProps}
          />
        ))}
      </RadioGroup>
      {error ? <small className="text-xs text-red-500">{meta.error}</small> : null}
    </FormGroup>
  );
};
