import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Tag, InputGroup } from '@blueprintjs/core';

import { SearchFacet } from 'app/molecules/SearchFacet/SearchFacet';

export const SearchFacetCheckboxes = ({ facets, refine, searchForItems, hasExhaustiveItems, label }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const items = useMemo(() => [...facets].sort((a, b) => Number(b.bool) - Number(a.bool)), [facets]);

  useEffect(() => {
    if (searchForItems) {
      searchForItems(searchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <SearchFacet label={label}>
      {!hasExhaustiveItems && searchForItems && (
        <InputGroup
          small
          placeholder="Search"
          className="mt-2 mb-4"
          onChange={e => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
      )}
      {items.map(facet => (
        <div className="flex items-center justify-between" key={facet.label}>
          <Checkbox
            key={facet.label}
            label={facet.label}
            checked={facet.isRefined}
            onChange={() => {
              setSearchTerm('');
              refine(facet.value);
            }}
          />
          <div>
            <Tag minimal round>
              {facet.count || 1}
            </Tag>
          </div>
        </div>
      ))}
    </SearchFacet>
  );
};

SearchFacetCheckboxes.propTypes = {
  label: PropTypes.string.isRequired,
  refine: PropTypes.func.isRequired,
  searchForItems: PropTypes.func,
  hasExhaustiveItems: PropTypes.bool,
  facets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      isRefined: PropTypes.bool.isRequired,
      count: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired
};
