import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@blueprintjs/core';

const propTypes = {
  onClick: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      status: PropTypes.oneOf([null, 'complete', 'current', 'upcoming']),
      intent: PropTypes.oneOf([null, 'success', 'danger'])
    })
  ).isRequired
};

export const ProgressPanels = ({ onClick, steps, ...rest }) => {
  return (
    <nav aria-label="Progress" {...rest}>
      <ol role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => {
          const color = step.intent === 'danger' ? 'red' : 'green';
          const icon = step.intent === 'danger' ? 'cross' : 'tick';
          return (
            <li key={step.label} className="relative md:flex md:flex-1">
              {step.status === 'complete' ? (
                <a onClick={() => onClick(step.value)} className="group flex w-full items-center no-underline">
                  <span className="flex items-center px-6 py-2 text-sm font-medium">
                    <span
                      className={`flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-${color}-600 group-hover:bg-${color}-800`}
                    >
                      <Icon icon={icon} className=" text-white" aria-hidden="true" />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-900">{step.label}</span>
                  </span>
                </a>
              ) : step.status === 'current' ? (
                <a
                  onClick={() => onClick(step.value)}
                  className="flex items-center px-6 py-2 text-sm font-medium no-underline"
                  aria-current="step"
                >
                  <span
                    className={`flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-2 border-${color}-600`}
                  >
                    <span className={`text-${color}-600`}>{step.id}</span>
                  </span>
                  <span className={`ml-4 text-sm font-medium text-${color}-600`}>{step.label}</span>
                </a>
              ) : (
                <a onClick={() => onClick(step.value)} className="group flex items-center no-underline">
                  <span className="flex items-center px-6 py-2 text-sm font-medium">
                    <span className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                      <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                      {step.label}
                    </span>
                  </span>
                </a>
              )}

              {stepIdx !== steps.length - 1 ? (
                <>
                  {/* Arrow separator for lg screens and up */}
                  <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                    <svg
                      className="h-full w-full text-gray-300"
                      viewBox="0 0 22 80"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              ) : null}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

ProgressPanels.propTypes = propTypes;
