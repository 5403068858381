import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Tag, Button, Checkbox, Dialog, Tooltip } from '@blueprintjs/core';
import * as yup from 'yup';

import { useDeleteClinMutation } from 'api/souptoolsClinsApi';
import { asCurrency } from 'app/lib/numbers';
import { SouptoolsClinContractStatus } from 'app/organisms/SouptoolsClinContractStatus/SouptoolsClinContractStatus';
import { SouptoolsClinForm } from 'app/organisms/SouptoolsClinForm/SouptoolsClinForm';

const validations = {
  clin: { validation: yup.string().required(), required: true },
  partnumber: { validation: yup.string().required(), required: true },
  salePrice: { validation: yup.number().required(), required: true },
  saleQuantity: { validation: yup.number().required(), required: true },
  itemdesc: { type: 'textarea' }
};

const propTypes = {
  clin: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool
};

export const SouptoolsQuoteClin = ({ clin, onSelect, selected, disabled }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { clin: clinNumber, salePrice, clinableId, clinableType, id, saleQuantity, partnumber, hasOtherClins } = clin;
  const error = !(salePrice && saleQuantity && clinNumber);
  const intent = error ? 'danger' : 'success';

  const [removeClin, { isLoading: isRemovingClin }] = useDeleteClinMutation();

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <Checkbox inline className="mb-0" disabled={disabled} checked={selected} onChange={onSelect} />
          <div>
            <div>
              <Tag minimal interactive intent={intent} onClick={() => setIsEditing(true)}>
                {clinNumber}
              </Tag>
              <div className="flex flex-wrap items-center py-1">
                <>
                  <Icon icon={error ? 'error' : 'tick'} intent={intent} />
                </>
                {salePrice ? (
                  <small className="ml-1">{`Sale Price: ${asCurrency(salePrice)}`}</small>
                ) : (
                  <small className="ml-1 text-red-600">Sale Price: MISSING</small>
                )}
                <>
                  <Icon icon="dot" iconSize={12} />
                </>
                {salePrice ? (
                  <small>{`Quantity: ${saleQuantity}`}</small>
                ) : (
                  <small className="text-red-600">Quantity: MISSING</small>
                )}
                {partnumber && (
                  <>
                    <Icon icon="dot" iconSize={12} />
                    <small>{`Part Number: ${partnumber}`}</small>
                  </>
                )}
              </div>
              <div className="flex flex-wrap items-center py-1">
                <SouptoolsClinContractStatus clin={clin} />
                {hasOtherClins && (
                  <>
                    <Icon icon="dot" iconSize={12} />
                    <Tag minimal intent="primary">
                      Other CLINS available with partnumber
                    </Tag>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Tooltip content="Remove CLIN from TR">
          <Button
            icon="cross"
            minimal
            intent="danger"
            loading={isRemovingClin}
            onClick={() => removeClin({ id, clinableId, clinableType })}
          />
        </Tooltip>
      </div>
      <Dialog isOpen={isEditing} onClose={() => setIsEditing(false)} className="min-w-[50%] p-0">
        <SouptoolsClinForm clin={clin} validations={validations} onClose={() => setIsEditing(false)} />
      </Dialog>
    </>
  );
};

SouptoolsQuoteClin.propTypes = propTypes;
