import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Button } from '@blueprintjs/core';

import { TextAreaInput } from 'app/atoms/inputs/TextAreaInput/TextAreaInput';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { CardBody, CardFooter, Card } from 'app/atoms/Card/Card';
import { errorToast, successToast } from 'app/lib/toaster';
import { useCreatePartnerPortalLinkMutation, useUpdatePartnerPortalLinkMutation } from 'api/partnerPortalLinksApi';

const propTypes = {
  partnerPortalLink: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired
};

export const PartnerPortalLinkForm = ({ partnerPortalLink: link, onCancel }) => {
  const mutation = link.id ? useUpdatePartnerPortalLinkMutation : useCreatePartnerPortalLinkMutation;

  const validationSchema = yup.object({
    label: yup.string().required('Label is required').max(50),
    url: yup.string().required('URL is required').url('URL must be a valid URL'),
    description: yup.string()
  });

  const [saveForm, { isLoading }] = mutation();

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const result = await saveForm({ ...values, id: link.id });
          if (result.error) throw result.error;
          setSubmitting(false);
          successToast();
          onCancel();
        } catch (e) {
          setSubmitting(false);
          errorToast(e);
        }
      }}
      initialValues={{
        label: link.label ?? '',
        url: link.url ?? '',
        description: link.description ?? ''
      }}
    >
      <Form>
        <Card
          title={`${link.id ? 'Edit' : 'Create a'} portal link`}
          rightElement={<Button minimal icon="cross" onClick={onCancel} />}
        >
          <CardBody>
            <TextInput name="label" label="Link label" />
            <TextInput name="url" label="Link URL" />
            <TextAreaInput name="description" label="Description" />
          </CardBody>
          <CardFooter>
            <Button intent="primary" type="submit" loading={isLoading}>
              Save
            </Button>
            <Button onClick={onCancel} disabled={isLoading}>
              Cancel
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </Formik>
  );
};

PartnerPortalLinkForm.propTypes = propTypes;
