import React, { useMemo } from 'react';
import { AnchorButton } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';

import { Vendor } from 'types/__generated__/GovlyApi';
import { useGetVendorsQuery } from 'api/vendorsApi';

import { CardHeading } from 'app/atoms/Typography/Typography';
import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableRowCountTag } from 'app/molecules/GovlyTable/GovlyTableRowCountTag';

const columnHelper = createColumnHelper<Vendor>();

const CreateVendorButtton = () => {
  const navigate = useNavigate();

  return (
    <AnchorButton
      key="create-vendor"
      onClick={() =>
        navigate(`/sys_admin/vendors/new`, {
          state: {
            breadcrumbs: [{ to: '/sys_admin/vendors', text: 'Vendors' }, { text: 'Create a Vendor' }]
          }
        })
      }
      intent="success"
      icon="plus"
    >
      Create
    </AnchorButton>
  );
};

export const VendorsTable = () => {
  const { data: vendors = [], isLoading } = useGetVendorsQuery();

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: 'ID',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        enableColumnFilter: true
      }),
      columnHelper.accessor('name', {
        header: 'Name',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        enableColumnFilter: true,
        cell: e => (
          <a href={`/sys_admin/vendors/${e.row.original.id}`} target="_blank" rel="noopener noreferrer">
            {e.row.original.name}
          </a>
        )
      })
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, vendors]
  );

  return (
    <GovlyTable
      trackingObject="vendors_table"
      columns={columns}
      data={vendors}
      title={
        <span className="flex gap-2 items-center">
          <CardHeading>Vendors</CardHeading>
          <GovlyTableRowCountTag />
        </span>
      }
      cardProps={{
        rightElement: (
          <GovlyTableToolbar>
            <CreateVendorButtton />
          </GovlyTableToolbar>
        )
      }}
      isLoading={isLoading}
      initialState={{
        pagination: {
          pageSize: 50
        }
      }}
    />
  );
};
