import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, Tooltip } from '@blueprintjs/core';

import { useGetCurrentOrganizationQuery } from 'api/organizationsApi';
import { SwitchInput } from 'app/atoms/inputs/SwitchInput/SwitchInput';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { useGetCrmConnectionsQuery, useCreateOrUpdateCrmConnectionMutation } from 'api/crmConnectionsApi';
import { formErrorToast, successToast } from 'app/lib/toaster';
import { Loading } from 'app/atoms/Loading/Loading';
import { useNango } from 'app/hooks/useNango';

export const CrmConnectionFormUnanet = ({ onSubmit }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showApiKey, setShowApiKey] = useState(false);
  const nango = useNango();

  const { data: currentOrg, isLoading: profileLoading } = useGetCurrentOrganizationQuery();
  const { data: crmConnections } = useGetCrmConnectionsQuery();
  const [createOrUpdateCrmConnection, { isLoading: isCreatingOrUpdating }] = useCreateOrUpdateCrmConnectionMutation();

  if (profileLoading) {
    return <Loading />;
  }

  const connection = crmConnections?.find(connection => connection?.name === 'Unanet');

  const {
    active,
    username,
    password,
    hasModSyncingEnabled,
    connectionConfig: { apiKey, firmId } = {}
  } = connection || {};

  const validationSchema = yup.object({
    username: yup.string().required('Username is required'),
    password: yup.string().required('A Password is required'),
    connectionConfig: yup.object({
      apiKey: yup.string().required('Api Key is required'),
      firmId: yup.string().required('Firm ID is required')
    })
  });

  const createNangoConnection = async ({ username, password, connectionConfig }) => {
    await nango.auth('unanet', `organization${currentOrg.id}`, {
      credentials: {
        username,
        password
      },
      params: {
        ...connectionConfig
      }
    });
  };

  return (
    <>
      <Formik
        onSubmit={async (values, { setErrors }) => {
          try {
            if (!connection) createNangoConnection(values);
            createOrUpdateCrmConnection(values);
            successToast(connection ? 'Connection updated' : 'Connection created');
            onSubmit();
          } catch (e) {
            const { data } = e;
            formErrorToast(e);
            if (data?.errors) setErrors({ data: data.errors });
          }
        }}
        initialValues={{
          integration: 'unanet',
          username: username || '',
          password: password || '',
          active: active || false,
          connectionConfig: {
            apiKey: apiKey || '',
            firmId: firmId || ''
          },
          hasOppPushingEnabled: true,
          hasModSyncingEnabled: hasModSyncingEnabled || false
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {() => (
          <Form>
            <Card title="Unanet Integration">
              <CardBody>
                <SwitchInput name="active" label="Active?" />
                <TextInput label="Username" name="username" labelInfo="(required)" />
                <TextInput
                  label="Password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  labelInfo="(required)"
                  inputProps={{
                    large: true,
                    rightElement: (
                      <Tooltip content={`${showPassword ? 'Hide' : 'Show'} Password`}>
                        <Button
                          icon={showPassword ? 'unlock' : 'lock'}
                          intent="warning"
                          minimal
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </Tooltip>
                    )
                  }}
                />
                <TextInput
                  label="API Key"
                  name="connectionConfig.apiKey"
                  type={showApiKey ? 'text' : 'password'}
                  labelInfo="(required)"
                  inputProps={{
                    large: true,
                    rightElement: (
                      <Tooltip content={`${showApiKey ? 'Hide' : 'Show'} Password`}>
                        <Button
                          icon={showApiKey ? 'unlock' : 'lock'}
                          intent="warning"
                          minimal
                          onClick={() => setShowApiKey(!showApiKey)}
                        />
                      </Tooltip>
                    )
                  }}
                />
                <TextInput label="Firm ID" name="connectionConfig.firmId" labelInfo="(required)" />
                <SwitchInput name="hasModSyncingEnabled" label="Sync Modifications?" />
              </CardBody>
              <CardFooter>
                <Button large type="submit" text="Save" loading={isCreatingOrUpdating} intent="primary" />
              </CardFooter>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  );
};

CrmConnectionFormUnanet.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
