import { useGetContractVehiclesQuery } from 'api/contractVehiclesApi';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { ContractVehicle } from 'types/__generated__/GovlyApi';
import { InputMultiSelectHook } from 'app/molecules/InputMultiSelect/utils';

export type ContractVehicleItem = ContractVehicle;

export const useContractVehicleMultiSelect: InputMultiSelectHook<ContractVehicleItem> = () => {
  const { data = [], isLoading } = useGetContractVehiclesQuery(
    { filter: 'searchable' },
    {
      selectFromResult: result => ({
        ...result,
        // only get US/FED contract vehicles
        data: result.data?.filter(cv => Number(cv.jurisdictionId ?? '1') === 1)
      })
    }
  );
  const contractVehicles = data as ContractVehicle[];
  const { data: currentUser, isLoading: currentUserLoading } = useGetCurrentUserQuery();

  const items = contractVehicles.filter(({ public: isPublic, isPredictionVehicle }) => {
    if (currentUser?.subscriptionStatus === 'free') return isPublic;

    return !isPredictionVehicle;
  });

  return { data: items, isLoading: isLoading || currentUserLoading };
};
