import { ProfileClaim } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['ProfileClaim', 'CurrentUser'] });

type GetProfileClaims = {
  params: void;
  result: ProfileClaim[];
};

type GetProfileClaim = {
  params: {
    id: string;
  };
  result: ProfileClaim;
};

type CreateProfileClaim = {
  params: {
    claimableId: string;
    claimableType: string;
  };
  result: { success: true };
};

type UpdateProfileClaim = {
  params: {
    id: string;
    state: 'approved' | 'denied';
    claimableId?: string;
    claimableType?: string;
  };
  result: { success: true };
};

export const profileClaimsApi = api.injectEndpoints({
  endpoints: build => ({
    getProfileClaims: build.query<GetProfileClaims['result'], GetProfileClaims['params']>({
      query: () => ({ url: '/v2/profile_claims' }),
      providesTags: ['ProfileClaim']
    }),
    getProfileClaim: build.query<GetProfileClaim['result'], GetProfileClaim['params']>({
      query: id => ({ url: `/v2/profile_claims/${id}` }),
      providesTags: ['ProfileClaim']
    }),
    createProfileClaim: build.mutation<CreateProfileClaim['result'], CreateProfileClaim['params']>({
      query: body => ({
        url: `/v2/profile_claims`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['ProfileClaim', 'CurrentUser']
    }),
    updateProfileClaim: build.mutation<UpdateProfileClaim['result'], UpdateProfileClaim['params']>({
      query: body => ({
        url: `/v2/profile_claims/${body.id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['ProfileClaim']
    })
  })
});

export const {
  useGetProfileClaimsQuery,
  useGetProfileClaimQuery,
  useCreateProfileClaimMutation,
  useUpdateProfileClaimMutation
} = profileClaimsApi;
