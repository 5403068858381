import React from 'react';
import { Formik, Form } from 'formik';

import { AWARD_SEARCH_CACHE_KEY, useAwardSearchMutation } from 'api/awardsApi';

import { useEventTracking } from 'app/hooks/useEventTracking';
import { AwardSearch } from 'app/organisms/AwardSearch/AwardSearch';
import { useDefaultSearchStateStore } from 'app/hooks/useDefaultSearchStateStore';
import { useLocation } from 'react-router-dom';
import { useHydrateSearchForm } from 'app/hooks/search/useHydrateSearchForm';
import { useAwardSearchCache, getFormState, defaultValues } from 'app/hooks/search/useAwardSearchCache';
import { getUsedFilters } from 'app/lib/tracking';
import { useScrollAppBody } from 'app/hooks/useScrollAppBody';

export const loader = async () => {
  useDefaultSearchStateStore.setState({ defaultState: defaultValues });
  return null;
};

export const AwardSearchPage = () => {
  const { pathname } = useLocation();
  const { trackEvent } = useEventTracking();
  const { initialValues, isInitializing } = useHydrateSearchForm({
    store: useAwardSearchCache,
    getFormState,
    parse: (s, queryParam) => {
      return JSON.parse(s, (_k, v) => {
        if (queryParam?.endsWith('dateRange') && Array.isArray(v)) {
          return v.map(s => new Date(s));
        }
        return v;
      });
    }
  });
  const scrollAppBody = useScrollAppBody();

  const [search] = useAwardSearchMutation({ fixedCacheKey: AWARD_SEARCH_CACHE_KEY });

  return (
    <Formik
      key={isInitializing.toString()}
      initialValues={initialValues}
      onSubmit={({ query, filters }) => {
        if (isInitializing) return;

        search({ query, ...filters });

        const usedFilters = getUsedFilters(filters);
        trackEvent({ object: 'awards', action: 'searched', properties: { query, ...filters, usedFilters } });
        if (pathname == '/awards/results') {
          scrollAppBody({ top: 0, behavior: 'smooth' });
        }
      }}
    >
      <Form>
        <AwardSearch />
      </Form>
    </Formik>
  );
};
