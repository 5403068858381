import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button } from '@blueprintjs/core';
import partition from 'lodash-es/partition';
import keyBy from 'lodash-es/keyBy';

import { H3 } from 'app/atoms/Typography/Typography';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { errorToast, successToast } from 'app/lib/toaster';
import { useGetOrganizationUsersQuery } from 'api/organizationUsersApi';
import { FollowerSelectInput } from 'app/organisms/FollowerSelectInput/FollowerSelectInput';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { WorkspaceFormNotificationsInput } from 'app/organisms/WorkspaceFormNotificationsInput/WorkspaceFormNotificationsInput';
import { useEventTracking } from 'app/hooks/useEventTracking';

const propTypes = {
  basicInputs: PropTypes.bool,
  followersInputs: PropTypes.bool,
  follows: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool,
  notifyNewFollowersOnly: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  title: PropTypes.string
};

export const ContractVehicleForm = ({
  basicInputs = false,
  followersInputs = true,
  follows,
  initialValues,
  isSubmitting,
  notifyNewFollowersOnly = true,
  onCancel,
  onSubmit,
  submitText = 'Create Contract Feed',
  title
}) => {
  const { trackEvent } = useEventTracking();
  const currentOrgId = useCurrentUserAttribute('organizationId');

  const { data: team = [], isLoading: teamLoading } = useGetOrganizationUsersQuery({
    view: 'current_org'
  });

  const { data: partners = [], isLoading: partnersLoading } = useGetOrganizationUsersQuery({
    filter: 'partners',
    view: 'displayable'
  });

  const [teamFollows, partnerFollows] = partition(
    follows.filter(({ state }) => state === 'following'),
    ({ organizationUser: { organizationId } }) => organizationId === currentOrgId
  );

  const followsByUser = keyBy(
    follows.map(({ organizationUser, ...rest }) => rest),
    'organizationUserId'
  );

  const form = {
    onSubmit: async values => {
      try {
        const { teamFollows, partnerFollows, customNotifyList, notifyGroup, ...payload } = values;

        if (followersInputs) {
          const updatedFollowsByUser = keyBy(teamFollows.concat(partnerFollows), 'organizationUserId');

          const allFollowers = [...new Set(Object.keys(followsByUser).concat(Object.keys(updatedFollowsByUser)))];

          const followsAttributes = allFollowers.map(ouid => {
            const updatedFollow = updatedFollowsByUser[ouid];
            const originalFollow = followsByUser[ouid];

            if (originalFollow && !updatedFollow) {
              return {
                id: originalFollow.id,
                state: 'unfollowed'
              };
            }

            return {
              id: originalFollow?.id || updatedFollow.id,
              state: updatedFollow.state,
              notifications: updatedFollow.notifications,
              organizationUserId: updatedFollow.organizationUserId,
              email: updatedFollow.organizationUser.email
            };
          });

          payload.followsAttributes = followsAttributes;
        }

        let notifyIds = [];

        if (notifyGroup === 'select') {
          notifyIds = customNotifyList.map(({ organizationUserId }) => organizationUserId);
        }

        if (notifyGroup === 'notifiables') {
          let notifiables = [...teamFollows, ...partnerFollows];

          if (notifyNewFollowersOnly) {
            notifiables = notifiables.filter(({ id }) => !id);
          }

          notifyIds = notifiables.map(({ organizationUserId }) => organizationUserId);
        }

        const data = await onSubmit({
          ...payload,
          notifyIds
        }).unwrap();

        const isUpdate = !!initialValues.id;

        trackEvent({
          object: 'contract_feed',
          action: isUpdate ? 'updated' : 'created',
          properties: {
            contractVehicleId: isUpdate ? data.contractVehicle.id : data.id,
            contractName: isUpdate ? data.contractVehicle.contractName : data.contractName,
            notifyIds,
            follows: payload.followsAttributes?.map(f => f.email),
            teamFollows: teamFollows?.map(f => f.organizationUser.email),
            partnerFollows: partnerFollows?.map(f => f.organizationUser.email)
          }
        });

        successToast(isUpdate ? 'Solicitors Updated' : 'Contract Created');
        onCancel();
      } catch (e) {
        errorToast(e);
      }
    },
    initialValues: {
      ...initialValues,
      teamFollows,
      partnerFollows,
      contractName: '',
      notifyGroup: 'notifiables',
      customNotifyList: []
    },
    validationSchema: yup.object({
      contractName: basicInputs ? yup.string().required('Contract Name is required.') : null
    })
  };

  const followersInputStep = step => {
    if (basicInputs) return step + 1;

    return step;
  };

  const followerText = 'able to post opportunities to this Contract Feed?';

  return (
    <Formik {...form}>
      <Form>
        <Card title={title} rightElement={onCancel && <Button minimal icon="cross" onClick={onCancel} />}>
          <CardBody>
            {basicInputs && (
              <>
                <div className="space-y-4">
                  <H3>1. What should we call this Contract Feed?</H3>
                  <TextInput name="contractName" inputProps={{ large: true }} />
                </div>
              </>
            )}
            {followersInputs && (
              <>
                <div className="space-y-4">
                  <H3>{`${followersInputStep(1)}. Which team members should be ${followerText}`}</H3>
                  {teamLoading ? (
                    <Button
                      disabled
                      large
                      text="Add a team member"
                      rightIcon="caret-down"
                      data-test="workspace-followers-input"
                    />
                  ) : (
                    <FollowerSelectInput
                      name="teamFollows"
                      defaultButtonText="Add a team member"
                      organizationUsers={team}
                      disabled={teamLoading}
                      data-test="workspace-followers-input"
                    />
                  )}
                </div>
                {!partnersLoading && partners.length > 0 && (
                  <div className="space-y-4">
                    <H3>{`${followersInputStep(2)}. Which partners should be ${followerText}`}</H3>
                    <FollowerSelectInput
                      name="partnerFollows"
                      defaultButtonText="Add a partner"
                      organizationUsers={partners}
                      disabled={partnersLoading}
                      data-test="workspace-partners-input"
                    />
                  </div>
                )}
              </>
            )}

            <WorkspaceFormNotificationsInput
              initialFollows={[...teamFollows, ...partnerFollows]}
              notifyNewFollowersOnly={notifyNewFollowersOnly}
            />
          </CardBody>
          <CardFooter>
            <Button
              large
              type="submit"
              text={submitText}
              intent="primary"
              loading={isSubmitting}
              disabled={teamLoading || partnersLoading || isSubmitting}
              data-test="workspace-submit-button"
            />
            {onCancel && <Button text="Cancel" disabled={isSubmitting} onClick={onCancel} />}
          </CardFooter>
        </Card>
      </Form>
    </Formik>
  );
};

ContractVehicleForm.propTypes = propTypes;
