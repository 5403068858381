import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';

import { ChartDataPointIndex } from 'types/__generated__/GovlyApi';

import { MainColumn, ColumnSpan } from 'app/atoms/MainColumn/MainColumn';
import { CardHeading } from 'app/atoms/Typography/Typography';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableRowCountTag } from 'app/molecules/GovlyTable/GovlyTableRowCountTag';

type VendorProfileSpendingTableProps = {
  label: string;
  colSpan?: ColumnSpan;
  isLoading?: boolean;
  spendingData: ChartDataPointIndex[];
};

const columnHelper = createColumnHelper<ChartDataPointIndex>();

export const VendorProfileSpendingTable = ({
  label,
  colSpan,
  spendingData,
  isLoading
}: VendorProfileSpendingTableProps) => {
  const columns = useMemo(
    () => [
      columnHelper.accessor('xStr', { header: 'Name', enableSorting: false, enableColumnFilter: false }),
      columnHelper.accessor('y', {
        header: 'Amount',
        enableSorting: false,
        enableColumnFilter: false,
        cell: e =>
          e.row.original.y.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
          })
      })
    ],
    []
  );

  const tableData = useMemo(() => spendingData, [spendingData]);

  if (!tableData.length) {
    return null;
  }

  return (
    <MainColumn columnSpan={colSpan || 6}>
      <GovlyTable
        trackingObject={`vspending_table_${label}`.toLowerCase() as Lowercase<string>}
        columns={columns}
        data={tableData}
        title={
          <span className="flex gap-2 items-center">
            <CardHeading>{label}</CardHeading>
            <GovlyTableRowCountTag />
          </span>
        }
        isLoading={isLoading}
        initialState={{
          pagination: {
            pageSize: 5
          },
          sorting: [
            {
              id: 'y',
              desc: true
            }
          ]
        }}
      />
    </MainColumn>
  );
};
