import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@blueprintjs/core';

import { useUpdateWorkspaceMutation } from 'api/workspacesApi';
import { WorkspaceForm } from 'app/organisms/WorkspaceForm/WorkspaceForm';

const propTypes = {
  workspaces: PropTypes.array.isRequired,
  setWorkspaceFormAttrs: PropTypes.func.isRequired,
  workspaceFormAttrs: PropTypes.object
};

export const WorklistFormDialog = ({ workspaces, workspaceFormAttrs, setWorkspaceFormAttrs }) => {
  const [updateOppWorkspace, { isLoading }] = useUpdateWorkspaceMutation();

  if (!workspaceFormAttrs) return null;
  const space = workspaces.find(w => w.id === workspaceFormAttrs.id);
  return (
    <Dialog
      onClose={() => setWorkspaceFormAttrs(null)}
      isOpen={workspaceFormAttrs?.id === space.id}
      className="min-w-[50%] p-0"
    >
      <WorkspaceForm
        basicInputs
        followersInputs
        stageInputs
        title={space.organizationDefault && space.name === 'Govly' ? 'Default Workspace' : space.name}
        titleDescription={space.oppData?.displayName}
        onCancel={() => setWorkspaceFormAttrs(null)}
        isSubmitting={isLoading}
        onSubmit={updateOppWorkspace}
        workableTypeForLink="opportunities"
        follows={space.follows}
        initialValues={{
          id: space.id,
          name: space.name,
          oppId: space.oppData.id,
          organizationDefault: space.organizationDefault,
          privateAccess: space.privateAccess,
          workflowStage: space.workflowStage
        }}
        {...workspaceFormAttrs}
      />
    </Dialog>
  );
};

WorklistFormDialog.propTypes = propTypes;
