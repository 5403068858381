import React from 'react';
import { MultiTagInput, MultiTagInputProps } from 'app/atoms/inputs/MultiTagInput/MultiTagInput';
import { useGetCertificationsQuery } from 'api/certificationsApi';

export const CertificationMultiSelectInput = (props: MultiTagInputProps) => {
  const { data: certifications = [], isLoading } = useGetCertificationsQuery({
    view: 'typeahead'
  });

  return <MultiTagInput {...props} items={certifications.map(({ name }) => name)} loading={isLoading} />;
};
