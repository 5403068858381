import React from 'react';
import { useFormikContext } from 'formik';
import { Button, FormGroup } from '@blueprintjs/core';

import { useContactSearchMutation } from 'api/contactsApi';
import { SearchQueryInput } from 'app/atoms/inputs/SearchQueryInput/SearchQueryInput';
import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { H2 } from 'app/atoms/Typography/Typography';
import { defaultValues } from 'app/hooks/search/useContactSearchCache';
import { SavedSearchSelectInput } from 'app/organisms/SavedSearchSelectInput/SavedSearchSelectInput';
import { SavedSearchControls } from 'app/organisms/SavedSearchControls/SavedSearchControls';

export const ContactSearchQuery = () => {
  const { setValues, submitForm } = useFormikContext();

  const [_search, { isLoading }] = useContactSearchMutation({
    fixedCacheKey: 'contact-search'
  });

  return (
    <div className="grid grid-cols-1 divide-y dark:divide-gray-600">
      <div className="flex items-center justify-between pb-6">
        <H2>Contact Search</H2>
        <LinkTag
          tag="button"
          onClick={e => {
            e.preventDefault();
            setValues(defaultValues);
            submitForm();
          }}
        >
          Reset search
        </LinkTag>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2">
        <SavedSearchSelectInput searchableType="Contact" />
        <FormGroup label={<LabelSpan showEmpty />} className="m-0 pb-6 pt-0 sm:pt-6" contentClassName="mt-2">
          <div className="flex min-w-max justify-end space-x-2">
            <SavedSearchControls searchableType="Contact" />
            <Button data-test="contact-search-button" large loading={isLoading} type="submit" intent="primary">
              Search
            </Button>
          </div>
        </FormGroup>
      </div>
      <SearchQueryInput
        label="Search Terms"
        name="query"
        className="m-0 pt-6"
        loading={isLoading}
        placeholder={`“end user” AND Nutanix`}
      />
    </div>
  );
};
