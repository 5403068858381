import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AnchorButton, Dialog, Tooltip } from '@blueprintjs/core';

import { WorkspaceForm } from 'app/organisms/WorkspaceForm/WorkspaceForm';
import { useCreateWorkspaceMutation } from 'api/workspacesApi';

export const OppDetailsCreateWorkspace = ({ tooltipProps = {}, follows = [], name, oppId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [createOppWorkspace, { isLoading }] = useCreateWorkspaceMutation();

  return (
    <>
      <Tooltip {...tooltipProps}>
        <AnchorButton icon="plus" onClick={() => setIsOpen(true)} />
      </Tooltip>
      <Dialog onClose={() => setIsOpen(false)} isOpen={isOpen} className="min-w-[50%] p-0">
        <WorkspaceForm
          onCancel={() => setIsOpen(false)}
          isSubmitting={isLoading}
          title="Create Workspace"
          onSubmit={createOppWorkspace}
          follows={follows}
          basicInputs={true}
          followersInputs={true}
          stageInputs={true}
          initialValues={{ oppId, name, workflowStage: 'qualifying' }}
          workableTypeForLink="opportunities"
          trackingData={{ event: 'Opp Workspace Created' }}
        />
      </Dialog>
    </>
  );
};

OppDetailsCreateWorkspace.propTypes = {
  oppId: PropTypes.string.isRequired,
  name: PropTypes.string,
  tooltipProps: PropTypes.object,
  follows: PropTypes.array
};
