import React from 'react';
import { Card, CardBody, CardProps, CardSectionProps } from 'app/atoms/Card/Card';
import { useGovlyTableChildren } from 'app/molecules/GovlyTable/useGovlyTableChildren';
import { cn } from 'app/lib/cn';
import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTableColumnVisibilityToggles } from 'app/molecules/GovlyTable/GovlyTableColumnVisibilityToggles';
import { useGovlyTableContext } from 'app/molecules/GovlyTable/GovlyTableContext';
import { GovlyTableBase } from './GovlyTableBase';

export type GovlyTableCardProps = React.PropsWithChildren<{
  bodyProps?: Partial<CardSectionProps>;
  cardProps?: Partial<CardProps>;
}>;

export function GovlyTableCard({ bodyProps, cardProps, children }: GovlyTableCardProps) {
  const { toolbar, pagination, emptyState } = useGovlyTableChildren(children);
  const { table } = useGovlyTableContext();

  return (
    <Card
      {...cardProps}
      rightElement={
        cardProps?.rightElement ??
        (React.isValidElement(toolbar) ? (
          toolbar
        ) : (
          <GovlyTableToolbar>
            {table.options.enableHiding !== false ? <GovlyTableColumnVisibilityToggles /> : null}
          </GovlyTableToolbar>
        ))
      }
    >
      <CardBody {...bodyProps} className={cn('!p-0', bodyProps?.className)}>
        <div>
          <div className="overflow-x-auto w-full">
            <GovlyTableBase />
          </div>

          {(pagination || emptyState) && (
            <div className="px-5 py-2 border-t border-gray-200">
              {pagination}
              {emptyState}
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
}
