import React, { useState } from 'react';
import { Button, Dialog } from '@blueprintjs/core';

import { useGetCurrentOrganizationQuery } from 'api/organizationsApi';
import { useGetCrmConnectionsQuery } from 'api/crmConnectionsApi';
import { OrganizationForm } from 'app/organisms/OrganizationForm/OrganizationForm';
import { CrmConnectionFormUnanet } from 'app/organisms/CrmConnectionFormUnanet/CrmConnectionFormUnanet';
import { Card, CardSection } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';

export const OrganizationSettings = () => {
  const [openConnectionForm, setOpenConnectionForm] = useState('');
  const { data: organization, isLoading } = useGetCurrentOrganizationQuery();
  const { data: crmConnections } = useGetCrmConnectionsQuery();

  const unanetConnection = crmConnections?.find(connection => connection?.name === 'Unanet' && connection?.active);

  if (isLoading || !organization) {
    return <Loading />;
  }

  const closeConnectionForm = () => setOpenConnectionForm('');

  return (
    <>
      <OrganizationForm organization={organization} />
      <Card title="Integrations">
        <CardSection>
          <Button
            text={unanetConnection ? 'Connected to Unanet' : 'Connect Unanet'}
            icon={unanetConnection ? 'tick-circle' : 'plus'}
            intent={unanetConnection ? 'success' : 'primary'}
            onClick={async () => {
              setOpenConnectionForm('Unanet');
            }}
          />
          <Dialog isOpen={!!openConnectionForm} onClose={() => closeConnectionForm()}>
            {openConnectionForm === 'Unanet' && <CrmConnectionFormUnanet onSubmit={() => closeConnectionForm()} />}
          </Dialog>
        </CardSection>
      </Card>
    </>
  );
};
