import React, { useMemo } from 'react';
import { AnchorButton } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper, SortingFn, Row } from '@tanstack/react-table';

import { OrganizationUserCurrentOrg } from 'types/__generated__/GovlyApi';
import { useGetOrganizationUsersQuery } from 'api/organizationUsersApi';

import { useAuthorized } from 'app/hooks/useAuthorize';
import { useDeviceWidth } from 'app/hooks/useDeviceWidth';

import {
  UserProfileSubscriptionTag,
  getUserProfileSubscriptionTagInfo
} from 'app/organisms/UserProfileSubscriptionTag/UserProfileSubscriptionTag';

import { CardHeading } from 'app/atoms/Typography/Typography';
import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableRowCountTag } from 'app/molecules/GovlyTable/GovlyTableRowCountTag';
import { GovlyTableCSVExportButton } from 'app/molecules/GovlyTable/GovlyTableCSVExportButton';

import { UsersTableRowActions } from './UsersTableRowActions';
import { UsersTableLastActivity } from './UsersTableLastActivity';
import { UsersTableRole } from './UsersTableRole';
import { UsersTableName } from './UsersTableName';

const columnHelper = createColumnHelper<OrganizationUserCurrentOrg>();

const subscriptionTypeSort: SortingFn<OrganizationUserCurrentOrg> = (
  rowA: Row<OrganizationUserCurrentOrg>,
  rowB: Row<OrganizationUserCurrentOrg>,
  _columnId: string
) => {
  const subscriptionTypeOrder = ['trial', 'free', 'basic', 'pro', 'enterprise'];
  const subscriptionTypeA = getUserProfileSubscriptionTagInfo({
    subscriptionType: rowA.original['subscriptionType'],
    subscriptionSeatPaid: rowA.original.subscriptionSeatPaid
  })['label'];
  const subscriptionTypeB = getUserProfileSubscriptionTagInfo({
    subscriptionType: rowB.original['subscriptionType'],
    subscriptionSeatPaid: rowB.original.subscriptionSeatPaid
  })['label'];

  const indexA = subscriptionTypeOrder.indexOf(subscriptionTypeA);
  const indexB = subscriptionTypeOrder.indexOf(subscriptionTypeB);

  if (indexA > indexB) return 1;
  if (indexB > indexA) return -1;
  return 0;
};

const lastActiveAtSort: SortingFn<OrganizationUserCurrentOrg> = (
  rowA: Row<OrganizationUserCurrentOrg>,
  rowB: Row<OrganizationUserCurrentOrg>,
  _columnId: string
) => {
  const rowALastActiveAt = rowA.original['lastActiveAt'];
  const rowBLastActiveAt = rowB.original['lastActiveAt'];

  if (!rowALastActiveAt && !rowBLastActiveAt) return 0;
  if (!rowALastActiveAt) return 1;
  if (!rowBLastActiveAt) return -1;
  if (rowALastActiveAt > rowBLastActiveAt) return 1;
  if (rowBLastActiveAt > rowALastActiveAt) return -1;
  return 0;
};

export const UsersTable = () => {
  const navigate = useNavigate();
  const isAdmin = useAuthorized({ role: 'admin' });
  const { isMobile, isTablet } = useDeviceWidth();

  const { data, isLoading } = useGetOrganizationUsersQuery({ view: 'current_org' });
  const organizationUsers = (data ?? []) as OrganizationUserCurrentOrg[];

  const users = organizationUsers.filter(organizationUser => organizationUser.internal === false);

  const hiddenColumns: { [k: string]: boolean } = useMemo(() => {
    if (isMobile) return { email: true, primaryRole: false, lastActiveAt: false };
    if (isTablet) return { email: true, primaryRole: true, lastActiveAt: false };
    return { email: true, primaryRole: true, lastActiveAt: true };
  }, [isMobile, isTablet]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        cell: e => <UsersTableName organizationUser={e.row.original} />,
        sortingFn: 'basic',
        filterFn: 'fuzzyText'
      }),
      columnHelper.accessor('email', {
        header: 'Email'
      }),
      columnHelper.accessor('primaryRole', {
        header: 'Role',
        cell: e => (e.row.original.primaryRole ? <UsersTableRole role={e.row.original.primaryRole} /> : undefined),
        sortingFn: 'basic',
        enableColumnFilter: false
      }),
      columnHelper.accessor('lastActiveAt', {
        header: 'Last Activity',
        cell: e => (
          <UsersTableLastActivity
            lastActiveAt={e.row.original.lastActiveAt}
            inviteSentAt={e.row.original.inviteSentAt}
          />
        ),
        sortingFn: lastActiveAtSort,
        enableColumnFilter: false
      }),
      columnHelper.accessor('subscriptionType', {
        header: 'Subscription',
        enableColumnFilter: false,
        cell: e => (
          <UserProfileSubscriptionTag
            subscriptionType={e.row.original.subscriptionType}
            subscriptionSeatPaid={e.row.original.subscriptionSeatPaid}
          />
        ),
        sortingFn: subscriptionTypeSort
      }),
      columnHelper.display({
        id: 'actions',
        cell: e => <UsersTableRowActions organizationUser={e.row.original} />
      })
    ],
    []
  );

  const tableData = useMemo(() => users, [users]);

  return (
    <GovlyTable
      trackingObject="user_table"
      columns={columns}
      data={tableData}
      title={
        <span className="flex gap-2 items-center">
          <CardHeading>Users</CardHeading>
          <GovlyTableRowCountTag />
        </span>
      }
      cardProps={{
        rightElement: (
          <GovlyTableToolbar>
            <GovlyTableCSVExportButton filename="govly_users" />
            <AnchorButton
              onClick={() =>
                navigate('/users/new', {
                  state: { breadcrumbs: [{ to: '/settings/users', text: 'Users' }, { text: 'Add User' }] }
                })
              }
              intent="primary"
              className="h-7 sm:ml-0 md:ml-2"
              disabled={!isAdmin}
              icon="plus"
              text="Add User"
            />
          </GovlyTableToolbar>
        )
      }}
      isLoading={isLoading}
      tableProps={{
        state: {
          columnVisibility: hiddenColumns
        }
      }}
      initialState={{
        sorting: [{ id: 'name', desc: false }],
        pagination: {
          pageSize: 25
        }
      }}
    />
  );
};
