import React from 'react';

import { AvatarList } from 'app/molecules/AvatarList/AvatarList';
import { TableColumnHeader } from 'app/templates/ReactTableTemplate/ReactTableTemplate';

export const avatarListColumn = ({ header, ...rest }) => {
  return {
    Header: header || 'Followers',
    HeaderComponent: React.memo(TableColumnHeader),
    accessor: 'follows',
    filter: (rows, columnIds, filterValue) => {
      if (!filterValue) return rows;

      const searchTerm = filterValue.toLowerCase();
      return rows.filter(row =>
        row.original.follows.find(
          f =>
            f.organizationUser.name.toLowerCase().includes(searchTerm) ||
            f.organizationUser.email.toLowerCase().includes(searchTerm)
        )
      );
    },
    textFilter: true,
    Cell: e => (
      <AvatarList
        limit={2}
        avatarData={e.row.original.follows.map(
          ({ organizationUser: { name, initials, organizationName, avatar, avatarColor }, active }) => ({
            initials,
            name,
            organizationName,
            avatar,
            avatarColor,
            active,
            notificationProps: {
              intent: active ? 'success' : 'danger',
              icon: active ? 'thumbs-up' : 'thumbs-down',
              size: 'xl',
              position: 'bottom'
            }
          })
        )}
      />
    ),
    ...rest
  };
};
