import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { Button, Dialog } from '@blueprintjs/core';

import { useShareOppSourceMutation } from 'api/oppSourcesApi';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { AttachmentList } from 'app/organisms/AttachmentList/AttachmentList';
import { ShareViaEmailForm } from 'app/organisms/ShareViaEmailForm/ShareViaEmailForm';
import { Breadcrumbs } from 'app/molecules/Breadcrumbs/Breadcrumbs';
import { useGetOppQuery } from 'api/oppsApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { NotFound } from 'app/molecules/NotFound/NotFound';
import { DescriptionListField } from 'app/molecules/DescriptionListField/DescriptionListField';
import { formatTime, DATETIME_24_SHORT } from 'app/lib/dates';
import { useCurrentUserAttribute } from 'api/currentUserApi';

type OppDetailsSourceProps = {
  oppId: string;
  oppSourceId: string;
};

export const OppDetailsSource = ({ oppId, oppSourceId }: OppDetailsSourceProps) => {
  const navigate = useNavigate();
  const [isSharing, setIsSharing] = useState(false);
  const [shareOppSource, { isLoading: isSubmittingShare }] = useShareOppSourceMutation();
  const disableDownloads = useCurrentUserAttribute('organization.settings.disableOppAttachmentDownloads');
  const { data: { oppSources, displayName } = {}, isLoading } = useGetOppQuery({ id: oppId });

  if (isLoading) {
    return <Loading />;
  }

  const source = oppSources?.find(({ id }) => id === oppSourceId);

  if (!source) {
    return <NotFound />;
  }

  const { attachments, subject, mail, postedAt, modificationReason } = source;
  const body = mail?.body ?? '';

  const sanitizedBody = DOMPurify.sanitize(body);
  const hasHTML = /\/[a-z]*>/i.test(sanitizedBody);

  const breadcrumbs = [
    { text: 'Opportunities', to: '/opportunities' },
    { text: displayName, tag: 'button', onClick: () => navigate(`/opportunities/${oppId}`) }
  ];

  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      <Card title={subject}>
        <CardBody>
          <div className="flex justify-between">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              {!!postedAt && (
                <DescriptionListField label="Posted At" value={formatTime(postedAt, DATETIME_24_SHORT)} cols={2} />
              )}
              <DescriptionListField label="Modification Reason" value={modificationReason} cols={2} />
            </dl>
            <div>
              <Button large text="Share Source" icon="send-message" onClick={() => setIsSharing(true)} />
            </div>
          </div>
        </CardBody>
        <CardBody>
          {/* @ts-expect-error Component Not typed */}
          <AttachmentList attachments={attachments} disableDownloads={disableDownloads} />
          <div className="prose prose-sm">
            {hasHTML ? (
              <div dangerouslySetInnerHTML={{ __html: sanitizedBody }} />
            ) : (
              <div className="whitespace-pre-line">{sanitizedBody}</div>
            )}
          </div>
        </CardBody>
      </Card>
      <Dialog isOpen={isSharing} className="min-w-[50%] p-0">
        <ShareViaEmailForm
          title="Share Source"
          onCancel={() => setIsSharing(false)}
          initialValues={{ subject, id: oppSourceId }}
          isSubmitting={isSubmittingShare}
          onSubmit={values => shareOppSource(values).unwrap()}
        />
      </Dialog>
    </>
  );
};
