import React from 'react';
import { TagInput } from 'app/atoms/inputs/TagInput/TagInput';

export const OnboardingFormInfoInvitations = () => (
  <TagInput
    label="Invite Team Members"
    name="invitations"
    helperText="Add email addresses for team members you would like to invite"
  />
);
