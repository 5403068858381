import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Tag, EntityTitle } from '@blueprintjs/core';

import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTablePagination } from 'app/molecules/GovlyTable/GovlyTablePagination';
import { LlmGeneratedIndicator } from 'app/molecules/LlmGeneratedIndicator/LlmGeneratedIndicator';
import { CardHeadingSmall } from 'app/atoms/Typography/Typography';

export type LineItem = {
  vendor?: string;
  product?: string;
  service?: string;
  quantity?: number;
  description?: string;
  partNumber?: string;
};

export type OppLineItemsTableProps = {
  data?: Array<LineItem>;
  title?: string;
};

const columnHelper = createColumnHelper<LineItem>();

const columns = [
  columnHelper.accessor('vendor', {
    header: 'Vendor',
    sortingFn: 'basic',
    filterFn: 'fuzzyText'
  }),
  columnHelper.accessor('product', {
    header: 'Product/Service',
    sortingFn: 'basic',
    filterFn: 'fuzzyText',
    cell: e => e.row.original.product || e.row.original.service
  }),
  columnHelper.accessor('quantity', {
    header: 'Quantity',
    sortingFn: 'basic'
  }),
  columnHelper.accessor('partNumber', {
    header: 'Part Number',
    sortingFn: 'basic'
  })
];

export const OppLineItemsTable = ({ data, title, ...rest }: OppLineItemsTableProps) => {
  const lineItems = data?.filter((item: LineItem) => !!item.vendor);
  if (!lineItems || lineItems.length === 0) {
    return null;
  }

  return (
    <GovlyTable<LineItem>
      trackingObject="opp_line_items_table"
      columns={columns}
      data={lineItems}
      cardProps={{
        compact: true,
        collapseProps: { defaultIsOpen: false },
        title: (
          <EntityTitle
            heading={CardHeadingSmall}
            title={title || 'Line Items'}
            tags={<Tag minimal>{lineItems.length}</Tag>}
          />
        ),
        collapsible: true,
        icon: <LlmGeneratedIndicator icon="generate" />
      }}
      {...rest}
    >
      <GovlyTablePagination />
    </GovlyTable>
  );
};
