import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { OppDetails } from 'app/organisms/OppDetails/OppDetails';

export const OppWorkspaceOppDetails = ({ oppIdProp, workspaceIdProp }) => {
  let { oppId, workspaceId } = useParams();

  if (!oppId && !workspaceId) {
    oppId = oppIdProp;
    workspaceId = workspaceIdProp;
  }

  return <OppDetails id={oppId} />;
};

OppWorkspaceOppDetails.propTypes = {
  oppIdProp: PropTypes.string,
  workspaceIdProp: PropTypes.string
};
