import React, { useState } from 'react';
import { Button, Menu, MenuItem, Popover } from '@blueprintjs/core';

import { formatTime, DATE_MONTH_AND_DAY, TIME_HOUR_AND_MINUTE } from 'app/lib/dates';
import { AvatarWithName } from 'app/molecules/AvatarWithName/AvatarWithName';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { Reactions } from 'app/molecules/Reactions/Reactions';
import { CommentShow } from 'types/__generated__/GovlyApi';

type CommentProps = {
  comment: Pick<CommentShow, 'id' | 'createdAt' | 'content' | 'organizationUser'>;
  editor: React.ReactElement;
  onRemove: () => void;
  isAuthor?: boolean;
};

const DELETED_ORGANIZATION_USER = {
  avatarColor: '#293742',
  name: 'User Removed',
  email: '[user removed]',
  initials: '?',
  avatar: null
};

export const Comment = ({ comment, editor, onRemove, isAuthor }: CommentProps) => {
  const [isEditing, setEditing] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const { id: commentId, organizationUser } = comment;
  const { avatarColor, avatar, name, email, initials } = organizationUser ?? DELETED_ORGANIZATION_USER;

  return (
    <Card className={deleted ? 'hidden' : ''}>
      <CardBody data-test="comment">
        <div className="flex justify-between">
          <AvatarWithName
            initials={initials}
            imgSrc={avatar?.thumbUrl}
            avatarColor={avatarColor}
            id={commentId}
            name={name}
            email={email}
            subtext={`${formatTime(comment.createdAt, DATE_MONTH_AND_DAY)} at ${formatTime(
              comment.createdAt,
              TIME_HOUR_AND_MINUTE
            )}`}
          />
          {isAuthor && (
            <Popover
              content={
                <Menu key="menu" large>
                  <MenuItem icon="edit" text="Edit" onClick={() => setEditing(true)} data-test="edit-comment-button" />
                  <MenuItem
                    icon="trash"
                    text="Delete"
                    onClick={() => {
                      setDeleted(true);
                      onRemove();
                    }}
                    data-test="delete-comment-button"
                  />
                </Menu>
              }
            >
              <Button minimal icon="more" data-test="comment-menu-button" />
            </Popover>
          )}
        </div>
        {isEditing ? (
          React.cloneElement(editor, { afterSubmit: () => setEditing(false) })
        ) : (
          <div>
            <div className="prose prose-sm">
              <div dangerouslySetInnerHTML={{ __html: comment.content ?? '' }} />
            </div>
            <Reactions commentId={commentId} />
          </div>
        )}
      </CardBody>
    </Card>
  );
};
