import React from 'react';
import { Formik } from 'formik';

import { oppsApi, useSearchMutation } from 'api/oppsApi';
import { OppSearchFormFields } from 'app/organisms/OppSearchFormFields/OppSearchFormFields';
import { AppLoading } from 'app/atoms/AppLoading/AppLoading';
import { getFormState, useOppSearchCache, defaultState } from 'app/hooks/search/useOppSearchCache';
import { useGetOppSearchesQuery } from 'api/oppSearchesApi';
import { useHydrateSearchForm } from 'app/hooks/search/useHydrateSearchForm';
import { useDefaultSearchStateStore } from 'app/hooks/useDefaultSearchStateStore';
import { LoaderFunction, useLocation } from 'react-router-dom';
import { useScrollAppBody } from 'app/hooks/useScrollAppBody';
import { makeCrumbHandle } from 'app/molecules/Breadcrumbs/useBreadcrumbs';
import { match, P } from 'ts-pattern';
import { store } from 'app/store';
import { DRAWER_PARAMS } from 'app/organisms/AppDrawer/constants';
import { oppWorkspacesApi } from 'api/oppWorkspacesApi';

type LoaderData = { drawerType?: string | null; drawerId?: string | null };

export const loader: LoaderFunction = async ({ request }): Promise<LoaderData> => {
  useDefaultSearchStateStore.setState({ defaultState });
  const url = new URL(request.url);
  return {
    drawerType: url.searchParams.get(DRAWER_PARAMS.type),
    drawerId: url.searchParams.get(DRAWER_PARAMS.id)
  };
};

export const handle = makeCrumbHandle<LoaderData>(async ({ drawerType, drawerId }) => {
  const drawerCrumbs = await match({ drawerType, drawerId })
    .with({ drawerType: 'opportunities', drawerId: P.string }, async ({ drawerId }) => {
      return store
        .dispatch(oppsApi.endpoints.getOpp.initiate({ id: drawerId, fromSearch: true }, { subscribe: false }))
        .then(({ data }) => [{ text: data?.displayName ?? data?.title ?? 'Opportunity' }]);
    })
    .with({ drawerType: 'workspaces', drawerId: P.string }, async ({ drawerId }) => {
      const { data } = await store.dispatch(
        oppWorkspacesApi.endpoints.getOppWorkspace.initiate({ id: drawerId }, { subscribe: false })
      );
      return data
        ? [
            {
              text: `${data.name ?? 'Workspace'} (Opportunity: ${data.workableDisplayName ?? '<Unnamed>'})`
            }
          ]
        : [{ text: 'Workspace' }];
    })
    .otherwise(() => []);

  return [{ text: 'Opportunities', to: '/opportunities' }, ...drawerCrumbs];
});

export const OppSearchPage = () => {
  const { pathname } = useLocation();
  const { isLoading } = useGetOppSearchesQuery({ active: true });
  const { initialValues, isInitializing } = useHydrateSearchForm({
    store: useOppSearchCache,
    getFormState,
    parse: (s, queryParam) =>
      JSON.parse(s, (k, v) => {
        if ((k === 'range' || queryParam?.endsWith('dateRange')) && Array.isArray(v)) {
          return v.map(s => new Date(s));
        }
        return v;
      })
  });
  const scrollAppBody = useScrollAppBody();

  const [search] = useSearchMutation({
    fixedCacheKey: 'opp-search'
  });

  if (isLoading || (isInitializing && !initialValues)) {
    return <AppLoading />;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({ query, filters: { predictions, searchType, ...filters } }) => {
        const safeSearchType = predictions ? 'predictions' : searchType || 'fed';
        search({ query, ...filters, searchType: safeSearchType });

        if (pathname == '/opportunities') {
          scrollAppBody({ top: 0, behavior: 'smooth' });
        }
      }}
    >
      <OppSearchFormFields />
    </Formik>
  );
};
