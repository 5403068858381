import React from 'react';
import { useParams } from 'react-router-dom';
import { AnchorButton, Tag } from '@blueprintjs/core';
import truncate from 'lodash-es/truncate';

import { useAuthorized } from 'app/hooks/useAuthorize';
import { AppLoading } from 'app/atoms/AppLoading/AppLoading';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { DatalistLeftAligned } from 'app/molecules/DatalistLeftAligned/DatalistLeftAligned';
import { CrmContact } from 'app/molecules/CrmContact/CrmContact';
import { useGetVendorQuery } from 'api/vendorsApi';
import { useMetaTags } from 'app/hooks/useMetaTags';

export const VendorProfileSummary = () => {
  const sysAdmin = useAuthorized({ role: 'sys_admin' });
  const authorized = useAuthorized({ role: 'member' });
  const { slug } = useParams();
  const { data: vendor, isLoading } = useGetVendorQuery({ id: slug });

  useMetaTags({
    title: `${vendor?.name} - Govly Vendor Profile`,
    description: vendor?.description?.split('. ')[0] || `View ${vendor?.name}'s profile on Govly`
  });

  if (isLoading) return <AppLoading />;

  const website = vendor.primaryWebsite;
  const datalistItems = [
    {
      label: 'Name',
      value: vendor.name
    },
    {
      label: 'Description',
      value: vendor.description
    },
    {
      label: 'Website',
      value: website && (
        <LinkTag target="_blank" rel="noreferrer" className="mt-1 text-sm" tag="a" href={website}>
          {truncate(website.replace(/^https?:\/\//, '').replace(/\/$/, ''), { length: 45 })}
        </LinkTag>
      )
    },
    {
      label: 'Phone',
      value: vendor.phone
    },
    {
      label: 'Identifiers',
      className: 'gap-x-2 flex',
      value: [
        { label: 'DUNS', value: vendor.duns },
        { label: 'UEI', value: vendor.uei }
      ]
        .filter(({ value }) => value)
        .map(({ label, value }) => (
          <Tag minimal key={label}>
            <strong className="mr-2">{label}</strong>
            <span>{value}</span>
          </Tag>
        ))
    },
    {
      label: 'Vendor Type',
      className: 'gap-2 flex flex-wrap',
      value: vendor.companyTypeList?.map(type => {
        const intent = {
          'Prime Contractor': 'primary',
          Reseller: 'warning',
          OEM: 'success'
        }[type];

        return (
          <Tag key={type} intent={intent}>
            {type}
          </Tag>
        );
      })
    },
    {
      label: 'Headquarters',
      value: vendor.details?.hq
    },
    {
      label: 'Linkedin',
      value: vendor.details?.linkedin && (
        <LinkTag target="_blank" rel="noreferrer" className="mt-1 text-sm" tag="a" href={vendor.details?.linkedin}>
          {vendor.details?.linkedin}
        </LinkTag>
      )
    },
    { label: 'Stock Symbol', value: vendor.details?.stockSymbol },
    { label: 'Estimated Revenue', value: vendor.details?.estimatedRevenue },
    { label: 'Number of Employees', value: vendor.details?.numberOfEmployees },
    {
      label: 'Tags',
      className: 'gap-2 flex flex-wrap',
      value: (vendor.tags || []).map((type, i) => {
        return (
          <Tag key={`${i}-${type}`} minimal intent="primary">
            {type}
          </Tag>
        );
      })
    }
  ];

  return (
    <MainColumn columnSpan={12}>
      <Card
        title="Summary"
        rightElement={
          <div className="flex gap-x-2">
            {vendor.organizationId && (
              <Tag intent="success" icon="endorsed" large>
                Govly Partner
              </Tag>
            )}
            <div className="flex space-x-2 items-center">
              {vendor.claimed && (
                <div>
                  <Tag minimal large intent="success" icon="endorsed">
                    Govly Vendor
                  </Tag>
                </div>
              )}
              {sysAdmin && (
                <AnchorButton href={`/sys_admin/vendors/${vendor.id}`} icon="edit" target="_blank" rel="noreferrer" />
              )}
            </div>
          </div>
        }
      >
        <CardBody padded={false}>
          <DatalistLeftAligned items={datalistItems} excludeNullRows />
        </CardBody>
      </Card>
      {vendor.contacts?.length ? (
        <Card title="Contacts">
          <CardBody padded={false}>
            <ul role="list" className="divide-y divide-gray-200">
              {vendor.contacts.map((contact, i) => (
                <li key={`contact-${i}`}>
                  <CrmContact contact={contact} authorized={authorized} />
                </li>
              ))}
            </ul>
          </CardBody>
        </Card>
      ) : null}
    </MainColumn>
  );
};
