import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { formatTime, DATETIME_24_SHORT } from 'app/lib/dates';

import { ReportOrganizationUserReport } from 'types/__generated__/GovlyApi';
import { useGetReportQuery } from 'api/reportsApi';

import { ContactIdTag } from 'app/organisms/ContactIdTag/ContactIdTag';
import { CardError } from 'app/atoms/ErrorFallback/CardError';
import { ErrorBoundary } from 'app/atoms/ErrorBoundary/ErrorBoundary';

import { CardHeading } from 'app/atoms/Typography/Typography';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableRowCountTag } from 'app/molecules/GovlyTable/GovlyTableRowCountTag';
import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTableCSVExportButton } from 'app/molecules/GovlyTable/GovlyTableCSVExportButton';

type OrganizationInsightsActivityTableProps = {
  filters: {
    orgUserId: string;
    dateRange: [Date, Date];
  };
};

const columnHelper = createColumnHelper<ReportOrganizationUserReport>();

export const OrganizationInsightsActivityTable = ({ filters }: OrganizationInsightsActivityTableProps) => {
  const { data = [], isLoading } = useGetReportQuery({
    ...filters,
    report_type: 'Report::OrganizationUserReport'
  });

  const report = data as ReportOrganizationUserReport[];

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        sortingFn: 'basic',
        cell: e => <ContactIdTag email={e.row.original.email ?? ''} name={e.row.original.name} hideAvatar />
      }),
      columnHelper.accessor('lastActiveAt', {
        header: 'Last Activity',
        sortingFn: 'basic',
        enableColumnFilter: false,
        cell: e => {
          if (e.row.original.lastActiveAt) {
            return formatTime(e.row.original.lastActiveAt, DATETIME_24_SHORT);
          }
          return 'Never';
        }
      }),
      columnHelper.accessor('oppsFollowed', {
        header: 'Opps Followed',
        sortingFn: 'basic',
        enableColumnFilter: false
      }),
      columnHelper.accessor('oppsMatched', {
        header: 'Opps Matched',
        sortingFn: 'basic',
        enableColumnFilter: false
      }),
      columnHelper.accessor('oppsShared', {
        header: 'Opps Shared',
        sortingFn: 'basic',
        enableColumnFilter: false
      })
    ],
    []
  );

  const tableData = useMemo(() => report, [report]);

  return (
    <ErrorBoundary
      action="OrganizationInsightsActivityTable"
      fallback={<CardError title="User Opportunity Activity" />}
    >
      <GovlyTable
        trackingObject="organization_insights_activity_table"
        columns={columns}
        data={tableData}
        title={
          <span className="flex gap-2 items-center">
            <CardHeading>User Opportunity Activity</CardHeading>
            <GovlyTableRowCountTag />
          </span>
        }
        cardProps={{
          rightElement: (
            <GovlyTableToolbar>
              <GovlyTableCSVExportButton filename="user_opportunity_activity" />
            </GovlyTableToolbar>
          )
        }}
        isLoading={isLoading}
        emptyStateProps={{
          icon: undefined,
          title: 'No activity',
          description: (
            <div>
              {`Your filters didn't yield any activity.`}
              <br />
              {`Try adjusting your the filters above.`}
            </div>
          )
        }}
        initialState={{
          sorting: [
            {
              id: 'oppsFollowed',
              desc: true
            }
          ],
          pagination: {
            pageSize: 3
          }
        }}
      />
    </ErrorBoundary>
  );
};
