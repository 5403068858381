import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash-es/startCase';

const getTopHighlight = highlights => {
  const f = {};

  Object.keys(highlights || {}).forEach(key => {
    const label = key.split('.')[0];
    const str = highlights[key][0];
    const start = Math.max(str.indexOf('<mark>') - 500, 0);
    const end = Math.min(str.indexOf('</mark>') + 500, str.length);
    const value = `${start !== 0 ? '...' : ''}${str.substring(start, end)}${end !== str.length ? '...' : ''}`;

    f[label] = { label, value };
  });

  return (
    f['identifiers'] ||
    f['title'] ||
    f['description'] ||
    f['source'] ||
    f['attachments'] ||
    f['aiSummary'] ||
    f['aiTitle']
  );
};

export const OppSearchResultHighlight = ({ highlights }) => {
  const highlight = getTopHighlight(highlights);
  if (!highlight) return null;

  let label = startCase(highlight.label);

  if (highlight.label === 'aiTitle') {
    label = 'AI Title';
  }

  if (highlight.label === 'aiSummary') {
    label = 'AI Summary';
  }

  return (
    <blockquote className="bp5-blockquote mt-2">
      <small>
        <em>
          <strong>{`Matched in ${label}:`}</strong>
        </em>
        <em>
          <div dangerouslySetInnerHTML={{ __html: highlight.value }} />
        </em>
      </small>
    </blockquote>
  );
};

OppSearchResultHighlight.propTypes = {
  highlights: PropTypes.object
};
