import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tag, Button, Dialog } from '@blueprintjs/core';

import { CardBody } from 'app/atoms/Card/Card';
import { useGetVendorQuery } from 'api/vendorsApi';
import { VendorPartnerForm } from 'app/organisms/VendorPartnerForm/VendorPartnerForm';

export const VendorPartnersFormList = ({ vendorId, useGetList, recordTitle, ...rest }) => {
  const [isEditing, setIsEditing] = useState();
  const { isLoading: vendorLoading, isError: vendorLoadingError } = useGetVendorQuery({ id: vendorId });
  const { data: list, isLoading: listLoading, isFetching } = useGetList({ vendorId });

  useEffect(() => {
    if (isEditing) {
      setIsEditing(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  if (vendorLoading || vendorLoadingError || listLoading) {
    return null;
  }

  return list.map(record => {
    const { vendor, ...initialValues } = record;

    return (
      <div key={record.id}>
        <CardBody>
          <div className="flex items-center justify-between">
            <div>
              <p className="mb-1 block text-base font-medium">{record.vendor.name}</p>
              <div className="flex space-x-2">
                <Tag minimal intent="primary">
                  {record.tier}
                </Tag>
                <Tag minimal>{`Sort Order ${record.sortOrder}`}</Tag>
              </div>
            </div>
            <div>
              <Button icon="edit" small onClick={() => setIsEditing(record.id)} />
            </div>
          </div>
        </CardBody>
        <Dialog title={`Edit ${vendor.name}`} isOpen={isEditing} className="p-0" onClose={() => setIsEditing(null)}>
          <CardBody>
            <VendorPartnerForm
              vendorId={vendorId}
              initialValues={initialValues}
              useGetList={useGetList}
              recordTitle={recordTitle}
              {...rest}
            />
          </CardBody>
        </Dialog>
      </div>
    );
  });
};

VendorPartnersFormList.propTypes = {
  vendorId: PropTypes.string.isRequired,
  useGetList: PropTypes.func.isRequired,
  recordTitle: PropTypes.string.isRequired
};
