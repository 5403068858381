import React, { useEffect, useMemo } from 'react';
import startCase from 'lodash-es/startCase';
import { Tag, Button, Intent } from '@blueprintjs/core';
import { createColumnHelper } from '@tanstack/react-table';

import { PartnershipRequestAdmin } from 'types/__generated__/GovlyApi';
import { useUpdatePartnershipRequestMutation, useGetPartnershipRequestsIndexQuery } from 'api/partnershipRequestApi';

import { errorToast, successToast } from 'app/lib/toaster';
import { DATETIME_24_SHORT, formatTime } from 'app/lib/dates';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { InfoTooltip } from 'app/molecules/InfoTooltip/InfoTooltip';

import { CardHeading } from 'app/atoms/Typography/Typography';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableRowCountTag } from 'app/molecules/GovlyTable/GovlyTableRowCountTag';

const columnHelper = createColumnHelper<PartnershipRequestAdmin>();

const FINISHED_STATES = ['accepted', 'declined'];

const STATE_MAP = {
  accepted: 'success',
  declined: 'danger',
  pending: 'primary'
};

export const PartnershipRequestsTableAdmin = () => {
  const { data, isLoading } = useGetPartnershipRequestsIndexQuery({ isAdmin: true });

  const [acceptPartnershipRequest, { isLoading: isAccepting, isSuccess: acceptSuccess, isError: acceptError }] =
    useUpdatePartnershipRequestMutation();
  const [rejectPartnershipRequest, { isLoading: isRejecting, isSuccess: rejectSuccess, isError: rejectError }] =
    useUpdatePartnershipRequestMutation();

  useEffect(() => {
    if (acceptSuccess || rejectSuccess) {
      successToast('Partnership request updated');
    }

    if (acceptError || rejectError) {
      errorToast('Error updating partnership request');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptSuccess, acceptError, rejectSuccess, rejectError, successToast, errorToast]);

  const tableData = useMemo(() => (data as PartnershipRequestAdmin[]) ?? [], [data]);

  const hiddenColumns = useMemo(() => {
    if (tableData.every(request => FINISHED_STATES.includes(request.state))) {
      return { actions: false };
    }
    return { actions: true };
  }, [tableData]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('createdAt', {
        header: 'Sent at',
        sortingFn: 'basic',
        cell: e => formatTime(e.row.original.createdAt, DATETIME_24_SHORT)
      }),
      columnHelper.accessor('requestorEmail', {
        header: 'Request From',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        cell: e => (
          <ul>
            <li>{e.row.original.requestorEmail}</li>
            <li>{e.row.original.requestorName}</li>
            <li>{e.row.original.requestorCompanyName}</li>
          </ul>
        )
      }),
      columnHelper.accessor('targetVendor.name', {
        header: 'Sent to',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        cell: e => {
          if (!e.row.original.targetVendor) {
            return null;
          }

          return (
            <>
              <LinkTag target="_blank" to={`/vendors/${e.row.original.targetVendor.slug}`}>
                {e.row.original.targetVendor.name}
              </LinkTag>
              {e.row.original.targetVendor.organizationId && (
                <ul>
                  <li>({e.row.original.targetVendor.organization?.name})</li>
                  <li>✅ Govly Organization</li>
                </ul>
              )}
            </>
          );
        }
      }),
      columnHelper.accessor('state', {
        header: 'Request Status',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        cell: e => <Tag intent={STATE_MAP[e.row.original.state] as Intent}>{startCase(e.row.original.state)}</Tag>
      }),
      columnHelper.display({
        id: 'actions',
        cell: e => {
          const { id } = e.row.original;
          const noOrg = !e.row.original.targetVendor?.organizationId;
          return FINISHED_STATES.includes(e.row.original.state) ? null : (
            <>
              <div className="flex items-center space-x-2">
                <Button
                  small
                  className="block"
                  text="Approve"
                  outlined
                  intent="primary"
                  onClick={() => acceptPartnershipRequest({ id, state: 'accepted' })}
                  loading={isAccepting}
                  disabled={isAccepting || isRejecting || noOrg}
                />
                {noOrg && (
                  <InfoTooltip>
                    There is no organization tied to this vendor. There must be an organization tied to the vendor to
                    create the partnership.
                  </InfoTooltip>
                )}
              </div>
              <Button
                small
                className="mt-2 block"
                text="Deny"
                outlined
                intent="danger"
                onClick={() => rejectPartnershipRequest({ id, state: 'declined' })}
                loading={isRejecting}
                disabled={isAccepting || isRejecting}
              />
            </>
          );
        }
      })
    ],
    [acceptPartnershipRequest, isAccepting, isRejecting, rejectPartnershipRequest]
  );

  return (
    <GovlyTable
      trackingObject="partnership_requests_table_admin"
      columns={columns}
      data={tableData}
      title={
        <span className="flex gap-2 items-center">
          <CardHeading>Partnership Requests</CardHeading>
          <GovlyTableRowCountTag />
        </span>
      }
      isLoading={isLoading}
      emptyStateProps={{
        icon: 'envelope',
        title: 'No partnership requests found',
        description: 'Invite a partner to get started'
      }}
      tableProps={{
        state: {
          columnVisibility: hiddenColumns
        }
      }}
      initialState={{
        pagination: {
          pageSize: 50
        },
        sorting: [{ id: 'createdAt', desc: true }]
      }}
    />
  );
};
