import React from 'react';
import PropTypes from 'prop-types';

import {
  useCreateRecordChannelPartnerMutation,
  useUpdateRecordChannelPartnerMutation,
  useDeleteRecordChannelPartnerMutation,
  useGetChannelPartnersQuery
} from 'api/channelPartnersApi';
import { VendorPartnerForm } from 'app/organisms/VendorPartnerForm/VendorPartnerForm';

export const VendorChannelPartnerForm = ({ vendorId, initialValues }) => (
  <VendorPartnerForm
    vendorId={vendorId}
    recordTitle="Channel Partner"
    initialValues={{ ...initialValues, channelPartnerableId: vendorId, channelPartnerableType: 'Vendor' }}
    useOnCreate={useCreateRecordChannelPartnerMutation}
    useOnUpdate={useUpdateRecordChannelPartnerMutation}
    useOnDelete={useDeleteRecordChannelPartnerMutation}
    useGetList={useGetChannelPartnersQuery}
  />
);

VendorChannelPartnerForm.propTypes = {
  vendorId: PropTypes.string.isRequired,
  initialValues: PropTypes.object
};
