import React from 'react';
import { AnchorButton } from '@blueprintjs/core';

import { Card, CardSection } from 'app/atoms/Card/Card';
import { useGetCurrentUserApiSettingsQuery } from 'api/currentUserApi';
import { SecretKey } from 'app/molecules/SecretKey/SecretKey';
import { WebhooksTable } from 'app/organisms/WebhooksTable/WebhooksTable';
import { Loading } from 'app/atoms/Loading/Loading';

export const ApiSettings = () => {
  const { data: { webhookSignatureKey, apiKey, email } = {}, isLoading } = useGetCurrentUserApiSettingsQuery();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Card
        title="API Credentials"
        rightElement={
          <AnchorButton
            icon="book"
            href="https://docs.govly.com"
            target="_blank"
            rel="noopener noreferrer"
            text="View documentation"
          />
        }
      >
        <CardSection>
          <SecretKey label="API Key" secret={apiKey} description={`This API key is specific to ${email}`} />
          <SecretKey label="Webhook Signature Key" secret={webhookSignatureKey} />
        </CardSection>
      </Card>
      <WebhooksTable />
    </>
  );
};
