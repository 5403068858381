import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { RadioGroup, Radio } from '@blueprintjs/core';

import { useCurrentUserAttribute } from 'api/currentUserApi';
import { FollowerSelectInput } from 'app/organisms/FollowerSelectInput/FollowerSelectInput';

const propTypes = {
  notifyNewFollowersOnly: PropTypes.bool
};

export const WorkspaceFormNotificationsInput = ({ notifyNewFollowersOnly = true }) => {
  const currentUserId = useCurrentUserAttribute('id');
  const [{ value: notifyGroup }, _meta, { setValue: setNotifyGroup }] = useField({ name: 'notifyGroup' });
  const [{ value: currentTeamFollows }] = useField({ name: 'teamFollows' });
  const [{ value: currentPartnerFollows }] = useField({ name: 'partnerFollows' });

  const follows = [...currentTeamFollows, ...currentPartnerFollows];

  const notifiableFollows = follows.filter(({ organizationUserId }) => organizationUserId !== currentUserId);

  const notifiableUsers = notifiableFollows.map(({ organizationUser }) => organizationUser);

  const hasNewFollows = notifiableFollows.some(({ id }) => !id);

  if ((notifyNewFollowersOnly && !hasNewFollows) || !notifiableUsers?.length) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <p className="mb-2 text-base font-medium">When I save this, notify...</p>

      <RadioGroup selectedValue={notifyGroup} large onChange={e => setNotifyGroup(e.target.value)}>
        <Radio large label={notifyNewFollowersOnly ? 'New followers' : 'All followers'} value="notifiables" />
        <Radio large label="No one" value="nobody" data-test="workspace-notify-nobody" />
        {notifiableUsers.length > 1 && <Radio large label="Only the people I select..." value="select" />}
      </RadioGroup>
      {notifyGroup === 'select' && notifiableUsers.length > 1 && (
        <FollowerSelectInput
          hideFollowSettings
          name="customNotifyList"
          defaultButtonText="Select notification recipients"
          organizationUsers={notifiableUsers}
        />
      )}
    </div>
  );
};

WorkspaceFormNotificationsInput.propTypes = propTypes;
