import { Attachment } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Souptools::TrFile'] });

type GetTrFile = {
  params: {
    id: string;
    type: 'Souptools::Tr' | 'Souptools::Quote';
  };
  result: Attachment | void;
};

type ExportTrFile = {
  params: {
    clinIds: string[];
    type: 'Souptools::Tr' | 'Souptools::Quote';
  };
  result: void;
};

export const souptoolsTrFilesApi = api.injectEndpoints({
  endpoints: build => ({
    getTrFile: build.query<GetTrFile['result'], GetTrFile['params']>({
      query: params => ({
        url: '/v2/souptools/tr_file',
        params
      })
    }),

    exportTrFile: build.mutation<ExportTrFile['result'], ExportTrFile['params']>({
      query: body => ({
        url: '/v2/souptools/tr_file',
        method: 'POST',
        body
      })
    })
  })
});

export const { useGetTrFileQuery, useExportTrFileMutation } = souptoolsTrFilesApi;
