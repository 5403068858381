import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';

import { WorkspaceIndex } from 'types/__generated__/GovlyApi';
import { useGetOppQuery } from 'api/oppsApi';
import { useGetCurrentUserQuery } from 'api/currentUserApi';

import { possessive } from 'app/lib/strings';
import { OppWorkspacePreview } from 'app/organisms/OppWorkspacePreview/OppWorkspacePreview';
import { OppDetailsCreateWorkspace } from 'app/organisms/OppDetailsCreateWorkspace/OppDetailsCreateWorkspace';

import { CardHeading } from 'app/atoms/Typography/Typography';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTableRowCountTag } from 'app/molecules/GovlyTable/GovlyTableRowCountTag';

const columnHelper = createColumnHelper<WorkspaceIndex>();

export const OppWorkspaces = ({ id, ...rest }: React.HTMLProps<HTMLDivElement> & { id: string }) => {
  const { data: { workspaces = [] } = {}, isLoading } = useGetOppQuery({ id });
  const { data: currentUser, isLoading: currentUserLoading } = useGetCurrentUserQuery();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        cell: e => (
          <OppWorkspacePreview
            workspace={e.row.original}
            contentClass="px-0 py-0 p-0 space-y-2 sm:p-0 lg:p-0"
            headerClass="flex flex-wrap gap-y-2 justify-between items-start"
            followsClass="flex flex-col gap-y-2 mt-2"
          />
        )
      })
    ],
    []
  );

  return (
    <div {...rest}>
      <GovlyTable
        trackingObject="organization_insights_activity_table"
        columns={columns}
        data={workspaces}
        title={
          <span className="flex gap-2 items-center">
            <CardHeading>Workspaces</CardHeading>
            <GovlyTableRowCountTag />
          </span>
        }
        cardProps={{
          rightElement: (
            <GovlyTableToolbar>
              <OppDetailsCreateWorkspace
                name={`${possessive(currentUser?.name)} Workspace`}
                oppId={id}
                follows={[
                  {
                    organizationUserId: currentUser?.id,
                    state: 'following',
                    notifications: 'user_setting',
                    organizationUser: { organizationId: currentUser?.organization?.id }
                  }
                ]}
                tooltipProps={{ content: 'Create a new Workspace' }}
              />
            </GovlyTableToolbar>
          )
        }}
        isLoading={isLoading || currentUserLoading}
        emptyStateProps={{
          icon: 'folder-open',
          title: 'No workspaces'
        }}
        initialState={{
          pagination: {
            pageSize: 5
          }
        }}
      />
    </div>
  );
};
