import React, { useCallback, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { Button, ButtonGroup } from '@blueprintjs/core';
import { ErrorBoundary } from 'app/atoms/ErrorBoundary/ErrorBoundary';
import { Card, CardSection } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { useLazyAwardAggregationQuery } from 'api/awardsApi';
import { CardError } from 'app/atoms/ErrorFallback/CardError';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';
import { HighchartsReact } from 'highcharts-react-official';
import { AwardSearchForm } from 'app/hooks/search/useAwardSearchCache';
import { getPosthogAttr } from 'app/hooks/useEventTracking';
import { AwardSearchAnalyticsTableDrawer } from 'app/organisms/AwardSearchAnalytics/AwardSearchAnalyticsTableDrawer';
import {
  columnVisibilitySelector,
  drilldownFiltersSelector,
  useAwardSearchAnalyticsHistogramStore
} from 'app/organisms/AwardSearchAnalytics/useAwardSearchAnalyticsHistogramStore';
import { AwardSearchAnalyticsHistogramToolbar } from 'app/organisms/AwardSearchAnalytics/AwardSearchAnalyticsHistogramToolbar';
import { useAwardSearchAnalyticsHistogramOptions } from 'app/organisms/AwardSearchAnalytics/useAwardSearchAnalyticsHistogramOptions';
import { AwardSearchAnalyticsHistogramDrawerTitle } from 'app/organisms/AwardSearchAnalytics/AwardSearchAnalyticsHistogramDrawerTitle';
import snakeCase from 'lodash-es/snakeCase';
import { AwardSearchAnalyticsFallback } from './AwardSearchAnalyticsFallback';

const chartTitle = 'Awards Over Time';

export const AwardSearchAnalyticsHistogram = ({ searchIsLoading }: { searchIsLoading: boolean }) => {
  const {
    values: { query, filters }
  } = useFormikContext<AwardSearchForm>();

  const { sliceType, view, setView, setIsDrawerOpen, isDrawerOpen } = useAwardSearchAnalyticsHistogramStore();
  const drilldownFilters = useAwardSearchAnalyticsHistogramStore(drilldownFiltersSelector);
  const columnVisibility = useAwardSearchAnalyticsHistogramStore(columnVisibilitySelector);

  const chartRef = useRef<HighchartsReact.RefObject | null>(null);

  /**
   * This is some "duct tape".
   * For some reason the highcharts auto-margin for y axis labels is slightly off for longer names.
   * This is used to redraw the chart after it has been rendered to fix the margin.
   */
  const redrawChart = useCallback(() => {
    setTimeout(() => {
      const chart = chartRef.current?.chart;
      chart?.redraw();
    });
  }, []);

  const [getAwardAggregation, { data = {}, isLoading, isError, isUninitialized, isFetching }] =
    useLazyAwardAggregationQuery();

  useEffect(() => {
    if (searchIsLoading) {
      return;
    }

    getAwardAggregation(
      {
        query,
        ...filters,
        aggs: [snakeCase(sliceType)]
      },
      true /* prefer cache value */
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAwardAggregation, searchIsLoading, sliceType]);

  const options = useAwardSearchAnalyticsHistogramOptions({ data });

  if (isLoading || isUninitialized || isFetching) {
    return <Loading />;
  }

  if (isError) {
    return <AwardSearchAnalyticsFallback />;
  }

  return (
    <ErrorBoundary action="AwardSearchAnalyticsHistogram" fallback={<CardError title={chartTitle} />}>
      <Card
        {...getPosthogAttr(`${chartTitle} chart`)}
        className="mb-4"
        title={
          <span className="flex items-center gap-1">
            <span>{chartTitle}</span>
          </span>
        }
        rightElement={<AwardSearchAnalyticsHistogramToolbar />}
      >
        <CardSection>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={node => {
              chartRef.current = node;
              redrawChart();
            }}
          />
        </CardSection>
        <CardSection className="text-center">
          <ButtonGroup>
            <Button active={view === 'amount'} onClick={() => setView('amount')} text="Award Amount" />
            <Button active={view === 'total'} onClick={() => setView('total')} text="Contract Volume" />
          </ButtonGroup>
        </CardSection>
      </Card>

      <AwardSearchAnalyticsTableDrawer
        key={JSON.stringify({ ...drilldownFilters, sliceType })}
        trackingObject="awards_histogram"
        isOpen={isDrawerOpen}
        columnVisibility={columnVisibility}
        drawerTitle={<AwardSearchAnalyticsHistogramDrawerTitle />}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
        filters={drilldownFilters}
      />
    </ErrorBoundary>
  );
};
