import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tag, Button, AnchorButton } from '@blueprintjs/core';

import { Card, CardBody } from 'app/atoms/Card/Card';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { supportChatMessage } from 'app/lib/support-chat';
import { useGetCurrentOrganizationQuery } from 'api/organizationsApi';

export const ContractManagementSewpVCard = () => {
  const navigate = useNavigate();
  const souptoolsSubscribed = useCurrentUserAttribute('subscriptionHasSouptools');

  const { data: currentOrg } = useGetCurrentOrganizationQuery();
  const { feedProfiles: profiles } = currentOrg || [];
  const profile = profiles?.find(p => p.type === 'FeedProfile::SewpV');

  return (
    <Card title="SEWP V">
      <CardBody>
        <div>
          <div className="flex justify-between">
            <h1 className="mt-0 text-2xl font-bold">Souptools</h1>
            <div>
              <Tag className="mb-4" icon="endorsed" intent={souptoolsSubscribed ? 'success' : 'danger'}>
                {souptoolsSubscribed ? 'Subscribed' : 'Not Subscribed'}
              </Tag>
            </div>
          </div>
          <h3>Streamline TR management.</h3>
          <p>Govly Souptools allows teams to collaborate on creating TRs and automate submissions.</p>
          {souptoolsSubscribed ? (
            <div className="flex">
              <AnchorButton
                onClick={() =>
                  navigate(`/settings/contract_management/sewp_v_profile`, {
                    breadcrumbs: [
                      { to: '/settings/contract_management', text: 'Contract Management' },
                      { text: 'Souptools' }
                    ]
                  })
                }
                className="mt-4"
                intent="primary"
              >
                {profile ? 'View Settings' : 'Setup'}
              </AnchorButton>
              <AnchorButton
                onClick={() => navigate('/souptools/trs')}
                className="ml-2 mt-4"
                intent="primary"
                outlined
                disabled={!profile}
              >
                Go To Souptools
              </AnchorButton>
            </div>
          ) : (
            <Button
              onClick={() => supportChatMessage('Hi, I would like to schedule a demo for Govly Souptools.')}
              className="mt-4"
              intent="primary"
            >
              Request a Demo
            </Button>
          )}
        </div>
      </CardBody>
    </Card>
  );
};
