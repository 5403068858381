import { Partnership, PartnershipShow, PartnershipWithFeeds } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['Partnership'] });

type GetPartnership = {
  params: {
    id: string;
    view?: string;
  };
  result: PartnershipShow;
};

type GetPartnerships = {
  params: {
    view?: 'with_feeds';
  } | void;
  result: Partnership[] | PartnershipWithFeeds[];
};

type UpdatePartnership = {
  params: { id: string };
  result: Partnership;
};

type CreatePartnership = {
  params: {
    partnerId: string;
  };
  result: void;
};

const partnershipApi = api.injectEndpoints({
  endpoints: build => ({
    getPartnership: build.query<GetPartnership['result'], GetPartnership['params']>({
      query: ({ id, ...params }) => ({ url: `/v2/partnerships/${id}`, params }),
      providesTags: result => [{ type: 'Partnership', id: result?.id }]
    }),

    getPartnerships: build.query<GetPartnerships['result'], GetPartnerships['params']>({
      query: params => ({ url: '/v2/partnerships', params: params ?? {} }),
      providesTags: ['Partnership']
    }),

    updatePartnership: build.mutation<UpdatePartnership['result'], UpdatePartnership['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/partnerships/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Partnership', id: arg.id }]
    }),

    createPartnership: build.mutation<CreatePartnership['result'], CreatePartnership['params']>({
      query: body => ({
        url: `/v2/partnerships`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Partnership']
    })
  })
});

export const {
  useGetPartnershipQuery,
  useGetPartnershipsQuery,
  useUpdatePartnershipMutation,
  useCreatePartnershipMutation
} = partnershipApi;
