import React from 'react';
import PropTypes from 'prop-types';
import { Tag, Spinner } from '@blueprintjs/core';

const propTypes = {
  trSubmission: PropTypes.object.isRequired
};

export const SouptoolsTrSubmissionStatusTag = ({ trSubmission: { status } }) => {
  let intent;
  let text;

  if (status === 'failed') {
    intent = 'danger';
    text = 'Submission Failed';
  } else if (status === 'submitting') {
    intent = 'primary';
    text = 'Submitting';
  } else if (status === 'scheduled') {
    intent = 'warning';
    text = 'Submission Scheduled';
  } else if (status === 'submitted') {
    intent = 'success';
    text = 'Submitted';
  } else if (status === 'canceled') {
    intent = 'danger';
    text = 'Submission Canceled';
  } else {
    return null;
  }

  return (
    <Tag minimal intent={intent}>
      <div className="flex">
        {status === 'submitting' && <Spinner className="mr-1" intent={intent} size={12} />}
        {text}
      </div>
    </Tag>
  );
};

SouptoolsTrSubmissionStatusTag.propTypes = propTypes;
