import React, { useState, useMemo } from 'react';
import { FormGroup, Button, MenuItem, ButtonProps, FormGroupProps } from '@blueprintjs/core';
import { Select, SelectProps } from '@blueprintjs/select';
import lodashGet from 'lodash-es/get';
import keyBy from 'lodash-es/keyBy';

import { cn } from 'app/lib/cn';
import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';
import { useFuzzySearch } from 'app/hooks/useFuzzySearch';
import { LoadingSpinner } from 'app/atoms/LoadingSpinner/LoadingSpinner';
import { useFormikInput } from 'app/hooks/useFormikInput';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type BaseItem = Record<string, any> & { disabled?: boolean };

const renderItem = <T extends BaseItem>(
  item: T,
  {
    modifiers,
    handleClick,
    valueAttr,
    labelAttr,
    shouldDismissPopover
  }: {
    modifiers: { matchesPredicate?: boolean; active?: boolean };
    handleClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    index: number;
    valueAttr: string;
    labelAttr: string;
    shouldDismissPopover: boolean;
  }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      active={modifiers.active}
      key={lodashGet(item, valueAttr) as string}
      onClick={handleClick}
      text={lodashGet(item, labelAttr) as string}
      shouldDismissPopover={shouldDismissPopover}
      disabled={lodashGet(item, 'disabled')}
    />
  );
};

export type SelectInputProps<T extends BaseItem> = {
  name: string;
  items: T[];
  searchKeys?: string[];
  defaultButtonText?: string;
  noSelectionText?: string;
  staticButtonText?: string;
  buttonProps?: ButtonProps;
  loading?: boolean;
  noResultsComponent?: React.ReactNode;
  filterActive?: boolean;
  submitOnChange?: boolean;
  valueAttr?: string;
  labelAttr?: string;
  beforeChange?: (value: string) => void;
  shouldDismissPopover?: boolean;
} & FormGroupProps &
  Partial<SelectProps<T>>;

export const SelectInput = <T extends BaseItem>({
  name,
  className,
  contentClassName,
  helperText,
  disabled,
  label,
  labelInfo,
  items,
  searchKeys,
  buttonProps,
  loading = false,
  defaultButtonText,
  noSelectionText,
  staticButtonText,
  noResultsComponent,
  filterActive = true,
  submitOnChange,
  valueAttr = 'value',
  labelAttr = 'label',
  beforeChange,
  shouldDismissPopover = false,
  ...rest
}: SelectInputProps<T>) => {
  const { field, meta, onChange } = useFormikInput<string>({ name, submitOnChange });
  const [query, setQuery] = useState<string>('');
  const itemValueMap = useMemo(() => keyBy(items, valueAttr), [items, valueAttr]);
  const error = meta.touched && meta.error;
  const intent = error ? 'danger' : undefined;

  const noResults = loading ? (
    <MenuItem
      disabled
      text={
        <div className="text-center">
          <LoadingSpinner />
        </div>
      }
    />
  ) : (
    <MenuItem disabled text="No results." />
  );

  const results = useFuzzySearch({
    options: { keys: searchKeys || [] },
    data: filterActive ? items.filter(({ [valueAttr]: value }) => field.value !== value) : items,
    query
  });

  return (
    <FormGroup
      className={cn('m-0', className)}
      helperText={helperText}
      disabled={disabled}
      label={label ? <LabelSpan label={label} /> : null}
      labelInfo={labelInfo}
      labelFor={name}
      intent={intent}
      contentClassName={cn('mt-2', contentClassName)}
      {...rest}
    >
      <Select
        activeItem={itemValueMap[field.value]}
        items={results.slice(0, 40)}
        noResults={noResultsComponent || noResults}
        onItemSelect={item => {
          const value = item[valueAttr] as string;
          beforeChange?.(value);
          onChange(value);
        }}
        itemRenderer={(item, options) => renderItem(item, { ...options, valueAttr, labelAttr, shouldDismissPopover })}
        onQueryChange={q => setQuery(q)}
        disabled={disabled}
        className="w-fit"
        {...rest}
      >
        <Button
          rightIcon="chevron-down"
          text={
            staticButtonText ||
            (itemValueMap[field.value]?.[labelAttr] as string) ||
            defaultButtonText ||
            noSelectionText ||
            'Select'
          }
          className="flex justify-between"
          large
          disabled={disabled}
          {...buttonProps}
        />
      </Select>
      {error ? <small className="text-xs text-red-500">{meta.error}</small> : null}
    </FormGroup>
  );
};
