import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, Tooltip, Tag } from '@blueprintjs/core';

import { useGetCurrentOrganizationQuery } from 'api/organizationsApi';
import { Breadcrumbs } from 'app/molecules/Breadcrumbs/Breadcrumbs';
import { SwitchInput } from 'app/atoms/inputs/SwitchInput/SwitchInput';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { TextAreaInput } from 'app/atoms/inputs/TextAreaInput/TextAreaInput';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import {
  useUpdateFeedProfileMutation,
  useCreateFeedProfileMutation,
  useCallFeedProfileMutation
} from 'api/feedProfilesApi';
import { formErrorToast, successToast } from 'app/lib/toaster';
import { Loading } from 'app/atoms/Loading/Loading';
import { UserMultiSelectInput } from 'app/organisms/inputs/UserMultiSelectInput';
import { useAuthorized } from 'app/hooks/useAuthorize';

export const FeedProfileSewpVForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const sysAdmin = useAuthorized({ role: 'sys_admin' });

  const { data: currentOrg, isLoading: profileLoading } = useGetCurrentOrganizationQuery();
  const [updateFeedProfile, { isLoading: isUpdating }] = useUpdateFeedProfileMutation();
  const [createFeedProfile, { isLoading: isCreating }] = useCreateFeedProfileMutation();
  const [callFeedProfileAction, { isLoading: isCalling }] = useCallFeedProfileMutation();

  const callAction = async values => {
    try {
      await callFeedProfileAction(values).unwrap();
      successToast('Action called. Check status in Apify.');
    } catch (e) {
      formErrorToast(e);
    }
  };

  if (profileLoading) {
    return <Loading />;
  }

  const { feedProfiles: profiles } = currentOrg || [];
  const profile = profiles?.find(p => p.type === 'FeedProfile::SewpV') || {};

  const {
    id,
    username,
    password,
    notificationEmail,
    failedLoginAttempts = 0,
    active,
    context: { chopOrganizationName, submitters = [], noBidDodEsi = false, noBidDodEsiReason } = {}
  } = profile;

  const inactive = failedLoginAttempts > 3 || !active;

  let failedLoginAttemptsClass = failedLoginAttempts === 0 ? 'text-green-600' : 'text-orange-600';
  if (failedLoginAttempts > 3) failedLoginAttemptsClass = 'text-red-600';

  const validationSchema = yup.object({
    username: yup.string().required('Username is required'),
    password: yup.string().required('A Password is required to run any job'),
    notificationEmail: yup
      .string()
      .required('Notification Email is required')
      .email('Notification Email should be a valid email address'),
    context: yup.object({
      chopOrganizationName: yup.string().required('Chop Organization Name is required')
    })
  });

  return (
    <>
      <Breadcrumbs />
      <Formik
        onSubmit={async (values, { setErrors }) => {
          try {
            const action = values.id ? updateFeedProfile : createFeedProfile;
            await action(values).unwrap();
            successToast(values.id ? 'Profile updated.' : 'Profile created.');
          } catch (e) {
            const { data } = e;
            formErrorToast(e);
            if (data.errors) setErrors({ data: data.errors });
          }
        }}
        initialValues={{
          id,
          type: 'FeedProfile::SewpV',
          username: username || '',
          password: password || '',
          notificationEmail: notificationEmail || '',
          active: active || false,
          context: {
            chopOrganizationName,
            submitters,
            noBidDodEsi,
            noBidDodEsiReason: noBidDodEsiReason || ''
          }
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <Card
              className="mb-4"
              title="SEWP V Profile"
              rightElement={<Tag intent={inactive ? 'danger' : 'success'}>{inactive ? 'Disabled' : 'Active'}</Tag>}
            >
              <CardBody>
                <SwitchInput name="active" label="Active?" />
                <TextInput label="Chop Username" name="username" labelInfo="(required)" />
                <TextInput
                  label="Chop Password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  labelInfo="(required)"
                  helperText={
                    <>
                      <p className={failedLoginAttemptsClass}>{`Failed login attempts: ${failedLoginAttempts}`}</p>
                      <span>
                        If failed login attempts exceeds 1, all jobs will be paused until the password is updated.
                      </span>
                    </>
                  }
                  inputProps={{
                    large: true,
                    rightElement: (
                      <Tooltip content={`${showPassword ? 'Hide' : 'Show'} Password`}>
                        <Button
                          icon={showPassword ? 'unlock' : 'lock'}
                          intent="warning"
                          minimal
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </Tooltip>
                    )
                  }}
                />
                <TextInput
                  label="Organization name as listed in Chop"
                  name="context.chopOrganizationName"
                  labelInfo="(required)"
                />
                <UserMultiSelectInput
                  preventCheckboxes
                  name="context.submitters"
                  label="Allowed TR Submitters"
                  helperText="Leave blank to allow any team member to submit TRs."
                />

                <TextInput label="Notification Email" name="notificationEmail" />

                <SwitchInput
                  name="context.noBidDodEsi"
                  label="Automate no bids for DOD ESI?"
                  helperText="No bids will be automated for any opportunity expiring within 6 hours that has not already been quoted in CHOP."
                />
                {values.context.noBidDodEsi && (
                  <TextAreaInput name="context.noBidDodEsiReason" label="No Bid Reason for DoD ESI" />
                )}
              </CardBody>
              <CardFooter>
                <Button large type="submit" text="Save" loading={isCreating || isUpdating} intent="primary" />
              </CardFooter>
            </Card>
          </Form>
        )}
      </Formik>
      {sysAdmin && id && noBidDodEsi && (
        <Card title="Manually Trigger Jobs" subtitle="Only system admin can see this card.">
          <CardBody>
            <div>
              <Button
                onClick={() => callAction({ id, profileAction: 'no_bid_dod_esi' })}
                intent="danger"
                outlined
                large
                text="Run DoD ESI No Bid Job"
                disabled={isCalling}
              />
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
};
