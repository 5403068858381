import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Button, Alert, Callout, Tag } from '@blueprintjs/core';

import {
  useGetVendorQuery,
  useUpdateVendorMutation,
  useDeleteVendorMutation,
  useScrapeVendorMutation
} from 'api/vendorsApi';
import { SwitchInput } from 'app/atoms/inputs/SwitchInput/SwitchInput';
import { TagInput } from 'app/atoms/inputs/TagInput/TagInput';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { deleteToast, errorToast, successToast } from 'app/lib/toaster';
import { useEffect } from 'react';
import { useEventTracking } from 'app/hooks/useEventTracking';

export const VendorSysAdminForm = ({ id }) => {
  const { trackEvent } = useEventTracking();
  const navigate = useNavigate();
  const [deleteAlert, setDeleteAlert] = useState(false);
  const { data: vendor, isLoading } = useGetVendorQuery({ id }, { skip: id === 'new' });

  const [updateVendor, { isLoading: isUpdating }] = useUpdateVendorMutation();
  const [deleteVendor, { isLoading: isDeleting }] = useDeleteVendorMutation();
  const [scrapeVendor, { isLoading: isScraping, isSuccess: scrapingQueued }] = useScrapeVendorMutation();

  useEffect(() => {
    if (scrapingQueued) {
      successToast('Vendor scraping queued.');
    }
  }, [scrapingQueued]);

  if (isLoading) {
    return <Loading />;
  }

  const initialValues = {
    id: vendor.id,
    active: vendor.active,
    matchAsOppOem: vendor.matchAsOppOem,
    aliases: vendor.aliases || [],
    domains: vendor.domains || [],
    removePriorMatches: false
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values, { setErrors }) => {
          try {
            trackEvent({
              object: 'vendor_profile',
              action: 'updated',
              properties: { ...values }
            });
            await updateVendor(values).unwrap();
            successToast('Vendor updated.');
          } catch (e) {
            const { data } = e;
            errorToast(e);
            if (data.errors) setErrors({ data: data.errors });
          }
        }}
      >
        <Form>
          <Card
            title="Opp Matching Options"
            rightElement={
              <Tag intent="danger" minimal>
                Sys Admin
              </Tag>
            }
          >
            <CardBody>
              <SwitchInput inline name="active" label="Active?" />
              <SwitchInput inline name="matchAsOppOem" label="Match as OEM for Opps?" />
              <TagInput name="aliases" label="Match Aliases" />
              <TagInput name="domains" label="Match Domains" />
              {vendor.usSpendingId && vendor.id !== 'new' && (
                <Button
                  text="Refresh US Spending Data"
                  intent="warning"
                  loading={isScraping}
                  onClick={() => scrapeVendor({ ids: [vendor.id], scrapeMethod: 'scrape_us_spending' })}
                />
              )}
              <Callout intent="danger">
                <SwitchInput
                  className="my-6"
                  name="removePriorMatches"
                  label="Should this change cause prior matches for this Vendor to be removed?"
                />
              </Callout>
            </CardBody>
            <CardFooter>
              <Button large loading={isUpdating} type="submit" intent="primary">
                Save
              </Button>
              <Button outlined large intent="danger" text="Delete" onClick={() => setDeleteAlert(true)} />
            </CardFooter>
          </Card>
        </Form>
      </Formik>
      <Alert
        cancelButtonText="Cancel"
        icon="trash"
        intent="danger"
        isOpen={deleteAlert}
        onCancel={() => setDeleteAlert(false)}
        onConfirm={async () => {
          try {
            await deleteVendor(vendor.id).unwrap();
            navigate('/sys_admin/vendors');
            deleteToast(`Vendor ${vendor.name} has been deleted.`);
          } catch (e) {
            errorToast(e);
          }
        }}
        loading={isDeleting}
        confirmButtonText="Delete Vendor"
      >
        <p>Are you sure you want to delete this vendor?</p>
      </Alert>
    </>
  );
};

VendorSysAdminForm.propTypes = {
  id: PropTypes.string.isRequired
};
