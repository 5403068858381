import React from 'react';
import PropTypes from 'prop-types';
import { Callout, Tag } from '@blueprintjs/core';

import { DescriptionListField } from 'app/molecules/DescriptionListField/DescriptionListField';
import { CardBody } from 'app/atoms/Card/Card';

export const ContactPageDescriptionFields = ({ contact }) => {
  if (!contact.titles && !contact.phoneNumbers && !contact.oppCount) return null;

  return (
    <CardBody>
      <Callout className="rounded" intent="warning">
        Please do not contact this individual regarding any active opportunities if you are not an official Prime on the
        opportunity.
      </Callout>
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <DescriptionListField
          label="Total Opps"
          value={
            <Tag round intent="success">
              {contact.oppCount}
            </Tag>
          }
          cols={2}
        />
        {contact.titles?.length && (
          <DescriptionListField
            label="Titles"
            value={
              <div className="flex gap-2">
                {contact.titles?.map(title => (
                  <Tag className="mr-2" minimal key={title}>
                    {title}
                  </Tag>
                ))}
              </div>
            }
            cols={2}
          />
        )}
        {contact.phoneNumbers?.length && (
          <DescriptionListField
            label="Phone Numbers"
            value={
              <div className="flex gap-2">
                {contact.phoneNumbers.map(phoneNumber => (
                  <Tag className="mr-2" minimal intent="primary" key={phoneNumber}>
                    {phoneNumber}
                  </Tag>
                ))}
              </div>
            }
            cols={2}
          />
        )}
      </dl>
    </CardBody>
  );
};

ContactPageDescriptionFields.propTypes = {
  contact: PropTypes.object.isRequired
};
