import React from 'react';
import { match } from 'ts-pattern';
import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTablePagination } from 'app/molecules/GovlyTable/GovlyTablePagination';
import { GovlyTableEmptyState } from 'app/molecules/GovlyTable/GovlyTableEmptyState';

export const useGovlyTableChildren = (children: React.ReactNode) => {
  let toolbar: React.ReactNode;
  let pagination: React.ReactNode;
  let emptyState: React.ReactNode;

  React.Children.forEach(children, child => {
    if (!React.isValidElement(child)) return;

    match(child)
      // Check to see if these components render anything so we can conditionally wrap them for padding
      .with({ type: GovlyTableToolbar }, c => (c.type(c.props) ? (toolbar = c) : void 0))
      .with({ type: GovlyTablePagination }, c => (c.type() ? (pagination = c) : void 0))
      .with({ type: GovlyTableEmptyState }, c => (c.type(c.props) ? (emptyState = c) : void 0))
      .otherwise(() => {});
  });

  return { toolbar, pagination, emptyState };
};
