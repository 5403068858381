import React from 'react';
import { useFormikContext } from 'formik';

import { useSearchMutation } from 'api/oppsApi';
import { pluralize } from 'app/lib/strings';
import { Loading } from 'app/atoms/Loading/Loading';
import { SearchResultsExportButton } from 'app/organisms/SearchResults/SearchResultsExportButton';
import { SearchResultsSort } from 'app/organisms/SearchResults/SearchResultsSort';

export const OppSearchResultsControls = () => {
  const { values } = useFormikContext();
  const { query, filters } = values;
  const { predictions: isPrediction } = values.filters;
  const [
    _search,
    { isFetching, isSuccess, data: { meta: { totalRelation, total, currentPage, totalPages } = {} } = {} }
  ] = useSearchMutation({
    fixedCacheKey: 'opp-search'
  });

  if (isFetching) {
    return <Loading type="flex-row" />;
  }

  if (!isSuccess) {
    return null;
  }

  let totalRelationPrefix = '';

  if (totalRelation && totalRelation.includes('gt')) {
    totalRelationPrefix = 'More Than ';
  }

  const resultCountLabel = `${totalRelationPrefix}${total.toLocaleString()} ${pluralize('result', 'results', total)}`;

  return (
    <div className="flex flex-wrap items-end justify-between px-6 lg:px-0">
      <div>
        <span data-test="opp-search-result-count" className="block text-lg font-medium text-gray-600">
          {resultCountLabel}
        </span>
        {totalPages > 0 && (
          <span className="block text-sm text-gray-500">
            Page {currentPage.toLocaleString()} of {totalPages.toLocaleString()}
          </span>
        )}
      </div>
      <div className="flex items-center space-x-2">
        <SearchResultsSort
          sortItems={[
            { value: 'posted', label: 'Posted at' },
            { value: 'modified', label: 'Modified at' },
            { value: 'expires', label: 'Expires at' },
            { value: 'relevance', label: 'Relevance' }
          ]}
        />

        {!isPrediction && <SearchResultsExportButton query={query} filters={filters} resultType="opps" />}
      </div>
    </div>
  );
};
