import React from 'react';
import { Button, ButtonProps, Tooltip } from '@blueprintjs/core';

import { useAwardExportMutation } from 'api/awardsApi';
import { useContactExportMutation } from 'api/contactsApi';
import { toast, errorToast } from 'app/lib/toaster';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { AwardSearchFilters } from 'app/hooks/search/useAwardSearchCache';
import { match } from 'ts-pattern';
import { useExportMutation } from 'api/oppsApi';
import { OppSearchState } from 'app/hooks/search/useOppSearchCache';

type Props = {
  query: string;
  buttonProps?: Partial<ButtonProps>;
} & (
  | {
      resultType: 'awards';
      filters: AwardSearchFilters;
    }
  | {
      resultType: 'contacts';
      filters: { [key: string]: string | number | boolean | Range };
    }
  | {
      resultType: 'opps';
      filters: OppSearchState['filters'];
    }
);

export const SearchResultsExportButton = ({ resultType, query, filters, buttonProps }: Props) => {
  const exportMutationHook = match(resultType)
    .with('awards', () => useAwardExportMutation)
    .with('contacts', () => useContactExportMutation)
    .with('opps', () => useExportMutation)
    .exhaustive();

  const [exportMutation] = exportMutationHook();
  const { trackEvent } = useEventTracking();
  const { data } = useGetCurrentUserQuery();
  const id = data?.id;

  const onClick = async () => {
    try {
      // @ts-expect-error - Range error is not easily fixed
      await exportMutation({ query, ...filters });
      await toast({
        message: (
          <>
            {
              'Your report is being generated and will be emailed to you shortly. You can also view the report under your '
            }
            <LinkTag tag="a" target="_blank" href={`/users/${id}`}>
              user profile
            </LinkTag>
            .
          </>
        )
      });
      trackEvent({
        object: resultType,
        action: 'exported',
        properties: { query, ...filters }
      });
    } catch (e) {
      errorToast(e);
    }
  };

  return (
    <Tooltip content="Download Results as CSV">
      <Button icon="import" onClick={onClick} {...buttonProps} />
    </Tooltip>
  );
};
