import { FollowDisplayable } from 'types/__generated__/GovlyApi';

export const showWorkspace = ({
  privateAccess,
  follows,
  createdById,
  currentOrgUserRole,
  currentOrgUserId
}: {
  privateAccess: boolean;
  follows: FollowDisplayable[];
  createdById: string;
  currentOrgUserRole: string;
  currentOrgUserId: string;
}) => {
  if (privateAccess === false) return true;
  if (follows.find(f => f.organizationUser?.id === currentOrgUserId)) return true;
  if (createdById === currentOrgUserId) return true;
  if (currentOrgUserRole === 'admin') return true;

  return false;
};
