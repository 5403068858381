import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { Button, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { CSVLink } from 'react-csv';
import snakeCase from 'lodash-es/snakeCase';
import { ErrorBoundary } from 'app/atoms/ErrorBoundary/ErrorBoundary';

import { Card, CardBody } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { OppSearchAnalyticsFallback } from 'app/organisms/OppSearchAnalyticsFallback/OppSearchAnalyticsFallback';
import { useLazyOppAggregationQuery } from 'api/oppsApi';
import { CardError } from 'app/atoms/ErrorFallback/CardError';
import { OppSearchState } from 'app/hooks/search/useOppSearchCache';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';
import { HighchartsReact } from 'highcharts-react-official';
import { OppSearchAnalyticsTableDrawer } from 'app/organisms/OppSearchAnalytics/OppSearchAnalyticsTableDrawer';
import {
  extractSeriesData,
  useOppSearchAnalyticsPostedAtStore,
  SLICES,
  AggWithSubAggs,
  sliceLabelSelector,
  makeDrilldownFiltersSelector
} from 'app/organisms/OppSearchAnalytics/useOppSearchAnalyticsPostedAtStore';
import { match } from 'ts-pattern';
import { OppSearchAnalyticsPostedAtDrawerTitle } from 'app/organisms/OppSearchAnalytics/OppSearchAnalyticsPostedAtDrawerTitle';
import { useGetContractVehiclesQuery } from 'api/contractVehiclesApi';
import { useGetSetAsidesQuery } from 'api/setAsidesApi';

type AggregationQueryResponse = {
  aggs: {
    postedAtAgg: AggWithSubAggs;
    modifiedAtAgg: AggWithSubAggs;
    respondByAgg: AggWithSubAggs;
  };
};

type AggType = 'postedAtAgg' | 'modifiedAtAgg' | 'respondByAgg';

export const OppSearchAnalyticsPostedAt = ({ searchIsLoading }: { searchIsLoading: boolean }) => {
  const title = 'Posted Over Time';
  const {
    values: { query, filters }
  } = useFormikContext<OppSearchState>();
  const { dateRangeParam } = filters;

  const { sliceType, setSliceType, isDrawerOpen, setIsDrawerOpen } = useOppSearchAnalyticsPostedAtStore();
  const sliceLabel = useOppSearchAnalyticsPostedAtStore(sliceLabelSelector);

  const contractVehiclesQuery = useGetContractVehiclesQuery(undefined, {
    skip: searchIsLoading || sliceType !== 'feedAgg'
  });
  const contractVehicleNamesToIds = useMemo(() => {
    return new Map(contractVehiclesQuery.data?.map(({ id, contractName }) => [contractName, id]));
  }, [contractVehiclesQuery.data]);

  const setAsidesQuery = useGetSetAsidesQuery(undefined, {
    skip: searchIsLoading || sliceType !== 'setAsidesAgg'
  });
  const setAsideNamesToCodes = useMemo(() => {
    return new Map([
      ...(setAsidesQuery.data?.map(({ name, code }) => [name ?? 'missing name', code ?? 'missing code'] as const) ??
        []),
      ['None', 'none']
    ]);
  }, [setAsidesQuery.data]);

  const drilldownFiltersSelector = useMemo(
    () => makeDrilldownFiltersSelector({ dateRangeParam, contractVehicleNamesToIds, setAsideNamesToCodes }),
    [contractVehicleNamesToIds, dateRangeParam, setAsideNamesToCodes]
  );
  const drilldownFilters = useOppSearchAnalyticsPostedAtStore(drilldownFiltersSelector);

  const aggType = match<string, AggType>(dateRangeParam)
    .with('modified_at', () => 'modifiedAtAgg')
    .with('respond_by', () => 'respondByAgg')
    .otherwise(() => 'postedAtAgg');

  const [getOppsAggregation, { data = {}, isLoading, isFetching, isError, isUninitialized }] =
    useLazyOppAggregationQuery();

  useEffect(() => {
    if (!searchIsLoading) {
      getOppsAggregation(
        {
          query,
          ...filters,
          aggs: [snakeCase(aggType)],
          aggSlices: sliceType === 'total' ? [] : [snakeCase(sliceType)]
        },
        true /* preferCache */
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOppsAggregation, searchIsLoading, aggType, sliceType]);

  const agg = (data as AggregationQueryResponse).aggs?.[aggType];
  const { seriesData, csv } = extractSeriesData(agg, sliceType);

  const options = useMemo<Highcharts.Options>(() => {
    return {
      series: seriesData,
      title: {
        text: undefined
      },
      legend: {
        enabled: sliceType !== 'total'
      },
      chart: {
        zooming: {
          singleTouch: true,
          type: 'xy'
        }
      },
      tooltip: {
        shared: false
      },
      time: {
        useUTC: true
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: undefined
        }
      },
      yAxis: {
        title: {
          text: undefined
        }
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          marker: {
            enabled: false
          },
          point: {
            events: {
              click: function () {
                useOppSearchAnalyticsPostedAtStore.setState(current => ({
                  ...current,
                  isDrawerOpen: true,
                  lastClickedPoint: this
                }));
              }
            }
          }
        }
      }
    };
  }, [seriesData, sliceType]);

  if (isLoading || isFetching || isUninitialized) {
    return <Loading />;
  }

  if (isError) {
    return <OppSearchAnalyticsFallback />;
  }

  return (
    <ErrorBoundary action="OppSearchAnalyticsPostedAt" fallback={<CardError title={title} />}>
      <Card
        className="mb-4"
        title={title}
        rightElement={
          <div className="flex items-center gap-2">
            {csv.length ? (
              <CSVLink data={csv} filename={`govly-posted-at-results.csv`} className="text-gray-400 no-underline">
                <Button outlined icon="download">
                  Download Data
                </Button>
              </CSVLink>
            ) : undefined}

            <Popover
              placement="bottom-start"
              minimal
              modifiers={{ offset: { enabled: true } }}
              content={
                <Menu>
                  {SLICES.map(({ key, label }) => (
                    <MenuItem
                      key={String(key)}
                      active={sliceType === key}
                      onClick={() => setSliceType(key)}
                      text={label}
                    />
                  ))}
                </Menu>
              }
            >
              <Button rightIcon="chevron-down">{sliceLabel ?? 'View'}</Button>
            </Popover>
          </div>
        }
      >
        <CardBody>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </CardBody>
      </Card>

      <OppSearchAnalyticsTableDrawer
        drawerTitle={<OppSearchAnalyticsPostedAtDrawerTitle />}
        trackingObject="opps_posted_at"
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        key={JSON.stringify({ ...drilldownFilters, sliceType })}
        filters={drilldownFilters}
      />
    </ErrorBoundary>
  );
};
