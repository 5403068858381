import React, { useEffect, useMemo, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { InputGroup, Icon, Button, Tag, Checkbox } from '@blueprintjs/core';
import { useFilters, useSortBy, useTable, usePagination, useRowSelect } from 'react-table';
import { DateTime } from 'luxon';
import { matchSorter } from 'match-sorter';
import intersectionWith from 'lodash-es/intersectionWith';
import isEqual from 'lodash-es/isEqual';

import { cn } from 'app/lib/cn';
import { PageHeading, CardHeading } from 'app/atoms/Typography/Typography';
import { TablePagination } from 'app/molecules/TablePagination/TablePagination';
import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { hasComments, hasMentions, hasReactions } from 'app/lib/activityMessageDecorator';

const TableColumnSort = ({ column }) => {
  return (
    <>
      {column.isSorted ? (
        column.isSortedDesc ? (
          <Icon icon="chevron-up" className="mr-0 w-4" />
        ) : (
          <Icon icon="chevron-down" className="mr-0 w-4" />
        )
      ) : (
        ''
      )}
    </>
  );
};

TableColumnSort.propTypes = {
  column: PropTypes.object.isRequired
};

const TableColumnFilterInput = ({ column: { filterValue, setFilter } }) => {
  return (
    <InputGroup
      value={filterValue || ''}
      small
      placeholder="Filter rows"
      className="max-w-sm"
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
    />
  );
};

TableColumnFilterInput.propTypes = {
  column: PropTypes.object.isRequired
};

export const TableColumnHeader = ({ column }) => {
  const sortProps = column.canSort ? column.getSortByToggleProps() : {};
  return (
    <>
      <div className="flex" {...sortProps}>
        {column.render('Header')}
        {column.canSort && <TableColumnSort column={column} />}
      </div>
      {column.canSort && column.textFilter && (
        <div className="mt-2">
          <TableColumnFilterInput column={column} />
        </div>
      )}
    </>
  );
};

TableColumnHeader.propTypes = {
  column: PropTypes.object.isRequired
};

export const ReactTableTemplate = forwardRef(function ReactTableTemplate(
  {
    columns,
    data,
    hiddenColumns: hiddenColumnsProp,
    striped,
    title,
    description,
    hasCSVButton,
    ToolbarComponents,
    EmptyState,
    Header,
    headerType = 'page-header',
    initialStateProps = { pageSize: 100 },
    hideTableHeader,
    initialFilters = [],
    onRowSelection,
    id,
    hideFiltersMargin,
    hideCount,
    stickyHeader,
    rowCount,
    context = {},
    customPaginationComponent,
    wrapText,
    compact,
    forceShowPagination
  },
  ref
) {
  const { isDesktop } = useDeviceWidth();

  const hiddenColumns = useMemo(() => hiddenColumnsProp ?? [], [hiddenColumnsProp]);

  const fuzzyText = (rows, id, filterValue) => {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
  };

  const dateStringFilter = (rows, id, filterValue) => {
    return rows.filter(row => {
      const rowValue = row.values[id];

      if (!rowValue) {
        return true;
      }

      const str = DateTime.fromISO(rowValue).toLocaleString(DateTime.DATETIME_SHORT);

      return str.includes(filterValue);
    });
  };

  const reactTable = useTable(
    {
      columns,
      data,
      filterTypes: { fuzzyText, dateStringFilter },
      initialState: {
        hiddenColumns,
        filters: initialFilters,
        ...initialStateProps
      },
      autoResetFilters: false,
      autoResetSortBy: false,
      autoResetGlobalFilter: false
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => {
        const visibleColumns = [...columns];
        if (onRowSelection) {
          visibleColumns.push({
            id: 'selection',
            HeaderComponent: React.memo(TableColumnHeader),
            Header: h => (
              <span className={!hideFiltersMargin ? 'mt-4' : ''}>
                <Checkbox className="mb-0" {...h.getToggleAllRowsSelectedProps()} />
              </span>
            ),
            Cell: e => <Checkbox className="mb-0" {...e.row.getToggleRowSelectedProps()} />
          });
        }
        return visibleColumns;
      });
    }
  );
  const {
    state: { selectedRowIds },
    selectedFlatRows,
    toggleAllRowsSelected,
    page
  } = reactTable;

  useImperativeHandle(ref, () => ({ toggleAllRowsSelected }));
  useEffect(() => {
    if (!onRowSelection) return;

    const filteredSelections = intersectionWith(selectedFlatRows, page, isEqual);
    onRowSelection(filteredSelections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds, onRowSelection]);

  useEffect(() => {
    reactTable.setHiddenColumns(hiddenColumns);
  }, [hiddenColumns, reactTable]);

  const csvData = useMemo(() => {
    const headers = columns.map(col => col.Header);
    const rows = reactTable.rows.map(row =>
      columns.map(col => {
        let value;
        if (col.accessor?.includes('.')) {
          const accessor = col.accessor.split('.');
          value = row.original[accessor[0]][accessor[1]];
        } else {
          value = row.original[col.accessor];
        }

        const isIsoDate = val => {
          return /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d+Z/.test(val);
        };

        if (col.accessor === 'follows') return row.original.follows.map(f => f.organizationUser.name).join(', ');

        return isIsoDate(value) ? DateTime.fromISO(value).toFormat('f') : value;
      })
    );
    return [headers, ...rows];
  }, [reactTable.rows, columns]);

  const showPagination =
    forceShowPagination || reactTable.rows.length > initialStateProps.pageSize || rowCount > reactTable.rows.length;

  return (
    <div id={id}>
      {!Header && headerType === 'page-header' && (
        <div className="flex items-center justify-between px-6 lg:px-0">
          <div className="min-w-0 flex-1">
            <PageHeading>{title}</PageHeading>
            <div className="mt-1 mb-2 flex items-center text-sm text-gray-500">{`${reactTable.rows.length} ${
              reactTable.rows.length === 1 ? 'row' : 'rows'
            }`}</div>
          </div>
          <div className="flex items-center gap-x-2">
            {isDesktop && hasCSVButton && (
              <div>
                <CSVLink
                  data={csvData}
                  filename={`govly-${title.toLowerCase()}.csv`}
                  className="text-gray-400 no-underline"
                >
                  <Button outlined large icon="import">
                    Download CSV
                  </Button>
                </CSVLink>
              </div>
            )}
            {ToolbarComponents && <ToolbarComponents />}
          </div>
        </div>
      )}
      <div className="rounded-none lg:rounded bg-white shadow-card dark:bg-gray-600 dark:shadow-dark">
        {!Header && headerType === 'card-header' && (
          <div
            className={cn(
              'flex items-center justify-between rounded-t-none lg:rounded-t bg-white py-3 px-4 dark:bg-gray-600 lg:px-6 border-b border-gray-300',
              { 'lg:sticky lg:top-[110px] lg:z-10 lg:border-y': stickyHeader }
            )}
          >
            <div className="flex items-center space-x-2">
              <CardHeading>{title}</CardHeading>
              {!hideCount && (
                <div id={id ? `${id}-count-indicator` : null}>
                  <Tag minimal>{rowCount || reactTable.rows.length.toLocaleString()}</Tag>
                </div>
              )}
              {description ? <div className="text-sm text-gray-500">{description}</div> : null}
            </div>
            <div className="flex gap-x-2">
              {isDesktop && hasCSVButton && (
                <div>
                  <CSVLink
                    data={csvData}
                    filename={`govly-${title.toLowerCase()}.csv`}
                    className="text-gray-400 no-underline"
                  >
                    <Button large icon="import">
                      Download CSV
                    </Button>
                  </CSVLink>
                </div>
              )}
              {ToolbarComponents && <ToolbarComponents />}
            </div>
          </div>
        )}
        {Header && <Header rowCount={rowCount || reactTable.rows.length.toLocaleString()} />}
        <div className="flex flex-col overflow-hidden">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden border-gray-200 dark:border-gray-800 sm:rounded">
                <table {...reactTable.getTableProps()} className="min-w-full">
                  {!hideTableHeader && (
                    <thead className={cn({ 'bg-gray-50 dark:bg-gray-700': striped })}>
                      {reactTable.headerGroups.map((headerGroup, groupIdx) => (
                        <tr
                          {...headerGroup.getHeaderGroupProps()}
                          key={groupIdx}
                          className={cn('border-b border-gray-200 align-top dark:border-gray-800')}
                        >
                          {headerGroup.headers.map(column => (
                            <th
                              key={column.id}
                              scope="col"
                              className={cn(
                                'text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-50',
                                { 'p-4 sm:px-6': !compact, 'p-2 sm:px-3': compact }
                              )}
                              style={getColumnWidthStyle(column)}
                            >
                              <div {...column.getHeaderProps(column.getSortByToggleProps())}></div>
                              {column.render('HeaderComponent')}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                  )}
                  <tbody
                    {...reactTable.getTableBodyProps()}
                    className="divide-y-gray-200 dark:divide-y-gray-800 divide-y"
                  >
                    {reactTable.page.map((row, rowIdx) => {
                      reactTable.prepareRow(row);
                      return (
                        <tr
                          key={`${rowIdx}-${row.original.id}`}
                          data-test={`row-${rowIdx}`}
                          className={cn({
                            'bg-gray-50 dark:bg-gray-700': striped && rowIdx % 2 !== 0,
                            'border-l-4 border-l-green-500': row.original.updates?.length > 0,
                            'border-l-4 border-l-orange-200':
                              (row.original.updates || []).filter(u => u.actionTargetType === 'Opp').length > 0 ||
                              hasComments(row.original.updates) ||
                              hasReactions(row.original.updates),
                            'border-l-4 border-l-red-400':
                              row.original.expiringSoon || hasMentions(row.original.updates, context?.email)
                          })}
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell, cellIdx) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                key={cellIdx}
                                data-test={`cell-${cellIdx}`}
                                className={cn('text-sm font-medium text-gray-700 dark:text-white', {
                                  'p-4 sm:px-6': !compact,
                                  'p-2 sm:px-3': compact,
                                  'whitespace-nowrap': !wrapText
                                })}
                                style={getColumnWidthStyle(cell.column)}
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {reactTable.rows.length === 0 && EmptyState && (
                  <div className="p-8">
                    <EmptyState />
                  </div>
                )}
                {showPagination && (
                  <div className="border-t border-gray-200 p-4 text-sm font-medium text-gray-700 dark:border-gray-800 dark:text-white sm:px-6">
                    {customPaginationComponent ? (
                      customPaginationComponent
                    ) : (
                      <TablePagination
                        total={reactTable.rows.length}
                        currentPage={reactTable.state.pageIndex + 1}
                        perPage={reactTable.state.pageSize}
                        onPageChange={({ page }) => {
                          reactTable.gotoPage(page - 1);
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const getColumnWidthStyle = column => {
  const { colWidth, colStyle = {} } = column;
  return colWidth != null ? { width: colWidth, minWidth: colWidth, maxWidth: colWidth } : colStyle;
};

ReactTableTemplate.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  hiddenColumns: PropTypes.array,
  striped: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  headerType: PropTypes.string,
  hasCSVButton: PropTypes.bool,
  ToolbarComponents: PropTypes.func,
  EmptyState: PropTypes.func,
  Header: PropTypes.func,
  initialStateProps: PropTypes.object,
  hideTableHeader: PropTypes.bool,
  initialFilters: PropTypes.array,
  onRowSelection: PropTypes.func,
  id: PropTypes.string,
  hideFiltersMargin: PropTypes.bool,
  hideCount: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  rowCount: PropTypes.number,
  context: PropTypes.object,
  forceShowPagination: PropTypes.bool,
  customPaginationComponent: PropTypes.node,
  wrapText: PropTypes.bool,
  compact: PropTypes.bool
};
