import React, { useMemo, useState } from 'react';
import { Button, Dialog, Tag } from '@blueprintjs/core';
import { createColumnHelper } from '@tanstack/react-table';
import orderBy from 'lodash-es/orderBy';

import { FollowDisplayable } from 'types/__generated__/GovlyApi';
import { useGetProviderFeedQuery, useUpdateProviderFeedMutation } from 'api/providerFeedsApi';

import { CardHeading } from 'app/atoms/Typography/Typography';
import { AvatarWithName } from 'app/molecules/AvatarWithName/AvatarWithName';
import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableRowCountTag } from 'app/molecules/GovlyTable/GovlyTableRowCountTag';
import { ContractVehicleForm } from 'app/organisms/ContractVehicleForm/ContractVehicleForm';

type Solicitor = FollowDisplayable['organizationUser'] & { followState: string };
const columnHelper = createColumnHelper<Solicitor>();

export const ManagedFeedSolicitors = ({ id }: { id: string }) => {
  const [openFollowsForm, setOpenFollowsForm] = useState(false);
  const [updateContractFeed, { isLoading: isUpdating }] = useUpdateProviderFeedMutation();
  const { data: { follows } = {}, isLoading } = useGetProviderFeedQuery({ id });

  const solicitors = useMemo(
    () =>
      orderBy(follows, ['stateInteger', 'organizationUser.organizationName', 'organizationUser.name']).map(
        ({ organizationUser, state }) =>
          ({
            ...organizationUser,
            followState: state
          }) as Solicitor
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [follows, isUpdating, isLoading]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Solicitor',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        cell: e => {
          const disinterested = ['disinterested', 'unfollowed'].includes(e.row.original.followState);
          return (
            <>
              <AvatarWithName
                initials={e.row.original.initials}
                imgSrc={e.row.original.avatar?.thumbUrl}
                avatarColor={e.row.original.avatarColor}
                id={e.row.original.id}
                email={e.row.original.email}
                name={e.row.original.name}
                notificationProps={{
                  intent: disinterested ? 'danger' : 'success',
                  icon: disinterested ? 'thumbs-down' : 'thumbs-up',
                  size: 'xl',
                  position: 'bottom'
                }}
              />
              <Tag minimal className="mt-2">
                {e.row.original.organizationName}
              </Tag>
            </>
          );
        }
      })
    ],
    []
  );

  const tableData = useMemo(() => solicitors, [solicitors]);

  if (isLoading || !follows?.length) return null;

  return (
    <>
      <GovlyTable
        trackingObject="managed_feed_solicitors_table"
        columns={columns}
        data={tableData}
        title={
          <span className="flex gap-2 items-center">
            <CardHeading>Solicitors</CardHeading>
            <GovlyTableRowCountTag />
            <span className="text-sm text-gray-500 font-normal">
              Solicitors are users who can post opportunities to this feed
            </span>
          </span>
        }
        cardProps={{
          rightElement: (
            <GovlyTableToolbar>
              <Button icon="plus" intent="success" onClick={() => setOpenFollowsForm(true)}>
                Add/Remove Solicitors
              </Button>
            </GovlyTableToolbar>
          )
        }}
        isLoading={isLoading}
        initialState={{
          pagination: {
            pageSize: 5
          }
        }}
      />

      <Dialog isOpen={openFollowsForm} onClose={() => setOpenFollowsForm(false)} className="min-w-[50%] p-0">
        <ContractVehicleForm
          followersInputs={true}
          follows={follows}
          initialValues={{ id }}
          isSubmitting={isUpdating}
          onCancel={() => setOpenFollowsForm(false)}
          onSubmit={updateContractFeed}
          submitText="Update Solicitors"
          title="Add/Remove Solicitors"
        />
      </Dialog>
    </>
  );
};
