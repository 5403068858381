import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Button } from '@blueprintjs/core';
import * as yup from 'yup';

import {
  useCreateContractHolderMutation,
  useUpdateContractHolderMutation,
  useDeleteContractHolderMutation
} from 'api/contractHoldersApi';
import { deleteToast, formErrorToast, successToast } from 'app/lib/toaster';
import { CardBody, CardFooter } from 'app/atoms/Card/Card';
import { useGetVendorQuery } from 'api/vendorsApi';
import { ContractVehicleSelectInput } from 'app/organisms/inputs/ContractVehicleSelectInput';
import { cn } from 'app/lib/cn';

export const VendorContractHolderForm = ({ vendorId, initialValues }) => {
  const { isLoading: vendorLoading, isError: vendorLoadingError } = useGetVendorQuery({ id: vendorId });

  const [create, { isLoading: isCreating, isSuccess: created, error: createError }] = useCreateContractHolderMutation();
  const [update, { isLoading: isUpdating, isSuccess: updated, error: updateError }] = useUpdateContractHolderMutation();
  const [remove, { isLoading: isRemoving, isSuccess: removed, error: removeError }] = useDeleteContractHolderMutation();

  const isSubmitting = isCreating || isUpdating;
  const success = created || updated;
  const error = createError || updateError || removeError;
  const action = initialValues.id ? update : create;

  useEffect(() => {
    if (success) {
      successToast(`Contract ${initialValues.id ? 'updated' : 'created'}.`);
    }

    if (error) {
      formErrorToast(error);
    }

    if (removed) {
      deleteToast();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, error, initialValues.id]);

  if (vendorLoading || vendorLoadingError) {
    return null;
  }

  const validationSchema = yup.object().shape({
    vendorId: yup.string().required('Required'),
    contractVehicleId: yup.string().required('Required')
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        vendorId: '',
        contractVehicleId: '',
        ...(initialValues || {})
      }}
      onSubmit={async (values, { resetForm }) => {
        await action(values);
        resetForm();
      }}
    >
      <Form>
        <CardBody>
          <div
            className={cn({
              'flex flex-col gap-y-4': initialValues.id,
              'grid justify-items-stretch gap-4 sm:grid-cols-3': !initialValues.id
            })}
          >
            {!initialValues.id && (
              <ContractVehicleSelectInput
                buttonProps={{ fill: true }}
                name="contractVehicleId"
                label="Contract Vehicle"
              />
            )}
          </div>
        </CardBody>
        <CardFooter>
          {!initialValues.id && (
            <Button icon="add" large intent="primary" type="submit" loading={isSubmitting} text="Add" />
          )}
          {initialValues.id && (
            <Button
              icon="trash"
              large
              intent="danger"
              loading={isRemoving}
              text="Delete"
              onClick={() => remove(initialValues)}
            />
          )}
        </CardFooter>
      </Form>
    </Formik>
  );
};

VendorContractHolderForm.propTypes = {
  vendorId: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired
};
