import React, { useState } from 'react';
import { useField } from 'formik';
import { FormGroup, MenuItem, Button, TagInputProps, FormGroupProps } from '@blueprintjs/core';
import { MultiSelect, MultiSelectProps } from '@blueprintjs/select';

import { cn } from 'app/lib/cn';
import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';
import { useFuzzySearch } from 'app/hooks/useFuzzySearch';
import { LoadingSpinner } from 'app/atoms/LoadingSpinner/LoadingSpinner';

const renderCreateOption: MultiSelectProps<unknown>['createNewItemRenderer'] = (query, active, handleClick) => (
  <MenuItem
    icon="add"
    text={`Create "${query}"`}
    roleStructure="listoption"
    active={active}
    onClick={handleClick}
    shouldDismissPopover={false}
  />
);

const renderItem = (
  item: string,
  { modifiers, handleClick, index }: Parameters<MultiSelectProps<string>['itemRenderer']>[1]
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      active={modifiers.active}
      key={`${item}-${index}`}
      onClick={handleClick}
      text={item}
      shouldDismissPopover={false}
    />
  );
};

export type MultiTagInputProps = {
  name: string;
  items: string[];
  tagInputProps?: Partial<Omit<TagInputProps, 'inputValue' | 'onInputChange'>>;
  loading?: boolean;
  hasSelectAll?: boolean;
} & FormGroupProps;

export const MultiTagInput = ({
  name,
  className,
  helperText,
  disabled,
  label,
  labelInfo,
  tagInputProps = {},
  items,
  loading,
  hasSelectAll,
  ...rest
}: MultiTagInputProps) => {
  const [query, setQuery] = useState<string>('');
  const [field, meta, { setValue }] = useField<string[]>({ name });
  const error = meta.touched && meta.error;
  const intent = error ? 'danger' : undefined;
  const clearButton = field.value.length > 0 ? <Button icon="cross" minimal onClick={() => setValue([])} /> : undefined;

  const selectItem = (item: string) => {
    const newItems = [...field.value, item];
    return setValue(newItems);
  };
  const deselectItem = (index: number) => {
    const deselected = field.value[index];
    const newItems = field.value.filter(v => v !== deselected);
    return setValue(newItems);
  };

  const noResults = loading ? (
    <MenuItem
      disabled
      text={
        <div className="text-center">
          <LoadingSpinner />
        </div>
      }
    />
  ) : (
    <MenuItem disabled text="No results." />
  );

  const results = useFuzzySearch({
    data: items.filter(tag => !field.value.includes(tag)),
    query
  });

  return (
    <FormGroup
      className={cn('m-0', className)}
      helperText={helperText}
      disabled={disabled}
      label={<LabelSpan label={label} />}
      labelInfo={labelInfo}
      labelFor={name}
      intent={intent}
      contentClassName="mt-2"
      {...rest}
    >
      <MultiSelect
        disabled={disabled}
        items={results.slice(0, 200)}
        itemRenderer={(item, options) => renderItem(item, { ...options })}
        onQueryChange={setQuery}
        noResults={noResults}
        onItemSelect={selectItem}
        tagRenderer={item => item}
        resetOnSelect
        resetOnQuery={false}
        createNewItemFromQuery={name => name}
        createNewItemRenderer={renderCreateOption}
        tagInputProps={{
          fill: true,
          tagProps: { minimal: true },
          onRemove: (_tag, index) => deselectItem(index),
          rightElement: clearButton,
          intent,
          large: true,
          ...tagInputProps
        }}
        selectedItems={field.value}
        {...rest}
      />
      {error ? <small className="text-xs text-red-500">{meta.error}</small> : null}
    </FormGroup>
  );
};
