import { OutreachRecipientIndex, OutreachRecipientShow } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

type GetOutreachRecipients = {
  params: {
    limit?: number;
  };
  result: OutreachRecipientIndex[];
};

type UpdateOutreachRecipient = {
  params: {
    id: string;
    invitedAt?: string;
    invitedToPartnerAt?: string;
  };
  result: OutreachRecipientShow;
};

export const outreachRecipientsApi = rootApi.injectEndpoints({
  endpoints: build => ({
    getOutreachRecipients: build.query<GetOutreachRecipients['result'], GetOutreachRecipients['params']>({
      query: params => ({ url: '/v2/outreach_recipients', params })
    }),
    updateOutreachRecipient: build.mutation<UpdateOutreachRecipient['result'], UpdateOutreachRecipient['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/outreach_recipients/${id}`,
        method: 'PATCH',
        body
      })
    })
  })
});

export const { useGetOutreachRecipientsQuery, useUpdateOutreachRecipientMutation } = outreachRecipientsApi;
