import { FileImport } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['FileImports'] });

type GetFileImports = {
  params: {
    page?: number;
  };
  result: {
    fileImports: FileImport[];
    meta: {
      currentPage: number;
      per: number;
      total: number;
    };
  };
};

type CreateFileImport = {
  params: Pick<FileImport, 'importWorkerClass' | 'file' | 'recordType'>;
  result: FileImport;
};

type DeleteFileImport = {
  params: { id: string };
  result: void;
};

export const fileImportsApi = api.injectEndpoints({
  endpoints: build => ({
    getFileImports: build.query<GetFileImports['result'], GetFileImports['params']>({
      query: params => ({
        url: '/v2/file_imports',
        params
      }),
      providesTags: ['FileImports']
    }),

    createFileImport: build.mutation<CreateFileImport['result'], CreateFileImport['params']>({
      query: body => ({
        url: `/v2/file_imports`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['FileImports']
    }),

    deleteFileImport: build.mutation<DeleteFileImport['result'], DeleteFileImport['params']>({
      query: id => ({
        url: `/v2/file_imports/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['FileImports']
    })
  })
});

export const { useGetFileImportsQuery, useCreateFileImportMutation, useDeleteFileImportMutation } = fileImportsApi;
