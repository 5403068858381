import React from 'react';
import { DateTime } from 'luxon';
import { FormGroup, FormGroupProps } from '@blueprintjs/core';
import { DateRangeInput as BlueprintDateRangeInput, DateRange } from '@blueprintjs/datetime';

import { cn } from 'app/lib/cn';
import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';
import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { useFormikInput } from 'app/hooks/useFormikInput';

export type DateRangeInputProps = {
  name: string;
  type?: string;
  tooltipContent?: string;
  large?: boolean;
  submitOnChange?: boolean;
} & FormGroupProps;

export const DateRangeInput = ({
  name,
  className,
  helperText,
  disabled,
  label,
  labelInfo,
  tooltipContent,
  large,
  submitOnChange,
  ...rest
}: DateRangeInputProps) => {
  const { field, meta, onChange } = useFormikInput<DateRange>({ name, submitOnChange });
  const { isMobile } = useDeviceWidth();
  const error = meta.touched && meta.error;
  const intent = error ? 'danger' : undefined;

  return (
    <FormGroup
      className={cn('m-0', className)}
      helperText={helperText}
      disabled={disabled}
      label={label ? <LabelSpan label={label} tooltipContent={tooltipContent} /> : null}
      labelInfo={labelInfo}
      labelFor={name}
      intent={intent}
      contentClassName="mt-2"
      {...rest}
    >
      <BlueprintDateRangeInput
        allowSingleDayRange
        closeOnSelection
        contiguousCalendarMonths
        selectAllOnFocus
        singleMonthOnly={isMobile}
        shortcuts={false}
        formatDate={date => (date == null ? '' : date.toLocaleDateString())}
        parseDate={str => new Date(Date.parse(str))}
        value={field.value}
        startInputProps={{ fill: true, large, placeholder: 'From', intent }}
        endInputProps={{ fill: true, large, placeholder: 'To', intent }}
        onChange={onChange}
        maxDate={DateTime.local().plus({ years: 5 }).toJSDate()}
        disabled={disabled}
      />
      {error ? <small className="text-xs text-red-500">{meta.error}</small> : null}
    </FormGroup>
  );
};
