import React from 'react';
import PropTypes from 'prop-types';

import { useFeedProvidersQuery } from 'api/queries/useFeedProvidersQuery';

const propTypes = {
  providerFeedIds: PropTypes.array.isRequired
};

const ProviderLogos = ({ providerFeedIds, ...rest }) => {
  const { data: authFeeds } = useFeedProvidersQuery({ providerFeedIds });

  if (!authFeeds.some(({ logoUrl }) => logoUrl)) {
    return null;
  }

  const displayed = {};

  return (
    <div {...rest}>
      {authFeeds.map(({ id, logoUrl, providerName }) => {
        if (logoUrl && !displayed[providerName]) {
          displayed[providerName] = true;
          return <img src={logoUrl} key={id} className="img-fluid mr-2" alt={providerName}></img>;
        }
      })}
    </div>
  );
};

ProviderLogos.propTypes = propTypes;

export { ProviderLogos };
