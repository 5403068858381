import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Dialog, Button, AnchorButton } from '@blueprintjs/core';

import { errorToast } from 'app/lib/toaster';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { LabelTagMultiSelectInput } from 'app/organisms/inputs/LabelTagMultiSelectInput';
import { useGetOrganizationLabelsQuery } from 'api/organizationLabelsApi';
import { useUpdateOrganizationLabelTaggingsMutation } from 'api/organizationLabelTaggingsApi';
import { useEventTracking } from 'app/hooks/useEventTracking';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  afterSubmit: PropTypes.func,
  cacheTags: PropTypes.array,
  initialValues: PropTypes.shape({
    organizationLabelIds: PropTypes.arrayOf(PropTypes.string.isRequired),
    taggableId: PropTypes.string.isRequired,
    taggableType: PropTypes.string.isRequired
  })
};

export const LabelFormDialog = ({ isOpen, onClose, initialValues, afterSubmit, cacheTags = [] }) => {
  const { data: labels = [], isLoading: labelsLoading } = useGetOrganizationLabelsQuery();
  const { trackEvent } = useEventTracking();

  const [onSubmit, { isLoading: isSubmitting }] = useUpdateOrganizationLabelTaggingsMutation();

  return (
    <Dialog className="p-0" isOpen={isOpen} onClose={onClose} lazy>
      <Formik
        initialValues={initialValues}
        onSubmit={async values => {
          try {
            await onSubmit({ ...values, cacheTags });
            afterSubmit?.(values.organizationLabelIds);

            trackEvent({
              object: values.taggableType.toLowerCase(),
              action: 'label_added',
              properties: { type: values.taggableType, id: values.taggableId, labelIds: values.organizationLabelIds }
            });
            onClose();
          } catch (e) {
            errorToast(e);
          }
        }}
      >
        <Form>
          <Card title="Add Labels" rightElement={<Button minimal icon="cross" onClick={onClose} />}>
            <CardBody>
              <LabelTagMultiSelectInput
                className="m-0 p-0"
                labels={labels}
                name="organizationLabelIds"
                loading={labelsLoading}
                helperText={
                  <AnchorButton
                    href="/settings/labels"
                    target="_blank"
                    rel="noreferrer"
                    minimal
                    intent="primary"
                    className="mt-2"
                    small
                    icon="edit"
                  >
                    Configure labels for your organizations
                  </AnchorButton>
                }
              />
            </CardBody>
            <CardFooter>
              <Button large type="submit" text="Save" intent="primary" loading={isSubmitting} />
              <Button large text="Cancel" disabled={isSubmitting} onClick={onClose} />
            </CardFooter>
          </Card>
        </Form>
      </Formik>
    </Dialog>
  );
};

LabelFormDialog.propTypes = propTypes;
