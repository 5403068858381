import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { TopNavHeading } from 'app/atoms/Typography/Typography';
import { OrganizationSwitcherButton } from 'app/molecules/OrganizationSwitcher/OrganizationSwitcher';
import { Button, Menu, MenuItem, Popover, Tab, Tabs, Tag } from '@blueprintjs/core';
import { AvatarButton } from 'app/atoms/AvatarButton/AvatarButton';
import Avatar from 'app/molecules/Avatar/Avatar';
import { cn } from 'app/lib/cn';
import { Loading, TextLoading } from 'app/atoms/Loading/Loading';
import { Select } from '@blueprintjs/select';
import { useFeatureFlag } from 'app/hooks/useFeatureFlag';
import { useBreadcrumbs } from 'app/molecules/Breadcrumbs/useBreadcrumbs';
import { Breadcrumbs } from 'app/molecules/Breadcrumbs/Breadcrumbs';
import { useAuthenticatedNavContext } from './useAuthenticatedNavContext';
import { useTopNavItems } from './useTopNavItems';
import { useGetNavMenuItemProps } from './useGetNavMenuItemProps';
import { useSelectNavItem } from './useSelectNavItem';

const TOO_MANY_CHILDREN_THRESHOLD = 5;

export const TopNav = () => {
  const oppShowNavEnabled = useFeatureFlag('opp-show-nav');
  const { crumbs, isLoading } = useBreadcrumbs();

  const context = useAuthenticatedNavContext();
  const { currentUser } = context;
  const { parent, children, profileNodes } = useTopNavItems();

  const navigate = useNavigate();

  const currentIndex = children.findLastIndex(x => x.current);
  const current = children[currentIndex];
  const currentIndexHidden = currentIndex > TOO_MANY_CHILDREN_THRESHOLD - 1;

  const { tabs, selectItems } = currentIndexHidden
    ? {
        tabs: children.slice(0, TOO_MANY_CHILDREN_THRESHOLD - 1).concat(current),
        selectItems: children.slice(TOO_MANY_CHILDREN_THRESHOLD - 1)
      }
    : {
        tabs: children.slice(0, TOO_MANY_CHILDREN_THRESHOLD),
        selectItems: children.slice(TOO_MANY_CHILDREN_THRESHOLD)
      };

  const getNavMenuItemProps = useGetNavMenuItemProps();
  const selectNavItem = useSelectNavItem();

  return (
    <Nav>
      <Top>
        {oppShowNavEnabled ? (
          <TopNavHeading>
            {isLoading ? (
              <TextLoading />
            ) : crumbs.length > 0 ? (
              <Breadcrumbs items={crumbs} />
            ) : (
              <Breadcrumbs items={[{ text: parent?.label }]} />
            )}
          </TopNavHeading>
        ) : (
          <TopNavHeading>{parent?.label}</TopNavHeading>
        )}

        <RightButtons>
          {currentUser?.multipleOrganizations ? (
            <OrganizationSwitcherButton key="orgSwitcher" />
          ) : (
            <Tag large key="organizationName">
              {currentUser?.organizationName}
            </Tag>
          )}

          <Popover
            placement="bottom-start"
            minimal
            modifiers={{ offset: { enabled: true } }}
            content={<Menu>{profileNodes?.map(n => <MenuItem key={n.to} {...getNavMenuItemProps(n)} />)}</Menu>}
          >
            <AvatarButton
              avatar={
                <Avatar
                  className="bg-blue-700 cursor-pointer"
                  initials={currentUser?.initials}
                  imgSrc={currentUser?.avatar?.thumbUrl}
                />
              }
              ariaLabel="Open menu"
            />
          </Popover>
        </RightButtons>
      </Top>

      <Bottom className={cn(tabs.length === 0 ? 'pb-5 mb-0' : '')}>
        {tabs.length > 0 && (
          <Tabs
            selectedTabId={current?.to}
            onChange={to => {
              const tabLookup = Object.fromEntries(children.map(x => [x.to, x]) ?? []);
              const node = tabLookup[to];
              selectNavItem(node);
            }}
            className="h-10"
            fill
          >
            {tabs.map((item, index) => (
              <Tab
                key={item.to}
                id={item.to}
                aria-current={item.current ? 'page' : undefined}
                tagContent={
                  item.TagContent ? (
                    <item.TagContent node={item} index={index} />
                  ) : item.intent ? (
                    'Sys admin'
                  ) : undefined
                }
                tagProps={{ intent: item.intent, minimal: true, ...item.getTagProps?.({ node: item, index }) }}
                title={item.TabTitle ? <item.TabTitle node={item} index={index} /> : item.label}
                icon={item.icon}
              />
            ))}
          </Tabs>
        )}
        {selectItems.length > 0 && (
          <Select
            items={selectItems}
            itemRenderer={child => <MenuItem intent={child.intent} key={child.to} {...getNavMenuItemProps(child)} />}
            itemPredicate={(query, child) => child.label.toLowerCase().includes(query.toLowerCase())}
            onItemSelect={child => navigate(child.to)}
          >
            <Button minimal small icon="more" aria-label="more tabs" className="ml-2 mb-2" />
          </Select>
        )}
      </Bottom>
    </Nav>
  );
};

export const TopNavLoading = () => {
  return (
    <Nav className="py-2 lg:pt-4 lg:pb-0">
      <Top className="lg:h-[32px]">
        <TopNavHeading className="w-24">
          <Loading type="text" />
        </TopNavHeading>
        <RightButtons>
          <Loading type="button" />
          <Loading type="button" buttonProps={{ className: 'rounded-full h-8 w-8', text: '' }} />
        </RightButtons>
      </Top>

      <Bottom className="h-12 hidden lg:flex">
        {Array.from({ length: 5 }).map((_, index) => (
          <div className="pb-1 flex items-center h-full" key={index}>
            <Loading type="button" buttonProps={{ text: 'Loading' }} />
          </div>
        ))}
      </Bottom>
    </Nav>
  );
};

const Nav = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <nav
      className={cn(
        'px-6 pt-4 border-b-gray-200 dark:border-b-gray-600 border-b-2 bg-gray-100 dark:bg-gray-800',
        className
      )}
      {...props}
    />
  );
};

const Top = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return <div className={cn('flex items-center justify-between', className)} {...props} />;
};

const RightButtons = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return <div className={cn('flex items-center gap-2', className)} {...props} />;
};

const Bottom = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => {
  return (
    <div ref={ref} className={cn('pb-2 -mb-[10px] overflow-x-auto flex items-baseline gap-2', className)} {...props} />
  );
});
Bottom.displayName = 'Bottom';
