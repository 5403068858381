import React from 'react';
import { useParams } from 'react-router-dom';

import { useAuthorized } from 'app/hooks/useAuthorize';
import { useGetVendorQuery } from 'api/vendorsApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { NotFound } from 'app/molecules/NotFound/NotFound';
import { Card } from 'app/atoms/Card/Card';
import { Breadcrumbs } from 'app/molecules/Breadcrumbs/Breadcrumbs';
import { VendorSummaryForm } from 'app/organisms/VendorSummaryForm/VendorSummaryForm';
import { VendorSysAdminForm } from 'app/organisms/VendorSysAdminForm/VendorSysAdminForm';
import { VendorResellerForm } from 'app/organisms/VendorResellerForm/VendorResellerForm';
import { VendorResellersFormList } from 'app/organisms/VendorResellersFormList/VendorResellersFormList';
import { VendorChannelPartnerForm } from 'app/organisms/VendorChannelPartnerForm/VendorChannelPartnerForm';
import { VendorChannelPartnersFormList } from 'app/organisms/VendorChannelPartnersFormList/VendorChannelPartnersFormList';
import { VendorContractHoldersFormList } from 'app/organisms/VendorContractHoldersFormList/VendorContractHoldersFormList';
import { VendorContractHolderForm } from 'app/organisms/VendorContractHolderForm/VendorContractHolderForm';

export const VendorProfileForm = () => {
  const { id } = useParams();
  const newRecord = id === 'new';
  const { isLoading, isError } = useGetVendorQuery({ id }, { skip: newRecord });
  const sysAdmin = useAuthorized({ role: 'sys_admin' });

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <NotFound />;
  }

  return (
    <>
      <Breadcrumbs />
      <VendorSummaryForm id={id} />
      {!newRecord && (
        <Card title="Resellers">
          <VendorResellersFormList vendorId={id} />
          <VendorResellerForm vendorId={id} initialValues={{ resellerableId: id, resellerableType: 'Vendor' }} />
        </Card>
      )}
      {!newRecord && (
        <Card title="Channel Partners">
          <VendorChannelPartnersFormList vendorId={id} recordTitle="Channel Partner" />
          <VendorChannelPartnerForm
            vendorId={id}
            recordTitle="Channel Partner"
            initialValues={{ channelPartnerableId: id, channelPartnerableType: 'Vendor' }}
          />
        </Card>
      )}
      {!newRecord && (
        <Card title="Prime Contracts">
          <VendorContractHoldersFormList vendorId={id} recordTitle="Channel Partner" />
          <VendorContractHolderForm vendorId={id} recordTitle="Channel Partner" initialValues={{ vendorId: id }} />
        </Card>
      )}
      {!newRecord && sysAdmin && <VendorSysAdminForm id={id} />}
    </>
  );
};
