import React, { useMemo, useCallback } from 'react';
import startCase from 'lodash-es/startCase';
import { Tag, Button, Intent } from '@blueprintjs/core';
import { createColumnHelper } from '@tanstack/react-table';

import { PartnershipRequest } from 'types/__generated__/GovlyApi';
import {
  useUpdatePartnershipRequestMutation,
  useDeletePartnershipRequestMutation,
  useGetPartnershipRequestsIndexQuery
} from 'api/partnershipRequestApi';
import { useCurrentUserAttribute } from 'api/currentUserApi';

import { errorToast, successToast } from 'app/lib/toaster';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';

import { CardHeading } from 'app/atoms/Typography/Typography';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableRowCountTag } from 'app/molecules/GovlyTable/GovlyTableRowCountTag';

const columnHelper = createColumnHelper<PartnershipRequest>();

const FINISHED_STATES = ['accepted', 'declined'];

const STATE_MAP = {
  accepted: 'success',
  declined: 'danger',
  pending: 'primary'
};

export const PartnershipRequestsTableUser = () => {
  const { data, isLoading } = useGetPartnershipRequestsIndexQuery({ isAdmin: false });
  const currentOrgId: string = useCurrentUserAttribute('organizationId');
  const [updatePartnershipRequest, { isLoading: isResending }] = useUpdatePartnershipRequestMutation();
  const [deletePartnershipRequest, { isLoading: isDeleting }] = useDeletePartnershipRequestMutation();

  const handleResend = useCallback(
    async (id: string, state: PartnershipRequest['state']) => {
      try {
        await updatePartnershipRequest({ id, state }).unwrap();
        successToast('Resend successful');
      } catch (error) {
        errorToast('There was an error resending the request. Please try again');
      }
    },
    [updatePartnershipRequest]
  );

  const handleDelete = useCallback(
    async (id: string) => {
      try {
        await deletePartnershipRequest({ id }).unwrap();
        successToast('Request successfully deleted');
      } catch (error) {
        errorToast('There was an error deleting the request. Please try again');
      }
    },
    [deletePartnershipRequest]
  );

  const tableData = useMemo(() => {
    const filteredData = data?.filter(r => {
      return r.targetOrganizationId !== currentOrgId;
    }) as PartnershipRequest[];

    return filteredData ?? [];
  }, [data, currentOrgId]);

  const hiddenColumns = useMemo(() => {
    if (tableData.every(request => FINISHED_STATES.includes(request.state))) {
      return { createdAt: false, actions: false };
    }
    return { createdAt: false, actions: true };
  }, [tableData]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('requestorEmail', {
        header: 'Requested By',
        sortingFn: 'basic',
        filterFn: 'fuzzyText'
      }),
      columnHelper.accessor('createdAt', {
        header: 'Sent at',
        sortingFn: 'basic'
      }),
      columnHelper.accessor('state', {
        header: 'Request Status',
        sortingFn: 'basic',
        cell: e => <Tag intent={STATE_MAP[e.row.original.state] as Intent}>{startCase(e.row.original.state)}</Tag>
      }),
      columnHelper.accessor('targetVendor.name', {
        header: 'Sent to',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        cell: e => {
          if (!e.row.original.targetVendor) {
            return null;
          }

          return (
            <LinkTag target="_blank" to={`/vendors/${e.row.original.targetVendor.slug}`}>
              {e.row.original.targetVendor.name}
            </LinkTag>
          );
        }
      }),
      columnHelper.display({
        id: 'actions',
        cell: e => {
          const { id, state } = e.row.original;
          return FINISHED_STATES.includes(state) ? null : (
            <>
              <Button
                small
                className="block"
                text="Resend"
                outlined
                intent="primary"
                onClick={() => handleResend(id, state)}
                loading={isResending}
                disabled={isResending || isDeleting}
              />
              <Button
                small
                className="mt-2 block"
                text="Delete"
                outlined
                intent="danger"
                onClick={() => handleDelete(id)}
                loading={isDeleting}
                disabled={isResending || isDeleting}
              />
            </>
          );
        }
      })
    ],
    [handleDelete, handleResend, isDeleting, isResending]
  );

  return (
    <GovlyTable
      trackingObject="partnership_requests_table_user"
      columns={columns}
      data={tableData}
      title={
        <span className="flex gap-2 items-center">
          <CardHeading>Sent Partnership Requests</CardHeading>
          <GovlyTableRowCountTag />
          <span className="text-sm text-gray-500 font-normal">
            Use partnership requests to build your network and win more opportunities
          </span>
        </span>
      }
      isLoading={isLoading}
      tableProps={{
        state: {
          columnVisibility: hiddenColumns
        }
      }}
      emptyStateProps={{
        icon: 'send-message',
        title: 'No partnership requests found',
        description: 'Invite a partner to get started'
      }}
      initialState={{
        pagination: {
          pageSize: 50
        },
        sorting: [{ id: 'createdAt', desc: true }]
      }}
    />
  );
};
