import React from 'react';
import { Formik, Form } from 'formik';
import { Button } from '@blueprintjs/core';
import * as Yup from 'yup';

import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { SelectInput } from 'app/atoms/inputs/SelectInput/SelectInput';
import { FileInput } from 'app/atoms/inputs/FileInput/FileInput';
import { successToast, errorToast } from 'app/lib/toaster';
import { useCreateFileImportMutation } from 'api/fileImportsApi';
import { FileImportsTable } from 'app/organisms/FileImportsTable/FileImportsTable';

const RECORD_TYPES = {
  'FileImport::CrunchbaseVendorImportWorker': 'Vendor'
};

export const FileImports = () => {
  const [createFileImport] = useCreateFileImportMutation();

  const validationSchema = Yup.object().shape({
    file: Yup.mixed().required('File is required'),
    importWorkerClass: Yup.string().required('Import type is required')
  });

  return (
    <>
      <Formik
        validateOnChange={false}
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          importWorkerClass: '',
          file: null
        }}
        onSubmit={async (values, { setErrors }) => {
          try {
            values.recordType = RECORD_TYPES[values.importWorkerClass];
            await createFileImport(values).unwrap();
            successToast('File import created');
          } catch (e) {
            const { data } = e;
            errorToast(e);
            if (data.errors) setErrors({ data: data.errors });
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Card title="Create an Import">
              <CardBody>
                <SelectInput
                  filterable={false}
                  label="Import Type"
                  name="importWorkerClass"
                  items={[{ label: 'Crunchbase Vendor Import', value: 'FileImport::CrunchbaseVendorImportWorker' }]}
                />
                <FileInput label="Import File" name="file" />
              </CardBody>
              <CardFooter>
                <Button type="submit" intent="primary" text="Create Import" large loading={isSubmitting} />
              </CardFooter>
            </Card>
          </Form>
        )}
      </Formik>
      <FileImportsTable />
    </>
  );
};
