import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Button } from '@blueprintjs/core';

import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { ColorInput, colorInputValidation } from 'app/atoms/inputs/ColorInput/ColorInput';
import { CardBody, CardFooter, Card } from 'app/atoms/Card/Card';
import { LabelTag } from 'app/organisms/LabelTag/LabelTag';
import { useCreateOrganizationLabelMutation, useUpdateOrganizationLabelMutation } from 'api/organizationLabelsApi';
import { formErrorToast } from 'app/lib/toaster';
import { useEventTracking } from 'app/hooks/useEventTracking';

const propTypes = {
  label: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired
};

export const LabelForm = ({ label, onCancel }) => {
  const mutation = label.id ? useUpdateOrganizationLabelMutation : useCreateOrganizationLabelMutation;
  const { trackEvent } = useEventTracking();

  const validationSchema = yup.object({
    name: yup.string().required('Label name is required').max(50),
    color: colorInputValidation()
  });

  const [saveForm, { isLoading }] = mutation();

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await saveForm({ ...values, id: label.id });
          setSubmitting(false);
          trackEvent({
            object: 'organization_label',
            action: label.id ? 'updated' : 'created',
            properties: { id: label.id, name: values.name, color: values.color, description: values.description }
          });
          onCancel();
        } catch (e) {
          setSubmitting(false);
          formErrorToast(e);
        }
      }}
      initialValues={{
        name: label.name || '',
        description: label.description || '',
        color: label.color || ''
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <Card
            title={`${label.id ? 'Update' : 'Create a'} Label`}
            rightElement={<Button minimal icon="cross" onClick={onCancel} />}
          >
            <CardBody>
              <div className="block">
                <LabelTag className="mr-16" color={values.color} name={values.name || 'Label Preview'} />
              </div>
              <TextInput name="name" label="Label name" />
              <TextInput name="description" label="Description" />
              <ColorInput name="color" label="Color" setValue={hex => setFieldValue('color', hex)} />
            </CardBody>
            <CardFooter>
              <Button intent="primary" type="submit" loading={isLoading}>
                Save changes
              </Button>
              <Button onClick={onCancel} disabled={isLoading}>
                Cancel
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

LabelForm.propTypes = propTypes;
