import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog } from '@blueprintjs/core';

import { CardBody } from 'app/atoms/Card/Card';
import { useGetVendorQuery } from 'api/vendorsApi';
import { VendorContractHolderForm } from 'app/organisms/VendorContractHolderForm/VendorContractHolderForm';
import { useGetContractHoldersQuery } from 'api/contractHoldersApi';

export const VendorContractHoldersFormList = ({ vendorId, ...rest }) => {
  const [isEditing, setIsEditing] = useState();
  const { isLoading: vendorLoading, isError: vendorLoadingError } = useGetVendorQuery({ id: vendorId });
  const { data: list, isLoading: listLoading, isFetching } = useGetContractHoldersQuery({ vendorId });

  useEffect(() => {
    if (isEditing) {
      setIsEditing(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  if (vendorLoading || vendorLoadingError || listLoading) {
    return null;
  }

  return list.map(record => {
    const { contractVehicle, ...initialValues } = record;

    return (
      <div key={record.id}>
        <CardBody>
          <div className="flex items-center justify-between">
            <div>
              <p className="mb-1 block text-base font-medium">{contractVehicle.label}</p>
            </div>
            <div>
              <Button icon="edit" small onClick={() => setIsEditing(record.id)} />
            </div>
          </div>
        </CardBody>
        <Dialog title={`Edit Contract`} isOpen={isEditing} className="p-0" onClose={() => setIsEditing(null)}>
          <CardBody>
            <VendorContractHolderForm vendorId={vendorId} initialValues={initialValues} {...rest} />
          </CardBody>
        </Dialog>
      </div>
    );
  });
};

VendorContractHoldersFormList.propTypes = {
  vendorId: PropTypes.string.isRequired
};
