import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { FormGroup, MenuItem, Button } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import keyBy from 'lodash-es/keyBy';

import { cn } from 'app/lib/cn';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';
import { RadioGroupInput } from 'app/atoms/inputs/RadioGroupInput/RadioGroupInput';
import { useFuzzySearch } from 'app/hooks/useFuzzySearch';
import { Avatar } from 'app/molecules/Avatar/Avatar';
import { AvatarWithName } from 'app/molecules/AvatarWithName/AvatarWithName';
import { LoadingSpinner } from 'app/atoms/LoadingSpinner/LoadingSpinner';

export const FollowerSelectInput = ({
  name,
  organizationUsers,
  className,
  helperText,
  disabled,
  label,
  labelInfo,
  loading = false,
  defaultButtonText,
  buttonProps,
  hideFollowSettings,
  ...rest
}) => {
  const [query, setQuery] = useState();
  const [field, meta, { setValue }] = useField({ name });
  const { data: currentUser, isLoading: currentUserLoading } = useGetCurrentUserQuery();
  const organizationUserIndex = keyBy(organizationUsers, 'id');

  const items = useMemo(
    () =>
      organizationUsers.map(organizationUser => ({
        organizationUserId: organizationUser.id,
        notifications: 'user_setting',
        state: 'following',
        createdById: currentUser?.id,
        organizationUser
      })),
    [organizationUsers, currentUser]
  );

  if (currentUserLoading) {
    return null;
  }

  const renderItem = (item, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    const { organizationUserId } = item;
    const orgUser = organizationUserIndex[organizationUserId];
    const text = (
      <span className="inline-flex items-center space-x-2">
        <Avatar
          className={cn(organizationUserIndex[organizationUserId].avatarColor)}
          initials={organizationUserIndex[organizationUserId].initials}
          imgSrc={organizationUserIndex[organizationUserId].avatar?.thumbUrl}
          size="xs"
        />
        <span>{`${orgUser.email}${name === 'partnerFollows' ? ` (${orgUser.organizationName})` : ''}`}</span>
      </span>
    );

    return <MenuItem key={item.organizationUserId} onClick={handleClick} text={text} />;
  };

  const error = meta.touched && meta.error;
  const intent = error ? 'danger' : undefined;

  const selectItem = item => {
    const newItems = [...field.value, item];

    return setValue(newItems);
  };

  const deselectItem = index => {
    const deselected = field.value[index];
    const newItems = field.value.filter(v => v.organizationUserId !== deselected.organizationUserId);

    return setValue(newItems);
  };

  const noResults = loading ? (
    <MenuItem
      disabled
      text={
        <div className="text-center">
          <LoadingSpinner />
        </div>
      }
    />
  ) : (
    <MenuItem disabled text="No results." />
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const selectedIds = useMemo(() => field.value.map(({ organizationUserId }) => organizationUserId), [field.value]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const results = useFuzzySearch({
    options: { keys: ['organizationUser.name', 'organizationUser.email'] },
    data: items
      .filter(({ organizationUserId }) => !selectedIds.includes(organizationUserId))
      .map(item => ({ ...item, organizationUser: { ...organizationUserIndex[item.organizationUserId] } })),
    query
  });

  return (
    <FormGroup
      className={cn('m-0', className)}
      helperText={helperText}
      disabled={disabled}
      label={<LabelSpan label={label} />}
      labelInfo={labelInfo}
      labelFor={name}
      intent={intent}
      contentClassName="mt-2"
      {...rest}
    >
      <Select
        items={results}
        noResults={noResults}
        onItemSelect={selectItem}
        itemRenderer={(item, options) => renderItem(item, { ...options })}
        resetOnSelect
        onQueryChange={setQuery}
        className="w-fit"
        {...rest}
      >
        <Button
          rightIcon="chevron-down"
          text={defaultButtonText || 'Select'}
          className="flex justify-between"
          data-test="follower-select-add-button"
          {...buttonProps}
        />
      </Select>
      <div className="mt-4 grid grid-cols-1 gap-y-2">
        {field.value.map(({ organizationUserId }, index) => {
          const organizationUser = organizationUserIndex[organizationUserId];
          if (!organizationUser) {
            return null;
          }

          return (
            <div
              key={organizationUser.id}
              className="col-span-1 flex flex-wrap items-center justify-between rounded border border-gray-100 p-2"
            >
              <AvatarWithName
                initials={organizationUser.initials}
                imgSrc={organizationUser.avatar?.thumbUrl}
                avatarColor={organizationUser.avatarColor}
                id={organizationUser.id}
                email={organizationUser.email}
                name={organizationUser.name}
              />
              <div className="flex items-center space-x-2">
                {!hideFollowSettings && (
                  <RadioGroupInput
                    label="Notification Settings"
                    name={`${name}[${index}].notifications`}
                    inline
                    options={[
                      { label: 'Default', value: 'user_setting', className: 'mb-0' },
                      { label: 'Email', value: 'email', className: 'mb-0' },
                      { label: 'Muted', value: 'muted', className: 'mb-0' }
                    ]}
                  />
                )}
                <div>
                  <Button
                    intent="danger"
                    outlined
                    icon="trash"
                    onClick={() => deselectItem(index)}
                    data-test="follower-select-remove-button"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </FormGroup>
  );
};

FollowerSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  organizationUsers: PropTypes.array.isRequired,
  className: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  intent: PropTypes.string,
  label: PropTypes.string,
  labelInfo: PropTypes.string,
  defaultButtonText: PropTypes.string,
  noSelectionText: PropTypes.string,
  staticButtonText: PropTypes.string,
  buttonProps: PropTypes.object,
  loading: PropTypes.bool,
  hideFollowSettings: PropTypes.bool,
  noResultsComponent: PropTypes.node
};
