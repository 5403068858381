import React from 'react';
import { DropzoneOptions, DropzoneInputProps } from 'react-dropzone';

import { useUpload, useUploadArgs } from 'app/hooks/useUpload';

export type FileUploaderProps = {
  id: string;
  wrapperElement?: string;
  children?: React.ReactNode;
  inputProps?: object;
  useUploadArgs?: Omit<DropzoneOptions, 'onError'>;
} & Omit<useUploadArgs, 'uploaderId'> &
  DropzoneInputProps;

export const FileUploader = ({
  wrapperElement = 'div',
  id,
  onInitialize,
  onAttach,
  children,
  inputProps,
  useUploadArgs,
  ...rest
}: FileUploaderProps) => {
  const { getRootProps, getInputProps } = useUpload({
    uploaderId: id,
    onAttach,
    onInitialize,
    ...(useUploadArgs || {})
  });
  const Element = wrapperElement;
  return (
    <Element {...getRootProps({ ...rest })} {...rest}>
      <input {...getInputProps()} {...(inputProps || {})} />
      {children}
    </Element>
  );
};
