import React, { useEffect } from 'react';
import { Alert } from '@blueprintjs/core';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { NotFound } from 'app/molecules/NotFound/NotFound';
import { useUpdateNotificationSettingMutation } from 'api/organizationUsersApi';
import { errorToast, successToast } from 'app/lib/toaster';
import { useCurrentUserAttribute } from 'api/currentUserApi';

const unsubscribeTypes = {
  'matched-notifications': {
    setting: 'matched',
    title: 'Match Notifications',
    description: (
      <>
        <p className="font-semibold">Are you sure you want to unsubscribe from Govly Match Notifications?</p>
        <p>You will no longer receive notifications when you match a new opportunity.</p>
      </>
    )
  },
  'workspace-notifications': {
    setting: 'update_workspace',
    title: 'Workspace Update Notifications',
    description: (
      <>
        <p className="font-semibold">Are you sure you want to unsubscribe from Govly Match Notifications?</p>
        <p>You will no longer receive notifications when you match a new opportunity.</p>
      </>
    )
  },
  'workable-notifications': {
    setting: 'update_workable',
    title: 'Opportunity Update Notifications'
  },
  'activity-digest-notifications': {
    setting: 'digest_frequency',
    title: 'Digest Notifications'
  },
  'org-digest-notifications': {
    setting: 'org_digest_frequency',
    title: 'Org Digest Notifications'
  }
};

export const UnsubscribePage = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const type = params.get('type');
  const organizationUserId = useCurrentUserAttribute('id');
  const settingInfo = unsubscribeTypes[type];

  const [updateNotificationSetting, { isLoading, error, isSuccess }] = useUpdateNotificationSettingMutation();

  useEffect(() => {
    if (error) return errorToast(error);
    if (isSuccess) {
      successToast(`You have unsubscribed from Govly ${settingInfo.title}.`);
      navigate(`/users/${organizationUserId}/edit`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, error]);

  if (!type || !unsubscribeTypes[type]) {
    return <NotFound />;
  }

  return (
    <Alert
      isOpen={true}
      intent="danger"
      icon="warning-sign"
      cancelButtonText="Cancel"
      confirmButtonText="Unsubscribe"
      loading={isLoading}
      onCancel={() => (window.location.href = '/')}
      onConfirm={() => {
        if (type.includes('-digest-notifications')) {
          return updateNotificationSetting({
            organizationUserId,
            setting: settingInfo.setting,
            value: 'never'
          });
        }

        updateNotificationSetting({
          organizationUserId,
          setting: settingInfo.setting,
          checked: false,
          value: 'email'
        });
      }}
    >
      {settingInfo.description || (
        <p className="font-semibold">Are you sure you want to unsubscribe from Govly {settingInfo.title}?</p>
      )}
    </Alert>
  );
};
