import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Button } from '@blueprintjs/core';
import partition from 'lodash-es/partition';
import keyBy from 'lodash-es/keyBy';

import { H3 } from 'app/atoms/Typography/Typography';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { formErrorToast, successToast } from 'app/lib/toaster';
import { useGetOrganizationUsersQuery } from 'api/organizationUsersApi';
import { FollowerSelectInput } from 'app/organisms/FollowerSelectInput/FollowerSelectInput';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { WorkspaceFormNotificationsInput } from 'app/organisms/WorkspaceFormNotificationsInput/WorkspaceFormNotificationsInput';
import { useEventTracking } from 'app/hooks/useEventTracking';

const propTypes = {
  title: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  follows: PropTypes.array,
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    workableType: PropTypes.string,
    workableId: PropTypes.string,
    organizationDefault: PropTypes.bool
  }),
  workableType: PropTypes.string,
  notifyNewFollowersOnly: PropTypes.bool,
  shouldTrack: PropTypes.bool,
  enableReinitialize: PropTypes.bool
};

export const WorkableForm = ({
  title,
  onCancel,
  isSubmitting,
  onSubmit,
  follows = [],
  initialValues,
  workableType,
  notifyNewFollowersOnly = true,
  shouldTrack = true,
  enableReinitialize = false,
  ...rest
}) => {
  const currentOrgId = useCurrentUserAttribute('organizationId');
  const { trackEvent } = useEventTracking();

  const { data: team = [], isLoading: teamLoading } = useGetOrganizationUsersQuery({
    view: 'current_org'
  });

  const { data: partners = [], isLoading: partnersLoading } = useGetOrganizationUsersQuery({
    filter: 'partners',
    view: 'displayable'
  });

  const [teamFollows, partnerFollows] = partition(
    follows.filter(({ state }) => state === 'following'),
    ({ organizationUser: { organizationId } }) => organizationId === currentOrgId
  );

  const followsByUser = keyBy(
    follows.map(({ organizationUser, ...rest }) => rest),
    'organizationUserId'
  );

  const form = {
    enableReinitialize,
    onSubmit: async ({ teamFollows, partnerFollows, customNotifyList, notifyGroup, ...payload }) => {
      try {
        const updatedFollowsByUser = keyBy(teamFollows.concat(partnerFollows), 'organizationUserId');

        const allFollowers = [...new Set(Object.keys(followsByUser).concat(Object.keys(updatedFollowsByUser)))];

        const payloadFollows = allFollowers.map(ouid => {
          const updatedFollow = updatedFollowsByUser[ouid];
          const originalFollow = followsByUser[ouid];

          if (originalFollow && !updatedFollow) {
            return {
              id: originalFollow.id,
              state: 'unfollowed'
            };
          }

          return {
            id: originalFollow?.id || updatedFollow.id,
            state: updatedFollow.state,
            notifications: updatedFollow.notifications,
            organizationUserId: updatedFollow.organizationUserId,
            email: updatedFollow.organizationUser.email
          };
        });

        payload.followsAttributes = payloadFollows;

        let notifyIds = [];

        if (notifyGroup === 'select') {
          notifyIds = customNotifyList.map(({ organizationUserId }) => organizationUserId);
        }

        if (notifyGroup === 'notifiables') {
          let notifiables = [...teamFollows, ...partnerFollows];

          if (notifyNewFollowersOnly) {
            notifiables = notifiables.filter(({ id }) => !id);
          }

          notifyIds = notifiables.map(({ organizationUserId }) => organizationUserId);
        }

        const data = await onSubmit({
          ...payload,
          notifyIds
        }).unwrap();

        const { followsAttributes, ...remainingData } = payload;
        if (shouldTrack)
          trackEvent({
            object: `${workableType?.toLowerCase() || 'opp'}_workspace`,
            action: 'updated',
            properties: {
              workspaceId: remainingData.id,
              ...remainingData,
              ...data,
              notifyIds,
              follows: followsAttributes?.map(f => f.email),
              teamFollows: teamFollows?.map(f => f.organizationUser.email),
              partnerFollows: partnerFollows?.map(f => f.organizationUser.email)
            }
          });

        successToast('Saved Changes');
        onCancel();
      } catch (e) {
        formErrorToast(400);
      }
    },
    initialValues: {
      teamFollows,
      partnerFollows,
      ...initialValues,
      notifyGroup: 'notifiables',
      customNotifyList: []
    }
  };
  const displayableType = workableType === 'USASpendingAward' ? 'Award' : workableType;

  return (
    <div {...rest}>
      <Formik {...form}>
        <Form>
          <Card title={title} rightElement={<Button minimal icon="cross" onClick={onCancel} />}>
            <CardBody>
              <div className="space-y-4">
                <H3>{`1. Which team members should be added as followers to this ${displayableType || 'Workspace'}?`}</H3>
                {teamLoading ? (
                  <Button
                    disabled
                    large
                    text="Add a team member"
                    rightIcon="caret-down"
                    data-test="workspace-followers-input"
                  />
                ) : (
                  <FollowerSelectInput
                    name="teamFollows"
                    defaultButtonText="Add a team member"
                    organizationUsers={team}
                    disabled={teamLoading}
                    data-test="workspace-followers-input"
                  />
                )}
              </div>
              {!partnersLoading && partners.length > 0 && (
                <div className="space-y-4">
                  <H3>{`2. Which partners should be added as followers to this ${displayableType || 'Workspace'}?`}</H3>
                  <FollowerSelectInput
                    name="partnerFollows"
                    defaultButtonText="Add a partner"
                    organizationUsers={partners}
                    disabled={partnersLoading}
                    data-test="workspace-partners-input"
                  />
                </div>
              )}
              <WorkspaceFormNotificationsInput
                initialFollows={[...teamFollows, ...partnerFollows]}
                notifyNewFollowersOnly={notifyNewFollowersOnly}
              />
            </CardBody>
            <CardFooter>
              <Button
                large
                type="submit"
                text="Save"
                intent="primary"
                loading={isSubmitting}
                disabled={teamLoading || partnersLoading || isSubmitting}
                data-test="workspace-submit-button"
              />
              <Button text="Cancel" disabled={isSubmitting} onClick={onCancel} />
            </CardFooter>
          </Card>
        </Form>
      </Formik>
    </div>
  );
};

WorkableForm.propTypes = propTypes;
