import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AnchorButton, Icon, Tag } from '@blueprintjs/core';

import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';
import { FileViewer } from 'app/molecules/FileViewer/FileViewer';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { cn } from 'app/lib/cn';

const propTypes = {
  attachments: PropTypes.array.isRequired,
  onRemove: PropTypes.func,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.string,
  downloadAllLink: PropTypes.string,
  disableDownloads: PropTypes.bool
};

export const AttachmentList = ({
  attachments,
  header,
  description,
  onRemove,
  downloadAllLink,
  disableDownloads,
  ...rest
}) => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const [fileToView, setFileToView] = useState(null);

  const preferBrowserView = disableDownloads || currentUser?.organization?.settings?.canViewFilesInBrowser;
  return (
    <div {...rest}>
      {(header || downloadAllLink) && (
        <div className="mb-2 flex items-center justify-between">
          <div>{header && typeof header === 'string' ? <LabelSpan label={header} className="mb-1.5" /> : header}</div>
          {!preferBrowserView && downloadAllLink && attachments.length > 1 && (
            <AnchorButton icon="download" text="Download All" href={downloadAllLink} />
          )}
        </div>
      )}
      {description && <dt className="block text-xs font-medium text-gray-500">{description}</dt>}
      <dd className="text-sm text-gray-900">
        {!!attachments.length && (
          <ul role="list" className="mb-2">
            {attachments.map((attachment, i) => (
              <li key={`${attachment.name}-${i}`} className={cn('mb-2 rounded-md border py-3 pl-3 pr-4 text-sm')}>
                <div className="flex items-center justify-between gap-x-2">
                  <div className="flex w-0 flex-1 items-center">
                    <Icon icon="paperclip" className="flex-shrink-0 text-gray-400" aria-hidden="true" />
                    {attachment.href || attachment.link ? (
                      <>
                        {preferBrowserView && attachment.serviceUrl ? (
                          <>
                            <span className="ml-2 w-0 flex-1 truncate">{attachment.name}</span>
                            <a
                              onClick={() =>
                                setFileToView({
                                  name: attachment.name,
                                  url: attachment.serviceUrl,
                                  downloadLink: attachment.link
                                })
                              }
                            >
                              View in Browser
                            </a>
                          </>
                        ) : (
                          <a
                            className="ml-2 w-0 flex-1 truncate text-blue-600"
                            href={attachment.href || attachment.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {attachment.name}
                          </a>
                        )}
                      </>
                    ) : (
                      <span className="ml-2 w-0 flex-1 truncate">{attachment.name}</span>
                    )}
                  </div>
                  {onRemove && (
                    <div className="ml-4 flex-shrink-0">
                      <button
                        onClick={() => onRemove(attachment.id)}
                        className="font-medium text-red-600 hover:text-red-500"
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </div>
                {(attachment.metadata?.warningTags?.length > 0 || attachment.presenters?.length > 0) && (
                  <div className="mt-1 flex items-center gap-x-2">
                    {attachment.metadata.warningTags.map(tag => (
                      <Tag icon="warning-sign" intent="warning" key={tag}>
                        {tag}
                      </Tag>
                    ))}
                    {attachment.presenters.map(tag => (
                      <Tag intent={tag === 'New' ? 'success' : 'warning'} key={tag}>
                        {tag}
                      </Tag>
                    ))}
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </dd>
      {!!fileToView?.url && (
        <FileViewer
          downloadLink={fileToView?.downloadLink}
          fileName={fileToView?.name}
          fileUrl={fileToView?.url}
          onClose={() => setFileToView(null)}
          disableDownload={disableDownloads}
        />
      )}
    </div>
  );
};

AttachmentList.propTypes = propTypes;
