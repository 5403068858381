import React, { useState } from 'react';

import { LabelList } from 'app/organisms/LabelList/LabelList';
import { LabelFormDialog } from 'app/organisms/LabelFormDialog/LabelFormDialog';
import { DescriptionListLabel } from 'app/molecules/DescriptionList/DescriptionList';

type Props = Partial<React.ComponentProps<typeof LabelFormDialog>> & {
  taggableId: string;
  taggableType: string;
  labels?: {
    id: string;
    name: string;
    color: string;
  }[];
};

export const LabelsDescriptionListField = ({ taggableId, taggableType, labels = [], ...rest }: Props) => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  return (
    <>
      <div className="flex flex-col gap-y-1">
        <DescriptionListLabel className="mb-2">Labels</DescriptionListLabel>
        <LabelList labels={labels} onAdd={() => setIsFormOpen(true)} />
      </div>

      <LabelFormDialog
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        initialValues={{
          organizationLabelIds: labels.map(({ id }) => id),
          taggableId,
          taggableType
        }}
        {...rest}
      />
    </>
  );
};
