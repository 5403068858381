import React from 'react';
import * as yup from 'yup';

import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { CheckboxGroupInput } from 'app/atoms/inputs/CheckboxGroupInput/CheckboxGroupInput';
import { RadioGroupInput } from 'app/atoms/inputs/RadioGroupInput/RadioGroupInput';
import { companySizes } from 'app/lib/businessClassifications';

export const companySchema = yup.object({
  organizationName: yup.string().required('Organization Name is required'),
  organizationType: yup.array().required('Organization Type is required'),
  organizationSize: yup.string().required('Organization Size is required')
});

export const OnboardingFormInfoCompany = () => (
  <>
    <TextInput name="organizationName" label="Organization Name" />
    <CheckboxGroupInput
      inline
      hasCount={false}
      hasClear={false}
      label="Organization Type"
      name="organizationType"
      items={['VAR', 'OEM', 'Prime Contractor', 'Distributor', 'System Integrator', 'Government Agency', 'Other'].map(
        value => ({ value, label: value })
      )}
    />
    <RadioGroupInput
      inline
      label="Organization Size"
      name="organizationSize"
      options={companySizes.map(value => ({ value, label: value }))}
    />
  </>
);
