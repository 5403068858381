import React, { useState, useMemo } from 'react';
import { Button, Dialog, Tag } from '@blueprintjs/core';
import { formatTime, DATETIME_24_SHORT } from 'app/lib/dates';
import { match } from 'ts-pattern';

import { createColumnHelper, PaginationState } from '@tanstack/react-table';

import { ActorRun, ActorRunSysAdmin } from 'types/__generated__/GovlyApi';
import { useGetActorRunsQuery } from 'api/actorRunsApi';

import { JsonViewer } from 'app/molecules/JsonViewer/JsonViewer';

import { CardHeading } from 'app/atoms/Typography/Typography';
import { GovlyTableRowCountTag } from 'app/molecules/GovlyTable/GovlyTableRowCountTag';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';

const columnHelper = createColumnHelper<ActorRun | ActorRunSysAdmin>();

export const ActorRuns = () => {
  const [viewing, setViewing] = useState<ActorRun | ActorRunSysAdmin | undefined>();
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: 20,
    pageIndex: 0
  });

  const {
    data: actorRuns = [],
    isLoading,
    isError
  } = useGetActorRunsQuery({ page: pagination.pageIndex + 1, per: pagination.pageSize });

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: 'ID',
        enableSorting: false,
        enableColumnFilter: false
      }),
      columnHelper.accessor('type', {
        header: 'Type',
        sortingFn: 'basic',
        filterFn: 'fuzzyText'
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        sortingFn: 'basic',
        filterFn: 'fuzzyText',
        cell: e => <Tag intent={e.row.original.statusIntent}>{e.row.original.statusIntent}</Tag>
      }),
      columnHelper.accessor('processedAt', {
        header: 'Ran At',
        enableColumnFilter: false,
        cell: e => (
          <>
            {formatTime(
              e.row.original.processedAt || e.row.original.completedAt || e.row.original.createdAt,
              DATETIME_24_SHORT
            )}
          </>
        )
      }),
      columnHelper.accessor('actionsCount', {
        header: 'Records Processed',
        enableSorting: false,
        enableColumnFilter: false
      }),
      columnHelper.display({
        header: 'Details',
        cell: e => {
          return <Button small text="View Details" onClick={() => setViewing(e.row.original)} />;
        }
      })
    ],
    []
  );

  const tableData = useMemo(() => actorRuns, [actorRuns]);

  const emptyStateProps = match({ isError, actorRunsLength: actorRuns.length })
    .with({ isError: true }, () => ({
      icon: 'warning-sign' as const,
      title: 'There was an error loading this data.',
      description: 'Please refresh the page to try again.'
    }))
    .with({ actorRunsLength: 0 }, () => ({
      icon: 'warning-sign' as const,
      title: 'No automations registered',
      description: 'You are not using contract automation products in Govly.'
    }))
    .otherwise(() => undefined);

  // This is a hack because the actorRun endpoint doesn't return the total count
  const rowCount = actorRuns.length > 0 && !isLoading && !isError ? 200 : 0;

  return (
    <>
      <GovlyTable
        trackingObject="actor_runs_table"
        columns={columns}
        data={tableData}
        title={
          <span className="flex gap-2 items-center">
            <CardHeading>Automation Runs</CardHeading>
            <GovlyTableRowCountTag />
          </span>
        }
        isLoading={isLoading}
        tableProps={{
          manualPagination: true,
          onPaginationChange: setPagination,
          rowCount: rowCount,
          state: { pagination }
        }}
        initialState={{
          pagination
        }}
        emptyStateProps={emptyStateProps}
      />

      <Dialog className="bg-transparent p-0" isOpen={!!viewing} onClose={() => setViewing(undefined)}>
        <div className="prose prose-sm">
          <pre>
            <JsonViewer
              json={Object.fromEntries(
                Object.entries({
                  stats: viewing?.stats,
                  records: viewing?.records,
                  logs: viewing?.logs,
                  apifyLink: viewing && 'apifyLink' in viewing ? viewing.apifyLink : undefined
                }).filter(entry => entry[1])
              )}
            />
          </pre>
        </div>
      </Dialog>
    </>
  );
};
