import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Tag, NonIdealState, Dialog } from '@blueprintjs/core';

import { Loading } from 'app/atoms/Loading/Loading';
import { useSearchCatalogQuery } from 'api/souptoolsCatalogsApi';
import { ReactTableTemplate, TableColumnHeader } from 'app/templates/ReactTableTemplate/ReactTableTemplate';
import { TablePagination } from 'app/molecules/TablePagination/TablePagination';
import { ControlGroup } from 'app/molecules/ControlGroup/ControlGroup';
import { validations } from 'app/organisms/SouptoolsTrClin/SouptoolsTrClin';
import { SouptoolsClinForm } from 'app/organisms/SouptoolsClinForm/SouptoolsClinForm';
import { asCurrency } from 'app/lib/numbers';
import { SouptoolsClin } from 'types/__generated__/GovlyApi';

type Cell = {
  row: {
    original: SouptoolsClin;
  };
};

export const SouptoolsCatalogPage = () => {
  const [page, setPage] = useState(1);
  const [clin, setClin] = useState<SouptoolsClin | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();

  const query = {
    column: searchParams.get('column') || 'partnumber',
    query: searchParams.get('query') || ''
  };

  const { data, isFetching } = useSearchCatalogQuery({ page, ...query });
  const { clins = [], meta } = data ?? {};

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Clin / Part Number',
          HeaderComponent: React.memo(TableColumnHeader),
          accessor: 'clin',
          Cell: (e: Cell) => (
            <div>
              <span>
                Clin:{' '}
                <Tag intent="primary" minimal interactive onClick={() => setClin(e.row.original)}>
                  {e.row.original.clin}
                </Tag>
              </span>
              <p>Part Number: {e.row.original.partnumber}</p>
            </div>
          )
        },
        {
          Header: 'Provider',
          HeaderComponent: React.memo(TableColumnHeader),
          accessor: 'provider'
        },
        {
          Header: 'Sspart',
          HeaderComponent: React.memo(TableColumnHeader),
          accessor: 'sspart'
        },
        {
          Header: 'Sewp Price',
          HeaderComponent: React.memo(TableColumnHeader),
          accessor: 'sewpprice',
          Cell: (e: Cell) => asCurrency(e.row.original.sewpprice)
        }
      ].map(column => ({ ...column, disableSortBy: true })),
    []
  );

  const tableData = useMemo(() => clins, [clins]);

  if (isFetching) return <Loading />;

  const ToolbarComponents = () => {
    return (
      <Formik
        initialValues={query}
        onSubmit={async ({ column, query }) => {
          if (!query) return setSearchParams({});
          setSearchParams({ column, query });
        }}
      >
        {() => (
          <Form>
            <ControlGroup
              filterName="column"
              filterOptions={[
                { value: 'partnumber', label: 'Part Number' },
                { value: 'clin', label: 'Clin' },
                { value: 'provider', label: 'Provider' },
                { value: 'sspart', label: 'Sspart' }
              ]}
              inputName="query"
              onClear={() => setSearchParams({})}
            />
          </Form>
        )}
      </Formik>
    );
  };

  const EmptyState = () => (
    <NonIdealState title="No Catalog Clins Found" description="Try Changing your search query." />
  );

  return (
    <>
      <ReactTableTemplate
        /* @ts-expect-error Component not typed */
        headerType="card-header"
        columns={columns}
        data={tableData}
        title="Catalog Clins"
        ToolbarComponents={ToolbarComponents}
        rowCount={meta?.total}
        EmptyState={EmptyState}
        striped
        customPaginationComponent={
          meta ? <TablePagination {...meta} onPageChange={({ page }) => setPage(page)} /> : undefined
        }
      />
      <Dialog isOpen={!!clin} onClose={() => setClin(undefined)} className="min-w-[50%] p-0">
        {/* @ts-expect-error Component not typed */}
        <SouptoolsClinForm clin={clin} validations={validations} onClose={() => setClin(undefined)} readOnly />
      </Dialog>
    </>
  );
};
