import React, { useState, useRef, useEffect } from 'react';
import { Button, RadioGroup, Radio } from '@blueprintjs/core';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Editor } from 'app/molecules/Editor/Editor';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { useGetOppWorkspaceQuery } from 'api/oppWorkspacesApi';
import { formErrorToast, successToast } from 'app/lib/toaster';
import { FollowerSelectInput } from 'app/organisms/FollowerSelectInput/FollowerSelectInput';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { CommentShow, OrganizationUserFollow } from 'types/__generated__/GovlyApi';

type FormValues = {
  id: string;
  content: string;
  organizationUserId: string;
  commentableType: string;
  oppId: string;
  commentableId: string;
  follows: OrganizationUserFollow[];
};

const NEW_COMMENT_ID = 'new-comment';

type OppWorkspaceCommentFormProps = {
  onSubmit: (values: Omit<FormValues, 'follows'> & { notifyIds: string[] }) => { unwrap: () => Promise<unknown> };
  oppId: string;
  workspaceId: string;
  comment?: CommentShow;
  afterSubmit?: () => void;
  isLoading: boolean;
};

export const OppWorkspaceCommentForm = ({
  comment,
  workspaceId,
  oppId,
  onSubmit,
  afterSubmit = () => {},
  isLoading
}: OppWorkspaceCommentFormProps) => {
  const [mentions, setMentions] = useState<string[]>([]);
  const editorRef = useRef<HTMLTextAreaElement>();
  const [notifyGroup, setNotifyGroup] = useState('followers');
  const currentUserId = useCurrentUserAttribute('id');
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: { follows = [] } = {}, isLoading: workspaceLoading } = useGetOppWorkspaceQuery({ id: workspaceId });

  useEffect(() => {
    if (mentions.length > 0) setNotifyGroup('select');
  }, [mentions, setNotifyGroup]);

  if (workspaceLoading) {
    return null;
  }

  const notifiableUsers = follows
    .filter(f => f.organizationUserId !== currentUserId)
    .map(({ organizationUser }) => organizationUser)
    .filter(user => user !== undefined);

  return (
    <Formik
      enableReinitialize
      onSubmit={async (values, { resetForm }) => {
        try {
          const { follows = [], ...rest } = values;

          let notifyIds: string[] = [];

          if (notifyGroup === 'followers') {
            notifyIds = notifiableUsers.map(({ id }) => id);
          }

          if (notifyGroup === 'select') {
            notifyIds = follows.map(({ organizationUserId }) => organizationUserId);
          }

          await onSubmit({ ...rest, notifyIds }).unwrap();
          setNotifyGroup('followers');

          if (values.id !== NEW_COMMENT_ID) {
            successToast('Message updated.');
          } else {
            resetForm();
            if (editorRef.current) {
              editorRef.current.value = '';
            }
            successToast('Message posted.');
          }

          setMentions([]);
          window.sessionStorage.removeItem(`commentDraft-${workspaceId}`);
          afterSubmit();
        } catch (error) {
          formErrorToast(error as number);
        }
      }}
      initialValues={{
        id: comment?.id ?? NEW_COMMENT_ID,
        content:
          comment?.contentRaw || JSON.parse(window.sessionStorage.getItem(`commentDraft-${workspaceId}`) || '""') || '',
        organizationUserId: currentUserId,
        commentableType: 'Workspace',
        oppId,
        commentableId: workspaceId,
        follows: notifiableUsers
          .filter(({ email }) => mentions.includes(email))
          .map(organizationUser => ({
            organizationUserId: organizationUser.id,
            notifications: 'user_setting',
            state: 'following',
            createdById: currentUser?.id,
            organizationUser
          }))
      }}
      validationSchema={yup.object({
        content: yup.string().required('Please enter a comment')
      })}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form>
          <div className="flex flex-col gap-y-4">
            <Editor
              // @ts-expect-error editor not typed
              id={comment?.id ?? NEW_COMMENT_ID}
              // @ts-expect-error editor not typed
              onChange={({ target: { value: newValue } }) => {
                setFieldValue('content', newValue);
                if (!comment?.id) {
                  window.sessionStorage.setItem(`commentDraft-${workspaceId}`, JSON.stringify(newValue));
                }
              }}
              inputProps={{ value: values.content }}
              ref={editorRef}
              notifiableUsers={notifiableUsers}
              setMentions={setMentions}
              trixEditorProps={{ placeholder: 'Write a new message...' }}
              formGroupProps={{
                className: 'mb-0',
                contentClassName: 'mt-0',
                labelFor: 'comment'
              }}
            />
            {!!values.content.length && (
              <div>
                {notifiableUsers.length > 0 && (
                  <div className="flex flex-col">
                    <p className="mb-2 text-base font-medium">When I post this, notify...</p>
                    <RadioGroup selectedValue={notifyGroup} onChange={e => setNotifyGroup(e.currentTarget.value)}>
                      <Radio label="Everyone in the workspace" value="followers" />
                      <Radio label="No one" value="nobody" />
                      <Radio label="Only the people I select..." value="select" />
                    </RadioGroup>
                    {notifyGroup === 'select' && (
                      // @ts-expect-error follower select input not typed
                      <FollowerSelectInput
                        hideFollowSettings
                        name="follows"
                        defaultButtonText="Select recipients"
                        organizationUsers={notifiableUsers}
                      />
                    )}
                  </div>
                )}

                <div className="flex flex-row-reverse">
                  <Button intent="primary" loading={isSubmitting || isLoading} type="submit">
                    Submit
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};
