import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button, Dialog, Tooltip } from '@blueprintjs/core';
import startCase from 'lodash-es/startCase';
import { Formik, Form } from 'formik';

import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { useRejectClinUploadMutation, useDeleteClinUploadMutation } from 'api/souptoolsClinsApi';
import { CardBody, CardFooter } from 'app/atoms/Card/Card';
import { formatTime } from 'app/lib/dates';
import { successToast } from 'app/lib/toaster';
import { TextAreaInput } from 'app/atoms/inputs/TextAreaInput/TextAreaInput';

const propTypes = {
  clinUpload: PropTypes.object.isRequired,
  uploadableId: PropTypes.string,
  uploadableType: PropTypes.string,
  className: PropTypes.string
};

export const SouptoolsClinUpload = ({
  className,
  uploadableId,
  uploadableType,
  clinUpload: {
    id,
    createdAt,
    updatedAt,
    userEmail,
    file: { name, link } = {},
    meta: { added = 0, changed = 0, failed = 0, rejected: numRejected, status, error, rejectedBy, rejectionReason }
  }
}) => {
  const { data: { email } = {} } = useGetCurrentUserQuery();
  const [rejectionModalIsOpen, setRejectionModalIsOpen] = useState(false);
  const [rejectClinUpload, { isLoading: isRejecting }] = useRejectClinUploadMutation();
  const [deleteClinUpload, { isLoading: isDeleting }] = useDeleteClinUploadMutation();

  const rejected = status === 'rejected';
  const uploadedAtText = ` Uploaded ${formatTime(createdAt)}${userEmail ? ` by ${userEmail}` : ''}`;
  let statusClass = 'text-red-600';

  if (status === 'processed') {
    statusClass = 'text-green-600';
  }

  if (status === 'processing') {
    statusClass = 'text-orange-600';
  }

  return (
    <div className={`card-body flex flex-col p-0 ${className}`}>
      <div key={id} className="card-body flex flex-col">
        <div className="flex flex-wrap items-center justify-between">
          <div>
            <strong>
              <a href={link} target="_blank" rel="noreferrer">
                {name}
              </a>
            </strong>
            <div className="flex flex-wrap items-center py-1">
              <>
                <Icon icon="time" iconSize={12} />
                <small className="ml-1">{uploadedAtText}</small>
              </>
            </div>
            <div className="flex flex-wrap items-center py-1">
              <>
                <small>
                  <strong className={statusClass}>{startCase(status)}</strong>
                </small>
                {error && (
                  <>
                    <Icon icon="dot" iconSize={12} />
                    <small className="ml-1">
                      <strong>{error}</strong>
                    </small>
                  </>
                )}
                <Icon icon="dot" iconSize={12} />
                {rejected ? (
                  <small className="ml-1">
                    <strong>{`${numRejected} rejected`}</strong>
                  </small>
                ) : (
                  <>
                    <small className="ml-1">
                      <strong>{`${added} added`}</strong>
                    </small>
                    <Icon icon="dot" iconSize={12} />
                    <small className="ml-1">
                      <strong>{`${changed} changed`}</strong>
                    </small>
                    <Icon icon="dot" iconSize={12} />
                    <small className="ml-1">
                      <strong>{`${failed} failed`}</strong>
                    </small>
                  </>
                )}
              </>
            </div>
            {rejected && (
              <div className="flex flex-wrap items-center py-1">
                <small>{`Rejected by ${rejectedBy} on ${formatTime(updatedAt)} - Reason: ${rejectionReason}`}</small>
              </div>
            )}
          </div>
          <div>
            {!rejected && (
              <Tooltip content="Remove upload">
                <Button
                  icon="trash"
                  minimal
                  intent="danger"
                  loading={isRejecting || isDeleting}
                  onClick={async () => {
                    if (email === userEmail) {
                      await deleteClinUpload({ id, uploadableId, uploadableType });
                      successToast('Upload removed successfully.');
                    } else {
                      setRejectionModalIsOpen(true);
                    }
                  }}
                />
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <Dialog isOpen={rejectionModalIsOpen} onClose={() => setRejectionModalIsOpen(false)} title="Remove Clin Upload">
        <Formik
          onSubmit={async ({ rejectionReason }) => {
            await rejectClinUpload({ id, uploadableId, uploadableType, rejectionReason });
            setRejectionModalIsOpen(false);
            successToast('Upload removed successfully.');
          }}
          initialValues={{ rejectionReason: '' }}
        >
          <Form>
            <CardBody>
              <TextAreaInput name="rejectionReason" label="Rejection Reason" />
            </CardBody>
            <CardFooter>
              <Button type="submit" loading={isRejecting} intent="danger" text="Remove Upload" />
            </CardFooter>
          </Form>
        </Formik>
      </Dialog>
    </div>
  );
};

SouptoolsClinUpload.propTypes = propTypes;
