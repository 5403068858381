import React from 'react';
import { useParams } from 'react-router-dom';
import { Tag, NonIdealState } from '@blueprintjs/core';

import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { Loading } from 'app/atoms/Loading/Loading';
import { Card, CardBody } from 'app/atoms/Card/Card';
import { DescriptionListField } from 'app/molecules/DescriptionListField/DescriptionListField';
import { InfoTooltip } from 'app/molecules/InfoTooltip/InfoTooltip';
import { CrmContact } from 'app/molecules/CrmContact/CrmContact';
import { useGetVendorQuery } from 'api/vendorsApi';

export const VendorProfileContracts = () => {
  const { slug } = useParams();

  const { data, isLoading: vendorLoading } = useGetVendorQuery({ id: slug, view: 'contract_holders' });

  if (vendorLoading) {
    return <Loading />;
  }

  const { name, contractHolders } = data || {};

  if (!contractHolders?.length) {
    return (
      <Card>
        <CardBody>
          <NonIdealState
            icon="document"
            title={`${name} has not added their contracts.`}
            description="Claim this profile to add contracts."
          />
        </CardBody>
      </Card>
    );
  }

  return (
    <MainColumn columnSpan={12}>
      {contractHolders?.map(contractHolder => (
        <Card
          key={contractHolder.id}
          title={contractHolder.contractVehicle.contractName}
          rightElement={
            <Tag intent="success" large icon="endorsed">
              Contract Holder
            </Tag>
          }
        >
          {(contractHolder.contractVehicle.shortDescription || contractHolder.contractVehicle.description) && (
            <CardBody>
              <DescriptionListField
                label="Description"
                cols={1}
                value={contractHolder.contractVehicle.shortDescription || contractHolder.contractVehicle.description}
              />
            </CardBody>
          )}
          {contractHolder.contractHolderContracts?.map(contract => (
            <CardBody key={contract.id}>
              <dl className="grid grid-cols-2 gap-x-4 gap-y-4">
                <DescriptionListField label="Contract Number" cols={1} value={contract.contractNumber} />
                <DescriptionListField
                  label="Set Aside"
                  cols={1}
                  value={contract.setAsides.map(s => {
                    const t = (
                      <Tag intent="primary" className="mr-2" key={s.code}>
                        {s.code}
                      </Tag>
                    );
                    if (!s.name && !s.description) {
                      return t;
                    }

                    return (
                      <InfoTooltip key={s.code} target={t}>
                        <div className="prose prose-sm">
                          <h3>{s.name}</h3>
                          <p>{s.description}</p>
                        </div>
                      </InfoTooltip>
                    );
                  })}
                />
                <DescriptionListField
                  label="NAICS"
                  cols={1}
                  value={contract.naicsCodes?.map(s => {
                    const t = (
                      <Tag intent="primary" minimal key={s.code}>
                        {s.code}
                      </Tag>
                    );
                    if (!s.title && !s.description) {
                      return t;
                    }

                    return (
                      <InfoTooltip key={s.code} target={t}>
                        <div className="prose prose-sm">
                          <h3>{s.title}</h3>
                          <p>{s.description}</p>
                        </div>
                      </InfoTooltip>
                    );
                  })}
                />
                <DescriptionListField
                  label="Groups"
                  cols={1}
                  value={contract.groupList?.map(g => (
                    <Tag minimal key={g}>
                      {g}
                    </Tag>
                  ))}
                />
              </dl>
            </CardBody>
          ))}
          {contractHolder.contacts && (
            <CardBody padded={false}>
              <ul role="list" className="divide-y divide-gray-200">
                {contractHolder.contacts?.map((contact, i) => (
                  <li key={`contact-${i}`}>
                    <CrmContact contact={contact} authorized />
                  </li>
                ))}
              </ul>
            </CardBody>
          )}
        </Card>
      ))}
    </MainColumn>
  );
};
