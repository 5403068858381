import { useCallback } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { DirectUpload, Blob } from '@rails/activestorage';
import { dangerToast, uploadToast } from 'app/lib/toaster';

const DIRECT_UPLOAD_URL = '/rails/active_storage/direct_uploads';

type onAttachArgs = { name: string; signedId: string; id: string };
type onProgressArgs = { percentComplete: number; filename: string; id: string };
type onErrorArgs = { filename: string; id: string };

const onProgress = async ({ percentComplete, filename, id }: onProgressArgs) => {
  await uploadToast({ key: id, amount: percentComplete, filename });
};

const onError = async ({ filename, id }: onErrorArgs) => {
  await dangerToast(`Failed to upload ${filename}`, id);
};

export type useUploadArgs = {
  uploaderId: string;
  onAttach: ({ name, signedId, id }: onAttachArgs) => Promise<void>;
  onInitialize?: (file: File) => void;
} & Omit<DropzoneOptions, 'onError'>;

type UploadKey = keyof XMLHttpRequestEventTargetEventMap;
export const useUpload = ({ uploaderId, onAttach, onInitialize, ...rest }: useUploadArgs) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach(async (file, index) => {
        onInitialize?.(file);
        const uploadId = `${uploaderId}-${file.name.replace(/\s/g, '-')}-${index}`;
        await onProgress({
          percentComplete: 0,
          filename: file.name,
          id: uploadId
        });

        const upload = new DirectUpload(file, DIRECT_UPLOAD_URL, {
          // 'direct-upload:progress' is an ActiveStorage thing
          directUploadWillCreateBlobWithXHR: xhr => {
            xhr.upload.addEventListener('direct-upload:progress' as UploadKey, async (event: ProgressEvent) => {
              const { loaded, total } = event;
              await onProgress({
                percentComplete: (loaded / total) * 100 + 50,
                filename: file.name,
                id: uploadId
              });
            });
            xhr.upload.addEventListener('progress', async ({ loaded, total }) => {
              await onProgress({
                percentComplete: ((loaded / total) * 100) / 2,
                filename: file.name,
                id: uploadId
              });
            });
          }
        });

        const onCreate = async (error: Error, blob: Blob) => {
          if (error) {
            await onError({ filename: file.name, id: uploadId });
            throw error;
          } else {
            try {
              await onAttach({
                name: file.name,
                signedId: blob.signed_id,
                id: blob.signed_id
              });
              await onProgress({
                percentComplete: 100,
                filename: file.name,
                id: uploadId
              });
            } catch (err) {
              if (typeof err === 'object' && err && 'message' in err && typeof err.message === 'string') {
                dangerToast(err.message, uploadId);
              } else {
                await onError({ filename: file.name, id: uploadId });
              }
              throw err;
            }
          }
        };
        await upload.create(onCreate);
      });
    },
    [onAttach, onInitialize, uploaderId]
  );

  return useDropzone({ onDrop, ...rest });
};
