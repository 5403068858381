import React from 'react';
import { WorkspaceShow } from 'types/__generated__/GovlyApi';
import { EntityTitle, Icon, IconProps, IconSize, Tooltip } from '@blueprintjs/core';
import { match } from 'ts-pattern';

export const OppWorkspaceAttachmentsCard = ({ workspace }: { workspace: WorkspaceShow }) => {
  const { comments = [] } = workspace;

  const attachments = comments.flatMap(comment => comment.attachments ?? []);

  return (
    <dl className="grid grid-cols-2 gap-y-4">
      <div className="flex flex-col gap-y-1 col-span-2">
        {attachments.map(attachment => {
          const parts = attachment.filename.split('.');
          const [extension, ...tail] = parts.reverse();
          const filename = tail.reverse().join('.');

          return (
            <Tooltip key={attachment.id} content={attachment.filename} hoverOpenDelay={1000}>
              <EntityTitle
                titleURL={attachment.url}
                title={filename}
                icon={
                  <Icon
                    size={IconSize.LARGE}
                    {...match<string | undefined, IconProps>(extension)
                      .with('pdf', () => ({ icon: 'document', intent: 'danger' }))
                      .with('doc', 'docx', () => ({ icon: 'paragraph', intent: 'primary' }))
                      .with('csv', 'xlsx', () => ({ icon: 'th', intent: 'success' }))
                      .with('png', 'jpg', 'jpeg', 'gif', 'bmp', 'tiff', 'webp', () => ({
                        icon: 'media',
                        intent: 'warning'
                      }))
                      .otherwise(() => ({ icon: 'document' }))}
                  />
                }
              />
            </Tooltip>
          );
        })}
        <div></div>
      </div>
    </dl>
  );
};
