import React from 'react';
import * as yup from 'yup';

import { RadioGroupStackedCardsInput } from 'app/atoms/inputs/RadioGroupStackedCardsInput/RadioGroupStackedCardsInput';

export const govExperienceSchema = yup.object({
  governmentExperience: yup.string().required('Government Experience is required')
});

export const OnboardingFormInfoGovExperience = () => (
  <RadioGroupStackedCardsInput
    name="governmentExperience"
    cardProps={{ compact: true }}
    options={[
      {
        name: 'We actively sell to the Federal Government.',
        value: 'activeFed'
      },
      {
        name: 'We’ve done some Federal or State and Local deals, but want to do more.',
        value: 'someFed'
      },
      {
        name: 'We’re trying to get started with Government Sales.',
        value: 'noFed'
      },
      { name: 'We’re a Government Agency.', value: 'govAgency' }
    ]}
  />
);
