import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tag, Button, AnchorButton } from '@blueprintjs/core';

import { useCurrentUserAttribute } from 'api/currentUserApi';
import { supportChatMessage } from 'app/lib/support-chat';
import { Card, CardBody } from 'app/atoms/Card/Card';

export const ContractManagementChessCard = () => {
  const navigate = useNavigate();
  const automateSubscribed = useCurrentUserAttribute('subscriptionHasPrimeAutomations');

  return (
    <Card title="CHESS">
      <CardBody>
        <div>
          <div className="flex justify-between">
            <h1 className="mt-0 text-2xl font-bold">CHESS Automate</h1>
            <div>
              <Tag className="mb-4" icon="endorsed" intent={automateSubscribed ? 'success' : 'danger'}>
                {automateSubscribed ? 'Subscribed' : 'Not Subscribed'}
              </Tag>
            </div>
          </div>
          <h3>Effortlessly manage contracts on the CHESS portal.</h3>
          <p>
            Govly CHESS Automate makes it easy to securely analyze contract opportunities and stay on top of your bid
            rate.
          </p>
          {automateSubscribed ? (
            <AnchorButton
              onClick={() =>
                navigate(`/settings/contract_management/chess_profile`, {
                  breadcrumbs: [
                    { to: '/settings/contract_management', text: 'Contract Management' },
                    { text: 'Chess Automate' }
                  ]
                })
              }
              className="mt-4"
              intent="primary"
            >
              View Settings
            </AnchorButton>
          ) : (
            <Button
              onClick={() =>
                supportChatMessage('Hi, I would like to schedule a demo for the Govly CHESS Automate tool.')
              }
              className="mt-4"
              intent="primary"
            >
              Request a Demo
            </Button>
          )}
        </div>
      </CardBody>
    </Card>
  );
};
