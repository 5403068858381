import React, { useMemo } from 'react';
import { Button } from '@blueprintjs/core';

import { CardBody, Card } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { useCreateSysAdminMutation, useListSysAdminsQuery } from 'api/sysAdminUsersApi';
import { Formik, Form } from 'formik';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { createColumnHelper } from '@tanstack/react-table';
import { User } from 'types/__generated__/GovlyApi';
import { hasDataErrors, isFetchBaseQueryError } from 'api/utils';

const columnHelper = createColumnHelper<User>();

export const SysAdminUsers = () => {
  const { data: systemAdmins = [], isLoading } = useListSysAdminsQuery();

  const [createSysAdmin, { isLoading: isCreatingSysAdmin }] = useCreateSysAdminMutation();

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: 'ID',
        sortingFn: 'basic',
        enableColumnFilter: false,
        size: 48
      }),
      columnHelper.accessor('email', {
        header: 'Email',
        sortingFn: 'basic',
        filterFn: 'fuzzyText'
      })
    ],
    []
  );

  if (isLoading) {
    return <Loading type="table" />;
  }

  return (
    <>
      <Card>
        <Formik
          initialValues={{ email: '' }}
          onSubmit={async (values, { resetForm, setErrors }) => {
            try {
              await createSysAdmin(values).unwrap();
              resetForm();
            } catch (e) {
              if (isFetchBaseQueryError(e) && hasDataErrors(e)) {
                const { data } = e;
                if (data.errors) setErrors(data.errors);
              }
            }
          }}
        >
          <Form>
            <CardBody>
              <TextInput name="email" label="Email" />
              <div className="flex flex-row-reverse">
                <Button
                  type="submit"
                  text="Add"
                  intent="primary"
                  loading={isCreatingSysAdmin}
                  disabled={isCreatingSysAdmin}
                />
              </div>
            </CardBody>
          </Form>
        </Formik>
      </Card>

      <GovlyTable trackingObject="sys_admin_users_table" columns={columns} data={systemAdmins} title="System Admins" />
    </>
  );
};
