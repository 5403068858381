import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';

import { ProviderFeedIndex } from 'types/__generated__/GovlyApi';
import { useGetProviderFeedsQuery } from 'api/providerFeedsApi';

import { formatTime } from 'app/lib/dates';

import { CardHeading } from 'app/atoms/Typography/Typography';
import { GovlyTableRowCountTag } from 'app/molecules/GovlyTable/GovlyTableRowCountTag';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';

const columnHelper = createColumnHelper<ProviderFeedIndex>();

export const ProviderFeedsTable = () => {
  const { data = [], isLoading } = useGetProviderFeedsQuery({ all: 'true' });

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: 'ID'
      }),
      columnHelper.accessor('organization.name', {
        header: 'Organization',
        sortingFn: 'basic',
        filterFn: 'fuzzyText'
      }),
      columnHelper.accessor('contractVehicle.contractName', {
        header: 'Contract Name',
        sortingFn: 'basic',
        filterFn: 'fuzzyText'
      }),
      columnHelper.accessor('lastActive', {
        header: 'Last Active',
        sortingFn: 'basic',
        cell: e => <span>{!e.row.original.lastActive ? 'Never Received' : formatTime(e.row.original.lastActive)}</span>
      })
    ],
    []
  );

  return (
    <GovlyTable
      trackingObject="provider_feeds_table"
      columns={columns}
      data={data}
      title={
        <span className="flex gap-2 items-center">
          <CardHeading>Provider Feeds</CardHeading>
          <GovlyTableRowCountTag />
        </span>
      }
      isLoading={isLoading}
      initialState={{
        pagination: {
          pageSize: 50
        },
        sorting: [
          {
            id: 'type',
            desc: false
          }
        ]
      }}
    />
  );
};
