import React from 'react';
import { useParams } from 'react-router-dom';

import UserProfileForm from 'app/organisms/UserProfileForm/UserProfileForm';
import UserNotificationSettings from 'app/organisms/UserProfileNotificationSettings/UserProfileNotificationSettings';
import DeleteUserCard from 'app/organisms/UserProfileDeleteUserCard/UserProfileDeleteUserCard';
import { Breadcrumbs } from 'app/molecules/Breadcrumbs/Breadcrumbs';
import { useGetOrganizationUserQuery } from 'api/organizationUsersApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { AvatarWithName } from 'app/molecules/AvatarWithName/AvatarWithName';
import { NotFound } from 'app/molecules/NotFound/NotFound';
import { NotAuthorized } from 'app/molecules/NotAuthorized/NotAuthorized';
import { useAuthorized } from 'app/hooks/useAuthorize';
import { useCurrentUserAttribute } from 'api/currentUserApi';

export const UserProfileSettings = () => {
  const currentOrgUserId = useCurrentUserAttribute('id');
  const { id } = useParams();

  let {
    data: organizationUser = {},
    isLoading,
    isError
  } = useGetOrganizationUserQuery({ id, view: 'current_org' }, { skip: !id });

  const authorized = useAuthorized({ role: 'admin' }) || id === currentOrgUserId;

  if (!authorized) {
    return <NotAuthorized />;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <NotFound />;
  }

  const { email, name, avatarColor, avatar, initials } = organizationUser;

  return (
    <div className="grid grid-cols-12 gap-x-4 gap-y-8">
      <div className="mx-4 col-span-12">
        <Breadcrumbs />
      </div>
      <div className="col-span-12 mx-4">
        {initials && (
          <AvatarWithName
            initials={initials}
            imgSrc={avatar?.thumbUrl}
            avatarColor={avatarColor}
            id={id}
            email={email}
            name={name}
            size="2xl"
          />
        )}
      </div>
      <div className="col-span-12">
        <UserProfileForm organizationUser={organizationUser} />
      </div>
      <div className="col-span-12">
        <UserNotificationSettings organizationUser={organizationUser} />
      </div>
      <div className="col-span-12">
        <DeleteUserCard organizationUser={organizationUser} />
      </div>
    </div>
  );
};
