import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Button, Tag } from '@blueprintjs/core';

import { useGetVendorQuery, useUpdateVendorMutation, useCreateVendorMutation } from 'api/vendorsApi';
import { RadioGroupInput } from 'app/atoms/inputs/RadioGroupInput/RadioGroupInput';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { TextAreaInput } from 'app/atoms/inputs/TextAreaInput/TextAreaInput';
import { MultiSelectInput } from 'app/atoms/inputs/MultiSelectInput/MultiSelectInput';
import { Card, CardBody, CardFooter } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { errorToast, successToast } from 'app/lib/toaster';
import { SetAsideMultiSelectInput } from 'app/organisms/inputs/SetAsideMultiSelectInput';
import { CertificationMultiSelectInput } from 'app/organisms/inputs/CertificationMultiSelectInput';
import { DynamicTagMultiSelectInput } from 'app/organisms/inputs/DynamicTagMultiSelectInput';
import { LogoUploader } from 'app/molecules/FileUploaders/LogoUploader/LogoUploader';
import { companySizes, estimatedRevenues } from 'app/lib/businessClassifications';

export const VendorSummaryForm = ({ id, organizationId }) => {
  const [tempLogoURL, setTempLogoURL] = useState();
  const navigate = useNavigate();
  const { data, isLoading } = useGetVendorQuery({ id }, { skip: id === 'new' });
  const newRecord = id === 'new';
  const vendor = newRecord ? { active: true, organizationId } : data;

  const [updateVendor, { isLoading: isUpdating }] = useUpdateVendorMutation();
  const [createVendor, { isLoading: isCreating }] = useCreateVendorMutation();

  if (isLoading) {
    return <Loading />;
  }

  const initialValues = {
    id: vendor.id,
    name: vendor.name || '',
    phone: vendor.phone || '',
    primaryWebsite: vendor.primaryWebsite || '',
    duns: vendor.duns || '',
    uei: vendor.uei || '',
    description: vendor.description || '',
    shortDescription: vendor.shortDescription || '',
    industryList: vendor.industryList || [],
    industryGroupList: vendor.industryGroupList || [],
    details: vendor.details || {},
    companyTypeList: vendor.companyTypeList || [],
    serviceList: vendor.serviceList || [],
    coreCompetencyList: vendor.coreCompetencyList || [],
    contractVehicles: vendor.contractVehicleIds || [],
    setAsides: vendor.setAsides?.map(({ id }) => id) || [],
    buyers: vendor.buyerIds || [],
    certifications: (vendor.certifications || []).map(({ name }) => name),
    organizationId: vendor.organizationId,
    logo: vendor.logo
  };

  const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    primaryWebsite: yup.string().url('Website must be a valid URL'),
    phone: yup.string().matches(/^\d{3}-\d{3}-\d{4}$/, 'Phone number must be in the correct format.')
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors }) => {
        try {
          const action = newRecord ? createVendor : updateVendor;
          const { id } = await action(values).unwrap();

          if (newRecord) {
            navigate(`/sys_admin/vendors/${id}`);
          }

          successToast(newRecord ? 'Vendor created.' : 'Vendor updated.');
        } catch (e) {
          const { data } = e;
          errorToast(e);
          if (data.errors) setErrors({ data: data.errors });
        }
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Card
            title={vendor.name || 'New Vendor'}
            rightElement={
              vendor.slug && (
                <a href={`/vendors/${vendor.slug}`} target="_blank" rel="noreferrer">
                  <Tag interactive>{vendor.slug}</Tag>
                </a>
              )
            }
          >
            <CardBody>
              <LogoUploader
                imageUrl={tempLogoURL || (values.logoRemoved ? null : values.logo?.variantLink || values.logo?.link)}
                id="logo"
                className="m-0"
                onRemove={() => {
                  setTempLogoURL(null);
                  setFieldValue('logo', null);
                  setFieldValue('logoRemoved', true);
                }}
                onInitialize={file => {
                  setTempLogoURL(URL.createObjectURL(file));
                }}
                onAttach={attachment => {
                  setFieldValue('logoRemoved', false);
                  setFieldValue('logo', attachment.signedId);
                }}
              />
              <TextInput name="name" label="Name" />
              <TextInput name="phone" label="Phone" helperText="Format: 123-456-7890" />
              <TextInput name="primaryWebsite" label="Website" />
              <TextAreaInput name="shortDescription" label="Short Description" />
              <TextAreaInput name="description" label="Description" />
              <TextInput name="duns" label="DUNS" />
              <TextInput name="uei" label="UEI" />
              <TextInput name="details.hq" label="HQ Address" />
              <RadioGroupInput
                inline
                label="Number of Employees"
                name="details.numberOfEmployees"
                options={companySizes.map(value => ({ value, label: value }))}
              />
              <RadioGroupInput
                inline
                label="Estimated Revenue"
                name="details.estimatedRevenue"
                options={estimatedRevenues.map(value => ({ value, label: value }))}
              />
              <MultiSelectInput
                label="Company Type"
                name="companyTypeList"
                items={['OEM', 'Prime', 'Reseller', 'Distributor', 'Service Provider', 'VAR', 'System Integrator'].map(
                  x => ({
                    value: x,
                    label: x
                  })
                )}
                searchKeys={['label']}
              />
              <SetAsideMultiSelectInput label="Set Asides" name="setAsides" />
              <CertificationMultiSelectInput label="Certifications" name="certifications" />
              <DynamicTagMultiSelectInput context="industries" name="industryList" label="Industries" />
              <DynamicTagMultiSelectInput context="industry_groups" name="industryGroupList" label="Industry Groups" />
              <DynamicTagMultiSelectInput
                context="core_competencies"
                name="coreCompetencyList"
                label="Core Competencies"
              />
              <DynamicTagMultiSelectInput context="services" name="serviceList" label="Services" />
            </CardBody>
            <CardFooter>
              <Button large loading={isUpdating || isCreating} type="submit" intent="primary">
                Save
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

VendorSummaryForm.propTypes = {
  id: PropTypes.string.isRequired,
  organizationId: PropTypes.string
};
