import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tag, Button, AnchorButton } from '@blueprintjs/core';

import { Card, CardBody } from 'app/atoms/Card/Card';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { supportChatMessage } from 'app/lib/support-chat';
import { useGetCurrentOrganizationQuery } from 'api/organizationsApi';

export const ContractManagementGsaAutomateCard = () => {
  const navigate = useNavigate();
  const gsaSubscribed = useCurrentUserAttribute('subscriptionHasPrimeAutomations');

  const { data: currentOrg } = useGetCurrentOrganizationQuery();
  const { feedProfiles: profiles } = currentOrg || [];
  const profile = profiles?.find(p => p.type === 'FeedProfile::Gsa');

  return (
    <Card title="GSA">
      <CardBody>
        <div>
          <div className="flex justify-between">
            <h1 className="mt-0 text-2xl font-bold">GSA Automate</h1>
            <div>
              <Tag className="mb-4" icon="endorsed" intent={gsaSubscribed ? 'success' : 'danger'}>
                {gsaSubscribed ? 'Subscribed' : 'Not Subscribed'}
              </Tag>
            </div>
          </div>
          <h3>Centralize and automate GSA</h3>
          <p>
            Govly GSA Automate allows you to ingest GSA opportunities into Govly (including 2GIT) as well as automate no
            bids.
          </p>
          {gsaSubscribed ? (
            <AnchorButton
              onClick={() =>
                navigate(`/settings/contract_management/gsa_profile`, {
                  breadcrumbs: [
                    { to: '/settings/contract_management', text: 'Contract Management' },
                    { text: 'GSA Automate' }
                  ]
                })
              }
              className="mt-4"
              intent="primary"
            >
              {profile ? 'View Settings' : 'Setup'}
            </AnchorButton>
          ) : (
            <Button
              onClick={() => supportChatMessage('Hi, I would like to learn more about Govly GSA Automate.')}
              className="mt-4"
              intent="primary"
            >
              Learn More
            </Button>
          )}
        </div>
      </CardBody>
    </Card>
  );
};
