import React from 'react';

import { useFuzzyTypeahead, UseFuzzyTypeaheadProps } from 'app/hooks/useFuzzyTypeahead';
import { InputMultiSelect, InputMultiSelectProps } from './InputMultiSelect';

export type FuzzyInputMultiSelectProps<Item> = {
  useTypeaheadProps?: { options?: UseFuzzyTypeaheadProps<Item>['options'] };
} & InputMultiSelectProps<Item>;

/**
 * This component is a wrapper around the InputMultiSelect component.
 * It adds fuzzy search functionality to the InputMultiSelect component.
 */
export function FuzzyInputMultiSelect<Item>({
  items = [],
  useTypeaheadProps = {},
  ...rest
}: FuzzyInputMultiSelectProps<Item>) {
  const { results = [], setQuery, query } = useFuzzyTypeahead({ ...useTypeaheadProps, data: items });

  return <InputMultiSelect {...rest} items={results} onQueryChange={setQuery} query={query} />;
}
