import { PartnershipRequest, PartnershipRequestAdmin } from 'types/__generated__/GovlyApi';
import { rootApi } from './rootApi';

const api = rootApi.enhanceEndpoints({ addTagTypes: ['PartnershipRequest', 'VendorProfile', 'Partnership'] });

type GetPartnershipRequestsIndex = {
  params: {
    isAdmin?: boolean;
  };
  result: PartnershipRequest[] | PartnershipRequestAdmin[];
};

type CreatePartnershipRequest = {
  params: { targetVendorId: 'string' } & Pick<
    PartnershipRequest,
    'targetOrganizationId' | 'requestorEmail' | 'details'
  >;
  result: PartnershipRequest;
};

type UpdatePartnershipRequest = {
  params: { id: string; state: PartnershipRequest['state'] };
  result: PartnershipRequest;
};

type DeletePartnershipRequest = {
  params: {
    id: string;
  };
  result: void;
};

const partnershipRequestApi = api.injectEndpoints({
  endpoints: build => ({
    getPartnershipRequestsIndex: build.query<
      GetPartnershipRequestsIndex['result'],
      GetPartnershipRequestsIndex['params']
    >({
      query: params => ({ url: '/v2/partnership_requests', params }),
      providesTags: ['PartnershipRequest']
    }),

    createPartnershipRequest: build.mutation<CreatePartnershipRequest['result'], CreatePartnershipRequest['params']>({
      query: body => ({
        url: '/v2/partnership_requests',
        method: 'POST',
        body
      }),
      invalidatesTags: result => [
        'PartnershipRequest',
        ...(result?.targetVendor?.slug ? [{ type: 'VendorProfile' as const, id: result.targetVendor.slug }] : [])
      ]
    }),

    updatePartnershipRequest: build.mutation<UpdatePartnershipRequest['result'], UpdatePartnershipRequest['params']>({
      query: ({ id, ...body }) => ({
        url: `/v2/partnership_requests/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['PartnershipRequest', 'Partnership']
    }),

    deletePartnershipRequest: build.mutation<DeletePartnershipRequest['result'], DeletePartnershipRequest['params']>({
      query: ({ id }) => ({
        url: `/v2/partnership_requests/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['PartnershipRequest']
    })
  })
});

export const {
  useCreatePartnershipRequestMutation,
  useDeletePartnershipRequestMutation,
  useGetPartnershipRequestsIndexQuery,
  useUpdatePartnershipRequestMutation
} = partnershipRequestApi;
