import React, { useState } from 'react';
import { Button, Tooltip, ButtonProps } from '@blueprintjs/core';

export type CopyButtonProps = ButtonProps & {
  copyText: string;
  spanClassName?: string;
  instructions?: string;
};

export const CopyButton = ({
  copyText,
  icon = 'clipboard',
  spanClassName,
  instructions = 'Click to copy',
  ...rest
}: CopyButtonProps) => {
  const [tooltipContent, setTooltipContent] = useState(instructions);

  return (
    <Tooltip className={spanClassName} onClose={() => setTooltipContent(instructions)} content={tooltipContent}>
      <Button
        {...rest}
        icon={icon}
        onClick={() => {
          navigator.clipboard.writeText(copyText);
          setTooltipContent('✅ Copied!');
        }}
      />
    </Tooltip>
  );
};
