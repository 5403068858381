import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from '@blueprintjs/core';
import { ColumnChart } from 'react-chartkick';
import { ErrorBoundary } from 'app/atoms/ErrorBoundary/ErrorBoundary';

import { Card, CardBody } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { OppSearchAnalyticsFallback } from 'app/organisms/OppSearchAnalyticsFallback/OppSearchAnalyticsFallback';
import { useGetReportQuery } from 'api/reportsApi';
import { useReportSlices } from 'app/hooks/useReportSlices';
import { CardError } from 'app/atoms/ErrorFallback/CardError';

const chartOptions = {
  xAxis: {}
};

export const OrganizationInsightsFollowed = ({ filters }) => {
  const slices = useReportSlices();
  const [view, setView] = useState('noSlice');
  const { data, isFetching, isError } = useGetReportQuery({
    report_type: 'Report::OrganizationFollowsReport',
    ...filters
  });

  if (isError) {
    return <OppSearchAnalyticsFallback />;
  }

  if (!data || isFetching) {
    return <Loading />;
  }

  const workflowStages = Object.keys(data.series);
  chartOptions.xAxis.categories = workflowStages;
  let chartData = workflowStages.map(stage => [stage, data.series[stage].count]);
  if (view !== 'noSlice') {
    chartData = Object.keys(data[view]).map(key => ({
      name: key,
      data: Object.entries(data[view][key])
    }));
  }

  return (
    <ErrorBoundary action="OrganizationInsightsFollowed" fallback={<CardError title="Workflow Stage Pipeline" />}>
      <Card className="mb-4" title="Workflow Stage Pipeline">
        <CardBody>
          <ColumnChart stacked data={chartData} chartOptions={chartOptions} />
        </CardBody>
        <CardBody className="text-center">
          <ButtonGroup>
            {slices.map(({ key, label }) => (
              <Button key={key} active={view === key} onClick={() => setView(key)} text={label} />
            ))}
          </ButtonGroup>
        </CardBody>
      </Card>
    </ErrorBoundary>
  );
};

OrganizationInsightsFollowed.propTypes = {
  filters: PropTypes.object.isRequired
};
