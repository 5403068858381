import React from 'react';
import { useParams } from 'react-router-dom';

import { useGetOppQuery } from 'api/oppsApi';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { OppWorkspaces } from 'app/organisms/OppWorkspaces/OppWorkspaces';
import { Breadcrumbs } from 'app/molecules/Breadcrumbs/Breadcrumbs';
import { NavSelect } from 'app/molecules/NavSelect/NavSelect';
import { useFeatureFlag } from 'app/hooks/useFeatureFlag';

export const OppDetailsWorkspacesPage = () => {
  const { id } = useParams();
  const { data: { displayName } = {} } = useGetOppQuery({ id: id ?? '' }, { skip: !id });
  const oppShowNavEnabled = useFeatureFlag('opp-show-nav');

  return (
    <>
      {oppShowNavEnabled ? null : (
        <MainColumn className="space-y-2">
          <Breadcrumbs
            items={[
              { text: 'Opportunities', to: '/opportunities' },
              { text: displayName, to: `/opportunities/${id}` },
              { text: 'Workspaces' }
            ]}
          />

          <div className="inline-block">
            <NavSelect
              items={[
                { label: 'Details', value: `opportunities/${id}` },
                { label: 'Workspaces', value: `opportunities/${id}/workspaces` }
              ]}
            />
          </div>
        </MainColumn>
      )}
      <MainColumn columnSpan={12}>
        <OppWorkspaces id={id ?? ''} />
      </MainColumn>
    </>
  );
};
