import React, { useEffect } from 'react';
import { Button, Tooltip, Tag } from '@blueprintjs/core';
import { Formik, Form } from 'formik';

import { ReactTableTemplate } from 'app/templates/ReactTableTemplate/ReactTableTemplate';
import { Loading } from 'app/atoms/Loading/Loading';
import { SelectInput } from 'app/atoms/inputs/SelectInput/SelectInput';
import { successToast, errorToast } from 'app/lib/toaster';
import { useWorklist } from 'app/hooks/useWorklist';
import { WorklistFormDialog } from 'app/organisms/WorklistFormDialog/WorklistFormDialog';
import { ActiveWorklistEmptyState, InactiveWorklistEmptyState } from './WorklistEmptyState';

export const WorklistWorkspaces = () => {
  const {
    workspaces,
    columns,
    updateReward,
    worklistOwnerId,
    bulkUpdateOppWorkspaces,
    setWorkspaceFormAttrs,
    workspaceFormAttrs,
    selectedRows,
    handleRowSelection,
    filterInTransition,
    setFilterTransition,
    setWorkspaceFilter,
    workspacesFetching,
    workspacesLoading,
    isUpdatingWorklistState,
    workspaceFilter,
    hiddenColumns,
    team,
    setWorklistOwnerId,
    teamLoading,
    viewer,
    viewingOwnWorklist,
    onActiveFilter,
    onInactiveFilter
  } = useWorklist();

  const worklistOptions = [
    ...team.map(({ email, id }) => ({
      value: id,
      label: viewer.id === id ? 'My Worklist' : email
    }))
  ];
  worklistOptions.splice(1, 0, { value: 'all', label: 'Team Worklist' });
  useEffect(() => {
    if (workspacesFetching === false) setFilterTransition(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspacesFetching]);

  if (workspacesLoading || (workspacesFetching && filterInTransition)) return <Loading type="table" />;

  const tableHeader = ({ rowCount }) => (
    <div className="p-4 flex items-center space-x-2 border-b border-gray-300 justify-between">
      <div className="flex items-center space-x-2">
        <Formik
          initialValues={{
            orgUserId: worklistOwnerId
          }}
          onSubmit={({ orgUserId }) => {
            setFilterTransition(true);
            setWorklistOwnerId(orgUserId);
          }}
        >
          <Form>
            <SelectInput
              filterable={true}
              searchKeys={['label']}
              loading={teamLoading}
              disabled={teamLoading}
              contentClassName="mt-0"
              name="orgUserId"
              items={worklistOptions}
              submitOnChange
              buttonProps={{
                large: false,
                outlined: true,
                icon: 'person'
              }}
            />
          </Form>
        </Formik>
        <Tag minimal large>
          {rowCount}
        </Tag>
      </div>
      <div className="flex gap-x-2">
        {!selectedRows.length ? (
          <Formik
            initialValues={{ selected: workspaceFilter }}
            onSubmit={({ selected }) => {
              setFilterTransition(true);
              setWorkspaceFilter(selected);
            }}
          >
            <Form>
              <SelectInput
                contentClassName="m-0"
                name="selected"
                items={[
                  { value: 'active', label: 'Active' },
                  { value: 'inactive', label: 'Inactive' }
                ]}
                searchKeys={['label']}
                filterable={false}
                buttonProps={{
                  large: false,
                  outlined: true,
                  icon: 'filter'
                }}
                submitOnChange
              />
            </Form>
          </Formik>
        ) : (
          <div className="flex items-center space-x-2">
            <span className="text-sm text-gray-500">{selectedRows.length} selected</span>
            <Tooltip content={'Remove the selected from your worklist and stop their notifications'}>
              <Button
                outlined
                intent="danger"
                text="Stop Following"
                onClick={async () => {
                  try {
                    await bulkUpdateOppWorkspaces({ unfollow: true, ids: selectedRows });
                    successToast();
                  } catch (e) {
                    errorToast(e);
                  }
                }}
              />
            </Tooltip>
            <Tooltip
              content={
                onInactiveFilter
                  ? 'Move back to your active worklist.'
                  : "You'll still have access in your worklist, under the inactive filter."
              }
            >
              <Button
                icon={onInactiveFilter ? 'undo' : 'tick'}
                intent={onInactiveFilter ? 'warning' : 'success'}
                text={onInactiveFilter ? 'Mark as Active' : 'Check off my list'}
                loading={isUpdatingWorklistState}
                onClick={async () => {
                  try {
                    await bulkUpdateOppWorkspaces({
                      removeFromWorklist: onActiveFilter,
                      ids: selectedRows
                    });

                    if (onActiveFilter) {
                      updateReward();
                    }
                  } catch (e) {
                    errorToast(e);
                  }
                }}
              />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      <ReactTableTemplate
        id="worklist-table"
        data={workspaces}
        columns={columns}
        hiddenColumns={hiddenColumns}
        Header={tableHeader}
        EmptyState={workspaceFilter === 'active' ? ActiveWorklistEmptyState : InactiveWorklistEmptyState}
        onRowSelection={viewingOwnWorklist ? handleRowSelection : null}
        context={viewingOwnWorklist ? { email: viewer.email } : null}
        initialStateProps={{
          pageSize: 50,
          sortBy: [{ id: 'respondBy', asc: workspaceFilter === 'active', desc: workspaceFilter === 'inactive' }]
        }}
      />
      {viewingOwnWorklist && (
        <WorklistFormDialog
          workspaces={workspaces}
          setWorkspaceFormAttrs={setWorkspaceFormAttrs}
          workspaceFormAttrs={workspaceFormAttrs}
        />
      )}
    </>
  );
};
