import React from 'react';
import PropTypes from 'prop-types';
import { Tag, Collapse, Callout } from '@blueprintjs/core';
import { useSearchParams } from 'react-router-dom';

import { cn } from 'app/lib/cn';
import { CopyButton } from 'app/molecules/CopyButton/CopyButton';
import { GoogleCalIcon } from 'images/components/GoogleCalIcon';
import { OutlookIcon } from 'images/components/OutlookIcon';
import { AppleCalIcon } from 'images/components/AppleCalIcon';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';

export const WorklistCalendarSubscription = ({ header, calendarUrl, linksClassName, className }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

  const instructions = searchParams.get('instructions');
  return (
    <div className={cn('flex flex-col gap-2', className)}>
      {header}
      <div className={cn('flex flex-wrap gap-4', linksClassName)}>
        <div className="gap flex items-center">
          <OutlookIcon />
          <LinkTag
            tag="a"
            className="ml-2 text-base font-medium"
            href="#outlookCalendarInstructions"
            onClick={e => {
              e.preventDefault();
              setSearchParams({ instructions: 'outlook' });
            }}
          >
            Outlook
          </LinkTag>
        </div>
        <div className="flex items-center gap-2">
          <GoogleCalIcon />
          <LinkTag
            tag="a"
            className="ml-1 text-base font-medium"
            href="#googleCalendarInstructions"
            onClick={e => {
              e.preventDefault();
              setSearchParams({ instructions: 'gcal' });
            }}
          >
            Google
          </LinkTag>
        </div>
        {isMac && (
          <div className="flex items-center gap-2">
            <AppleCalIcon />
            <LinkTag
              tag="a"
              className="text-base font-medium"
              href="#appleCalendarInstructions"
              onClick={e => {
                e.preventDefault();
                setSearchParams({ instructions: 'apple' });
              }}
            >
              Apple
            </LinkTag>
          </div>
        )}
      </div>

      <Collapse isOpen={instructions}>
        <Callout className="mx-auto max-w-xl text-left">
          {instructions === 'gcal' && (
            <div className="prose prose-sm">
              <h3>How to subscribe using Google Calendar</h3>
              <ol>
                <li>
                  <CopyButton small className="mr-1" icon={null} intent="primary" copyText={calendarUrl}>
                    Click here
                  </CopyButton>
                  to copy the calendar subscription link to your clipboard.
                </li>
                <li>
                  <LinkTag
                    tag="a"
                    className="text-base font-medium"
                    onClick={e => {
                      e.preventDefault();
                      window.open('https://calendar.google.com/calendar/u/0/r/settings/addbyurl', '_blank');
                    }}
                    href="https://calendar.google.com/calendar/u/0/r/settings/addbyurl"
                  >
                    Click this link
                  </LinkTag>{' '}
                  to navigate to the Google Calendar settings page.
                </li>
                <li>
                  Paste the link you copied from above and click{' '}
                  <Tag intent="primary" className="mx-1">
                    Add calendar
                  </Tag>
                </li>
                <li>
                  On the left side, click on the newly created calendar (the name will look like a URL). Change the name
                  to something recognizable.
                </li>
              </ol>
            </div>
          )}

          {instructions === 'outlook' && (
            <div className="prose prose-sm">
              <h3>How to subscribe using Outlook</h3>
              <ol>
                <li>
                  <CopyButton small className="mr-1" icon={null} intent="primary" copyText={calendarUrl}>
                    Click here
                  </CopyButton>
                  to copy the calendar subscription link to your clipboard.
                </li>
                <li>
                  <LinkTag
                    tag="a"
                    className="text-base font-medium"
                    onClick={e => {
                      e.preventDefault();
                      window.open('https://outlook.office365.com/calendar/addcalendar', '_blank');
                    }}
                    href="https://outlook.office365.com/calendar/addcalendar"
                  >
                    Click this link
                  </LinkTag>{' '}
                  to navigate to the Outlook calendar settings page.
                </li>
                <li>
                  Paste the link you copied from above, add a Calendar name of your choice, and click{' '}
                  <Tag intent="primary" className="mx-1">
                    Import
                  </Tag>
                </li>
              </ol>
            </div>
          )}

          {instructions === 'outlook-on-apple' && (
            <div className="prose prose-sm">
              <h3>How to subscribe using Outlook</h3>
              <ol>
                <li>
                  <CopyButton small className="mr-1" icon={null} intent="primary" copyText={calendarUrl}>
                    Click here
                  </CopyButton>
                  to copy the calendar subscription link to your clipboard.
                </li>
                <li>
                  Open your Outlook calendar and click the{' '}
                  <Tag intent="primary" className="mx-1">
                    Add calendar
                  </Tag>{' '}
                  button in the toolbar.
                </li>
                <li>
                  Click{' '}
                  <Tag intent="primary" className="mx-1">
                    Subscribe from web
                  </Tag>
                  in the pop-up and paste the link in the input then click{' '}
                  <Tag intent="primary" className="mx-1">
                    Import
                  </Tag>
                </li>
                <li>You will now see your new calendar in the lefthand toolbar.</li>
              </ol>
            </div>
          )}

          {isMac && instructions === 'apple' && (
            <div className="prose prose-sm">
              <h3>How to subscribe using Apple Calendar</h3>
              <ol>
                <li>
                  <LinkTag tag="a" className="text-base font-medium" href={`webcal://${calendarUrl.split('//')[1]}`}>
                    Click this link
                  </LinkTag>{' '}
                  and your browser will ask you if you would like open the link in Calendar.
                </li>
                <li>
                  Click{' '}
                  <Tag intent="primary" className="mx-1">
                    Open in Calendar
                  </Tag>{' '}
                  and then click{' '}
                  <Tag intent="primary" className="mx-1">
                    Subscribe
                  </Tag>
                </li>
                <li>
                  Change the
                  <Tag intent="primary" className="mx-1">
                    Auto-refresh
                  </Tag>
                  setting to
                  <Tag intent="primary" className="mx-1">
                    Every 5 minutes
                  </Tag>
                </li>
                <li>
                  Click{' '}
                  <Tag intent="primary" className="mx-1">
                    OK
                  </Tag>
                </li>
              </ol>
            </div>
          )}
        </Callout>
      </Collapse>
    </div>
  );
};

WorklistCalendarSubscription.propTypes = {
  header: PropTypes.node,
  className: PropTypes.string,
  linksClassName: PropTypes.string,
  calendarUrl: PropTypes.string.isRequired
};
