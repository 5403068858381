import React, { useMemo } from 'react';
import { Drawer, Button } from '@blueprintjs/core';
import camelCase from 'lodash-es/camelCase';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { useDeviceWidth } from 'app/hooks/useDeviceWidth';
import { successToast } from 'app/lib/toaster';
import { CSVLink } from 'react-csv';
import { ReactTableTemplate, TableColumnHeader } from 'app/templates/ReactTableTemplate/ReactTableTemplate';
import { QuoteShow as Quote } from 'types/__generated__/GovlyApi';

type OppWorkspaceQuoteDrawerProps = {
  quote?: Quote;
  workspaceName: string;
  workableDisplayName: string;
  workableId: string;
  setQuoteToView: (quote?: Quote) => void;
};

type TableData = {
  columns: { Header: string; accessor: string }[];
  rows: { [key: string]: string | number }[];
};

function toCSV({ columns, rows }: TableData) {
  const headerValues = columns.map(column => column.Header);
  const data = [headerValues, ...rows.map(row => columns.map(column => row[column.accessor]))];
  return data;
}

function copyToClipboard(data: TableData) {
  navigator.clipboard.writeText(
    toCSV(data)
      .map(lines => lines.join('\t'))
      .join('\n')
  );

  successToast('Copied to clipboard');
}

export function OppWorkspaceQuoteDrawer({
  quote,
  setQuoteToView,
  workspaceName,
  workableDisplayName,
  workableId
}: OppWorkspaceQuoteDrawerProps) {
  const { isMobile } = useDeviceWidth();

  const getColumns = (lineItemsRaw?: Quote['lineItemsRaw']) => {
    if (!lineItemsRaw) return [];
    const headers = lineItemsRaw[0];

    return headers.map(header => {
      const isDescription = /description/i.test(header.header);

      return {
        accessor: camelCase(header.header),
        Header: header.header,
        HeaderComponent: React.memo(TableColumnHeader),
        className: 'whitespace-nowrap p-4 text-sm font-medium text-gray-700 dark:text-white sm:px-6',
        colStyle: isDescription ? { maxWidth: '300px', minWidth: '200px' } : { maxWidth: '150px' }
      };
    });
  };

  const getData = (lineItemsRaw?: Quote['lineItemsRaw'], lineItemsTotal?: Quote['lineItemsTotal']) => {
    if (!lineItemsRaw) return [];

    const mappedLineItems = lineItemsRaw.map(lineItem => {
      const row: Record<string, string> = {};
      lineItem.forEach(item => {
        row[camelCase(item.header)] = item.value;
      });
      return row;
    });

    if (lineItemsTotal) {
      const totalHeaders = lineItemsRaw[0].slice(-2);

      const totalRow = {
        [camelCase(totalHeaders[0].header)]: 'Total',
        [camelCase(totalHeaders[1].header)]: new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(lineItemsTotal)
      };

      mappedLineItems.push(totalRow);
    }

    return mappedLineItems;
  };

  const data = useMemo(() => {
    return getData(quote?.lineItemsRaw, quote?.lineItemsTotal);
  }, [quote?.lineItemsRaw, quote?.lineItemsTotal]);

  const columns = useMemo(() => {
    return getColumns(quote?.lineItemsRaw);
  }, [quote?.lineItemsRaw]);

  const drawerTitle = (
    <div>
      <LinkTag to={`/opportunities/${workableId}`} target="_blank">
        {workableDisplayName || `Opportunity #${workableId}`}
      </LinkTag>
      <dd>Workspace: {workspaceName}</dd>
    </div>
  );

  const tableTitle = quote?.file ? quote.file.name.replace(/\.[^/.]+$/, '') : '';

  return (
    <Drawer
      isOpen={!!quote}
      onClose={() => setQuoteToView(undefined)}
      size={isMobile ? '100%' : '85%'}
      position="right"
      title={drawerTitle}
      className="overflow-scroll"
    >
      {quote ? (
        <div className="p-2 pt-8 lg:p-12 h-full bg-gray-50">
          <div className="mb-3">
            <div className="flex justify-between">
              <div className="min-w-0 flex-1">
                <h1 className="text-2xl font-bold leading-normal text-gray-700 dark:text-gray-100 line-clamp-2 sm:line-clamp-1 mr-3">
                  {tableTitle}
                </h1>
              </div>
              <div className="flex flex-col gap-y-2 sm:flex-row sm:items-center sm:gap-x-2">
                <Button icon="clipboard" onClick={() => copyToClipboard({ columns, rows: data })}>
                  Copy
                </Button>

                <CSVLink
                  data={toCSV({ columns, rows: data })}
                  filename={`govly-${tableTitle.toLowerCase()}.csv`}
                  className="text-gray-400 no-underline"
                >
                  <Button icon="import">Download</Button>
                </CSVLink>
              </div>
            </div>
            <span className="text-gray-500 dark:text-gray-50">{quote?.lineItemsRaw?.length} line items</span>
          </div>

          <ReactTableTemplate
            /* @ts-expect-error Props Not Typed */
            data={data}
            columns={columns}
            headerType="none"
            title={tableTitle}
            hideCount={true}
            striped={true}
            wrapText={true}
            compact={true}
          />
        </div>
      ) : null}
    </Drawer>
  );
}
