import React from 'react';
import { Icon, Tooltip, AnchorButton, Callout } from '@blueprintjs/core';

import { Card, CardBody } from 'app/atoms/Card/Card';
import { LoadingSpinner } from 'app/atoms/LoadingSpinner/LoadingSpinner';

import { Loading } from 'app/atoms/Loading/Loading';
import { useGetCurrentUserQuery } from 'api/currentUserApi';

export const ManagedFeedInstructions = () => {
  const { data: currentUser, isLoading } = useGetCurrentUserQuery();

  if (isLoading) {
    return <Loading />;
  }

  const {
    organization: { inboundFeedEmail, feedRedirectEmail, feedRedirectEmailStatus }
  } = currentUser;

  const connected = feedRedirectEmailStatus === 'connected';

  return (
    <Card title="Inbound Feed Email">
      <CardBody>
        {feedRedirectEmail && feedRedirectEmailStatus === 'pending' && (
          <Callout intent="primary" icon={null}>
            <div className="flex items-center space-x-2">
              <LoadingSpinner className="mx-0 h-4" />
              <span>Verifying connection</span>
            </div>
          </Callout>
        )}
        {feedRedirectEmail && connected && (
          <Callout>
            <div className="flex items-center space-x-2">
              <span>🎉</span>
              <span>Connection confirmed</span>
            </div>
          </Callout>
        )}
        {feedRedirectEmail && feedRedirectEmailStatus === 'failed' && (
          <Callout>
            <div className="flex items-center space-x-2">
              <span>🚫</span>
              <span>Connection could not be confirmed.</span>
            </div>
          </Callout>
        )}
        <div className="flex items-center justify-between">
          <div className="mr-4">
            <Tooltip content={connected ? '🎉 Connected!' : 'Your inbound email has not been configured.'}>
              <Icon
                className="mr-1"
                icon={connected ? 'tick-circle' : 'issue'}
                intent={connected ? 'success' : 'danger'}
              />
            </Tooltip>
            <strong>{inboundFeedEmail}</strong>
          </div>
          <div>
            <AnchorButton
              large
              className="mr-2"
              href="https://intercom.help/govly/en/articles/6385748-contract-feed-ingestion-guide"
              target="_blank"
              rel="noreferrer"
            >
              Setup Guide
            </AnchorButton>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
