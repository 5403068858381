import React, { useMemo, useState } from 'react';
import { Tag, Dialog, Button } from '@blueprintjs/core';
import { PaginationState, createColumnHelper } from '@tanstack/react-table';

import { WebhookEvent } from 'app/organisms/WebhookEvent/WebhookEvent';
import { CardHeading } from 'app/atoms/Typography/Typography';
import { Loading } from 'app/atoms/Loading/Loading';
import { formatTime, DATETIME_24_SHORT } from 'app/lib/dates';
import { useGetWebhookEventsQuery, useRetryWebhookEventMutation } from 'api/webhooksApi';
import { GovlyTableRowCountTag } from 'app/molecules/GovlyTable/GovlyTableRowCountTag';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { WebhookEvent as WebhookEventType } from 'types/__generated__/GovlyApi';
import { successToast } from 'app/lib/toaster';
import { GovlyTableFilterForm } from 'app/molecules/GovlyTable/GovlyTableFilterForm';

const columnHelper = createColumnHelper<WebhookEventType>();

export const WebhookEvents = ({ webhookId }: Pick<WebhookEventType, 'webhookId'>) => {
  const [webhookEventDialog, setWebhookEventDialog] = useState<WebhookEventType | null>(null);
  const [filter, setFilter] = useState<string>('');
  const [retryWebhookEventMutation, { isLoading: isRetrying }] = useRetryWebhookEventMutation();
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: 20,
    pageIndex: 0
  });

  const {
    data: { results: data, meta } = {},
    isLoading,
    isFetching
  } = useGetWebhookEventsQuery({
    webhookId,
    page: pagination.pageIndex + 1,
    per: pagination.pageSize,
    filter
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', { header: 'ID' }),
      columnHelper.accessor('event', {
        header: 'Event',
        cell: e => (
          <Tag minimal interactive onClick={() => setWebhookEventDialog(e.row.original)}>
            {e.row.original.event}
          </Tag>
        )
      }),
      columnHelper.accessor('payload.id', {
        header: 'Solicitation #',
        cell: e => {
          const event = e.row.original;
          const displayName = e.row.original.event.includes('WORKSPACE')
            ? // @ts-expect-error this field is dynamic
              event.payload.opportunity.displayName
            : event.payload.displayName;

          return displayName;
        }
      }),
      columnHelper.accessor('createdAt', {
        header: 'Created At',
        cell: e => (e.row.original.createdAt ? formatTime(e.row.original.createdAt, DATETIME_24_SHORT) : 'N/A')
      }),
      columnHelper.accessor('response', {
        header: 'Status',
        cell: e => {
          const status = JSON.parse(e.row.original.response)?.code;

          if (!status) {
            return (
              <Tag minimal intent="warning">
                Not Received
              </Tag>
            );
          }

          if (status < 300) {
            return (
              <Tag minimal intent="success">
                {status}
              </Tag>
            );
          }

          return (
            <Tag minimal intent="danger">
              {status}
            </Tag>
          );
        }
      }),
      columnHelper.accessor('updatedAt', {
        header: 'Retry',
        cell: e => (
          <Button
            disabled={isRetrying || isLoading || isFetching}
            icon="refresh"
            intent="primary"
            minimal
            onClick={() => {
              setPagination({ ...pagination, pageIndex: 0 });
              retryWebhookEventMutation({ id: e.row.original.id });
              successToast('Retrying webhook event... The table will update shortly');
            }}
          />
        )
      })
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, data, isRetrying, isFetching]
  );

  if (isLoading) return <Loading type="table" />;

  return (
    <>
      <GovlyTable
        trackingObject="webhook_events_table"
        title={
          <span className="flex gap-2 items-center">
            <CardHeading>Webhook Events</CardHeading>
            <GovlyTableRowCountTag />
            <GovlyTableFilterForm
              setFilter={setFilter}
              filter={filter}
              resetPagination={() => setPagination({ ...pagination, pageIndex: 0 })}
            />
          </span>
        }
        columns={columns}
        data={data ?? []}
        initialState={{
          pagination: {
            pageSize: pagination.pageSize
          }
        }}
        tableProps={{
          manualFiltering: true,
          manualPagination: true,
          enableSorting: false,
          enableColumnFilters: false,
          state: { pagination },
          onPaginationChange: setPagination,
          rowCount: meta?.total
        }}
      />
      <Dialog
        isOpen={!!webhookEventDialog}
        onClose={() => setWebhookEventDialog(null)}
        className="min-w-[65%] p-0"
        lazy
      >
        {webhookEventDialog ? <WebhookEvent webhookEvent={webhookEventDialog} /> : null}
      </Dialog>
    </>
  );
};
