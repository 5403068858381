import React, { useState } from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash-es/sortBy';

import { useGetOppQuery } from 'api/oppsApi';
import { Loading } from 'app/atoms/Loading/Loading';
import { LinkTag } from 'app/atoms/LinkTag/LinkTag';
import { AttachmentList } from 'app/organisms/AttachmentList/AttachmentList';
import {
  DescriptionListColumn,
  DescriptionList,
  DescriptionListLabel
} from 'app/molecules/DescriptionList/DescriptionList';
import { formatTime, DATETIME_24_SHORT } from 'app/lib/dates';
import { openDrawerOrNewTab } from 'app/lib/navigation';
import { NewTabTooltip } from 'app/molecules/NewTabTooltip/NewTabTooltip';
import { OppSourceDrawer } from 'app/organisms/OppSourceDrawer/OppSourceDrawer';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { CardHeadingSmall } from 'app/atoms/Typography/Typography';

export const OppDetailsSources = ({ id }) => {
  const [openDrawer, setOpenDrawer] = useState({});
  const { data: { recordType, oppSources } = {}, isLoading } = useGetOppQuery({ id });
  const disableDownloads = useCurrentUserAttribute('organization.settings.disableOppAttachmentDownloads');

  if (isLoading) {
    return <Loading />;
  }

  if (recordType === 'Prediction') return null;

  return (
    <>
      <div className="divide-y divide-gray-100 space-y-2">
        {sortBy(oppSources, 'posted_at').map(
          ({
            id: sourceId,
            postedAt,
            attachments,
            subject,
            modificationReason,
            remoteUpdateQueuedAt,
            remoteUpdateCompletedAt,
            expectedAttachmentCount
          }) => (
            <DescriptionList type="columns" key={sourceId} id={`${sourceId}`} className="px-4 py-2">
              <LinkTag
                tag="a"
                className="sm:col-span-2"
                onClick={event => {
                  openDrawerOrNewTab(event, `/opportunities/${id}/sources/${sourceId}`, () =>
                    setOpenDrawer({ id, sourceId, subject })
                  );
                }}
              >
                <NewTabTooltip title="View Opp Source">
                  <CardHeadingSmall>{subject}</CardHeadingSmall>
                </NewTabTooltip>
              </LinkTag>
              <DescriptionListColumn label="Posted At" value={formatTime(postedAt, DATETIME_24_SHORT)} />

              {!expectedAttachmentCount && !attachments?.length && (
                <DescriptionListColumn label="Attachments" value="None" />
              )}
              {!!expectedAttachmentCount &&
                !!remoteUpdateQueuedAt &&
                !attachments?.length &&
                !remoteUpdateCompletedAt && (
                  <>
                    <DescriptionListColumn
                      label="Attachments"
                      value="Attachments have been requested from contracting portal."
                    />
                    <DescriptionListColumn
                      label="Attachments Requested At"
                      value={formatTime(remoteUpdateQueuedAt, DATETIME_24_SHORT)}
                    />
                  </>
                )}
              {!!remoteUpdateCompletedAt && !!expectedAttachmentCount && !attachments?.length && (
                <DescriptionListColumn label="Attachments" value="Attachments will be posted in a separate source." />
              )}
              <DescriptionListColumn label="Modification Reason" value={modificationReason} className="sm:col-span-2" />
              {!!attachments?.length && (
                <AttachmentList
                  attachments={attachments}
                  header={<DescriptionListLabel>Attachments</DescriptionListLabel>}
                  downloadAllLink={`/api/v2/opp_sources/${sourceId}/zip`}
                  disableDownloads={disableDownloads}
                  className="sm:col-span-2"
                />
              )}
            </DescriptionList>
          )
        )}
      </div>
      {Object.keys(openDrawer).length > 0 && <OppSourceDrawer {...openDrawer} onClose={() => setOpenDrawer({})} />}
    </>
  );
};

OppDetailsSources.propTypes = {
  id: PropTypes.string.isRequired
};
