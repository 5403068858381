import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Button, FormGroup, Icon } from '@blueprintjs/core';

import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { useGetOrganizationUsersQuery } from 'api/organizationUsersApi';
import { useGetOppSearchesQuery, useCreateOppSearchMutation } from 'api/oppSearchesApi';
import { Card, CardFooter, CardBody } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { TextInput } from 'app/atoms/inputs/TextInput/TextInput';
import { SwitchInput } from 'app/atoms/inputs/SwitchInput/SwitchInput';
import { LabelSpan } from 'app/atoms/inputs/LabelSpan/LabelSpan';
import { successToast } from 'app/lib/toaster';
import { FollowerSelectInput } from 'app/organisms/FollowerSelectInput/FollowerSelectInput';
import { SavedSearchVolumeCallout } from 'app/organisms/SavedSearchVolumeCallout/SavedSearchVolumeCallout';
import { useEventTracking } from 'app/hooks/useEventTracking';

export const OppSavedSearchCreateForm = ({ templateSavedSearchId, onCancel, handleClose, ...rest }) => {
  const { values: searchFormValues, setValues } = useFormikContext();
  const { query, filters } = searchFormValues;
  const { trackEvent } = useEventTracking();
  const { templateSavedSearch, isLoading: savedSearchesLoading } = useGetOppSearchesQuery(
    { active: true },
    {
      selectFromResult: ({ data }) => ({
        templateSavedSearch: data?.find(({ id }) => id === templateSavedSearchId)
      })
    }
  );

  const { data: organizationUsers = [], isLoading: organizationUsersLoading } = useGetOrganizationUsersQuery({
    view: 'current_org'
  });

  const { data: currentUser } = useGetCurrentUserQuery();
  const [createSavedSearch, { isLoading: isCreating }] = useCreateOppSearchMutation();

  const form = {
    onSubmit: async (createValues, { setErrors }) => {
      try {
        const { follows = [], ...rest } = createValues;
        const followsAttributes = follows.map(follow => ({
          id: follow.id,
          state: follow.state,
          notifications: follow.notifications,
          organizationUserId: follow.organizationUserId
        }));

        const createdSavedSearch = await createSavedSearch({ ...rest, followsAttributes }).unwrap();
        const { query, setAsDefault, name } = createValues;

        trackEvent({
          object: 'opp_search',
          action: 'created',
          properties: {
            ...query,
            setAsDefault,
            name,
            follows: follows?.map(f => f?.organizationUser?.email)
          }
        });

        const savedSearchState = {
          query: searchFormValues.query,
          filters: searchFormValues.filters,
          meta: {
            ...searchFormValues.meta,
            savedSearchId: createdSavedSearch.id
          }
        };

        setValues(savedSearchState);

        successToast();
        handleClose();
      } catch (e) {
        const { data: { errors = {} } = {} } = e;
        setErrors(errors);
      }
    },
    initialValues: {
      query: { query, ...filters },
      name: templateSavedSearch?.name ? `${templateSavedSearch.name} (copy)` : '',
      setAsDefault: false,
      follows: [
        {
          organizationUser: currentUser,
          organizationUserId: currentUser.id,
          notifications: 'user_setting',
          state: 'following'
        }
      ]
    },
    validationSchema: Yup.object({
      name: Yup.string().max(80, 'Must be 80 characters or less').required('Please give a name to your saved search')
    })
  };

  if (savedSearchesLoading || organizationUsersLoading) {
    return <Loading />;
  }

  return (
    <div {...rest}>
      <Formik {...form}>
        <Form>
          <Card>
            <CardBody>
              <SavedSearchVolumeCallout />
              <TextInput
                name="name"
                label="Saved Search Name"
                inputProps={{ placeholder: 'My Saved Search', large: true }}
                data-test="saved-search-name-input"
              />
              <FormGroup
                label={<LabelSpan label="Default Search" />}
                className="m-0"
                helperText={
                  <span className="flex items-center">
                    <Icon icon="info-sign" iconSize={10} className="mr-1" />
                    Default searches are preloaded on your dashboard when you visit Govly.
                  </span>
                }
              >
                <SwitchInput className="m-0" name="setAsDefault" label="Set as your default search?" />
              </FormGroup>
              <FollowerSelectInput
                name="follows"
                label="Followers"
                defaultButtonText="Add a Teammate"
                organizationUsers={organizationUsers}
                disabled={isCreating}
              />
            </CardBody>
            <CardFooter>
              <Button
                large
                type="submit"
                text="Save"
                intent="primary"
                loading={isCreating}
                data-test="saved-search-form-save"
              />
              {onCancel && <Button text="Cancel" disabled={isCreating} onClick={onCancel} />}
            </CardFooter>
          </Card>
        </Form>
      </Formik>
    </div>
  );
};

OppSavedSearchCreateForm.propTypes = {
  templateSavedSearchId: PropTypes.string,
  onCancel: PropTypes.func,
  handleClose: PropTypes.func
};
