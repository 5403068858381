import { Table } from '@tanstack/react-table';
import React, { createContext, useContext } from 'react';

type Context<Data = unknown> = {
  /** Used for tracking events */
  trackingObject: Lowercase<string>;
  table: Table<Data>;
  isLoading: boolean;
  hasConfigurablePageSize: boolean;
  paginationSteps: number[];
  isFixedLayout: boolean;
  defaultColumnSize: number;
};

export type { Context as TGovlyTableContext };

export const GovlyTableContext = createContext<Context | undefined>(undefined);

export function useGovlyTableContext<Data>() {
  const context = useContext(GovlyTableContext) as Context<Data> | undefined;
  if (!context) {
    throw new Error('useGovlyTableContext must be used within a GovlyTableProvider');
  }

  return context as Context<Data>;
}

const DEFAULT_PAGINATION_STEPS = [5, 10, 20, 30, 50, 100];

type Props<Data> = React.PropsWithChildren<
  { table: Table<Data> } & Omit<Partial<Context<Data>>, 'trackingObject'> & Pick<Context<Data>, 'trackingObject'>
>;

export type { Props as GovlyTableProviderProps };

export function GovlyTableProvider<Data>({
  trackingObject,
  table,
  isLoading = false,
  hasConfigurablePageSize = false,
  paginationSteps = DEFAULT_PAGINATION_STEPS,
  isFixedLayout = false,
  defaultColumnSize = 160,
  children
}: Props<Data>) {
  return (
    <GovlyTableContext.Provider
      value={{
        table: table as Table<unknown>,
        isLoading,
        hasConfigurablePageSize,
        paginationSteps,
        trackingObject,
        isFixedLayout,
        defaultColumnSize
      }}
    >
      {children}
    </GovlyTableContext.Provider>
  );
}
