import React, { useMemo, useRef, useEffect, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { Button, ButtonGroup, Popover, Menu, MenuItem, Tooltip } from '@blueprintjs/core';
import { ErrorBoundary } from 'app/atoms/ErrorBoundary/ErrorBoundary';
import { Card, CardSection } from 'app/atoms/Card/Card';
import { Loading } from 'app/atoms/Loading/Loading';
import { useLazyAwardAggregationQuery } from 'api/awardsApi';
import { CardError } from 'app/atoms/ErrorFallback/CardError';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src.js';
import 'highcharts/es-modules/masters/modules/drilldown.src';
import { HighchartsReact } from 'highcharts-react-official';
import { AwardSearchFilters, AwardSearchForm } from 'app/hooks/search/useAwardSearchCache';
import { P, match } from 'ts-pattern';
import { AwardSearchAnalyticsTableDrawer } from 'app/organisms/AwardSearchAnalytics/AwardSearchAnalyticsTableDrawer';
import { capitalize } from 'app/lib/strings';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { create } from 'zustand';
import snakeCase from 'lodash-es/snakeCase';
import truncate from 'lodash-es/truncate';
import { DRILLDOWN, CHANGE_VIEW, EVENT_OBJECT } from './AwardSearchAnalyticsEntitiesEvents';
import { AwardSearchAnalyticsFallback } from './AwardSearchAnalyticsFallback';

type AggBucket = {
  key: string;
  docCount: number;
  subAgg: Agg;
};

type Agg = {
  buckets: AggBucket[];
};

type AggregationQueryResponse = {
  aggs: {
    awardingAgencyNamesAgg?: Agg;
    fundingAgencyNamesAgg?: Agg;
    recipientNamesAgg?: Agg;
    trendingAwardingAgencyNamesAgg?: Agg;
    trendingFundingAgencyNamesAgg?: Agg;
    trendingRecipientNamesAgg?: Agg;
  };
};

type Aggregations = 'awardingAgencyNamesAgg' | 'fundingAgencyNamesAgg' | 'recipientNamesAgg';

const SLICES: Array<{ key: Aggregations; label: string }> = [
  { key: 'awardingAgencyNamesAgg', label: 'Awarding Agency' },
  { key: 'fundingAgencyNamesAgg', label: 'Funding Agency' },
  { key: 'recipientNamesAgg', label: 'Recipients' }
];

export const AwardSearchAnalyticsEntities = ({ searchIsLoading }: { searchIsLoading: boolean }) => {
  const { trackEvent } = useEventTracking();

  const { values } = useFormikContext<AwardSearchForm>();
  const { query, filters } = values;

  const { sliceType, setSliceType, view, setView, subAgencyName, setSubAgencyName } = useStore();
  const chartEventProperties = useStore(chartEventPropertiesSelector);
  const { chartTitle } = chartEventProperties;
  const chartComponentRef = useRef<HighchartsReact.RefObject | null>(null);

  const [getAwardAggregation, { data = {}, isLoading, isError, isUninitialized, isFetching }] =
    useLazyAwardAggregationQuery();

  useEffect(() => {
    if (searchIsLoading) {
      return;
    }

    const snakeCaseAgg = snakeCase(sliceType);

    getAwardAggregation(
      {
        query,
        ...filters,
        size: 10,
        subAggSize: 10,
        aggs: [view === 'trending' ? `trending_${snakeCaseAgg}` : snakeCaseAgg]
      },
      true /* prefer cache value */
    ).then(() => {
      resetChart();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAwardAggregation, searchIsLoading, sliceType, view]);

  const sliceTypeKey = view === 'top' ? sliceType : (`trending${capitalize(sliceType)}` as const);

  /**
   * This is some "duct tape".
   * For some reason the highcharts auto-margin for y axis labels is slightly off for longer names.
   * This is used to redraw the chart after it has been rendered to fix the margin.
   */
  const redrawChart = useCallback(() => {
    setTimeout(() => {
      const chart = chartComponentRef.current?.chart;
      chart?.redraw();
    });
  }, []);

  const resetChart = useCallback(() => {
    setSubAgencyName(undefined);
    const chart = chartComponentRef.current?.chart as Highcharts.Chart;
    drillUpRecursive(chart);
  }, [setSubAgencyName]);

  const options: Highcharts.Options = useMemo(() => {
    const agg = (data as AggregationQueryResponse).aggs?.[sliceTypeKey];
    const extractedSeriesData = extractSeriesData(
      agg,
      sliceType === 'recipientNamesAgg' ? { drilldown: LEAF_DRILLDOWN } : {}
    );
    const extractedDrilldownSeriesData = extractDrilldownSeriesData(agg);

    return {
      title: {
        text: undefined
      },
      tooltip: {
        pointFormat: '<span style="color: {point.color}">\u25CF</span><b>{point.y}</b> awards'
      },
      chart: {
        type: 'bar',
        events: {
          drilldown: function (e) {
            trackEvent({
              object: EVENT_OBJECT,
              action: DRILLDOWN,
              properties: { ...chartEventPropertiesSelector(useStore.getState()), point: e.point.name }
            });
            if (isLeafDrilldown(e)) {
              e.preventDefault();
              setSubAgencyName(e.point.name);
            } else {
              setSubAgencyName(undefined);
            }
            redrawChart();
          },
          drillup: function () {
            trackEvent({
              object: EVENT_OBJECT,
              action: 'drillup',
              properties: { ...chartEventPropertiesSelector(useStore.getState()) }
            });
            redrawChart();
          }
        },
        zooming: {
          type: 'xy'
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        bar: {
          colorByPoint: true
        },
        column: {
          colorByPoint: true
        }
      },
      series: extractedSeriesData,
      xAxis: {
        type: 'category',
        events: {
          afterSetExtremes: function () {
            trackEvent({
              object: EVENT_OBJECT,
              action: 'zoom',
              properties: { ...chartEventPropertiesSelector(useStore.getState()), axis: 'x' }
            });
          }
        }
      },
      yAxis: {
        title: { text: undefined },
        events: {
          afterSetExtremes: function () {
            trackEvent({
              object: EVENT_OBJECT,
              action: 'zoom',
              properties: { ...chartEventPropertiesSelector(useStore.getState()), axis: 'y' }
            });
          }
        }
      },
      drilldown: {
        series: extractedDrilldownSeriesData,
        breadcrumbs: {
          formatter: function (e) {
            const breadcrumb = e as unknown as Highcharts.BreadcrumbOptions;
            return breadcrumb.levelOptions.name === 'Awards' ? '← Back' : String(breadcrumb.levelOptions.name);
          }
        }
      }
    };
  }, [data, redrawChart, setSubAgencyName, sliceType, sliceTypeKey, trackEvent]);

  const drilldownFilters = subAgencyName
    ? match<{ subAgencyName: string; sliceType: string }, Partial<AwardSearchFilters>>({
        subAgencyName,
        sliceType
      })
        .with({ sliceType: 'awardingAgencyNamesAgg' }, ({ subAgencyName }) => ({
          awardingAgencyNames: { any: [subAgencyName], none: [] }
        }))
        .with({ sliceType: 'fundingAgencyNamesAgg' }, ({ subAgencyName }) => ({
          fundingAgencyNames: { any: [subAgencyName], none: [] }
        }))
        .with({ sliceType: 'recipientNamesAgg' }, () => ({
          recipientNames: { any: [subAgencyName], none: [] }
        }))
        .otherwise(() => ({}))
    : undefined;

  return match({ isLoading, isError, isUninitialized, isFetching })
    .with({ isLoading: true }, { isUninitialized: true }, { isFetching: true }, () => <Loading />)
    .with({ isError: true }, () => <AwardSearchAnalyticsFallback />)
    .otherwise(() => (
      <ErrorBoundary action="AwardSearchAnalyticsEntities" fallback={<CardError title={chartTitle} />}>
        <Card
          className="mb-4"
          title={chartTitle}
          aria-label={`${chartTitle} ${sliceType}`}
          rightElement={
            <Popover
              placement="bottom-start"
              minimal
              modifiers={{ offset: { enabled: true } }}
              content={
                <Menu>
                  {SLICES.map(({ key, label }) => (
                    <MenuItem
                      key={key}
                      active={sliceType === key}
                      onClick={() => {
                        setSliceType(key);
                        trackEvent({ object: EVENT_OBJECT, action: 'change_slice', properties: chartEventProperties });
                        resetChart();
                      }}
                      text={label}
                    />
                  ))}
                </Menu>
              }
            >
              <Button rightIcon="chevron-down">{SLICES.find(agg => agg.key === sliceType)?.label ?? 'View'}</Button>
            </Popover>
          }
        >
          <CardSection>
            <HighchartsReact
              key={String(searchIsLoading)}
              ref={node => {
                chartComponentRef.current = node;
                redrawChart();
              }}
              highcharts={Highcharts}
              options={options}
            />
          </CardSection>

          <CardSection className="text-center">
            <ButtonGroup>
              <Button
                active={view === 'top'}
                onClick={() => {
                  trackEvent({
                    object: EVENT_OBJECT,
                    action: CHANGE_VIEW,
                    properties: { ...chartEventProperties, view: 'top' }
                  });
                  setView('top');
                  resetChart();
                }}
                text={sliceType === 'recipientNamesAgg' ? 'Top Recipients' : 'Top Agencies'}
              />
              <Button
                active={view === 'trending'}
                onClick={() => {
                  trackEvent({
                    object: EVENT_OBJECT,
                    action: CHANGE_VIEW,
                    properties: { ...chartEventProperties, view: 'trending' }
                  });
                  setView('trending');
                  resetChart();
                }}
                text={sliceType === 'recipientNamesAgg' ? 'Trending Recipients' : 'Trending Agencies'}
              />
            </ButtonGroup>
          </CardSection>
        </Card>

        <AwardSearchAnalyticsTableDrawer
          key={subAgencyName}
          trackingObject={`${EVENT_OBJECT}_table`}
          isOpen={Boolean(subAgencyName)}
          drawerTitle={
            <Tooltip content={subAgencyName} disabled={(subAgencyName?.length ?? 0) <= 50}>
              {`${truncate(subAgencyName, { length: 50 })} Awards`}
            </Tooltip>
          }
          columnVisibility={{
            awardingAgencyName: false,
            fundingAgencyName: false
          }}
          onClose={() => {
            trackEvent({
              object: EVENT_OBJECT,
              action: 'close_table_drawer',
              properties: chartEventProperties
            });
            setSubAgencyName(undefined);
          }}
          filters={drilldownFilters}
        />
      </ErrorBoundary>
    ));
};

const extractSeriesData = (
  agg?: Agg,
  options: Partial<Highcharts.PointOptionsObject> = {}
): Highcharts.SeriesBarOptions[] => {
  return [
    {
      type: 'bar',
      name: 'Awards',
      data:
        agg?.buckets.map((bucket): Highcharts.PointOptionsObject => {
          const { key, docCount, subAgg } = bucket;
          return { ...options, name: key, y: docCount, ...(subAgg ? { drilldown: key } : {}) };
        }) ?? []
    }
  ];
};

const extractDrilldownSeriesData = (agg?: Agg): Highcharts.SeriesOptionsType[] => {
  if (!agg) {
    return [];
  }

  return agg.buckets.reduce((acc, bucket) => {
    const { key, subAgg } = bucket;
    if (subAgg) {
      const { buckets } = subAgg;
      acc.push({
        type: 'bar',
        id: key,
        name: 'Awards',
        data: buckets.map(({ key, docCount, subAgg }) => ({
          name: key,
          y: docCount,
          drilldown: subAgg ? key : LEAF_DRILLDOWN
        }))
      });
      acc.push(...extractDrilldownSeriesData(subAgg));
    }
    return acc;
  }, [] as Highcharts.SeriesOptionsType[]);
};

type Store = {
  sliceType: Aggregations;
  setSliceType: (sliceType: Aggregations) => void;
  view: 'top' | 'trending';
  setView: (view: 'top' | 'trending') => void;
  subAgencyName: string | undefined;
  setSubAgencyName: (subAgencyName: string | undefined) => void;
};

const useStore = create<Store>(set => {
  return {
    sliceType: 'awardingAgencyNamesAgg',
    setSliceType: sliceType => set({ sliceType }),
    view: 'top',
    setView: view => set({ view }),
    subAgencyName: undefined,
    setSubAgencyName: subAgencyName => set({ subAgencyName })
  };
});

const chartEventPropertiesSelector = (store: Store) => ({
  chartTitle: match(store)
    .with({ sliceType: P.union('awardingAgencyNamesAgg', 'fundingAgencyNamesAgg'), view: 'top' }, () => 'Top Agencies')
    .with({ sliceType: 'recipientNamesAgg', view: 'top' }, () => 'Top Recipients')
    .with(
      { sliceType: P.union('awardingAgencyNamesAgg', 'fundingAgencyNamesAgg'), view: 'trending' },
      () => 'Trending Agencies'
    )
    .with({ sliceType: 'recipientNamesAgg', view: 'top' }, () => 'Top Recipients')
    .with({ sliceType: 'recipientNamesAgg', view: 'trending' }, () => 'Trending Recipients')
    .exhaustive(),
  slice: store.sliceType,
  view: store.view,
  subAgencyName: store.subAgencyName
});

const LEAF_DRILLDOWN = '__LEAF__';

const isLeafDrilldown = (e: Highcharts.DrilldownEventObject): boolean => {
  return (e.point as unknown as { drilldown: string }).drilldown === LEAF_DRILLDOWN;
};

// @ts-expect-error drillUp is not in the types, but provided/extended by drilldown module
const drillUpRecursive = (chart?: Highcharts.Chart, levels: number = chart?.drilldownLevels?.length ?? 0) => {
  if (levels === 0 || !chart) return;
  chart.drillUp();
  drillUpRecursive(chart, levels - 1);
};
